import React, { useEffect, useState } from "react";
import "./styles.css";
import Metricas from "./components/Metricas/Metricas";
import Respostas from "./components/Respostas/Respostas";
import { Collapse } from "antd";
import { AnaliseTypes } from "../../../../../@types/analise";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import { useAuth0 } from "@auth0/auth0-react";
import api from "../../../../../services/api";

const Analise: React.FC<AnaliseTypes> = ({ questId, respostas }) => {
  const [activeCat, setActiveCat] = useState<any>(
    "Informações gerais da empresa"
  );

  const itemsCollapse = [
    {
      key: "1",
      label: "Métricas",
      children: <Metricas respostas={respostas} />,
    },
    {
      key: "2",
      label: "Respostas",
      children: (
        <Respostas
          activeCap={activeCat}
          questId={questId}
          respostas={respostas}
        />
      ),
    },
  ];

  const categorias = [
    "Informações gerais da empresa",
    "Encarregado de Proteção de dados (DPO)",
    "Identificação dos tratamentos de dados pessoais",
    "Conformidade à Privacidade e Proteção de dados (LGPD)",
    "Atendimento aos direitos do titular de dados",
    "Tecnologia da Informação",
    "Segurança da Informação",
    "Cultura de Segurança da informação",
    "Histórico de incidentes de segurança envolvendo dados pessoais",
    "Treinamento e conscientização",
    "Gerenciamento de Banco de dados",
    "Transferência internacional de dados",
  ];

  return (
    <div className="analise">
      <div className="analise__indice">
        <p className="analise__indice--title">Índice</p>
        {categorias.map((cat: any) => {
          return (
            <p
              key={cat}
              className={`${
                activeCat === cat
                  ? "analise__indice--cap__active"
                  : "analise__indice--cap"
              }`}
              onClick={() => setActiveCat(cat)}
            >
              {cat}
            </p>
          );
        })}
      </div>
      <div className="analise__content">
        <Collapse items={itemsCollapse} defaultActiveKey={["1", "2"]} />
      </div>
    </div>
  );
};

export default Analise;
