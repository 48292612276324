import React, { useEffect, useState } from "react";
import "./styles.css";

import { GlobalContextType } from "../../../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../../../context/globalContext";
import { Button, Form, Input, Timeline } from "antd";
import TimelineItem from "../../../../../../../../../components/TimelineItem/TimelineItem";
import { SendOutlined } from "@ant-design/icons";
import { AnaliseRespostasTypes } from "../../../../../../../../../@types/analise";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
const { TextArea } = Input;

const PlanoAcao: React.FC<AnaliseRespostasTypes> = ({ activeCap, questId }) => {
  const { currentAnalise, postPlanoAcao } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [form] = Form.useForm();
  const [newPlano, setNewPlano] = useState<any>("");

  const handleSend = (v: any) => {
    postPlanoAcao({
      description: newPlano,
      questionario_capitulos_id: activeCap.questionario_capitulos_id,
      quest_id: questId,
    });
    form.resetFields();
  };
  return (
    <div className="planoAcao">
      <h2>Plano de ação</h2>

      {currentAnalise.planoAcao && (
        <>
          <div className="planoAcao__timeline">
            <Timeline
              items={currentAnalise.planoAcao.map((pl: any) => {
                return {
                  children: <TimelineItem planoAcao={pl} lastIndex={false} />,
                };
              })}
            />
          </div>
          {currentAnalise.planoAcao[0] && (
            <>
              <h3>Ultima atualização</h3>

              <div
                dangerouslySetInnerHTML={{
                  __html:
                    currentAnalise.planoAcao[
                      currentAnalise.planoAcao.length - 1
                    ].item.value,
                }}
                style={{ margin: "0 2rem" }}
              />
            </>
          )}

          <Form className="planoAcao__form" onFinish={handleSend} form={form}>
            <Form.Item name={"description"}>
              <div>
                <ReactQuill
                  placeholder="Escreva a recomendação"
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                  ]}
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link", "image"],
                      ["clean"],
                    ],
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  onChange={(v: any) => setNewPlano(v)}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <Button
                className="planoAcao__btn"
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
              >
                Enviar
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default PlanoAcao;
