import React, { useEffect, useState } from "react";
import {
  Upload,
  Button,
  Table,
  Collapse,
  Empty,
  Tag,
  Select,
  Alert,
} from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useParams } from "react-router-dom";
import moment from "moment";

const OWASP = () => {
  const {
    postAnexoOwasp,
    getAnexosOwasp,
    anexosOwasp,
    userInfo,
    clientes,
    usuarios,
    currentCliente,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const params = useParams();

  const [importedData, setImportedData] = useState<any>(null);
  const [currentRelatorio, setCurrentRelatorio] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  useEffect(() => {
    (params.id || currentCliente) &&
      getAnexosOwasp(params.id || currentCliente._id);

    !params.id &&
      clientes &&
      clientes[0] &&
      clientes.length === 1 &&
      getAnexosOwasp(clientes[0]._id);
  }, [params.id, clientes, currentCliente]);

  const handleImport = async (file: any) => {
    try {
      setFile(file);
      const fileContent: any = await readFile(file);
      const jsonData = JSON.parse(fileContent);
      setImportedData(jsonData);
    } catch (error) {
      console.error("Erro ao processar o arquivo JSON:", error);
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };

      reader.onerror = (e: any) => {
        reject(e.target.error);
      };

      reader.readAsText(file);
    });
  };

  const columns = [
    {
      title: "Nome do program",
      dataIndex: "@programName",
      key: "@programName",
      render: (text: string) => text,
    },
    {
      title: "Versão",
      dataIndex: "@version",
      key: "@version",
      render: (text: string) => text,
    },
    {
      title: "Data geração",
      dataIndex: "@generated",
      key: "@generated",
      render: (text: string) => text,
    },
  ];
  const downloadAndReadFile = async (url: string) => {
    setCurrentRelatorio(null);
    try {
      // Fazer o Download do Arquivo
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Erro ao baixar o arquivo");
      }

      // Ler o Conteúdo do Arquivo como ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Converter ArrayBuffer para JSON
      const jsonString = new TextDecoder().decode(arrayBuffer);
      const jsonData = JSON.parse(jsonString);

      setCurrentRelatorio(jsonData);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  return (
    <div className="owasp">
      {userInfo?.grupos?.find(
        (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
      ) &&
        currentCliente && (
          <>
            <h3>Dados da empresa</h3>
            <hr />
            <Table
              size="small"
              style={{ marginTop: "1rem", marginBottom: "2rem" }}
              dataSource={[
                {
                  razao_social: currentCliente.razao_social,
                  representante: currentCliente.representante,
                  email: currentCliente.email,
                  telefone: currentCliente.telefone,
                },
              ]}
              pagination={false}
              columns={[
                {
                  title: "Razão social",
                  dataIndex: "razao_social",
                  key: "razao_social",
                },
                {
                  title: "E-mail",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Telefone",
                  dataIndex: "telefone",
                  key: "telefone",
                },
                {
                  title: "Responsável legal",
                  dataIndex: "representante",
                  key: "representante",
                },
              ]}
            />
            <h3>Criar novo relatório</h3>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Upload
                showUploadList={false}
                beforeUpload={(file) => handleImport(file)}
                accept=".json"
              >
                <Button
                  style={{
                    marginLeft: "-1.9rem",
                  }}
                  icon={<UploadOutlined style={{ color: "orange" }} />}
                >
                  Enviar JSON
                </Button>
              </Upload>

              {importedData && (
                <div>
                  <Select
                    onChange={(v) => setSelectedUser(v)}
                    placeholder="Selecione um encarregado"
                    options={usuarios
                      ?.filter(
                        (usr: any) => usr.cliente_id === currentCliente?._id
                      )
                      ?.map((user: any) => {
                        return {
                          label: user.name,
                          value: user._id,
                        };
                      })}
                    style={{ marginRight: "1rem", width: 400 }}
                  />
                  <Button
                    type="primary"
                    disabled={selectedUser ? false : true}
                    onClick={() => {
                      (params.id || currentCliente?._id) &&
                        postAnexoOwasp(
                          file,
                          params.id || currentCliente._id,
                          selectedUser
                        ).finally(() => setImportedData(null));
                      setSelectedUser(null);
                    }}
                    icon={<SaveOutlined />}
                  >
                    Salvar relatório
                  </Button>
                </div>
              )}
            </div>

            {importedData && (
              <div style={{ marginBottom: "1rem" }}>
                <h4 style={{ marginBottom: "1rem" }}>Dados Importados:</h4>
                <Table
                  style={{ marginBottom: "1rem" }}
                  pagination={false}
                  dataSource={[
                    {
                      "@programName": importedData["@programName"],
                      "@version": importedData["@version"],
                      "@generated": importedData["@generated"],
                    },
                  ]}
                  columns={columns}
                />

                <Collapse
                  items={importedData.site.map((site: any, index: number) => {
                    return {
                      key: index + 1,
                      label: `${site["@name"]} :${site["@port"]}`,
                      extra: site.alerts[0] ? (
                        <ExclamationCircleOutlined
                          style={{ color: "#fc0303" }}
                        />
                      ) : (
                        <CheckCircleOutlined style={{ color: "#22c900" }} />
                      ),
                      children: site.alerts[0] ? (
                        site.alerts.map((alerta: any, index2: number) => (
                          <Collapse
                            items={[
                              {
                                key: index2 + 1 + alerta.name,
                                label: `${alerta.name}`,
                                extra: (
                                  <p
                                    style={{
                                      color: alerta.riskdesc.includes(
                                        "Informativo"
                                      )
                                        ? "#0054d1"
                                        : alerta.riskdesc.includes("Baixo (")
                                        ? "#ffb638"
                                        : alerta.riskdesc.includes("Médio (")
                                        ? "#ff6a00"
                                        : "#fc0303",
                                    }}
                                  >
                                    {alerta.riskdesc}
                                  </p>
                                ),
                                children: (
                                  <>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: alerta.desc,
                                      }}
                                    />
                                    <br />
                                    <h4>Recomendação</h4>
                                    <div
                                      style={{ marginLeft: "1rem" }}
                                      dangerouslySetInnerHTML={{
                                        __html: alerta.solution,
                                      }}
                                    />
                                    <br />
                                    <Collapse
                                      items={[
                                        {
                                          key: index2 + alerta.desc,
                                          label: `Instâncias (${alerta.instances.length})`,
                                          children: (
                                            <Collapse
                                              items={alerta.instances.map(
                                                (
                                                  instance: any,
                                                  index3: number
                                                ) => {
                                                  return {
                                                    key: index3 + instance.uri,
                                                    label: (
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                        }}
                                                      >
                                                        <Tag
                                                          color={
                                                            instance.method ===
                                                            "GET"
                                                              ? "green"
                                                              : instance.method ===
                                                                "POST"
                                                              ? "#0054d1"
                                                              : ""
                                                          }
                                                        >
                                                          GET
                                                        </Tag>
                                                        <p>{instance.uri}</p>
                                                      </div>
                                                    ),
                                                    children: (
                                                      <>
                                                        <h4>Evidencia:</h4>
                                                        <p
                                                          style={{
                                                            marginLeft: "1rem",
                                                          }}
                                                        >
                                                          {instance.evidence}
                                                        </p>
                                                        <br />
                                                        <h4>
                                                          Outras Informações
                                                        </h4>
                                                        <div
                                                          style={{
                                                            marginLeft: "1rem",
                                                          }}
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              instance.otherinfo,
                                                          }}
                                                        />
                                                      </>
                                                    ),
                                                  };
                                                }
                                              )}
                                            />
                                          ),
                                        },
                                      ]}
                                    />
                                  </>
                                ),
                              },
                            ]}
                          />
                        ))
                      ) : (
                        <Empty description="Nenhuma falha de segurança detectada" />
                      ),
                    };
                  })}
                />
              </div>
            )}
          </>
        )}
      {userInfo?.grupos?.find(
        (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
      ) &&
        !currentCliente && (
          <>
            <Alert
              type="warning"
              message="Selecione um cliente para visualizar!"
              showIcon
            />
            <br />
          </>
        )}
      <h3 style={{ marginBottom: "1rem" }}>Relatórios existentes</h3>
      <Table
        size="small"
        pagination={{ pageSize: 3 }}
        dataSource={anexosOwasp?.filter((a: any) =>
          a.name?.includes("ZAP-Report")
        )}
        columns={[
          {
            title: "Item",
            dataIndex: "_id",
            key: "_id",
            render: (text: string, record: any, index: number) => index + 1,
          },
          {
            title: "Data geração",
            dataIndex: "name",
            key: "name",
            render: (text: string) =>
              moment(new Date(text.replace("-ZAP-Report-.json", ""))).format(
                "DD/MM/YYYY"
              ),
          },
          {
            title: "Ações",
            dataIndex: "_id",
            key: "_id",
            render: (text: string, record: any) => (
              <Button
                type="link"
                onClick={() => downloadAndReadFile(record.url)}
                icon={<EyeOutlined />}
              >
                Ver
              </Button>
            ),
          },
        ]}
      />
      {currentRelatorio && (
        <>
          <h3 style={{ marginBottom: "1rem" }}>Visualização</h3>
          {currentRelatorio && (
            <div>
              <Table
                pagination={false}
                dataSource={[
                  {
                    "@programName": currentRelatorio["@programName"],
                    "@version": currentRelatorio["@version"],
                    "@generated": currentRelatorio["@generated"],
                  },
                ]}
                columns={columns}
              />
              <br />
              <Collapse
                items={currentRelatorio.site.map((site: any, index: number) => {
                  return {
                    key: index + 1,
                    label: `${site["@name"]} :${site["@port"]}`,
                    extra: site.alerts[0] ? (
                      <ExclamationCircleOutlined style={{ color: "#fc0303" }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: "#22c900" }} />
                    ),
                    children: site.alerts[0] ? (
                      site.alerts.map((alerta: any, index2: number) => (
                        <Collapse
                          items={[
                            {
                              key: index2 + 1 + alerta.name,
                              label: `${alerta.name}`,
                              extra: (
                                <p
                                  style={{
                                    color: alerta.riskdesc.includes(
                                      "Informativo"
                                    )
                                      ? "#0054d1"
                                      : alerta.riskdesc.includes("Baixo (")
                                      ? "#ffb638"
                                      : alerta.riskdesc.includes("Médio (")
                                      ? "#ff6a00"
                                      : "#fc0303",
                                  }}
                                >
                                  {alerta.riskdesc}
                                </p>
                              ),
                              children: (
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: alerta.desc,
                                    }}
                                  />
                                  <br />
                                  <h4>Recomendação</h4>
                                  <div
                                    style={{ marginLeft: "1rem" }}
                                    dangerouslySetInnerHTML={{
                                      __html: alerta.solution,
                                    }}
                                  />
                                  <br />
                                  <Collapse
                                    items={[
                                      {
                                        key: index2 + alerta.desc,
                                        label: `Instâncias (${alerta.instances.length})`,
                                        children: (
                                          <Collapse
                                            items={alerta.instances.map(
                                              (
                                                instance: any,
                                                index3: number
                                              ) => {
                                                return {
                                                  key: index3 + instance.uri,
                                                  label: (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                      }}
                                                    >
                                                      <Tag
                                                        color={
                                                          instance.method ===
                                                          "GET"
                                                            ? "green"
                                                            : instance.method ===
                                                              "POST"
                                                            ? "#0054d1"
                                                            : ""
                                                        }
                                                      >
                                                        GET
                                                      </Tag>
                                                      <p>{instance.uri}</p>
                                                    </div>
                                                  ),
                                                  children: (
                                                    <>
                                                      <h4>Evidencia:</h4>
                                                      <p
                                                        style={{
                                                          marginLeft: "1rem",
                                                        }}
                                                      >
                                                        {instance.evidence}
                                                      </p>
                                                      <br />
                                                      <h4>
                                                        Outras Informações
                                                      </h4>
                                                      <div
                                                        style={{
                                                          marginLeft: "1rem",
                                                        }}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            instance.otherinfo,
                                                        }}
                                                      />
                                                    </>
                                                  ),
                                                };
                                              }
                                            )}
                                          />
                                        ),
                                      },
                                    ]}
                                  />
                                </>
                              ),
                            },
                          ]}
                        />
                      ))
                    ) : (
                      <Empty description="Nenhuma falha de segurança detectada" />
                    ),
                  };
                })}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default OWASP;
