import React, { useEffect } from "react";
import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { GlobalContext } from "../../../context/globalContext";
import MapeamentoGestor from "./Gestor/Mapeamento";
import MapeamentoLider from "./Lider/Mapeamento";
import MapeamentoSponsor from "./Sponsor/Mapeamento";
import { Select, Table, Tabs, Tag, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Questionario } from "../../../context/mapeamentoContext";

const Mapeamentos = () => {
  const { userInfo, clientes, moduloMapeamento, usuarios, currentCliente } =
    React.useContext(GlobalContext) as GlobalContextType;

  const params = useParams();

  const [currentMapeamento, setCurrentMapeamento] =
    React.useState<Questionario | null>(null);

  useEffect(() => {
    if (
      (currentCliente?._id || params.clienteid) &&
      !moduloMapeamento.questionarios?.[0] &&
      userInfo?._id
    ) {
      moduloMapeamento?.getQuestionariosCliente(
        currentCliente?._id || params.clienteid
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (!currentMapeamento && moduloMapeamento?.questionarios?.[0]) {
      const mapeamentos = moduloMapeamento?.questionarios?.filter(
        (item) => item.nome === "Assessment Process"
      );

      if (mapeamentos && mapeamentos.length === 1) {
        const firstMapeamento = params?.mapeamentoid
          ? mapeamentos.find((m) => m._id === params?.mapeamentoid)
          : mapeamentos[0];

        firstMapeamento && setCurrentMapeamento(firstMapeamento);
      }

      if (mapeamentos && mapeamentos.length > 1 && params?.mapeamentoid) {
        const selectedMapeamento = mapeamentos.find(
          (m) => m._id === params?.mapeamentoid
        );

        selectedMapeamento && setCurrentMapeamento(selectedMapeamento);
      }
    }
  }, [moduloMapeamento.questionarios]);

  useEffect(() => {
    // Verifica se tem permissao de Lider e Gestor
    if (usuarios && currentMapeamento) {
      moduloMapeamento.setCurrentUserHasLiderPermission(
        usuarios
          ?.filter((user: any) => user._id === userInfo._id)
          ?.find(
            (user: any) =>
              user.funcoesDetalhadas.find(
                (funcao: any) =>
                  funcao.funcao_id.name === "responder-processo" &&
                  currentMapeamento?.capitulos?.find(
                    (c: any) => c._id === funcao.reference_model_id
                  )
              ) &&
              user.funcoesDetalhadas.find(
                (funcao: any) =>
                  funcao.funcao_id.name === "responder-questionario" &&
                  funcao.reference_model_id === currentMapeamento._id
              )
          )
          ? true
          : false
      );

      moduloMapeamento.setCurrentUserHasGestorPermission(
        usuarios
          .filter((user: any) => user._id === userInfo._id)
          .find(
            (user: any) =>
              user.funcoesDetalhadas.find(
                (funcao: any) =>
                  funcao.funcao_id.name === "view-area" &&
                  currentMapeamento?.areas?.includes(funcao.reference_model_id)
              ) &&
              user.funcoesDetalhadas.find(
                (funcao: any) =>
                  funcao.funcao_id.name === "responder-questionario" &&
                  funcao.reference_model_id === currentMapeamento._id
              )
          )
          ? true
          : false
      );
    }
  }, [usuarios, currentMapeamento]);

  return (
    <div className="mapeamentos">
      <BackHeader
        title="Mapeamentos"
        prev={
          userInfo?.grupos?.find(
            (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
          )
            ? true
            : false
        }
      />

      {userInfo?.grupos?.find(
        (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
      ) && (
        <>
          <h4>Dados da empresa</h4>
          <hr />
          <Table
            size="small"
            dataSource={clientes?.filter(
              (cli: any) => cli._id === params.clienteid
            )}
            pagination={false}
            columns={[
              {
                title: "Razão social",
                dataIndex: "razao_social",
                key: "razao_social",
              },
              {
                title: "CNPJ",
                dataIndex: "cnpj",
                key: "cnpj",
              },
              {
                title: "Responsável legal",
                dataIndex: "representante",
                key: "representante",
              },
            ]}
          />
          <br />
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: "1. Áreas (Sponsor)",
                children: (
                  <MapeamentoSponsor
                    currentMapeamento={currentMapeamento}
                    setCurrentMapeamento={setCurrentMapeamento}
                  />
                ),
              },
              {
                key: "2",
                label: "2. Processos (Gestor)",
                children: (
                  <MapeamentoGestor
                    currentMapeamento={currentMapeamento}
                    setCurrentMapeamento={setCurrentMapeamento}
                  />
                ),
              },
              {
                key: "3",
                label: "3. Subprocessos e Titular (Líder)",
                children: (
                  <MapeamentoLider
                    currentMapeamento={currentMapeamento}
                    setCurrentMapeamento={setCurrentMapeamento}
                  />
                ),
              },
            ]}
          />
        </>
      )}
      {moduloMapeamento?.questionarios?.filter(
        (item) => item.nome === "Assessment Process"
      )?.length > 1 &&
        !userInfo?.grupos?.find(
          (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
        ) && (
          <>
            <br />
            <h4>Selecione um mapeamento para visualizar:</h4>
            <br />
            <Select
              size="large"
              value={currentMapeamento?._id}
              loading={
                moduloMapeamento.questionarios?.filter(
                  (quest: any) => quest.cliente_id === clientes?.[0]._id
                )?.[0]
                  ? false
                  : true
              }
              disabled={
                moduloMapeamento.questionarios?.filter(
                  (quest: any) => quest.cliente_id === clientes?.[0]._id
                )?.[0]
                  ? false
                  : true
              }
              onChange={(v) => {
                const currentQuest = moduloMapeamento?.questionarios?.find(
                  (q) => q._id === v
                );

                currentQuest && setCurrentMapeamento(currentQuest);
              }}
              style={{ width: "24rem" }}
              placeholder="Selecione um mapeamento"
              options={moduloMapeamento.questionarios
                ?.filter((quest: any) => quest.nome === "Assessment Process")
                ?.filter((quest: any) => quest.cliente_id === clientes?.[0]._id)
                ?.sort(
                  (a: any, b: any) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((quest: any) => {
                  return {
                    key: quest._id,
                    value: quest._id,
                    label: (
                      <Tooltip title={quest.nome}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {quest.nome.length > 25
                              ? quest.nome.substring(0, 25) + "..."
                              : quest.nome}
                          </p>
                          <Tag color="magenta" style={{ marginLeft: "1rem" }}>
                            {moment(new Date(quest.created_at)).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </Tag>
                        </div>
                      </Tooltip>
                    ),
                  };
                })}
            />
            <br />
            <br />
            <hr />
          </>
        )}

      {currentMapeamento && (
        <>
          {/* SPONSOR */}
          {userInfo?.grupos?.find((gp: any) => gp.name === "sponsor") && (
            <>
              {!moduloMapeamento.currentUserHasGestorPermission && (
                <MapeamentoSponsor
                  currentMapeamento={currentMapeamento}
                  setCurrentMapeamento={setCurrentMapeamento}
                />
              )}
              {moduloMapeamento.currentUserHasGestorPermission && (
                <Tabs
                  defaultActiveKey="1"
                  activeKey={moduloMapeamento.activeMapeamentoTab}
                  onTabClick={(v: string) =>
                    moduloMapeamento.setActiveMapeamentoTab(v)
                  }
                  items={
                    moduloMapeamento.currentUserHasLiderPermission
                      ? [
                          {
                            key: "1",
                            label: "1. Áreas",
                            children: (
                              <MapeamentoSponsor
                                currentMapeamento={currentMapeamento}
                                setCurrentMapeamento={setCurrentMapeamento}
                              />
                            ),
                          },
                          {
                            key: "2",
                            label: "2. Processos",
                            children: (
                              <MapeamentoGestor
                                currentMapeamento={currentMapeamento}
                                setCurrentMapeamento={setCurrentMapeamento}
                              />
                            ),
                          },
                          {
                            key: "3",
                            label: "3. Titular de dados",
                            children: (
                              <MapeamentoLider
                                currentMapeamento={currentMapeamento}
                                setCurrentMapeamento={setCurrentMapeamento}
                              />
                            ),
                          },
                        ]
                      : [
                          {
                            key: "1",
                            label: "1. Áreas",
                            children: (
                              <MapeamentoSponsor
                                currentMapeamento={currentMapeamento}
                                setCurrentMapeamento={setCurrentMapeamento}
                              />
                            ),
                          },
                          {
                            key: "2",
                            label: "2. Processos",
                            children: (
                              <MapeamentoGestor
                                currentMapeamento={currentMapeamento}
                                setCurrentMapeamento={setCurrentMapeamento}
                              />
                            ),
                          },
                        ]
                  }
                />
              )}
            </>
          )}
          {/* SPONSOR */}

          {/* GESTOR DE AREA */}
          {userInfo?.grupos?.find(
            (gp: any) => gp.name === "Gestor de área"
          ) && (
            <>
              {!moduloMapeamento.currentUserHasLiderPermission && (
                <MapeamentoGestor
                  currentMapeamento={currentMapeamento}
                  setCurrentMapeamento={setCurrentMapeamento}
                />
              )}
              {moduloMapeamento.currentUserHasLiderPermission && (
                <Tabs
                  defaultActiveKey="1"
                  activeKey={moduloMapeamento.activeMapeamentoTab}
                  onTabClick={(v: string) =>
                    moduloMapeamento.setActiveMapeamentoTab(v)
                  }
                  items={[
                    {
                      key: "2",
                      label: "1. Processos",
                      children: (
                        <MapeamentoGestor
                          currentMapeamento={currentMapeamento}
                          setCurrentMapeamento={setCurrentMapeamento}
                        />
                      ),
                    },
                    {
                      key: "3",
                      label: "2. Titular de dados",
                      children: (
                        <MapeamentoLider
                          currentMapeamento={currentMapeamento}
                          setCurrentMapeamento={setCurrentMapeamento}
                        />
                      ),
                    },
                  ]}
                />
              )}
            </>
          )}
          {/* GESTOR DE AREA */}

          {/* LIDER DE PROCESSO */}
          {userInfo?.grupos?.find(
            (gp: any) => gp.name === "Líder de processo"
          ) && (
            <MapeamentoLider
              currentMapeamento={currentMapeamento}
              setCurrentMapeamento={setCurrentMapeamento}
            />
          )}
          {/* LIDER DE PROCESSO */}
        </>
      )}
    </div>
  );
};

export default Mapeamentos;
