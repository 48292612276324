import React, { useContext } from "react";
import { Button, Modal, List, Tooltip } from "antd";
import { DeleteOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { GlobalContext } from "../../../../../context/globalContext";
import { GlobalContextType } from "../../../../../@types/global";
import Task from "../../../../Tarefas/Task/Task";
import CriarTarefa from "../../../../Tarefas/CriarTarefa/CriarTarefa";
import { Reuniao } from "../types";
import { Pointer } from "iconsax-react";

interface PautasModalProps {
  isVisible: boolean;
  onClose: () => void;
  reuniaoId: string;
}

const PautasModal: React.FC<PautasModalProps> = ({
  isVisible,
  onClose,
  reuniaoId,
}) => {
  const { moduloTask } = useContext(GlobalContext) as GlobalContextType;

  const [isCriarPautaModalVisible, setIsCriarPautaModalVisible] =
    React.useState(false);
  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    React.useState(false);
  const [inativas, setInativas] = React.useState<string[]>([]);

  const showCriarPautaModal = () => {
    setIsCriarPautaModalVisible(!isCriarPautaModalVisible);
  };

  return (
    <Modal
      title="Pautas"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={900} // Adjust width here
    >
      <br />
      <Button
        type="primary"
        onClick={showCriarPautaModal}
        className="mb-4"
        icon={<PlusOutlined />}
      >
        Criar pauta
      </Button>
      <List
        dataSource={moduloTask?.tasksCliente?.filter(
          (task) =>
            task.tipo === "pauta" && task.modelo_referencia_id === reuniaoId
        )}
        renderItem={(item) => (
          <div className="microtask_container" style={{ marginLeft: 0 }}>
            <Tooltip title="Ver item">
              <EyeOutlined
                style={{
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  color: "#1677ff",
                }}
                onClick={() => {
                  moduloTask?.setSelectedTask(item);
                  setIsVerPautaModalVisible(true);
                }}
              />
            </Tooltip>
            {!inativas?.includes(item._id) ? (
              item.title
            ) : (
              <s style={{ opacity: 0.3 }}>{item.title}</s>
            )}
            {!inativas?.includes(item._id) && (
              <DeleteOutlined
                onClick={() => {
                  moduloTask?.inativarTask(item._id);
                  setInativas((prev: string[]) => [...prev, item._id]);
                }}
                style={{ marginLeft: "auto", color: "red", cursor: "pointer" }}
              />
            )}
          </div>
        )}
      />
      <CriarTarefa
        showModal={isCriarPautaModalVisible}
        handleShowModal={showCriarPautaModal}
        tipo="pauta"
        modelo_referencia_id={reuniaoId}
      />
      <Task
        setShowModal={setIsVerPautaModalVisible}
        showModal={isVerPautaModalVisible}
      />
    </Modal>
  );
};

export default PautasModal;
