import React, { useState } from "react";
import "./styles.css";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import { Button, Tooltip } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { PlanoAcaoTypes } from "../../@types/planoAcao";
import moment from "moment";

const TimelineItem: React.FC<PlanoAcaoTypes> = ({ planoAcao, lastIndex }) => {
  const {} = React.useContext(GlobalContext) as GlobalContextType;

  const date = moment(new Date(planoAcao.item?.created_at)).format(
    "DD/MM/YYYY"
  );
  const [viewItem, setViewItem] = useState(false);
  return (
    <>
      <div className="timelineItem" onClick={() => console.log(planoAcao)}>
        <p className="timelineItem__description">
          {planoAcao.item?.description}
        </p>
        <p className="timelineItem__date">{date}</p>
        {!lastIndex && (
          <Tooltip title={viewItem ? "Esconder" : "Visualizar"}>
            <Button
              className="timelineItem__icon"
              type="link"
              onClick={() => setViewItem(!viewItem)}
              icon={viewItem ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            />
          </Tooltip>
        )}
      </div>
      {lastIndex ? (
        <div
          dangerouslySetInnerHTML={{
            __html: planoAcao.item?.value,
          }}
        />
      ) : (
        viewItem && (
          <div
            dangerouslySetInnerHTML={{
              __html: planoAcao.item?.value,
            }}
          />
        )
      )}
    </>
  );
};

export default TimelineItem;
