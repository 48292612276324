import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Radio } from "antd";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import BackHeader from "../../../BackHeader/BackHeader";

// Registrar os plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const InformacoesAgendamentos: React.FC = () => {
  const { obterReunioesPorCppd } = useContext(
    GlobalContext
  ) as GlobalContextType;
  const { cppdId } = useParams<{ cppdId: string }>();
  const [intervalo, setIntervalo] = useState<string | undefined>(undefined);
  const [diaSemana, setDiaSemana] = useState<string>("Não definido");
  const [dataPrimeiraReuniao, setDataPrimeiraReuniao] = useState<
    string | undefined
  >(undefined);
  const [horarioPadrao, setHorarioPadrao] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchReunioes = async () => {
      try {
        const data = await obterReunioesPorCppd(cppdId!);
        const reunioes = data.reunioes;

        if (reunioes.length > 0) {
          const primeiraReuniao = reunioes[0];
          const intervaloDias = primeiraReuniao.intervalo;

          // Determina o intervalo
          switch (intervaloDias) {
            case 15:
              setIntervalo("quinzenal");
              break;
            case 30:
              setIntervalo("mensal");
              break;
            case 60:
              setIntervalo("bimensal");
              break;
            case 90:
              setIntervalo("trimestral");
              break;
            default:
              setIntervalo("Não definido");
          }

          // Ajusta o horário para o fuso horário correto na exibição
          const formattedDate = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("DD/MM/YYYY");
          setDataPrimeiraReuniao(formattedDate);

          const horario = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("HH:mm");
          setHorarioPadrao(horario || "Não definido");

          // Determina o dia da semana
          const diaSemana = dayjs(primeiraReuniao.data_agendamento)
            .tz("America/Sao_Paulo")
            .format("dddd");
          setDiaSemana(diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1)); // Capitaliza a primeira letra
        }
      } catch (error) {
        console.error("Erro ao obter reuniões:", error);
      }
    };

    if (cppdId) {
      fetchReunioes();
    } else {
      console.log("cliente_id não definido.");
    }
  }, [obterReunioesPorCppd, cppdId]);

  return (
    <div className="my-8">
      <BackHeader title="CPPD - Reuniões" prev />

      <h2 className="text-xl font-bold">Informações dos agendamentos</h2>
      <div className="my-4">
        <Form className="flex flex-col gap-5">
          <Form.Item label="Regimento de funcionamento">
            <Radio.Group value={intervalo}>
              <Radio.Button
                value="quinzenal"
                checked={intervalo === "quinzenal"}
              >
                Quinzenal
              </Radio.Button>
              <Radio.Button value="mensal" checked={intervalo === "mensal"}>
                Mensal
              </Radio.Button>
              <Radio.Button value="bimensal" checked={intervalo === "bimensal"}>
                Bimensal
              </Radio.Button>
              <Radio.Button
                value="trimestral"
                checked={intervalo === "trimestral"}
              >
                Trimestral
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <div className="flex justify-evenly">
            <Form.Item label="Dia da semana">
              <Input value={diaSemana} readOnly />
            </Form.Item>
            <Form.Item label="Data da primeira reunião">
              <Input value={dataPrimeiraReuniao || "Não definido"} readOnly />
            </Form.Item>
            <Form.Item label="Horário padrão">
              <Input value={horarioPadrao || "Não definido"} readOnly />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InformacoesAgendamentos;
