import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./styles.css";
import { Table, Input, Button, Switch } from "antd";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";

const { Search } = Input;

const ClientesFornecedor = () => {
  const { clientes, pageSize, windowWidth, userInfo } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [search, setSearch] = React.useState<string>("");
  const navigate = useNavigate();

  const columns = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      render: (text: any) => text || "",
    },
  ];

  const columnsMobile = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
  ];

  useEffect(() => {
    if (clientes && clientes[0]) {
      clientes.length === 1 &&
        navigate("/clientesFornecedor/fornecedor/" + clientes[0]._id);
    }
  }, [clientes]);

  const onSearch = (value: string) => setSearch(value);

  const transformData = (data: any) => {
    const transformedData: any = [];

    // Agrupa os objetos pelo cliente_sup
    const groupedData = data.reduce((acc: any, obj: any) => {
      const key = obj.cliente_sup || "root";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

    // Cria o array transformado com os filhos
    Object.keys(groupedData).forEach((key) => {
      const parent = data.find((item: any) => item._id === key) || {};
      const children = groupedData[key].map((item: any) => ({ ...item }));
      transformedData.push({
        ...parent,
        children: children,
      });
    });

    return transformedData;
  };

  return (
    <div className="clientes">
      <BackHeader title="Clientes" />
      <div className="clientes__actions">
        <Search
          placeholder="Buscar cliente"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          onSearch={onSearch}
        />
      </div>
      {clientes?.length === 1 ? (
        <Table
          className="clientes__list"
          dataSource={clientes?.filter((c: any) =>
            c.razao_social.toUpperCase().includes(search.toUpperCase())
          )}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate("/clientesFornecedor/fornecedor/" + record._id);
              },
            };
          }}
          pagination={{ pageSize }}
          columns={windowWidth > 800 ? columns : columnsMobile}
        />
      ) : clientes?.[0] ? (
        <>
          {userInfo?.grupos?.find((gp: any) => gp.name === "assinante") ? (
            <Table
              className="clientes__list"
              dataSource={clientes?.filter((c: any) =>
                c.razao_social.toUpperCase().includes(search.toUpperCase())
              )}
              rowKey="_id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate("/clientesFornecedor/fornecedor/" + record._id);
                  },
                };
              }}
              pagination={{ pageSize }}
              columns={windowWidth > 800 ? columns : columnsMobile}
            />
          ) : (
            <Table
              className="clientes__list"
              dataSource={transformData(
                clientes?.filter((c: any) =>
                  c.razao_social.toUpperCase().includes(search.toUpperCase())
                )
              ).filter((item: any) => item._id)}
              rowKey="_id"
              onRow={(record) => {
                return {
                  onClick: () => {
                    navigate("/clientesFornecedor/fornecedor/" + record._id);
                  },
                };
              }}
              pagination={{ pageSize }}
              columns={windowWidth > 800 ? columns : columnsMobile}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClientesFornecedor;
