import React, { useEffect, useState } from "react";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tag,
} from "antd";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";

export type ItemPauta = {
  _id: string;
  title: string;
  description: string;
  ordem_reuniao: number;
  subordem_reuniao?: number;
  inativo?: boolean;
  created_at?: Date;
  updated_at?: Date;
};

export type PautasType = {
  _id: string;
  regimento: 15 | 30 | 60 | 90; // Enum que define os valores permitidos para o regimento
  observacoes?: string; // Observações são opcionais
  items: ItemPauta[]; // Lista de ObjectIds que referenciam itens da pauta
  inativo?: boolean; // Inativo é opcional, com valor padrão false
  created_at?: Date; // Data de criação (opcional, gerada automaticamente)
  updated_at?: Date; // Data de atualização (opcional, gerada automaticamente)
  ordem_reuniao?: number;
  subordem_reuniao?: number;
};

const { TextArea } = Input;

const ModeloPautas = () => {
  const { moduloCppd, userInfo } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [pautas, setPautas] = useState<PautasType[] | null>(null);
  const [itensPautas, setItensPautas] = useState<ItemPauta[]>([]);

  const [selectedPauta, setSelectedPauta] = useState<PautasType | null>(null);

  const [isModalPautaOpen, setIsModalPautaOpen] = useState(false);
  const [isModalItemPautaOpen, setIsModalItemPautaOpen] = useState(false);

  useEffect(() => {
    userInfo._id &&
      moduloCppd.getPautas().then((data: PautasType[]) => setPautas(data));

    userInfo._id &&
      moduloCppd
        .getItensPautas()
        .then((data: ItemPauta[]) => setItensPautas(data));
  }, [userInfo]);

  const handleNewPauta = (values: PautasType) => {
    moduloCppd
      ?.updatePauta(selectedPauta)
      .then(() =>
        moduloCppd.getPautas().then((data: PautasType[]) => setPautas(data))
      );
  };

  const handleNewItemPauta = (values: PautasType) => {
    moduloCppd?.postItensPautas(values).then(() =>
      moduloCppd
        .getItensPautas()
        .then((data: ItemPauta[]) => setItensPautas(data))
        .finally(() => setIsModalItemPautaOpen(false))
    );
  };

  return (
    <div className="questionarios_CPPD">
      <BackHeader title="Modelo de pautas" />
      <h2>Regimentos:</h2>
      <br />
      <Row gutter={24}>
        {pautas?.map((pauta: PautasType) => {
          return (
            <Col span={6}>
              <Card
                title={"Intervalo " + pauta.regimento + " dias"}
                bordered={false}
                extra={
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setSelectedPauta(pauta);
                      setIsModalPautaOpen(true);
                    }}
                  >
                    Editar
                  </Button>
                }
              >
                {pauta.items?.length} itens
              </Card>
            </Col>
          );
        })}
      </Row>
      <br />

      <h2>Todas as pautas:</h2>
      <br />
      <Button
        icon={<PlusOutlined />}
        type="primary"
        style={{ marginBottom: "1rem" }}
        onClick={() => {
          setIsModalItemPautaOpen(true);
        }}
      >
        Nova Pauta
      </Button>
      <Table
        size="small"
        columns={[
          {
            title: "Assunto",
            dataIndex: "title",
            key: "title",
            render: (text) => (
              <Button type="link">
                {text?.length > 60 ? `${text?.substring(0, 60)}...` : text}
              </Button>
            ),
          },
          {
            title: "Reunião",
            dataIndex: "_id",
            key: "_id",
            render: (_, record: ItemPauta) => (
              <Tag color="blue">
                <b>
                  R{record.ordem_reuniao}
                  {record.subordem_reuniao && "." + record.subordem_reuniao}
                </b>
              </Tag>
            ),
          },
          {
            title: "Descrição",
            dataIndex: "description",
            key: "description",
            render: (description) =>
              description?.length > 30
                ? `${description?.substring(0, 30)}...`
                : description,
          },
          {
            title: "",
            dataIndex: "_id",
            key: "_id",
            render: (_, record: ItemPauta) => (
              <Button size="small" type="link" icon={<EditOutlined />}>
                Editar
              </Button>
            ),
          },
        ]}
        dataSource={itensPautas?.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )}
      />

      <Modal
        title={"Intervalo " + selectedPauta?.regimento + " dias"}
        open={isModalPautaOpen}
        footer={null}
        onCancel={() => setIsModalPautaOpen(false)}
        width={900}
      >
        <Form layout="vertical" onFinish={handleNewPauta}>
          <Form.Item label="Items" name={"items"}>
            <Select
              maxTagCount={"responsive"}
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Adicione pautas"
              onChange={(selectedValues) => {
                setSelectedPauta((prev) => {
                  if (!prev) return null; // Verificação para garantir que prev não é null
                  return {
                    ...prev,
                    items:
                      itensPautas?.filter((item) =>
                        selectedValues.includes(item._id)
                      ) || [],
                  };
                });
              }}
              defaultValue={selectedPauta?.items?.map((item) => item._id)}
              value={selectedPauta?.items?.map((item) => item._id)}
              options={itensPautas?.map((item) => {
                return {
                  label: item.title,
                  value: item._id,
                };
              })}
            />
          </Form.Item>
          <Table
            size="small"
            columns={[
              {
                title: "Assunto",
                dataIndex: "title",
                key: "title",
                render: (text) => (
                  <Button type="link">
                    {text?.length > 60 ? `${text?.substring(0, 60)}...` : text}
                  </Button>
                ),
              },
            ]}
            dataSource={selectedPauta?.items?.sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )}
          />

          <Form.Item>
            <Button
              className="planoAcao__btn"
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={"Nova pauta"}
        open={isModalItemPautaOpen}
        footer={null}
        onCancel={() => setIsModalItemPautaOpen(false)}
        width={900}
      >
        <Form layout="vertical" onFinish={handleNewItemPauta}>
          <Form.Item
            label="Assunto"
            name={"title"}
            rules={[
              { required: true, message: "Por favor, escreva um assunto!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Reunião"></Form.Item>
          <Row>
            <Col span={1}>R</Col>
            <Col span={2}>
              <Form.Item
                name={"ordem_reuniao"}
                rules={[
                  { required: true, message: "Por favor, digite a reunião!" },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col span={1}>.</Col>
            <Col span={2}>
              <Form.Item name={"subordem_reuniao"}>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Descrição" name={"description"}>
            <TextArea rows={6} placeholder="Escreva..." />
          </Form.Item>

          <Form.Item>
            <Button
              className="planoAcao__btn"
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ModeloPautas;
