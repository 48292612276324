import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import Fornecedores from "../../Apps/AcaoFornecedores/Fornecedores/Fornecedores";

const Diagnosticos: React.FC = () => {
  const {} = React.useContext(GlobalContext) as GlobalContextType;

  return (
    <>
      <BackHeader title="Diagnósticos" />
      <Fornecedores type="diagnosticos" />
    </>
  );
};

export default Diagnosticos;
