import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";

export const AuthenticationGuard = ({ component }) => {
  const location = useLocation();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="spinContainer">
        <Spin size="large" />
      </div>
    ),
    onBeforeAuthentication: () => {
      localStorage.setItem("@desiredroute", location.pathname);
    },
  });

  return <Component />;
};
