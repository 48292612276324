import React, { useEffect, useState } from "react";

import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import { Button, Col, Form, Input, message, Modal, Row, Select } from "antd";
import "./styles.css";
import { SaveOutlined } from "@ant-design/icons";
import InputMask from "react-input-mask";

interface NewClienteProps {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
}

const { TextArea } = Input;

const NewCliente: React.FC<NewClienteProps> = ({ showModal, setShowModal }) => {
  const { tomTicketOrganizacoes, clientes, userInfo, saveCliente } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();

  const onFinish = async (values: any) => {
    try {
      await saveCliente(values);

      setShowModal(false);
      form.resetFields();
    } catch (error) {
      message.error("Erro ao salvar cliente");
    }
  };

  return (
    <>
      <Modal
        title={"Cadastrar novo cliente"}
        open={showModal}
        footer={[]}
        width={900}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <Form layout="vertical" name="edit-clientes-form" form={form} onFinish={onFinish}>
          <Row gutter={[24, 0]} style={{ marginTop: "2rem" }}>
            {userInfo?.grupos?.find((gp: any) => gp.name === "seusdados") && (
              <Col span={24}>
                <Form.Item name={"cliente_sup"} rules={[{ required: true }]} label="Cliente Superior">
                  <Select
                    allowClear
                    placeholder="Selecione um cliente superior"
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input: string, option: any) => {
                      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    options={clientes?.map((cli: any) => {
                      return {
                        value: cli._id,
                        label: cli.razao_social,
                      };
                    })}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={12}>
              <Form.Item name={"razao_social"} rules={[{ required: true }]} label="Razão Social">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name={"cnpj"} rules={[{ required: true }]} label="CNPJ">
                <InputMask type="text" mask="99.999.999/9999-99">
                  <Input />
                </InputMask>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item rules={[{ required: true, type: "email" }]} name={"email"} label="E-mail">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"telefone"} label="Telefone / Celular">
                <InputMask type="text" mask="99 *9999-9999">
                  <Input />
                </InputMask>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"representante"} label="Representante legal">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={"tomticket_org_id"} label="Vínculo Tom Ticket">
                <Select
                  allowClear
                  placeholder="Selecione um cliente do Tom Ticket"
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input: string, option: any) => {
                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                  options={tomTicketOrganizacoes?.map((org: any) => {
                    return {
                      value: org.id_organizacao,
                      label: org.nome,
                    };
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={"obs"} label="Observações">
                <TextArea rows={6} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{
              marginTop: "2rem",
              marginBottom: "-0.5rem",
              marginRight: "-0.5rem",
            }}
          >
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="default" form="form-pergunta" style={{ marginRight: "1rem" }} onClick={() => setShowModal(false)}>
                Cancelar
              </Button>
              <Button icon={<SaveOutlined />} type="primary" className="btn-success" htmlType="submit">
                Salvar
              </Button>
            </span>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewCliente;
