import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import "./styles.css";
import { Upload, Input, Radio, Form, Button, Tabs } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import Analise from "./components/analise";

const { TextArea } = Input;

const EditAnalise = () => {
  const params = useParams();

  const tabItems = [
    {
      key: "1",
      label: `Análise`,
      children: <Analise questId={params.qid} />,
    },
    {
      key: "2",
      label: `Configuração`,
      children: `Configurações`,
    },
  ];

  return (
    <div className="editAnalise">
      <BackHeader
        title={"Análise " + params.qid || "Carregando..."}
        route="/analise"
      />
      <hr />
      <Tabs
        defaultActiveKey="1"
        items={tabItems}
        className="editAnalise__tabs"
      />
    </div>
  );
};

export default EditAnalise;
