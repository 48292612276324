import React from 'react';
import { Modal, Form, Input } from 'antd';

interface EnviarAtaModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
}

const EnviarAtaModal: React.FC<EnviarAtaModalProps> = ({
  isVisible,
  setIsVisible,
}) => {
  const [enviarAtaForm] = Form.useForm();

  const handleEnviarAtaOk = () => {
    enviarAtaForm
      .validateFields()
      .then((values) => {
        console.log('E-mail:', values.email);
        setIsVisible(false);
        enviarAtaForm.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title="Enviar Ata por E-mail"
      visible={isVisible}
      onOk={handleEnviarAtaOk}
      onCancel={() => setIsVisible(false)}
    >
      <Form layout="vertical" form={enviarAtaForm}>
        <Form.Item label="E-mail" name="email">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EnviarAtaModal;
