import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./styles.css";
import { Alert, Button, Progress, Skeleton, Spin } from "antd";
import Header from "../Header/Header";
import Drawer from "../Drawer/Drawer";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ProgressBar } from "react-loader-spinner";
import { Logout, LogoutCurve } from "iconsax-react";

const MainLayout: React.FC = () => {
  const { alert, collapsed, userInfo, windowWidth, loadingCount } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      // Atualiza o progresso a cada 50 milissegundos
      setProgress((prevProgress) => (prevProgress + 1) % 101);
    }, 15);

    return () => {
      // Limpa o intervalo quando o componente é desmontado
      clearInterval(interval);
    };
  }, []);

  const location = useLocation();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const handleOnClick = async () => {
    !isAuthenticated && (await loginWithRedirect());
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: "https://workday.seusdados.com",
      },
    });
  };

  return (
    <span style={{ overflow: "hidden", height: "100vh" }}>
      <>
        {!userInfo?.name &&
          !location.pathname.includes("responder") &&
          !location.pathname.includes("analisefornecedor") &&
          !location.pathname.includes("formulario/") && (
            <>
              {userInfo?.error ? (
                <div className="errorContainer">
                  <Alert
                    message="Erro"
                    description="Falha ao obter dados de usuário, deseja entrar novamente?"
                    type="error"
                    showIcon
                  />
                  <br />
                  <Button
                    type="default"
                    icon={
                      <LogoutCurve
                        style={{
                          width: 14,
                          height: 14,
                          marginBottom: -2,
                        }}
                      />
                    }
                    onClick={handleLogout}
                  >
                    Entrar novamente
                  </Button>
                </div>
              ) : (
                <div className="spinContainer">
                  <Button type="link" onClick={handleLogout}>
                    SAIR
                  </Button>
                  <Spin size="large" />
                </div>
              )}
            </>
          )}

        {loadingCount > 0 && (
          <div className="progress-bar">
            <div
              className="progress"
              style={{ transform: `translateX(${progress - 100}%)` }}
            ></div>
          </div>
        )}

        {/* )} */}
        <main className="main d-flex">
          {userInfo?.name && <Drawer />}
          <section
            className={` ${
              !location.pathname.includes("responder") &&
              !location.pathname.includes("formulario/")
                ? "main__content"
                : "main__content--noPadding"
            }`}
            style={{
              marginLeft: userInfo?.name ? (collapsed ? "80px" : "280px") : 0,
            }}
          >
            <Header />
            <div style={{ padding: "2rem" }}>
              <Outlet />
            </div>
            {windowWidth <= 800 && <div style={{ paddingTop: "25rem" }} />}
            {alert && (
              <Alert
                className="main__content--alert"
                message={alert.message}
                description={alert.description}
                type={alert.type}
                showIcon
                closable
              />
            )}
          </section>
        </main>
      </>
    </span>
  );
};
export default MainLayout;
