import React, { useEffect, useState } from "react";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { Alert } from "antd";

const Leads: React.FC = () => {
  const {} = React.useContext(GlobalContext) as GlobalContextType;

  return (
    <>
      <BackHeader title="Leads" />
      <Alert
        message="Esta funcionalidade será disponibilizada em breve."
        showIcon
        type="info"
      />
    </>
  );
};

export default Leads;
