import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  message,
  Tooltip,
  Spin,
  Upload,
} from "antd";
import { useParams } from "react-router-dom"; // Importar o hook useParams
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { GlobalContextType } from "../../../../../../src/@types/global";
import { GlobalContext } from "../../../../../../src/context/globalContext";
import {
  FilePdfFilled,
  FilePdfOutlined,
  InboxOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { TaskType } from "../../../../../../src/context/taskContext";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Dragger } = Upload;

interface Participante {
  _id?: string;
  name: string;
  email: string;
  phone?: string;
}

interface Reuniao {
  _id: string;
  titulo: string;
  data_agendamento: string;
  status: string;
  membrosPresentes?: any[];
  razao_social?: string;
  responsavel_id?: string;
  transcricaoAta?: string;
  ordem_reuniao?: number;
  subordem_reuniao?: number;
}

interface AtaModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  reuniao?: Reuniao;
  cliente?: any;
  participante?: Participante[];
}

type UploadProps = {
  name: string;
  multiple: boolean;
  fileList: any[];
  beforeUpload: (file: any) => boolean;
  onChange: (info: any) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
};

type PdfMakeContent = any;

const AtaModal: React.FC<AtaModalProps> = ({
  isVisible,
  setIsVisible,
  reuniao,
  cliente,
  participante = [],
}) => {
  const { cppdId } = useParams(); // Captura o cppdId da URL
  const [ataTexto, setAtaTexto] = useState<string>("");

  const [isSignatureModalVisible, setIsSignatureModalVisible] =
    useState<boolean>(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState<boolean>(false);
  const [isSavingTranscricao, setIsSavingTranscricao] =
    useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [documentDefinition, setDocumentDefinition] = useState<any>(null);

  const {
    moduloTask,
    criarPastaNoCofreD4sign,
    uploadDocumentoD4sign,
    adicionarParticipantesD4sign,
    enviarDocumentoParaAssinatura,
    usuarios,
    atualizarReuniaoPorId,
  } = useContext(GlobalContext) as GlobalContextType;

  const isMembrosPresentesValid =
    reuniao?.membrosPresentes && reuniao.membrosPresentes.length > 0;

  const gerarPdfAta = async () => {
    // Função para gerar um URL de blob para o PDF
    const generatePdfBlobUrl = (documentDefinition: any) => {
      return new Promise((resolve, reject) => {
        pdfMake.createPdf(documentDefinition).getBlob((blob: any) => {
          const url = URL.createObjectURL(blob);
          resolve(url);
        });
      });
    };

    setIsGeneratingPDF(true); // Inicia o loading no botão
    try {
      const gruposDoComite = [
        "65f1a790ff2de399bce35a8d",
        "66aa6aa01386fb3c3b13bafd",
        "66c61fe39810d75998778418",
        "66c61ff79810d75998778419",
      ];

      const participantesPresentes = [
        ...participante.filter((p) =>
          reuniao?.membrosPresentes?.some((m: any) => m._id === p._id)
        ),
        responsavel,
      ];

      const tasks = moduloTask?.tasksCliente;

      const usuariosComite = usuarios
        ?.filter(
          (usr: any) =>
            usr.cliente_id === cliente?._id &&
            usr.grupos
              ?.map((g: any) => g._id)
              .some((grupo: string) => gruposDoComite.includes(grupo))
        )
        ?.map((u: any) => u?._id);

      const followUp = tasks?.filter(
        (task) =>
          task.tipo !== "pauta" &&
          task.status !== "Concluída" &&
          (usuariosComite?.includes(task.owner_id) ||
            usuariosComite?.includes(task.assignment_id))
      )?.[0]
        ? tasks
            ?.filter(
              (task) =>
                task.tipo !== "pauta" &&
                task.status !== "Concluída" &&
                (usuariosComite?.includes(task.owner_id) ||
                  usuariosComite?.includes(task.assignment_id)) &&
                reuniao &&
                new Date(task.created_at) < new Date(reuniao.data_agendamento) // tarefas anteriores a reuniao
            )
            ?.map((item, index) => item.title)
        : ["Nenhum item encontrado"];

      const deliberacoes = tasks
        ?.filter(
          (Task) =>
            Task.tipo === "pauta" && Task.modelo_referencia_id === reuniao?._id
        )
        ?.flatMap((t) => {
          const subtasks = moduloTask?.tasksCliente
            ?.filter((Task) =>
              moduloTask?.tasksCliente
                ?.filter(
                  (Task) =>
                    Task.tipo === "pauta" &&
                    Task.modelo_referencia_id === reuniao?._id
                )
                ?.map((item) => item._id)
                ?.find((item: any) => item === Task.dependencia)
            )
            ?.filter((task: any) => task.dependencia === t._id)
            ?.reduce((accumulator: any[], task: any) => {
              if (
                !accumulator.some(
                  (existingTask) => existingTask._id === task._id
                )
              ) {
                accumulator.push(task);
              }
              return accumulator;
            }, [])
            ?.map((task) => task.title);

          // Retorna o título e, se houver subtarefas, inclui um objeto com `ul`
          return subtasks.length > 0 ? [t.title, { ul: subtasks }] : [t.title];
        });

      const assinaturas = participantesPresentes?.flatMap((participante) => [
        {
          text: "\n\n\n\n__________________________________",
          alignment: "center",
          margin: [0, 40, 0, 10],
        },
        {
          text: participante.name,
          alignment: "center",
        },
      ]);

      const document = {
        content: [
          {
            text: `R${reuniao?.ordem_reuniao}${
              reuniao?.subordem_reuniao ? "." + reuniao?.subordem_reuniao : ""
            } - Comitê de privacidade e proteção de dados CPPD`,
            style: "header",
          },
          {
            text: `Data: ${
              reuniao?.data_agendamento
                ? dayjs(reuniao.data_agendamento).format("DD/MM/YYYY")
                : "Data não disponível"
            }`,
            margin: [0, 40, 0, 10],
          },
          {
            text: `Empresa: ${
              cliente?.razao_social || "Empresa não disponível"
            }`,
            margin: [0, 0, 0, 10],
          },
          {
            text: `CNPJ: ${cliente?.cnpj || "CNPJ não disponível"}`,
            margin: [0, 0, 0, 10],
          },
          { text: "Participantes", style: "header2", margin: [0, 20, 0, 10] },
          {
            table: {
              widths: ["*", "*"],
              body: [
                [
                  { text: "Nome", style: "tableHeader" },
                  { text: "E-mail", style: "tableHeader" },
                ],
                ...participantesPresentes.map((p) => [
                  p.name || "Nome não disponível",
                  p.email || "E-mail não disponível",
                ]),
              ],
            },
            margin: [0, 20, 0, 20],
            layout: {
              hLineWidth: (i: number, node: any) => 0.5,
              vLineWidth: (i: number, node: any) => 0.5,
              hLineColor: (i: number, node: any) => "#aaa",
              vLineColor: (i: number, node: any) => "#aaa",
              paddingLeft: (i: number, node: any) => 8,
              paddingRight: (i: number, node: any) => 8,
              paddingTop: (i: number, node: any) => 4,
              paddingBottom: (i: number, node: any) => 4,
            },
            dontBreakRows: true,
          },

          {
            text: "Detalhes da reunião",
            style: "header2",
            margin: [0, 20, 0, 10],
          },
          {
            text: "Itens em aberto",
            style: "subheader",
            margin: [0, 20, 0, 10],
          },
          {
            ol: followUp,
          },
          {
            text: "Pautas e deliberações",
            style: "subheader",
            margin: [0, 20, 0, 10],
          },
          {
            // to treat a paragraph as a bulleted list, set an array of items under the ul key
            ol: deliberacoes,
          },
          // Quebra de página
          { text: "", pageBreak: "after" },
          {
            text: "Transcrição",
            style: "subheader",
            margin: [0, 20, 0, 10],
          },
          ...(ataTexto?.[0]
            ? convertHtmlToPdfMake(ataTexto)
            : [{ text: "Transcrição pendende" }]),
          // Quebra de página
          { text: "", pageBreak: "after" },
          {
            text: "Assinaturas",
            style: "header2",
            margin: [0, 20, 0, 10],
          },
          ...assinaturas,
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          header2: {
            fontSize: 16,
            bold: true,
          },
          subheader: {
            fontSize: 14,
            bold: true,
          },
          tableHeader: {
            bold: true,
            fontSize: 12,
            color: "black",
          },
          h1: {
            fontSize: 24,
            bold: true,
          },
          h2: {
            fontSize: 20,
            bold: true,
          },
          h3: {
            fontSize: 16,
            bold: true,
          },
          bold: {
            bold: true,
          },
          italics: {
            italics: true,
          },
          underline: {
            decoration: "underline",
          },
        },
        defaultStyle: {
          fontSize: 12,
        },
        pageBreakBefore: (
          currentNode: any,
          followingNodesOnPage: any,
          nodesOnNextPage: any,
          previousNodesOnPage: any
        ) => {
          return (
            currentNode.headlineLevel === 1 && followingNodesOnPage.length === 0
          );
        },
      };

      setDocumentDefinition(document);

      const url: any = await generatePdfBlobUrl(document);

      setPdfUrl(url);
    } catch (error) {
      message.error("Erro ao atualizar a reunião antes de gerar o PDF.");
      console.error("Erro ao atualizar a reunião:", error);
    } finally {
      setIsGeneratingPDF(false); // Termina o loading no botão
    }
  };

  useEffect(() => {
    if (reuniao && cliente) {
      if (reuniao.transcricaoAta) {
        setAtaTexto(reuniao.transcricaoAta || ""); // Preenche o ReactQuill com a transcrição existente
      }

      gerarPdfAta();
    }
  }, [reuniao, cliente]);

  const responsavel_id = reuniao?.responsavel_id;
  const responsavel = usuarios?.find(
    (user: any) => user._id === responsavel_id
  );

  const handleSaveAta = async () => {
    setIsSavingTranscricao(true);
    const response = await atualizarReuniaoPorId(cppdId!, reuniao!._id, {
      transcricaoAta: ataTexto,
    });

    // Verifique se a resposta contém dados válidos
    if (response && response.transcricaoAta) {
      message.success("Trancrição atualizada com sucesso!");
    } else {
      message.error("Erro ao atualizar transcrição");
    }
    setIsSavingTranscricao(false);
  };

  const convertHtmlToPdfMake = (html: string): PdfMakeContent[] => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    const convertElement = (
      element: ChildNode,
      parentStyles: Partial<PdfMakeContent> = {}
    ): PdfMakeContent[] => {
      if (element.nodeType === Node.TEXT_NODE) {
        return [{ ...parentStyles, text: element.textContent || "" }];
      }

      const styles: Partial<PdfMakeContent> = { ...parentStyles };

      switch (element.nodeName.toLowerCase()) {
        case "strong":
          styles.bold = true;
          break;
        case "em":
          styles.italics = true;
          break;
        case "u":
          styles.decoration = "underline";
          break;
        case "a":
          styles.link = (element as HTMLAnchorElement).href;
          styles.color = "blue";
          break;
      }

      const children = Array.from(element.childNodes).flatMap((c: ChildNode) =>
        convertElement(c, styles)
      );

      if (["strong", "em", "u", "a"].includes(element.nodeName.toLowerCase())) {
        return [{ ...styles, text: children }];
      } else if (
        ["h1", "h2", "h3", "p"].includes(element.nodeName.toLowerCase())
      ) {
        const style = element.nodeName.toLowerCase();
        return [{ text: children, style, ...styles }];
      } else if (element.nodeName.toLowerCase() === "li") {
        return [
          { ...styles, text: children.map((c) => c.text || "").join("") },
        ];
      } else if (element.nodeName.toLowerCase() === "ol") {
        return [{ ol: children, margin: [0, 5], ...styles }];
      } else if (element.nodeName.toLowerCase() === "ul") {
        return [{ ul: children, margin: [0, 5], ...styles }];
      } else if (element.nodeName.toLowerCase() === "br") {
        return [{ text: "\n", ...styles }];
      }

      return children;
    };

    return convertElement(tempDiv) as PdfMakeContent[];
  };

  const downloadPdfAta = async () => {
    setIsGeneratingPDF(true); // Inicia o loading no botão
    try {
      // Atualiza a reunião com a transcrição
      await handleSaveAta();

      pdfMake
        .createPdf(documentDefinition)
        .download(
          `ATA ${dayjs(reuniao?.data_agendamento).format("DDMMYYYY")}.pdf`
        );
    } catch (error) {
      message.error("Erro ao atualizar a reunião antes de gerar o PDF.");
      console.error("Erro ao atualizar a reunião:", error);
    } finally {
      setIsGeneratingPDF(false); // Termina o loading no botão
    }
  };

  const handleConfirm = async () => {
    if (fileList.length === 0) {
      message.error("Por favor, envie um arquivo antes de confirmar.");
      return;
    }

    setIsLoading(true);

    try {
      const folderName = `${reuniao?.razao_social}-${crypto.randomUUID()}`;

      const novaPasta = await criarPastaNoCofreD4sign(folderName);
      const documento = await uploadDocumentoD4sign(
        novaPasta.uuid,
        fileList[0]
      );

      const responsavel_id = reuniao?.responsavel_id;

      const responsavel = usuarios?.find(
        (user: any) => user._id === responsavel_id
      );

      const participantesPresentes = [
        ...participante.filter((p) =>
          reuniao?.membrosPresentes?.some((m: any) => m._id === p._id)
        ),
        responsavel,
      ];

      await adicionarParticipantesD4sign(
        documento.uuid,
        participantesPresentes
      );
      await enviarDocumentoParaAssinatura(documento.uuid);

      message.success("Documento enviado para assinatura com sucesso!");
      setIsSignatureModalVisible(false);
    } catch (error) {
      console.error(
        "Erro ao listar as pastas ou realizar operações subsequentes:",
        error
      );
      message.error("Erro ao enviar documento para assinatura.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignatureUpload = () => {
    setIsSignatureModalVisible(true);
  };

  const handleSignatureModalCancel = () => {
    setIsSignatureModalVisible(false);
  };

  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    fileList: fileList,
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} arquivo enviado com sucesso.`);
      } else if (status === "error") {
        message.error(`${info.file.name} falhou ao enviar.`);
      }
      setFileList(info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <>
      {moduloTask.tasksCliente && reuniao && (
        <Modal
          title="Informações para Composição de Ata"
          open={isVisible}
          onOk={() => setIsVisible(false)}
          onCancel={() => setIsVisible(false)}
          width={1100}
          footer={[
            <Button
              key="generate"
              type="primary"
              onClick={handleSaveAta}
              icon={<SaveOutlined />}
              loading={isSavingTranscricao}
            >
              Salvar transcrição
            </Button>,
            <Button key="close" onClick={() => setIsVisible(false)}>
              Fechar
            </Button>,
          ]}
        >
          <Space style={{ marginBottom: "20px" }}>
            <Tooltip
              title={
                !ataTexto.trim()
                  ? "É necessário ter uma transcrição para gerar o PDF"
                  : ""
              }
              key="generate-tooltip"
            >
              <Button
                key="generate"
                type="primary"
                onClick={downloadPdfAta}
                // disabled={!ataTexto.trim()}
                loading={isGeneratingPDF}
                icon={<FilePdfOutlined />}
              >
                Baixar PDF
              </Button>
            </Tooltip>
            <Tooltip
              title={
                !isMembrosPresentesValid
                  ? "É necessário marcar os membros presentes no 'Dia da Reunião' antes de gerar a assinatura"
                  : ""
              }
            >
              <Button
                type="primary"
                onClick={handleSignatureUpload}
                disabled={!isMembrosPresentesValid}
              >
                Solicitar assinaturas
              </Button>
            </Tooltip>
          </Space>
          <Form layout="vertical">
            {pdfUrl && (
              <iframe
                src={pdfUrl}
                width="100%"
                height="600px"
                style={{ border: "none" }}
                title="PDF Viewer"
              />
            )}
            {/* <Form.Item label="Data da Realização">
              <Input
                value={
                  reuniao?.data_agendamento
                    ? dayjs(reuniao.data_agendamento).format("DD/MM/YYYY")
                    : "Data não disponível"
                }
                readOnly
              />
            </Form.Item>
            <Form.Item label="Empresa">
              <Input
                value={cliente?.razao_social || "Empresa não disponível"}
                readOnly
              />
            </Form.Item>
            <Form.Item label="CNPJ">
              <Input value={cliente?.cnpj || "CNPJ não disponível"} readOnly />
            </Form.Item>
            <Form.Item label="Participantes (Empresa/Cliente)">
              {participante.map((p, index) => (
                <Input.Group
                  compact
                  key={index}
                  style={{ marginBottom: "8px" }}
                >
                  <Input
                    style={{ width: "30%" }}
                    placeholder="Nome"
                    value={p.name || "Nome não disponível"}
                    readOnly
                  />
                  <Input
                    style={{ width: "30%" }}
                    placeholder="E-mail"
                    value={p.email || "E-mail não disponível"}
                    readOnly
                  />
                  <Input
                    style={{ width: "30%" }}
                    placeholder="Celular"
                    value={p.phone || "Celular não disponível"}
                    readOnly
                  />
                </Input.Group>
              ))}
            </Form.Item> */}
            <br />
            <br />
            <Form.Item name="ataTexto" label="Editar transcrição da reunião">
              <div>
                <ReactQuill
                  value={ataTexto}
                  formats={[
                    "header",
                    "font",
                    "size",
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "blockquote",
                    "list",
                    "bullet",
                    "indent",
                    "link",
                    "image",
                    "video",
                  ]}
                  modules={{
                    toolbar: [
                      [{ font: [] }],
                      [{ size: [] }],
                      ["bold", "italic", "underline", "strike", "blockquote"],
                      [
                        { list: "ordered" },
                        { list: "bullet" },
                        { indent: "-1" },
                        { indent: "+1" },
                      ],
                      ["link", "image"],
                      ["clean"],
                    ],
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  placeholder="Escreva a transcrição"
                  onChange={(v) => setAtaTexto(v)}
                />
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}

      <Modal
        title="Envio de Assinatura"
        open={isSignatureModalVisible}
        onCancel={handleSignatureModalCancel}
        footer={[
          <Button key="cancel" onClick={handleSignatureModalCancel}>
            Cancelar
          </Button>,
          <Button key="confirm" type="primary" onClick={handleConfirm}>
            Confirmar
          </Button>,
        ]}
      >
        <Dragger {...uploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique ou arraste o arquivo para esta área para fazer o upload
          </p>
          <p className="ant-upload-hint">Apenas arquivos PDF são suportados.</p>
        </Dragger>
      </Modal>

      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999,
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </>
  );
};

export default AtaModal;
