import React, { useEffect, useState } from "react";
import { Timeline, Spin, Button } from "antd";
import moment from "moment";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";

interface Log {
  _id: string;
  title: string;
  description?: string;
  created_at: string;
  user_id: string;
  local?: {
    reference_model_name: string;
    reference_model_id: string[];
  }[];
}

interface TimelineFichasProps {
  mapeamentoId?: string;
  logs?: any[] | null;
  loadingLogs?: boolean;
}

const TimelineFicha: React.FC<TimelineFichasProps> = ({
  mapeamentoId,
  logs,
  loadingLogs,
}) => {
  const { usuarios } = React.useContext(GlobalContext) as GlobalContextType;

  const [visibleLogs, setVisibleLogs] = useState(6); // Mostrar 10 logs inicialmente
  const [filteredLogs, setFilteredLogs] = useState<any[] | null>(null); // Mostrar 10 logs inicialmente

  const handleLoadMore = () => {
    setVisibleLogs((prevVisibleLogs) => prevVisibleLogs + 10); // Carregar mais 10 logs
  };

  useEffect(() => {
    if (logs && mapeamentoId) {
      setFilteredLogs(
        logs
          .sort(
            (a: Log, b: Log) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "questionario"
            )
              ? log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
          .filter((log: Log) =>
            log.local?.find(
              (local) => local.reference_model_name === "responder-questionario"
            )
              ? log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )?.reference_model_id?.[0] === mapeamentoId
              : true
          )
      );
    }
  }, [logs, mapeamentoId]);

  return (
    <div>
      {/* historico */}
      {loadingLogs && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Spin size="small" />
          <p style={{ marginLeft: "0.5rem", color: "#b3b3b3" }}>
            Verificando logs...
          </p>
        </div>
      )}
      {logs && (
        <div
          style={{
            marginTop: "2rem",
            pageBreakBefore: "always",
          }}
        >
          <h3>Histórico</h3>
          <hr />
          {/* <Timeline style={{ marginLeft: "1rem" }}>
            {logs
              .sort(
                (a: Log, b: Log) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
              )
              // filtro por mapeamento/questionario cadastrado
              ?.filter((log: Log) =>
                log.local?.find(
                  (local) => local.reference_model_name === "questionario"
                )
                  ? log.local?.find(
                      (local) => local.reference_model_name === "questionario"
                    )?.reference_model_id?.[0] === mapeamentoId
                  : true
              )
              // filtro de usuario cadstrado/atualizado por mapeamento/questionario
              ?.filter((log: Log) =>
                log.local?.find(
                  (local) =>
                    local.reference_model_name === "responder-questionario"
                )
                  ? log.local?.find(
                      (local) =>
                        local.reference_model_name === "responder-questionario"
                    )?.reference_model_id?.[0] === mapeamentoId
                  : true
              )
              ?.map((log: Log) => (
                <Timeline.Item key={log._id}>
                  <div className="ficha__log">
                    <p className="ficha__log--date">
                      {moment(new Date(log.created_at)).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </p>
                    <p className="ficha__log--title">{log.title}</p>
                    {usuarios?.find((usr: any) => usr._id === log.user_id) && (
                      <p className="ficha__log--user">
                        por{" "}
                        <span>
                          {
                            usuarios?.find(
                              (usr: any) => usr._id === log.user_id
                            ).name
                          }
                        </span>
                      </p>
                    )}
                  </div>
                  <p className="ficha__log--desc">{log.description}</p>
                </Timeline.Item>
              ))}
          </Timeline> */}
          <Timeline style={{ marginLeft: "1rem" }}>
            {filteredLogs?.slice(0, visibleLogs).map((log: Log) => (
              <Timeline.Item key={log._id}>
                <div className="ficha__log">
                  <p className="ficha__log--date">
                    {moment(new Date(log.created_at)).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                  </p>
                  <p className="ficha__log--title">{log.title}</p>
                  {usuarios?.find((usr: any) => usr._id === log.user_id) && (
                    <p className="ficha__log--user">
                      por{" "}
                      <span>
                        {
                          usuarios?.find((usr: any) => usr._id === log.user_id)
                            .name
                        }
                      </span>
                    </p>
                  )}
                </div>
                <p className="ficha__log--desc">{log.description}</p>
              </Timeline.Item>
            ))}
          </Timeline>
          {filteredLogs && visibleLogs < filteredLogs?.length && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 0",
              }}
            >
              <Button onClick={handleLoadMore} type="primary">
                Ver Mais
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineFicha;
