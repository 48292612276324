import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import "./styles.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";

interface BackHeaderProps {
  title: string;
  route?: string;
  prev?: boolean;
}

const BackHeader: React.FC<BackHeaderProps> = ({ title, route, prev }) => {
  const navigate = useNavigate();
  const { setRote, rote } = useContext(GlobalContext) as GlobalContextType;

  useEffect(() => {
    setRote({ title, route, prev });
  }, [title, route, prev]);

  return (
    <></>
    // <div className="back-route">
    //   {route && <ArrowLeftOutlined className="back" onClick={() => navigate(route)} />}
    //   {prev && <ArrowLeftOutlined className="back" onClick={() => navigate(-1)} />}
    //   <h2>{title}</h2>
    // </div>
  );
};

export default BackHeader;
