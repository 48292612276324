import * as React from "react";
import "./styles.css";
import { Table, Input, Button } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";

const { Search } = Input;

const Perguntas = () => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [searchCap, setSearchCap] = React.useState<string>("");

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
  ];

  const onSearchCap = (value: string) => setSearchCap(value);

  return (
    <div className="clientes">
      <BackHeader title={"Perguntas"} />

      <div className="clientes__actions">
        <Search
          placeholder="Buscar pergunta"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          style={{ marginRight: "5rem" }}
          onSearch={onSearchCap}
        />
        <Button className="clientes__actions--new" type="primary">
          Nova pergunta
        </Button>
      </div>

      <Table
        className="clientes__list"
        dataSource={moduloProcessos.perguntas?.filter((c: any) =>
          c.description.toUpperCase().includes(searchCap.toUpperCase())
        )}
        columns={columns}
      />
    </div>
  );
};

export default Perguntas;
