import React, { useEffect, useState } from "react";
import "./styles.css";
import Metricas from "./components/Metricas/Metricas";
import Respostas from "./components/Respostas/Respostas";
import { Alert, Collapse } from "antd";
import { AnaliseTypes } from "../../../../../@types/analise";
import { GlobalContext } from "../../../../../context/globalContext";
import { GlobalContextType } from "../../../../../@types/global";
import api from "../../../../../services/api";
import { useParams } from "react-router-dom";

const Analise: React.FC<AnaliseTypes> = ({ questId }) => {
  const { windowWidth } = React.useContext(GlobalContext) as GlobalContextType;
  const [activeCat, setActiveCat] = useState<any>(
    "Informações gerais da empresa"
  );
  const [tratamentoDados, setTratamentoDados] = useState(true);

  const params = useParams();

  useEffect(() => {
    const verificaExistenciaTratamento = async () => {
      const response = await api.get(`/respostasforn/${params.questionarioid}`);

      if (
        response.data.respostas.find(
          (q: any) => q.questao_id === "660fee5de65c9279bff20fa8"
        ) &&
        response.data.respostas.find(
          (q: any) => q.questao_id === "64ee165a41736da1927a46b1"
        )
      ) {
        if (
          response.data.respostas
            .find((q: any) => q.questao_id === "660fee5de65c9279bff20fa8")
            ?.resposta.includes("Sim") &&
          response.data.respostas
            .find((q: any) => q.questao_id === "64ee165a41736da1927a46b1")
            ?.resposta.includes("Não")
        ) {
          setTratamentoDados(false);
        } else if (
          response.data.respostas
            .find((q: any) => q.questao_id === "660fee5de65c9279bff20fa8")
            ?.resposta.includes("Não") ||
          response.data.respostas
            .find((q: any) => q.questao_id === "64ee165a41736da1927a46b1")
            ?.resposta.includes("Sim")
        ) {
          setTratamentoDados(true);
        }
      }
    };

    verificaExistenciaTratamento();
  }, []);

  const itemsCollapse = [
    {
      key: "1",
      label: "Métricas",
      children: tratamentoDados ? (
        <Metricas />
      ) : (
        <Alert
          type="info"
          message="Identificou-se que não haverá tratamento de dados, de modo que o questionário não foi aplicado na íntegra e os gráficos sobre maturidade e riscos não são disponibilizados."
          showIcon
        />
      ),
    },
    {
      key: "2",
      label: "Respostas",
      children: <Respostas activeCap={activeCat} questId={questId} />,
    },
  ];

  const categorias = [
    "Informações gerais da empresa",
    "Encarregado de Proteção de dados (DPO)",
    "Identificação dos tratamentos de dados pessoais",
    "Conformidade à Privacidade e Proteção de dados (LGPD)",
    "Atendimento aos direitos do titular de dados",
    "Tecnologia da Informação",
    "Segurança da Informação",
    "Treinamento e conscientização",
    "Cultura de Segurança da informação",
    "Histórico de incidentes de segurança envolvendo dados pessoais",
    "Gerenciamento de Banco de dados",
    "Transferência internacional de dados",
  ];

  return (
    <div className="analise">
      {windowWidth > 800 && (
        <div className="analise__indice">
          <p className="analise__indice--title">Índice</p>
          {(tratamentoDados
            ? categorias
            : [
                "Informações gerais da empresa",
                "Encarregado de Proteção de dados (DPO)",
                "Identificação dos tratamentos de dados pessoais",
              ]
          ).map((cat: any) => {
            return (
              <p
                key={cat}
                className={`${
                  activeCat === cat
                    ? "analise__indice--cap__active"
                    : "analise__indice--cap"
                }`}
                onClick={() => setActiveCat(cat)}
              >
                {cat}
              </p>
            );
          })}
        </div>
      )}

      <div className="analise__content">
        <Collapse items={itemsCollapse} defaultActiveKey={["1", "2"]} />
      </div>
    </div>
  );
};

export default Analise;
