import * as React from "react";
import "./styles.css";
import { Table, Input, Button, Tag, Alert } from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import {
  CheckOutlined,
  CloseOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import NewUser from "../../../components/NewUser/NewUser";
import moment from "moment";

const { Search } = Input;

interface UsuariosProps {
  noHeader: boolean;
  cliente_id: string | undefined;
  cppd?: boolean;
}

const Usuarios: React.FC<UsuariosProps> = ({ noHeader, cliente_id, cppd }) => {
  const {
    usuarios,
    clientes,
    currentCliente,
    setCurrentUser,
    auth0Users,
    userInfo,
    moduloMapeamento,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [searchUser, setSearchUser] = React.useState<string>("");
  const [showModal, setShowModal] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState<
    string | undefined | null
  >(cliente_id);

  const columnsUsers = [
    {
      title: "Nome",
      dataIndex: "name",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Cliente",
      dataIndex: "cliente_id",
      render: (clienteId: string) =>
        clientes?.find((cli: any) => cli._id === clienteId)?.razao_social,
    },

    {
      title: "Grupo",
      dataIndex: "grupos",
      render: (grupos: any[]) => {
        return grupos.map((g: any) => {
          let cor = "blue";

          switch (g.name) {
            case "seusdados":
              cor = "red";
              break;
            case "assinante":
              cor = "volcano";
              break;
            default:
              break;
          }
          return <Tag color={cor}>{g.name.toUpperCase()}</Tag>;
        });
      },
    },
    {
      title: "Status",
      dataIndex: "inativo",
      render: (inativo: any) =>
        inativo ? (
          <Button type="link" style={{ color: "red" }} icon={<CloseOutlined />}>
            Inativo
          </Button>
        ) : (
          <Button
            type="link"
            style={{ color: "green" }}
            icon={<CheckOutlined />}
          >
            Ativo
          </Button>
        ),
    },
  ];

  const onSearchUser = (value: string) => setSearchUser(value);

  React.useEffect(() => {
    if (currentCliente) {
      setSelectedClientId(currentCliente._id);
    } else if (!currentCliente && cliente_id) {
      setSelectedClientId(cliente_id);
    } else {
      setSelectedClientId(null);
    }
  }, [currentCliente]);

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  const ListagemUsuarios = () => {
    return (
      <>
        {" "}
        <div className="clientes__actions">
          <Search
            placeholder="Buscar usuário, cliente ou grupo"
            className="clientes__actions--search"
            allowClear
            enterButton="Buscar"
            style={{ marginRight: "5rem" }}
            onSearch={onSearchUser}
          />
          <Button
            className="clientes__actions--new"
            type="primary"
            onClick={() => {
              setCurrentUser(null);
              setShowModal(true);
            }}
            icon={<UserAddOutlined />}
          >
            Novo usuário
          </Button>
        </div>
        <Table
          dataSource={usuarios
            ?.filter((u: any) =>
              selectedClientId ? u.cliente_id === selectedClientId : true
            )
            ?.filter((u: any) =>
              cppd
                ? u.grupos
                    ?.map((g: any) => g._id)
                    .some((grupo: any) => gruposDoComite.includes(grupo))
                : true
            )
            ?.filter(
              (u: any) =>
                u.name
                  .replaceAll(" ", "")
                  .toUpperCase()
                  .includes(searchUser.replaceAll(" ", "").toUpperCase()) ||
                u.email
                  .replaceAll(" ", "")
                  .toUpperCase()
                  .includes(searchUser.replaceAll(" ", "").toUpperCase())
              //  ||
              //   u.grupos.find((g: any) => g.name.toUpperCase() === searchUser.toUpperCase()) ||
              //   u.cliente_id === clientes.find((cli: any) => cli.razao_social.toUpperCase().includes(searchUser.toUpperCase()))?._id,
            )}
          size="small"
          style={{ cursor: "pointer" }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                setShowModal(true);
                setCurrentUser(record);
              },
            };
          }}
          columns={
            auth0Users
              ? [
                  ...columnsUsers,
                  {
                    title: "Último acesso",
                    dataIndex: "_id",
                    render: (_: any, record: any) =>
                      auth0Users?.find(
                        (authUser: any) =>
                          authUser?.user_id === record?.auth_user_id
                      )?.last_login
                        ? moment(
                            new Date(
                              auth0Users?.find(
                                (authUser: any) =>
                                  authUser?.user_id === record?.auth_user_id
                              )?.last_login
                            )
                          ).format("DD/MM/YYYY HH:mm")
                        : "-",
                  },
                  {
                    title: "Qtd. Acessos",
                    dataIndex: "_id",
                    render: (_: any, record: any) =>
                      auth0Users?.find(
                        (authUser: any) =>
                          authUser?.user_id === record?.auth_user_id
                      )?.logins_count || "-",
                  },
                ]
              : columnsUsers
          }
        />
      </>
    );
  };

  return (
    <div className="usuarios">
      {!noHeader && (
        <BackHeader
          title={`Usuários${
            currentCliente?.razao_social
              ? " - " +
                (currentCliente?.razao_social?.length > 25
                  ? currentCliente?.razao_social?.substring(0, 25) + "..."
                  : currentCliente?.razao_social)
              : ""
          }
          `}
          prev
        />
      )}
      {userInfo?.grupos?.find((gp: any) => gp.name === "seusdados") ? (
        <ListagemUsuarios />
      ) : currentCliente ? (
        <ListagemUsuarios />
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}

      <NewUser
        currentMapeamento={moduloMapeamento?.currentMapeamento}
        type="grupo"
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default Usuarios;
