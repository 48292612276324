import * as React from "react";
import { SaveBtnTypes } from "../../@types/buttom";
import "./styles.css";
import { SaveOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const SaveBtn: React.FC<SaveBtnTypes> = ({ onClick, type, text }) => {
  return (
    <Tooltip title="Salvar progresso">
      <button type={type || "button"} onClick={onClick} className={`saveBtn ${text && "saveBtn-text"}`}>
        {text && <p style={{ color: "#fff" }}>{text}</p>}
        <SaveOutlined rev={undefined} />
      </button>
    </Tooltip>
  );
};
export default SaveBtn;
