import { EditOutlined } from "@ant-design/icons";
import { Button, Select, Tag } from "antd";
import React, { useState } from "react";

const { Option } = Select;

interface ReuniaoStatusProps {
  reuniaoId: string;
  status: string;
  onUpdateStatus: (reuniaoId: string, newStatus: string) => Promise<void>;
}

const ReuniaoStatus: React.FC<ReuniaoStatusProps> = ({
  reuniaoId,
  status,
  onUpdateStatus,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>(status);
  const [loading, setLoading] = useState<boolean>(false);

  const handleStatusChange = (value: string) => {
    setSelectedStatus(value);
  };

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      await onUpdateStatus(reuniaoId, selectedStatus);
      setIsEditing(false);
    } catch (error) {
      console.error("Erro ao atualizar status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <p>Status Atual:</p>
      {isEditing ? (
        <>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            style={{ width: 150 }}
          >
            <Option value="Pendente">Pendente</Option>
            {/* <Option value="Em Andamento">Em Andamento</Option> */}
            <Option value="Realizada">Realizada</Option>
          </Select>
          <Button type="primary" onClick={handleUpdateStatus} loading={loading}>
            Ok
          </Button>
        </>
      ) : (
        <>
          <Tag
            color={
              status === "Pendente"
                ? "red"
                : status === "Em Andamento"
                ? "orange"
                : "green"
            }
          >
            {status.replace("finalizda", "Realizada")}
          </Tag>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => setIsEditing(true)}
          >
            Editar
          </Button>
        </>
      )}
    </div>
  );
};

export default ReuniaoStatus;
