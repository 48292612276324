import React, { useEffect, useState } from "react";
import { Upload, Button, Table, Collapse, Empty, Tag } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  SaveOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useParams } from "react-router-dom";
import moment from "moment";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import OWASP from "../OWASP/OWASP";

const OwaspCliente = () => {
  const { postAnexoOwasp, getAnexosOwasp, anexosOwasp } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const params = useParams();

  const [importedData, setImportedData] = useState<any>(null);
  const [currentRelatorio, setCurrentRelatorio] = useState<any>(null);
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    params.id && getAnexosOwasp(params.id);
  }, [params.id]);

  const handleImport = async (file: any) => {
    try {
      setFile(file);
      const fileContent: any = await readFile(file);
      const jsonData = JSON.parse(fileContent);
      setImportedData(jsonData);
    } catch (error) {
      console.error("Erro ao processar o arquivo JSON:", error);
    }
  };

  const readFile = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        resolve(e.target.result);
      };

      reader.onerror = (e: any) => {
        reject(e.target.error);
      };

      reader.readAsText(file);
    });
  };

  const columns = [
    {
      title: "Nome do program",
      dataIndex: "@programName",
      key: "@programName",
      render: (text: string) => text,
    },
    {
      title: "Versão",
      dataIndex: "@version",
      key: "@version",
      render: (text: string) => text,
    },
    {
      title: "Data geração",
      dataIndex: "@generated",
      key: "@generated",
      render: (text: string) => text,
    },
  ];
  const downloadAndReadFile = async (url: string) => {
    setCurrentRelatorio(null);
    try {
      // Fazer o Download do Arquivo
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Erro ao baixar o arquivo");
      }

      // Ler o Conteúdo do Arquivo como ArrayBuffer
      const arrayBuffer = await response.arrayBuffer();

      // Converter ArrayBuffer para JSON
      const jsonString = new TextDecoder().decode(arrayBuffer);
      const jsonData = JSON.parse(jsonString);

      setCurrentRelatorio(jsonData);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  return (
    <div>
      <BackHeader title="Análise do site" />
      <OWASP />
    </div>
  );
};

export default OwaspCliente;
