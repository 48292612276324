import { useNavigate } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import { Button, Form, Input, InputNumber, Switch, Table } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import Search from "antd/es/input/Search";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";

const Analises = () => {
  const { clientes } = React.useContext(GlobalContext) as GlobalContextType;
  const [search, setSearch] = React.useState<string>("");

  const navigate = useNavigate();

  const onChange = () => {};

  const columns = [
    {
      title: "Cliente",
      dataIndex: "razao_social",
    },

    {
      title: "Ativo",
      dataIndex: "ativo",
      render: (v: any) => <Switch checked={false} onChange={onChange} />,
    },
  ];
  const onFinish = (values: any) => {
    console.log(values);
  };
  const onSearch = (value: string) => setSearch(value);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <div className="analises">
      <BackHeader title="Análises" route="/" />
      <Search
        placeholder="Buscar questionário"
        allowClear
        enterButton="Buscar"
        onSearch={onSearch}
        style={{ width: "60%", marginBottom: "2rem" }}
      />

      <Table
        className="clientes__list"
        dataSource={clientes?.filter((c: any) =>
          c.razao_social.toUpperCase().includes(search.toUpperCase())
        )}
        columns={columns}
        style={{ cursor: "pointer" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate("/analise/questionarios/" + record._id);
            },
          };
        }}
      />
    </div>
  );
};

export default Analises;
