import { useNavigate } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import { Button, Form, Input, InputNumber } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";

const Book = () => {
  const navigate = useNavigate();
  const onFinish = (values: any) => {
    console.log(values);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <div className="editUsers">
      <BackHeader title="Interpretação Book" route="/" />
      <Form
        {...layout}
        layout="vertical"
        name="edit-users-form"
        onFinish={onFinish}
      >
        <Form.Item name={"book"} label="Cabeçalho">
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          {/* <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}> */}
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Book;
