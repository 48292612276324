import {
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  RightCircleFilled,
  RightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Collapse,
  Input,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { Questionario } from "../../../../context/mapeamentoContext";
import { CapituloType } from "../../../../context/processosContext";
import GuiasMapeamento from "./components/GuiasMapeamento";
import "./styles.css";
import { BackSquare } from "iconsax-react";
import moment from "moment";
import { useParams } from "react-router-dom";
import Fichas from "../../../Modulos/Fichas/Fichas";

interface MapeamentoProps {
  currentMapeamento: Questionario | null; // ou qualquer outro tipo apropriado
  setCurrentMapeamento: (mapeamento: Questionario | any) => void;
}

const Mapeamento: React.FC<MapeamentoProps> = ({
  currentMapeamento,
  setCurrentMapeamento,
}) => {
  const { userInfo, moduloProcessos, moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [formValues, setFormValues] = useState<any>({});
  const [disabled, setDisabled] = useState<string[]>([]);
  const [clickedProcesso, setClickedProcesso] = useState<any | null>(null);
  const [tabProcesso, setTabProcesso] = useState<any | null>(null);
  const [showMapeamentos, setShowMapeamentos] = useState<boolean>(false);
  const [newProcess, setNewProcess] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [icon, setIcon] = useState<any>(<SaveOutlined />);

  const [showFicha, setShowFicha] = useState<any>(false);
  const [fichaClienteId, setFichaClienteId] = useState<any>(null);
  const [fichaMapeamentoId, setFichaMapeamentoId] = useState<any>(null);
  const [fichaProcessoId, setFichaProcessoId] = useState<any>(null);
  const [fichaAreaId, setFichaAreaId] = useState<any>(null);

  const [showMapeamentosInitialized, setShowMapeamentosInitialized] =
    useState(false);

  useEffect(() => {
    if (currentMapeamento && !showMapeamentosInitialized) {
      const hasDependencia = currentMapeamento.capitulos?.some(
        (p: any) => p.dependencia && !p.inativo
      );

      if (hasDependencia) {
        setShowMapeamentos(true);
        setShowMapeamentosInitialized(true);
      } else {
        setShowMapeamentosInitialized(true);
      }
    }
  }, [currentMapeamento, showMapeamentosInitialized]);

  // const handleInputChange = (fieldName: string, value: any) => {
  //   setFormValues((prevValues: any) => ({ ...prevValues, [fieldName]: value }));
  // };

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const toggleProcesso = (processo: CapituloType) => {
    if (currentMapeamento) {
      const currentProcessos = currentMapeamento.capitulos || [];
      const newProcesso = currentProcessos.find(
        (p: any) => p._id === processo._id
      )
        ? [
            ...currentProcessos.filter((p: any) => p._id !== processo._id),
            {
              ...currentProcessos.find((p: any) => p._id === processo._id),
              inativo: !currentProcessos.find(
                (p: any) => p._id === processo._id
              ).inativo,
            },
          ]
        : [
            ...currentProcessos,
            { ...processo, areas_id: [currentMapeamento?.areas?.[0]] },
          ];
      setCurrentMapeamento({ ...currentMapeamento, capitulos: newProcesso });

      setClickedProcesso(processo._id);
    }
  };

  const handleAddProcess = (dependencia_id: string | undefined) => {
    if (dependencia_id) {
      // posta o novo processo e retorna o _id
      moduloProcessos?.postCapitulo({
        id: null,
        description: newProcess?.processo,
        norma: "",
        recomendacao: "",
        dependencia: dependencia_id,
      });

      setNewProcess("");
    }
  };

  return tabProcesso ? (
    <div className="mapeamentos">
      <GuiasMapeamento
        setTabProcesso={setTabProcesso}
        mapeamento={currentMapeamento}
        setMapeamento={setCurrentMapeamento}
        processo={tabProcesso}
      />
    </div>
  ) : showMapeamentos ? (
    <div className="mapeamentos">
      <h5>
        <span
          onClick={() => {
            if (showFicha) {
              setFichaAreaId(null);
              setFichaProcessoId(null);
              setFichaMapeamentoId(null);
              setFichaClienteId(null);
              setFichaClienteId(null);
              setShowFicha(false);
            } else {
              setShowMapeamentos(false);
            }
          }}
          style={{ cursor: "pointer", color: "#a3a3a3" }}
        >
          <BackSquare size={18} style={{ marginBottom: "-2px" }} /> Etapa
          anterior
        </span>
      </h5>

      {!showFicha && (
        <>
          <br />
          <div
            className="mapeamentos_containner"
            style={{ overflow: "hidden" }}
          >
            <div className={"mapeamentos_steps"}>
              <h2>
                Meus processos{" "}
                <Tooltip title="Todos os processos designados a você estão aqui.">
                  <ExclamationCircleOutlined
                    style={{ fontSize: 16, cursor: "pointer" }}
                  />
                </Tooltip>
              </h2>

              {currentMapeamento?.abordagem === "detalhada" &&
                currentMapeamento?.capitulos?.filter(
                  (p: any) => p.dependencia && !p.inativo
                )?.[0] && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      {currentMapeamento?.abordagem === "detalhada" &&
                        currentMapeamento?.capitulos
                          // ?.filter((processo: any) =>
                          //   processo.tags?.find(
                          //     (area: any) =>
                          //       area === currentMapeamento?.areas?.[0]
                          //   )
                          // )
                          ?.filter((processo: any) => !processo.dependencia)
                          .map((processo: any) => (
                            <Table
                              size="small"
                              title={() => <h5>{processo.description}</h5>}
                              bordered
                              dataSource={currentMapeamento?.capitulos
                                ?.filter((process: any) => !process.inativo)
                                ?.filter(
                                  (process: any) =>
                                    process.dependencia === processo._id
                                )}
                              columns={[
                                {
                                  title: "Processo",
                                  dataIndex: "description",
                                  key: "description",
                                },

                                {
                                  title: "Data de início",
                                  dataIndex: "created_at",
                                  key: "created_at",
                                  render: (value: any, record) =>
                                    moduloMapeamento?.currentTitulares?.find(
                                      (titular) =>
                                        titular.relacao_id ===
                                        record?.questionarioCapitulo?._id
                                    )?.created_at ? (
                                      <p style={{ fontSize: 12 }}>
                                        {moment(
                                          new Date(
                                            moduloMapeamento?.currentTitulares?.find(
                                              (titular) =>
                                                titular.relacao_id ===
                                                record?.questionarioCapitulo
                                                  ?._id
                                            )?.created_at || ""
                                          )
                                        ).format("DD/MM/YYYY HH:mm")}
                                      </p>
                                    ) : (
                                      "-"
                                    ),
                                },
                                {
                                  title: "Status",
                                  dataIndex: "description",
                                  key: "description",
                                  render: (_, record) => {
                                    let status = "Pendente";

                                    if (
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          titular.faixa_etaria &&
                                          titular.categoria &&
                                          titular.quantidade &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) ||
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0]?.frequencia &&
                                          (titular.dados?.[0]
                                            .bases_legais_comuns ||
                                            titular.dados?.[0]
                                              .bases_legais_sensiveis) &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) ||
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          (titular.dados?.[0]
                                            .areas_compartilhadas?.[0] ||
                                            titular.dados?.[0].externos?.[0]) &&
                                          titular.dados?.[0]
                                            .compartilhamento?.[0] &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) ||
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          titular.dados?.[0].local?.[0] &&
                                          titular.dados?.[0].prazo &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      )
                                    ) {
                                      status = "Em andamento";
                                    }

                                    if (
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          titular.faixa_etaria &&
                                          titular.categoria &&
                                          titular.quantidade &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) &&
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0]?.frequencia &&
                                          (titular.dados?.[0]
                                            .bases_legais_comuns ||
                                            titular.dados?.[0]
                                              .bases_legais_sensiveis) &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) &&
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          (titular.dados?.[0]
                                            .areas_compartilhadas?.[0] ||
                                            titular.dados?.[0].externos?.[0]) &&
                                          titular.dados?.[0]
                                            .compartilhamento?.[0] &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      ) &&
                                      moduloMapeamento?.currentTitulares?.find(
                                        (titular) =>
                                          titular.dados?.[0] &&
                                          titular.dados?.[0].local?.[0] &&
                                          titular.dados?.[0].prazo &&
                                          titular.relacao_id ===
                                            record?.questionarioCapitulo?._id
                                      )
                                    ) {
                                      status = "Concluído";
                                    }

                                    return (
                                      <p
                                        style={{
                                          color:
                                            status === "Em andamento"
                                              ? "#0368c8"
                                              : status === "Concluído"
                                              ? "#008C76"
                                              : "",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {status}
                                      </p>
                                    );
                                  },
                                },
                                {
                                  title: "Ações",
                                  dataIndex: "_id",
                                  key: "_id",
                                  render: (_, record) => (
                                    <>
                                      <Button
                                        // icon={<RightOutlined />}
                                        style={
                                          {
                                            // backgroundColor: "#008C76",
                                          }
                                        }
                                        size="small"
                                        type="primary"
                                        onClick={() => {
                                          setTabProcesso(record);
                                        }}
                                      >
                                        Ir para mapeamento{" "}
                                        <RightCircleFilled
                                          style={{ color: "#fff" }}
                                        />
                                      </Button>
                                      <Button
                                        // icon={<RightOutlined />}
                                        style={{
                                          backgroundColor: "#008C76",
                                          marginLeft: "1rem",
                                        }}
                                        size="small"
                                        type="primary"
                                        onClick={() => {
                                          setFichaAreaId(
                                            currentMapeamento?.areas?.[0]
                                          );
                                          setFichaProcessoId(record._id);
                                          setFichaMapeamentoId(
                                            currentMapeamento._id
                                          );
                                          setFichaClienteId(
                                            currentMapeamento.cliente_id
                                          );
                                          setFichaClienteId(
                                            currentMapeamento.cliente_id
                                          );
                                          setShowFicha(true);
                                        }}
                                      >
                                        Ficha de processo{" "}
                                        <RightCircleFilled
                                          style={{ color: "#fff" }}
                                        />
                                      </Button>
                                    </>
                                  ),
                                },
                              ]}
                            />
                          ))}
                    </div>
                  </div>
                )}

              {currentMapeamento?.abordagem === "simplificada" && (
                <div style={{}}>
                  <br />
                  <p
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <ExclamationCircleOutlined /> Selecione o processo para
                    iniciar mapeamento
                  </p>
                  <br />
                  <div style={{ width: "100%" }}>
                    <Table
                      size="small"
                      // title={() => <h5>Midias Sociais</h5>}
                      bordered
                      dataSource={currentMapeamento?.capitulos
                        ?.filter((process: any) => !process.inativo)
                        ?.filter((process: any) => !process.dependencia)}
                      columns={[
                        {
                          title: "Processo",
                          dataIndex: "description",
                          key: "description",
                        },

                        {
                          title: "Data de inicio",
                          dataIndex: "created_at",
                          key: "created_at",
                          render: (value: any) =>
                            moment(new Date(value)).format("DD/MM/YYYY HH:mm"),
                        },
                        {
                          title: "Status",
                          dataIndex: "description",
                          key: "description",
                          render: () => (
                            <p style={{ color: "#0368c8", fontWeight: "bold" }}>
                              Em andamento
                            </p>
                          ),
                        },
                        {
                          title: "Ações",
                          dataIndex: "_id",
                          key: "_id",
                          render: (_, record) => (
                            <>
                              <Button
                                // icon={<RightOutlined />}
                                style={
                                  {
                                    // backgroundColor: "#008C76",
                                  }
                                }
                                size="small"
                                type="primary"
                                onClick={() => {
                                  setTabProcesso(record);
                                }}
                              >
                                Ir para mapeamento{" "}
                                <RightCircleFilled style={{ color: "#fff" }} />
                              </Button>
                              <Button
                                // icon={<RightOutlined />}
                                style={{
                                  backgroundColor: "#008C76",
                                  marginLeft: "1rem",
                                }}
                                size="small"
                                type="primary"
                                onClick={() => {
                                  setFichaAreaId(currentMapeamento?.areas?.[0]);
                                  setFichaProcessoId(record._id);
                                  setFichaMapeamentoId(currentMapeamento._id);
                                  setFichaClienteId(
                                    currentMapeamento.cliente_id
                                  );
                                  setFichaClienteId(
                                    currentMapeamento.cliente_id
                                  );
                                  setShowFicha(true);
                                }}
                              >
                                Ficha de processo{" "}
                                <RightCircleFilled style={{ color: "#fff" }} />
                              </Button>
                            </>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {showFicha &&
        fichaClienteId &&
        fichaMapeamentoId &&
        fichaAreaId &&
        fichaProcessoId && (
          <Fichas
            filtrosFeitos={{
              cliente: fichaClienteId,
              mapeamento: fichaMapeamentoId,
              area: fichaAreaId,
              processo: fichaProcessoId,
            }}
          />
        )}
    </div>
  ) : (
    <div className="mapeamentos">
      {!userInfo?.grupos?.find(
        (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
      ) && (
        <>
          <h1>Olá, {userInfo?.name?.split(" ")[0]}</h1>
          <br />
          <p>
            Você recebeu{" "}
            <b>
              {
                currentMapeamento?.capitulos.filter(
                  (c: any) => !c.dependencia && !c.inativo
                )?.length
              }
            </b>{" "}
            processo
            {currentMapeamento?.capitulos?.filter(
              (c: any) => !c.dependencia && !c.inativo
            ).length > 1 && "s"}{" "}
            para cadastrar na sua área.
          </p>
          <br />
        </>
      )}

      {currentMapeamento?.capitulos?.[0] ? (
        <div className="mapeamentos_containner">
          <div
            className="mapeamentos_steps"
            style={{ minWidth: "350px", overflowX: "auto" }}
          >
            <h5>Escolha a abordagem desejada:</h5>
            <br />
            <div className="d-flex" style={{ justifyContent: "space-between" }}>
              <div
                className="card_mode"
                onClick={() => {
                  setCurrentMapeamento((prev: Questionario | any) => {
                    return {
                      ...prev,
                      abordagem: "detalhada",
                    };
                  });
                  moduloMapeamento?.saveQuestionario(
                    currentMapeamento?.cliente_id,
                    {
                      ...currentMapeamento,
                      abordagem: "detalhada",
                      perguntasRelacao:
                        moduloProcessos?.capituloPerguntas?.filter((cp: any) =>
                          currentMapeamento?.capitulos
                            .map((c: any) => c._id)
                            .includes(cp.capitulo_id)
                        ),
                    }
                  );
                }}
              >
                {currentMapeamento?.abordagem === "detalhada" && (
                  <div className="card_mode--selected">
                    <CheckCircleFilled
                      style={{
                        fontSize: "80px",
                        color: "#32E6BE",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                )}
                <p
                  style={{
                    fontWeight: "bold",
                    fontSize: "0.6vw",
                    color: "#fff",
                    background: "#5f29cc",
                    borderRadius: "6px 6px 0  0",
                    height: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  MAIS RECOMENDADA
                </p>
                <br />
                <p
                  style={{
                    fontSize: "1.4rem",
                    color: "#5f29cc",
                    textAlign: "center",
                  }}
                >
                  Detalhada
                </p>
                <div
                  style={{
                    background: "#5f29cc",
                    height: "1px",
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0.5rem",
                  }}
                />
                <br />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: "#333333",
                    padding: "0 1rem",
                    height: 180,
                  }}
                >
                  Ao selecionar esta opção, você receberá um fluxo de
                  subprocessos para cadastrar.
                </p>
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <Button type="primary" style={{ background: "#5F29CC" }}>
                    Selecionar
                  </Button>
                </div>
                <br />
                <br />
              </div>
              <div
                className="card_mode"
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  setCurrentMapeamento((prev: Questionario) => ({
                    ...prev,
                    abordagem: "simplificada",
                  }));
                  moduloMapeamento?.saveQuestionario(
                    currentMapeamento?.cliente_id,
                    {
                      ...currentMapeamento,
                      abordagem: "simplificada",
                      perguntasRelacao:
                        moduloProcessos?.capituloPerguntas?.filter((cp: any) =>
                          currentMapeamento?.capitulos
                            .map((c: any) => c._id)
                            .includes(cp.capitulo_id)
                        ),
                    }
                  );
                }}
              >
                {currentMapeamento?.abordagem === "simplificada" && (
                  <div className="card_mode--selected">
                    <CheckCircleFilled
                      style={{
                        fontSize: "80px",
                        color: "#32E6BE",
                        backgroundColor: "#fff",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                )}
                <div style={{ height: "2rem" }} />
                <br />
                <p
                  style={{
                    fontSize: "1.4rem",
                    color: "#333333",
                    textAlign: "center",
                  }}
                >
                  Simplificada
                </p>
                <div
                  style={{
                    background: "#333333",
                    height: "1px",
                    width: "30%",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0.5rem",
                  }}
                />
                <br />
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    color: "#333333",
                    padding: "0 1rem",
                    height: 180,
                  }}
                >
                  Caso você não tenha todos os subprocessos, você apenas
                  cadastrará as informações referentes ao processo escolhido.
                </p>
                <div className="d-flex" style={{ justifyContent: "center" }}>
                  <Button
                    type="text"
                    style={{ borderColor: "#5F29CC", color: "#5F29CC" }}
                  >
                    Selecionar
                  </Button>
                </div>
                <br />
                <br />
              </div>
            </div>
            <br />
            <p>
              <ExclamationCircleOutlined /> Texto explicativo sobre o que é
              processo e subprocesso
            </p>
          </div>
          <div
            className={
              currentMapeamento?.abordagem
                ? "mapeamentos_steps triangulo"
                : "mapeamentos_steps--hidden"
            }
            style={{ marginLeft: "1.5rem", padding: "0" }}
          >
            {currentMapeamento?.abordagem && (
              <p
                style={{
                  fontSize: 18,
                  color: "#fff",
                  background: "#5F29CC",
                  textAlign: "center",
                  padding: "1rem 0",
                  borderRadius: "20px 20px 0 0",
                }}
              >
                Você escolheu a opção{" "}
                <b style={{ color: "#33CCFF" }}>
                  {currentMapeamento?.abordagem}
                </b>
              </p>
            )}

            <div style={{ padding: "1.5rem 2rem" }}>
              <h5>
                {currentMapeamento?.abordagem === "detalhada" &&
                  "Abaixo estão listados os seus processos. Agora, você deve acrescentar as atividades relacionadas a eles."}
              </h5>
              <br />

              {currentMapeamento?.areas
                ?.filter(
                  (areaCurrentMap) =>
                    moduloProcessos?.areas?.find(
                      (area) => area._id === areaCurrentMap
                    )?.description
                )
                ?.filter((areaCurrentMap) =>
                  currentMapeamento?.capitulos?.find((processo: any) =>
                    processo.tags?.includes(areaCurrentMap)
                  )
                )
                ?.map((area: any) => (
                  <div>
                    {currentMapeamento?.areas?.length !== 1 &&
                      currentMapeamento?.abordagem === "detalhada" && (
                        <p>
                          {
                            moduloProcessos?.areas?.find(
                              (a: any) => a._id === area
                            )?.description
                          }
                        </p>
                      )}

                    <div
                      style={
                        currentMapeamento?.areas?.length !== 1
                          ? { marginLeft: "1rem", marginTop: "0.5rem" }
                          : {}
                      }
                    >
                      {currentMapeamento?.abordagem === "detalhada" &&
                        currentMapeamento?.capitulos
                          ?.filter((processo: any) =>
                            processo.tags?.includes(area)
                          )
                          ?.filter((cap: any) => !cap.dependencia)
                          .map((processo: any) => (
                            <Collapse
                              items={[
                                {
                                  key: "1",
                                  label: processo.description,
                                  children: (
                                    <>
                                      {moduloProcessos?.capitulos
                                        ?.filter(
                                          (process) =>
                                            process.dependencia === processo._id
                                        )
                                        .map((process) => (
                                          <div>
                                            <Tooltip
                                              title={
                                                currentMapeamento?.capitulos
                                                  ?.filter(
                                                    (p: any) =>
                                                      p.inativo !== true
                                                  )
                                                  ?.find(
                                                    (proc: any) =>
                                                      proc._id === process._id
                                                  )
                                                  ? "Remover"
                                                  : process.description
                                              }
                                            >
                                              <Button
                                                key={process._id}
                                                style={{
                                                  marginBottom: "0.5rem",
                                                }}
                                                size="small"
                                                icon={
                                                  currentMapeamento?.capitulos
                                                    ?.filter(
                                                      (p: any) =>
                                                        p.inativo !== true
                                                    )
                                                    ?.find(
                                                      (proc: any) =>
                                                        proc._id === process._id
                                                    ) ? (
                                                    <CheckCircleFilled
                                                      style={{
                                                        color: "#3333FF",
                                                      }}
                                                    />
                                                  ) : (
                                                    <PlusCircleOutlined />
                                                  )
                                                }
                                                onClick={() =>
                                                  toggleProcesso(process)
                                                }
                                              >
                                                {truncateDescription(
                                                  process.description,
                                                  50
                                                )}
                                              </Button>
                                            </Tooltip>
                                          </div>
                                        ))}
                                      {/* Campo para adicionar novo processo */}
                                      <Space
                                        style={{
                                          marginTop: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Input
                                          value={
                                            newProcess.dependencia_id ===
                                            processo._id
                                              ? newProcess.processo
                                              : ""
                                          }
                                          size="small"
                                          onChange={(e) =>
                                            setNewProcess({
                                              processo: e.target.value,
                                              dependencia_id: processo._id,
                                            })
                                          }
                                          placeholder="Novo processo"
                                          style={{ width: 200 }}
                                        />
                                        <Button
                                          size="small"
                                          type="primary"
                                          style={{
                                            color: "#fff",
                                            backgroundColor: "#0368c8",
                                            borderColor: "#0368c8",
                                          }}
                                          onClick={() =>
                                            handleAddProcess(processo._id)
                                          }
                                          disabled={
                                            newProcess.dependencia_id !==
                                            processo._id
                                          }
                                          icon={<PlusCircleOutlined />}
                                        >
                                          Adicionar
                                        </Button>
                                      </Space>
                                      {!moduloProcessos?.capitulos?.filter(
                                        (process) =>
                                          process.dependencia === processo._id
                                      )[0] ? (
                                        <p style={{ color: "#333" }}>
                                          Não há processos internos
                                        </p>
                                      ) : (
                                        <div>
                                          <Button
                                            style={{
                                              background: "#32E6BE",
                                              color: "#333333",
                                              marginTop: "1rem",
                                            }}
                                            size="small"
                                            type="primary"
                                            icon={icon}
                                            loading={loading}
                                            onClick={() => {
                                              setLoading(true);
                                              setIcon(<SaveOutlined />);

                                              moduloMapeamento
                                                ?.saveQuestionario(
                                                  currentMapeamento?.cliente_id,
                                                  {
                                                    ...currentMapeamento,
                                                    perguntasRelacao:
                                                      moduloProcessos?.capituloPerguntas?.filter(
                                                        (cp: any) =>
                                                          currentMapeamento.capitulos
                                                            .map(
                                                              (c: any) => c._id
                                                            )
                                                            .includes(
                                                              cp.capitulo_id
                                                            )
                                                      ),
                                                  }
                                                )
                                                .then(() => {
                                                  setIcon(<CheckOutlined />);
                                                })
                                                .catch(() => {
                                                  setIcon(<CloseOutlined />);
                                                })
                                                .finally(() => {
                                                  setLoading(false);

                                                  setTimeout(() => {
                                                    setIcon(<SaveOutlined />);
                                                  }, 2000);
                                                });
                                            }}
                                          >
                                            Salvar
                                          </Button>
                                        </div>
                                      )}
                                    </>
                                  ),
                                },
                              ]}
                            />
                          ))}

                      {currentMapeamento?.abordagem === "simplificada" && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CheckCircleFilled
                            style={{ fontSize: "80px", color: "#32E6BE" }}
                          />
                          <br />
                          <h2>Etapa concluída</h2>
                          <br />
                          {/* <h5>
                        <ExclamationCircleOutlined /> Selecione um processo para
                        iniciar o mapeamento:
                      </h5> */}
                          <br />
                          <Button
                            loading={loading}
                            onClick={() => {
                              setShowMapeamentos(true);
                            }}
                            type="text"
                            style={{ color: "#008C76" }}
                          >
                            Iniciar mapeamentos <RightOutlined />
                          </Button>
                          {/* <div style={{ width: "100%" }}>
                        {currentMapeamento?.capitulos
                          ?.filter((process: any) => !process.inativo)
                          ?.filter((process: any) => !process.dependencia)
                          .map((process: any) => (
                            <div>
                              <Tooltip title="Ir para mapeamento">
                                <Button
                                  key={process._id}
                                  style={{ marginBottom: "0.5rem" }}
                                  size="small"
                                  onClick={() => setTabProcesso(process)}
                                >
                                  {process.description}
                                  <RightCircleFilled style={{ color: "#3333FF" }} />
                                </Button>
                              </Tooltip>
                            </div>
                          ))}
                      </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div
            className={
              currentMapeamento?.abordagem === "detalhada"
                ? currentMapeamento?.capitulos?.filter(
                    (p: any) => p.dependencia && !p.inativo
                  )?.[0]
                  ? "mapeamentos_steps triangulo"
                  : "mapeamentos_steps--hidden"
                : "mapeamentos_steps--hidden"
            }
            style={{ marginLeft: "1.5rem" }}
          >
            {currentMapeamento?.abordagem === "detalhada" &&
              currentMapeamento?.capitulos?.filter(
                (p: any) => p.dependencia && !p.inativo
              )?.[0] && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    textAlign: "center",
                  }}
                >
                  {/* <CheckCircleFilled
                style={{ fontSize: "80px", color: "#32E6BE" }}
              />
              <br /> */}
                  <h2>
                    Você selecionou{" "}
                    <b style={{ color: "rgb(51, 204, 255)" }}>
                      {currentMapeamento?.capitulos?.filter(
                        (p: any) => p.dependencia && !p.inativo
                      ).length || 0}
                    </b>{" "}
                    subprocessos. Deseja iniciar seus mapeamentos?
                  </h2>
                  <br />
                  <p style={{ fontSize: 14, opacity: 0.6 }}>
                    <ExclamationCircleOutlined /> Você poderá voltar a esta
                    etapa posteriormente.
                  </p>
                  <br />
                  <br />
                  <Button
                    loading={loading}
                    onClick={() => {
                      setLoading(true);

                      moduloMapeamento
                        ?.saveQuestionario(currentMapeamento?.cliente_id, {
                          ...currentMapeamento,
                          perguntasRelacao:
                            moduloProcessos?.capituloPerguntas?.filter(
                              (cp: any) =>
                                currentMapeamento.capitulos
                                  .map((c: any) => c._id)
                                  .includes(cp.capitulo_id)
                            ),
                        })

                        .finally(() => setLoading(false));

                      setShowMapeamentos(true);
                    }}
                    type="text"
                    style={{ color: "#008C76" }}
                  >
                    Iniciar mapeamentos <RightOutlined />
                  </Button>

                  <br />
                  <br />
                  {/* <div style={{ width: "100%" }}>
                {currentMapeamento?.abordagem === "detalhada" &&
                  currentMapeamento?.capitulos
                    ?.filter((processo: any) =>
                      processo.tags?.find(
                        (area: any) => area === currentMapeamento?.areas?.[0]
                      )
                    )
                    .map((processo: any) => (
                      <Collapse
                        items={[
                          {
                            key: "1",
                            label: processo.description,
                            children: (
                              <>
                                {currentMapeamento?.capitulos
                                  ?.filter((process: any) => !process.inativo)
                                  ?.filter(
                                    (process: any) =>
                                      process.dependencia === processo._id
                                  )
                                  .map((process: any) => (
                                    <div>
                                      <Tooltip title="Ir para mapeamento">
                                        <Button
                                          key={process._id}
                                          style={{ marginBottom: "0.5rem" }}
                                          size="small"
                                          onClick={() => {
                                            setTabProcesso(process);
                                          }}
                                        >
                                          {process.description}
                                          <RightCircleFilled
                                            style={{ color: "#3333FF" }}
                                          />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  ))}
                                {!currentMapeamento?.capitulos?.filter(
                                  (process: any) =>
                                    process.dependencia === processo._id
                                )?.[0] && (
                                  <p style={{ color: "#333" }}>
                                    Não há processos internos
                                  </p>
                                )}
                              </>
                            ),
                          },
                        ]}
                      />
                    ))}
              </div> */}
                </div>
              )}
          </div>
        </div>
      ) : (
        <Alert
          message="Nenhum processo cadastrado para preenchimento"
          showIcon
          type="warning"
        />
      )}
    </div>
  );
};

export default Mapeamento;
