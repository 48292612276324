import { useParams } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import "./styles.css";
import { Button, Checkbox } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";

import BackHeader from "../../../../components/BackHeader/BackHeader";
import api from "../../../../services/api";
interface Response {
  questao_id: string;
  resposta: string[];
}

interface Questao {
  _id: string; // Make sure this matches the actual type from your API
  questao: string;
  ordem: number;
}

const Respostas = () => {
  const params = useParams();
  const printRef = useRef<HTMLDivElement>(null);

  const [mergedData, setMergedData] = useState<
    { questao: string; ordem: number; respostas: string[] }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await api.get(`/respostas/${params.questionarioid}`);

        const questoesResponse = await api.get("/questoes");
        const questoes: Questao[] = questoesResponse.data;

        const mergedDataArray = response.data.respostas.map(
          (resposta: Response) => {
            const questao = questoes.find(
              (q: Questao) => q._id === resposta.questao_id
            );

            return {
              ...questao,
              respostas: resposta.resposta,
            };
          }
        );

        const sortedMergedData = mergedDataArray.sort(
          (a: any, b: any) => a.ordem - b.ordem
        );

        let allRespData: any = [];

        sortedMergedData
          .filter((r: any) => r._id)
          .forEach((questao: any) => {
            if (questao.respostas && questao.respostas[0]) {
              questao.respostas.map((item: any) => {
                if (
                  allRespData.filter(
                    (item: any) =>
                      item._id.toString() === questao._id.toString()
                  ).length !== 0
                ) {
                  return;
                }
                return allRespData.push({
                  ...questao,
                  resposta: item,
                });
              });
            }
          });

        setMergedData(sortedMergedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="Fornecedores">
      <BackHeader title={"Respostas"} prev />
      {/* ... Rest of your JSX */}

      <hr />
      <h2>Respostas do Formulário {params.questionarioid}</h2>
      <br />

      <ReactToPrint
        trigger={() => (
          <Button
            type="default"
            icon={<PrinterOutlined />}
            style={{ marginBottom: "2rem" }}
          >
            Imprimir
          </Button>
        )}
        content={() => printRef.current}
      />
      <div ref={printRef}>
        {mergedData && (
          <div className="respostas__pergunta">
            {mergedData?.map((pergunta: any) => {
              return (
                <div
                  key={pergunta + "analiseResp"}
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label className="label">
                    <b>{pergunta.ordem}. </b>
                    {`${pergunta.questao}`}
                  </label>

                  <div style={{ marginLeft: "1rem" }}>
                    {pergunta.tipo !== "input"
                      ? pergunta.opcoes &&
                        pergunta.opcoes.map((opt: string) => (
                          <p>
                            <Checkbox
                              checked={pergunta.respostas.includes(opt)}
                              style={{ marginRight: "0.5rem" }}
                            />
                            {opt}
                          </p>
                        ))
                      : pergunta.resposta}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <hr />
    </div>
  );
};

export default Respostas;
