import React, { useEffect, useState } from "react";
import "./styles.css";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import { AnaliseTypes } from "../../../../../@types/analise";
import Metricas from "./components/Metricas/Metricas";
import Respostas from "./components/Respostas/Respostas";
import { Collapse } from "antd";

const Analise: React.FC<AnaliseTypes> = ({ questId }) => {
  const { moduloProcessos, currentAnalise, getAnalise, accessToken } =
    React.useContext(GlobalContext) as GlobalContextType;

  const [areasAnalise, setAreasAnalise] = useState<any>([]);
  const [activeCap, setActiveCap] = useState<any>({});

  useEffect(() => {
    if (questId && accessToken) {
      getAnalise(questId);
    }
  }, [questId, accessToken]);

  useEffect(() => {
    if (currentAnalise.capitulos) {
      setActiveCap(currentAnalise.capitulos[0]);
      setAreasAnalise(
        currentAnalise.capitulos.map((c: any) => {
          return c.areas_id[0];
        })
      );
    }
  }, [currentAnalise]);

  const itemsCollapse = [
    {
      key: "1",
      label: "Métricas",
      children: <Metricas />,
    },
    {
      key: "2",
      label: "Respostas",
      children: <Respostas activeCap={activeCap} questId={questId} />,
    },
  ];
  return (
    <div className="analise">
      <div className="analise__indice">
        <p className="analise__indice--title">Índice</p>
        {currentAnalise &&
          moduloProcessos.areas
            ?.filter((area: any) => areasAnalise?.includes(area._id))
            .map((area: any) => {
              return (
                <div
                  key={area._id + "areasIndiceAnalise"}
                  className="analise__indice--area"
                >
                  <p>{area.description}</p>
                  {currentAnalise.capitulos
                    .filter((cap: any) => cap.areas_id.includes(area._id))
                    .map((cap: any) => {
                      return (
                        <p
                          key={cap._id + "capsIndiceAnalise"}
                          className={`${
                            activeCap._id === cap._id
                              ? "analise__indice--cap__active"
                              : "analise__indice--cap"
                          }`}
                          onClick={() => setActiveCap(cap)}
                        >
                          {cap.description}
                        </p>
                      );
                    })}
                </div>
              );
            })}
      </div>
      <div className="analise__content">
        <Collapse items={itemsCollapse} defaultActiveKey={["1", "2"]} />
      </div>
    </div>
  );
};

export default Analise;
