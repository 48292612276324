import React, { useEffect, useState } from "react";
import "./styles.css";
import { Button, Form, Input, Modal, Radio, Select, Table, Tag } from "antd";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import InputMask from "react-input-mask";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ModalImportFornecedoresTypes } from "../../../../../../@types/modalFornecedores";

const refPerg = [
  {
    ordem: 3,
    pergunta: "A empresa possui Encarregado de Proteção de Dados (DPO)?",
  },
  {
    ordem: 4,
    pergunta:
      "Em caso afirmativo, informe o nome e contato do profissional (DPO).",
  },
  {
    ordem: 5,
    pergunta:
      "A sua empresa tratará dados pessoais* (acesso, compartilhamento, armazenamento, eliminação) com a nossa empresa? ",
  },
  {
    ordem: 6,
    pergunta: "Selecione quais dados serão tratados nesta relação contratual:",
  },
  {
    ordem: 7,
    pergunta:
      "Selecione quais dados serão necessários para a realização desta relação contratual:",
  },
  {
    ordem: 9,
    pergunta:
      "Existe um programa de privacidade e proteção de dados permanente e contínuo implementado na sua empresa? ",
  },
  { ordem: 10, pergunta: "O programa de adequação foi finalizado há:" },
  {
    ordem: 11,
    pergunta: "Favor assinalar quais etapas e atividades já foram cumpridas. ",
  },
  {
    ordem: 12,
    pergunta:
      "Favor enviar* cópias dos documentos ou amostragem que evidenciem que essas etapas ou atividades foram ou estão sendo realizadas. ",
  },
  {
    ordem: 13,
    pergunta:
      "A empresa executa o monitoramento da conformidade à LGPD e possui plano de atualização permanente/contínuo da conformidade?",
  },
  {
    ordem: 14,
    pergunta:
      "A empresa possui um Comitê* de Privacidade e Proteção de Dados ativo (com reuniões periódicas, discussões e adequações de processos, treinamentos)?",
  },
  {
    ordem: 15,
    pergunta:
      "A empresa possui canal específico para atendimento aos direitos dos titulares de dados?",
  },
  {
    ordem: 16,
    pergunta: "Em caso afirmativo, insira o link/local para o canal.",
  },
  {
    ordem: 17,
    pergunta:
      "Em caso de recebimento de solicitação de direitos de titular de dados sob a nossa responsabilidade, a sua empresa possui condições de nos comunicar em tempo hábil ou no prazo legal para que possam...",
  },
  {
    ordem: 18,
    pergunta: "A área de Tecnologia da Informação é executada internamente?",
  },
  {
    ordem: 19,
    pergunta:
      "Em caso de terceirização, informar a razão social e nome fantasia da pessoa jurídica responsável pela gestão e suporte de TI da sua empresa.",
  },
  {
    ordem: 20,
    pergunta:
      "A empresa possui evidências (cláusulas contratuais, comprovação de treinamentos realizados, políticas confeccionadas, documentos e relatórios elaborados) sobre o compliance com a LGPD do terceiro ...",
  },
  { ordem: 21, pergunta: "A área de segurança da informação é de gestão." },
  {
    ordem: 22,
    pergunta:
      "Informar a razão social e nome fantasia da empresa que faz a gestão da segurança da informação.",
  },
  {
    ordem: 23,
    pergunta:
      "A empresa possui evidências (cláusulas contratuais, comprovação de treinamentos realizados, políticas confeccionadas, documentos e relatórios elaborados) sobre o compliance com a LGPD do terceiro ...",
  },
  {
    ordem: 31,
    pergunta:
      "A empresa sofreu algum incidente de segurança relevante* envolvendo dados pessoais nos últimos 12 meses?",
  },
  {
    ordem: 30,
    pergunta:
      "Em caso do uso de documentos físicos (não digitais), a empresa possui controle de acesso a salas, arquivos e locais onde estão armazenados os documentos?",
  },
  {
    ordem: 28,
    pergunta:
      "Quais ferramentas de segurança são aplicadas no dia a dia no seu ambiente digital? ",
  },
  {
    ordem: 29,
    pergunta:
      "Em relação à pergunta anterior, favor informar quais as empresas/desenvolvedores/marcas* utilizadas para implementação dessas ferramentas.",
  },
  {
    ordem: 33,
    pergunta:
      "Há alguma política específica que considere as regras de privacidade e proteção de dados advindas da lei para o acesso via dispositivo particular? ",
  },
  {
    ordem: 24,
    pergunta:
      "O colaborador recebe treinamento permanente e atualizado sobre o compliance com a LGPD?",
  },
  {
    ordem: 25,
    pergunta:
      "Em caso afirmativo, favor informar a periodicidade aproximada dos treinamentos.",
  },
  {
    ordem: 35,
    pergunta:
      "Quanto a higienização da base de dados da empresa, a última vez que foi realizada.",
  },
  {
    ordem: 39,
    pergunta: "A empresa realiza tratamento internacional de dados pessoais?",
  },
  { ordem: 33, pergunta: "Os bancos de dados da empresa estão em:" },
  {
    ordem: 37,
    pergunta:
      "Indicar quais bancos de dados e respectivos países se encontram no exterior.",
  },
  {
    ordem: 38,
    pergunta:
      "Existe algum comprovante, atestado ou documento que você gostaria de anexar em relação à adequação à Lei Geral de Proteção de Dados (LGPD)?",
  },
];

const { Search } = Input;

const ModalImportarCve: React.FC<ModalImportFornecedoresTypes> = ({
  showImport,
  setShowImport,
  importFile,
  setImportFile,
  clientId,
  importQuestionario,
}) => {
  const { postCveCliente, usuarios } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loading, setLoading] = useState<boolean>(false);
  const [searchImport, setSearchImport] = useState<string>("");
  const [multipleSponsors, setMultipleSponsors] = useState<any>(null);
  const [selectedSponsor, setSelectedSponsor] = useState(null);

  const onSearchImport = (value: string) => setSearchImport(value);

  useEffect(() => {
    clientId &&
      usuarios &&
      setMultipleSponsors(
        usuarios?.filter((u: any) => u.cliente_id === clientId)
      );
  }, [clientId, usuarios]);

  const salvaFornecedoresImportados = async () => {
    setLoading(true);
    try {
      clientId &&
        postCveCliente(
          importFile.data,
          clientId,
          importFile.nome,
          selectedSponsor
        );
      setShowImport(false);
    } catch (error) {
      console.error("Erro ao adicionar fornecedores:", error);
    } finally {
      // setImportFornecedoresType(null);
      setLoading(false);
      // setShowImport(false);
    }
  };

  const columnsImport = [
    { title: "ITEM", dataIndex: "ITEM", key: "ITEM" },
    { title: "IP ALVO", dataIndex: "IP_ALVO", key: "IP_ALVO" },
    {
      title: "PORTAS ABERTAS",
      dataIndex: "PORTAS_ABERTAS",
      key: "PORTAS_ABERTAS",
    },
    { title: "SERVIÇOS", dataIndex: "SERVIÇOS", key: "SERVIÇOS" },
    { title: "CVE", dataIndex: "CVE", key: "CVE" },
    {
      title: "VULNERABILIDADES ENCONTRADAS",
      dataIndex: "VULNERABILIDADES_ENCONTRADAS",
      key: "VULNERABILIDADES_ENCONTRADAS",
    },
    { title: "RECOMENDAÇÃO", dataIndex: "RECOMENDAÇÃO", key: "RECOMENDAÇÃO" },
    {
      title: "GRAU DE RISCO",
      dataIndex: "GRAU_DE RISCO",
      key: "GRAU_DE RISCO",
    },
  ];

  return (
    <>
      <Modal
        title="Importar CVEs"
        open={showImport}
        footer={[
          <Button
            type="default"
            form="form-pergunta"
            onClick={() => setShowImport(false)}
          >
            Cancelar
          </Button>,
          <Button
            loading={loading}
            onClick={salvaFornecedoresImportados}
            type="primary"
            disabled={selectedSponsor ? false : true}
            // disabled={importFornecedoresType === null ? true : false}
          >
            Adicionar
          </Button>,
        ]}
        width={"95%"}
        onCancel={() => setShowImport(false)}
      >
        <>
          {importFile && importFile.data && importFile.data[0]["ITEM"] ? (
            <>
              <Search
                style={{
                  width: "50%",
                  marginBottom: "1rem",
                }}
                placeholder="Buscar item"
                className="clientes__actions--search"
                allowClear
                enterButton="Buscar"
                onSearch={onSearchImport}
              />
              {multipleSponsors && (
                <Form.Item label="Responsável pelo plano de ação">
                  <Select
                    status={selectedSponsor ? "" : "error"}
                    defaultValue={selectedSponsor}
                    value={selectedSponsor}
                    onChange={(v) => setSelectedSponsor(v)}
                    placeholder="Adicione um responsável"
                    options={multipleSponsors.map((user: any) => {
                      return {
                        label: user.name,
                        value: user._id,
                      };
                    })}
                  />
                </Form.Item>
              )}

              <Table
                pagination={{ pageSize: 5 }}
                dataSource={importFile?.data}
                columns={columnsImport}
              />
            </>
          ) : (
            <h2
              style={{
                textAlign: "center",
                marginBottom: "2rem",
                marginTop: "2rem",
              }}
            >
              Sem dados ou planilha incorreta.
            </h2>
          )}
        </>
      </Modal>
    </>
  );
};

export default ModalImportarCve;
