import React, { useEffect, useState } from "react";
import "./styles.css";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import {
  AnaliseRespostasTypes,
  AnaliseTypes,
} from "../../../../../../../@types/analise";
import { Button, Space, Switch, Tag, Tooltip } from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExpandOutlined,
} from "@ant-design/icons";
import PlanoAcao from "./components/PlanoAcao/PlanoAcao";

const Respostas: React.FC<AnaliseRespostasTypes> = ({ activeCap, questId }) => {
  const { currentAnalise, moduloProcessos } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  const [viewNormas, setViewNormas] = useState(true);

  const handleViewNormas = () => {
    viewNormas ? setViewNormas(false) : setViewNormas(true);
  };
  return (
    <div className="analise__respostas">
      <div className="respostas__headerCap">
        <h2>{activeCap.description}</h2>
        {activeCap?.areas_id && (
          <Tag
            className="respostas__tags"
            color="magenta"
            style={{ userSelect: "none" }}
          >
            <span>
              {
                moduloProcessos.areas?.filter(
                  (area: any) => area._id === activeCap?.areas_id[0],
                )[0].description
              }
            </span>
          </Tag>
        )}

        <Space direction="horizontal" className="respostas__headerCap--actions">
          <Tooltip title="Recomendações e Plano de ação">
            <Button
              type="link"
              icon={viewNormas ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              onClick={handleViewNormas}
            >
              Recomendações e Plano de ação
            </Button>
          </Tooltip>
          <Tooltip title="Visualizar em tela cheia">
            <Button type="link" icon={<ExpandOutlined />} />
          </Tooltip>
        </Space>
      </div>
      <hr className="hrAnalise" />
      <div className="respostas__containner">
        {currentAnalise.respostas && (
          <div className="respostas__pergunta">
            {currentAnalise.perguntas?.map((pergunta: any) => {
              const currentResposta = currentAnalise.respostas
                .filter((r: any) => r.pergunta_id === pergunta._id)
                .filter((r: any) => r.cap_id === activeCap._id);

              if (!currentResposta[0]) {
                return null;
              }

              const respSim = currentResposta?.filter(
                (r: any) => r.value === "s",
              ).length;

              const respNao = currentResposta?.filter(
                (r: any) => r.value === "n",
              ).length;

              const respTotal = currentResposta?.length;

              return (
                <div key={pergunta._id + "analiseResp"}>
                  <div className="respostas__pergunta--label">
                    {pergunta.description}
                    <b
                      className={
                        pergunta.tipo !== "CP"
                          ? currentResposta.filter(
                              (r: any) =>
                                r.value.toUpperCase() !==
                                pergunta.opt_conformidade.toUpperCase(),
                            )[0]
                            ? "respostas__pergunta--label__inconforme"
                            : "respostas__pergunta--label__conforme"
                          : "respostas__pergunta--label__cp"
                      }
                    >
                      {pergunta.tipo === "AM"
                        ? currentResposta.filter(
                            (r: any) =>
                              r.value.toUpperCase() !==
                              pergunta.opt_conformidade.toUpperCase(),
                          )[0]
                          ? "Não conforme"
                          : "Conforme"
                        : "Conhecimento de processo"}
                    </b>
                  </div>
                  <div className="respostas__pergunta--result">
                    <p>
                      Sim: {respSim} ({(respSim / respTotal) * 100}%)
                    </p>
                    <p>
                      Não: {respNao} ({(respNao / respTotal) * 100}%)
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {viewNormas && (
          <div className="respostas__normas">
            <h2>Recomendação</h2>

            <div
              dangerouslySetInnerHTML={{ __html: activeCap.recomendacao }}
              style={{ margin: "0 2rem" }}
            ></div>
            <h2>Norma</h2>
            {activeCap.norma}
            <PlanoAcao activeCap={activeCap} questId={questId} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Respostas;
