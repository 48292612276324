import React, { useEffect, useState } from "react";
import "./styles.css";

import {
  Button,
  Checkbox,
  Popconfirm,
  Popover,
  Space,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  ExpandOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { AnaliseRespostasTypes } from "../../../../../../../@types/analise";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import { useParams } from "react-router-dom";
import api from "../../../../../../../services/api";
import { useAuth0 } from "@auth0/auth0-react";
interface Response {
  questao_id: string;
  resposta: string[];
}

interface Questao {
  _id: string; // Make sure this matches the actual type from your API
  questao: string;
  ordem: number;
  tipo: string;
}
const Respostas: React.FC<AnaliseRespostasTypes> = ({
  activeCap,
  questId,
  respostas,
}) => {
  const { grauRiscos, questoesFornecedores, currentCliente } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const { user } = useAuth0();

  const [allRespostas, setAllRespostas] = useState<any>([]);
  const [allRespostasSemDuplicatas, setAllRespostasSemDuplicatas] =
    useState<any>([]);

  const [mergedData, setMergedData] = useState<
    { questao: string; ordem: number; respostas: string[] }[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      if (currentCliente && questoesFornecedores && respostas) {
        try {
          const flattenedRespostas = respostas.data.flatMap(
            (item: any) => item.respostas
          );

          const mergedDataArray = flattenedRespostas.map(
            (resposta: Response) => {
              const questao = questoesFornecedores.find(
                (q) => q._id === resposta.questao_id
              );

              return {
                ...questao,
                respostas: resposta.resposta,
                graus: grauRiscos.filter(
                  (g: any) =>
                    g.questao_id.toString() === questao?._id.toString()
                ),
              };
            }
          );

          const sortedMergedData = mergedDataArray
            ?.filter((q: any) => q.tipo !== "input" && q.graus[0])
            ?.sort((a: any, b: any) => a.ordem - b.ordem);

          let allRespData: any = [];
          sortedMergedData
            .filter((r: any) => r._id)
            .forEach((questao: any) => {
              if (questao.respostas && questao.respostas[0]) {
                questao.respostas.map((item: any) => {
                  if (
                    allRespData.filter(
                      (item: any) =>
                        item._id.toString() === questao._id.toString()
                    ).length !== 0
                  ) {
                    return;
                  }
                  return allRespData.push({
                    ...questao,
                    resposta: item,
                    grau: grauRiscos
                      .filter(
                        (gr: any) =>
                          gr.questao_id.toString() === questao._id.toString()
                      )
                      .filter((gr: any) => gr.value.includes(item))[0]?.grau,
                  });
                });
              }
            });

          setAllRespostas(allRespData);
          setMergedData(sortedMergedData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [currentCliente, questoesFornecedores, respostas]);
  return (
    <div className="analise__respostas">
      <div className="respostas__headerCap">
        <h2>{activeCap}</h2>
      </div>
      <hr className="hrAnalise" />
      <div className="respostas__containner">
        {allRespostas && (
          <div className="respostas__pergunta">
            {allRespostas
              ?.filter((r: any) => r.categoria === activeCap)
              .map((pergunta: any) => {
                // const currentResposta = currentAnalise.respostas.filter(
                //   (r: any) => r.pergunta_id === pergunta._id
                // )[0];

                // if (currentResposta?.cap_id !== activeCap._id) {
                //   return null;
                // }

                const respostasDaQuestao = mergedData?.filter(
                  (item) => item.questao === pergunta.questao
                );

                const opcoesComQtd = pergunta.opcoes.map((opt: any) => {
                  return {
                    opcao: opt,
                    qtd: respostasDaQuestao.filter((perg: any) =>
                      perg.respostas.includes(opt)
                    )?.length,
                  };
                });

                return (
                  <div key={pergunta + "analiseResp"}>
                    <div
                      className="respostas__pergunta--label"
                      style={{ fontSize: 16 }}
                    >
                      {pergunta.questao}{" "}
                      {pergunta.norma && (
                        <Popover
                          title="Norma"
                          trigger={"click"}
                          content={
                            <p
                              style={{
                                maxHeight: 300,
                                overflowX: "auto",
                                padding: "1rem",
                              }}
                            >
                              {pergunta.norma}
                            </p>
                          }
                          overlayStyle={{
                            maxWidth: "600px",
                            whiteSpace: "pre-line",
                            maxHeight: "300px",
                          }}
                        >
                          <InfoCircleTwoTone className="info" />
                        </Popover>
                      )}
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                      {pergunta.opcoes.map((opt: string) => {
                        const grauAtualOpcao = pergunta.graus?.filter(
                          (g: any) => g.value.includes(opt)
                        )[0]?.grau;

                        return (
                          <p>
                            {opt} -{" "}
                            <b
                              className={
                                grauAtualOpcao === "sim"
                                  ? pergunta.respostas.includes(opt)
                                    ? "respostas__pergunta--label__conforme"
                                    : "respostas__pergunta--label__inconforme"
                                  : grauAtualOpcao === "nao"
                                  ? pergunta.respostas.includes(opt)
                                    ? "respostas__pergunta--label__inconforme"
                                    : "respostas__pergunta--label__conforme"
                                  : grauAtualOpcao === "meio" ||
                                    grauAtualOpcao === "Ponto de Atenção"
                                  ? "respostas__pergunta--label__orange"
                                  : "respostas__pergunta--label__cp"
                              }
                            >
                              {
                                opcoesComQtd?.find(
                                  (item: any) => item.opcao === opt
                                )?.qtd
                              }
                            </b>{" "}
                            resposta
                            {opcoesComQtd?.find(
                              (item: any) => item.opcao === opt
                            )?.qtd !== 1 && "s"}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Respostas;
