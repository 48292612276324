import React, { useState } from "react";
import "./styles.css";
import { Table, Input, Button, Upload, Tabs } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { FileExcelFilled } from "@ant-design/icons";
import * as XLSX from "xlsx";
import ModalImportar from "./components/ModalImportar/ModalImportar";

const { Search } = Input;

const Areas = () => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [searchArea, setSearchArea] = useState<string>("");
  const [showNovaArea, setShowNovaArea] = useState(false);
  const [novaArea, setNovaArea] = useState("");
  const [showImport, setShowImport] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<any>(null);
  const [importQuestionario, setImportQuestionario] = useState<any>(null);

  const columnsArea = [
    {
      title: "Nome",
      dataIndex: "description",
    },
  ];
  const handleImport = (file: any) => {
    setShowImport(true);

    const reader = new FileReader();

    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];

        const worksheet: any = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        // Extrair itens da coluna A
        const itensColunaA: any = [];
        const itensColunaB: any = [];
        const itensColunaC: any = [];
        const itensColunaE: any = [];

        const range = XLSX.utils.decode_range(worksheet["!ref"]);
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
          const cellAddressA = { r: rowNum, c: 0 }; // Coluna A (c: 0)
          const cellRefA = XLSX.utils.encode_cell(cellAddressA);
          const cellA = worksheet[cellRefA];

          if (cellA) {
            const cellValue = cellA.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 0 });
            itensColunaA.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressB = { r: rowNum, c: 1 }; // Coluna B (c: 1)
          const cellRefB = XLSX.utils.encode_cell(cellAddressB);
          const cellB = worksheet[cellRefB];

          if (cellB) {
            const cellValue = cellB.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
            itensColunaB.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressC = { r: rowNum, c: 2 }; // Coluna C (c: 2)
          const cellRefC = XLSX.utils.encode_cell(cellAddressC);
          const cellC = worksheet[cellRefC];

          if (cellC) {
            const cellValue = cellC.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 2 });
            itensColunaC.push({ value: cellValue, location: cellLocation });
          }

          const cellAddressE = { r: rowNum, c: 4 }; // Coluna E (c: 42)
          const cellRefE = XLSX.utils.encode_cell(cellAddressE);
          const cellE = worksheet[cellRefE];

          if (cellE) {
            const cellValue = cellE.v;
            const cellLocation = XLSX.utils.encode_cell({ r: rowNum, c: 4 });
            itensColunaE.push({ value: cellValue, location: cellLocation });
          }
        }

        const perguntasUnicas: any = {};

        itensColunaB.forEach((pergunta: any) => {
          const perguntaSplit = pergunta.value.split("?");
          const textoPergunta = perguntaSplit[0];

          if (!perguntasUnicas[textoPergunta]) {
            perguntasUnicas[textoPergunta] = {
              value: pergunta.value,
              location: pergunta.location,
              valor: itensColunaC
                .filter(
                  (grau: any) =>
                    Number(grau.location.split("C")[1]) ===
                    Number(pergunta.location.split("B")[1])
                )
                .map((grau: any) => {
                  return {
                    ...grau,
                    option: perguntaSplit[1],
                    cp: itensColunaE.filter(
                      (tipo: any) =>
                        Number(grau.location.split("C")[1]) ===
                        Number(tipo.location.split("E")[1])
                    )?.[0]?.value,
                  };
                }),
            };
          } else {
            perguntasUnicas[textoPergunta].valor.push({
              ...itensColunaC.filter(
                (grau: any) =>
                  Number(grau.location.split("C")[1]) ===
                  Number(pergunta.location.split("B")[1])
              )[0],
              option: perguntaSplit[1],
            });
          }
        });

        // Formatar o array final no formato desejado
        const perguntas = Object.values(perguntasUnicas)
          .map((item: any) => {
            return {
              value: item.value,
              location: item.location,
              valor: item.valor,
            };
          })
          .filter((item: any) => item.value !== "" && item.value !== " ");
        let areaMap = itensColunaA.map((cap: any, index: number) => {
          return {
            ...cap,
            perguntas: perguntas.filter(
              (perguntas: any) =>
                Number(perguntas.location.split("B")[1]) >
                  Number(cap.location.split("A")[1]) &&
                Number(perguntas.location.split("B")[1]) <
                  Number(itensColunaA[index + 1]?.location.split("A")[1])
              // ? //    &&
              //   // itensColunaA.length - 1 === index
              //   true
              // : Number(perguntas.location.split("B")[1]) <
              //   Number(itensColunaA[index + 1]?.location.split("A")[1])
            ),
          };
        });

        setImportQuestionario(areaMap);
        // Verificar as células mescladas
        // if (worksheet["!merges"]) {
        //   worksheet["!merges"].forEach((merge) => {
        //     // Obter as coordenadas iniciais e finais das células mescladas
        //     const startCell = XLSX.utils.encode_cell(merge.s);
        //     const endCell = XLSX.utils.encode_cell(merge.e);

        //     // Extrair valor da célula mesclada
        //     const startCellRef = worksheet[startCell];
        //     const mergedValue = startCellRef ? startCellRef.v : "";

        //     // Armazenar o valor na estrutura de células mescladas da coluna A
        //     for (let row = merge.s.r; row <= merge.e.r; row++) {
        //       const cellAddress = XLSX.utils.encode_cell({ r: row, c: 0 });
        //       areasCaps[cellAddress] = mergedValue;
        //     }
        //   });
        //   console.log(areasCaps);
        // }
        // console.log(data);
        // console.log(data.filter((item: any) => item["__EMPTY_1"]));
        // Transforma o nome das propriedades para maiúsculas
        const newData = data.map((item: any) => {
          const newItem: any = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              newItem[key.toUpperCase()] = item[key];
            }
          }
          return newItem;
        });

        setImportFile(newData);
      };
    }

    return false;
  };

  const onSearchArea = (value: string) => setSearchArea(value);

  return (
    <div className="clientes">
      <BackHeader title={"Áreas"} />

      <ModalImportar
        importFile={importFile}
        setImportFile={setImportFile}
        setShowImport={setShowImport}
        showImport={showImport}
        importQuestionario={importQuestionario}
      />
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "Padrões",
            children: (
              <>
                <div className="clientes__actions">
                  <Search
                    placeholder="Buscar área"
                    className="clientes__actions--search"
                    allowClear
                    enterButton="Buscar"
                    onSearch={onSearchArea}
                    style={{ marginRight: "5rem" }}
                  />
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Upload
                      showUploadList={false}
                      beforeUpload={(file: any) => handleImport(file)}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                      <Button
                        type="default"
                        icon={<FileExcelFilled style={{ color: "green" }} />}
                      >
                        Importar XLSX
                      </Button>
                    </Upload>
                    <Button
                      className="clientes__actions--new"
                      type="primary"
                      onClick={() => setShowNovaArea(true)}
                    >
                      Nova área
                    </Button>
                  </div>
                </div>
                {showNovaArea && (
                  <div className="clientes__actions">
                    <Input
                      placeholder="Digite o nome da nova área"
                      style={{ marginRight: "5rem" }}
                      onChange={(v: any) => setNovaArea(v.target.value)}
                    />
                    <Button
                      className="clientes__actions--new"
                      type="primary"
                      disabled={novaArea.length > 1 ? false : true}
                      onClick={() => {
                        novaArea.length > 1 && setShowNovaArea(false);
                        novaArea.length > 1 && setNovaArea("");
                        novaArea.length > 1 &&
                          moduloProcessos.postArea(novaArea);
                      }}
                    >
                      Salvar
                    </Button>
                  </div>
                )}

                <Table
                  className="clientes__list"
                  dataSource={moduloProcessos.areas
                    ?.filter((a: any) => !a.cliente_id)
                    .filter((c: any) =>
                      c.description
                        .toUpperCase()
                        .includes(searchArea.toUpperCase())
                    )}
                  columns={columnsArea}
                />
              </>
            ),
          },
          {
            key: "2",
            label: "De clientes",
            children: (
              <>
                <div className="clientes__actions">
                  <Search
                    placeholder="Buscar área"
                    className="clientes__actions--search"
                    allowClear
                    enterButton="Buscar"
                    onSearch={onSearchArea}
                    style={{ marginRight: "5rem" }}
                  />
                </div>
                <Table
                  className="clientes__list"
                  dataSource={moduloProcessos.areas
                    ?.filter((a: any) => a.cliente_id)
                    .filter((c: any) =>
                      c.description
                        .toUpperCase()
                        .includes(searchArea.toUpperCase())
                    )}
                  columns={columnsArea}
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Areas;
