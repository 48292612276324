import React, { useEffect, useState } from "react";
import "./styles.css";
import { GlobalContextType } from "../../../../../../../@types/global";
import { GlobalContext } from "../../../../../../../context/globalContext";
import { AnaliseTypes } from "../../../../../../../@types/analise";
import { PieChart, Pie, Legend, Tooltip, Label } from "recharts";

const Metricas = () => {
  const { currentAnalise } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const [dataAll, setDataAll] = useState<any>([]);

  useEffect(() => {
    if (currentAnalise.respostas && currentAnalise.respostas[0]) {
      setDataAll([
        {
          name: "Conforme",
          value: currentAnalise.respostas.filter(
            (resp: any) => resp.value === "s"
          ).length,
          fill: "#43CB03",
        },
        {
          name: "Não Conforme",
          value: currentAnalise.respostas.filter(
            (resp: any) => resp.value === "n"
          ).length,
          fill: "#FE0C0C",
        },
      ]);
    }
  }, [currentAnalise]);

  return (
    <div className="analise__metrics">
      <PieChart width={325} height={175}>
        <Pie
          dataKey="value"
          data={dataAll}
          innerRadius={25}
          outerRadius={50}
          label
        />

        <Legend align="right" verticalAlign="middle" layout="vertical" />
        <Tooltip />
      </PieChart>
      <PieChart width={325} height={175}>
        <Pie
          dataKey="value"
          data={dataAll}
          innerRadius={25}
          outerRadius={50}
          label
        />

        <Legend align="right" verticalAlign="middle" layout="vertical" />
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default Metricas;
