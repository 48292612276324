import { useContext } from "react";
import { GlobalContext } from "./globalContext";
import { message } from "antd";
import api from "../services/api";
import {
  ItemPauta,
  PautasType,
} from "../../src/pages/Modulos/CPPD/ModeloPautas/modeloPautas";

export type CppdContextType = {
  getPautas: () => Promise<any>;
  getItensPautas: () => Promise<any>;
  updatePauta: (pauta: PautasType) => Promise<any>;
  postItensPautas: (itemPauta: ItemPauta) => Promise<any>;
};

export const useCppdContext = (): CppdContextType => {
  const globalContext = useContext(GlobalContext);

  const getPautas = async () => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/pautas`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao buscar regimentos!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const updatePauta = async (pauta: PautasType) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .put(`/pautas/${pauta._id}`, pauta)
        .then((response) => {
          getPautas();
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao atualizar regimento!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const getItensPautas = async () => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .get(`/itemsPauta`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao criar tarefa!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };

  const postItensPautas = async (itemPauta: ItemPauta) => {
    globalContext?.incrementLoadingCount();
    return new Promise(async (resolve, reject) => {
      await api
        .post(`/itemsPauta`, itemPauta)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          message.error("Erro ao criar tarefa!");
          reject(err);
        })
        .finally(() => globalContext?.decrementLoadingCount());
    });
  };
  return {
    getPautas,
    getItensPautas,
    updatePauta,
    postItensPautas,
  };
};
