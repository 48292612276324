import React, { useState } from 'react';
import { Modal, Form, Input } from 'antd';

interface EditLinkModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  currentReuniaoId: string | null;
}

const EditLinkModal: React.FC<EditLinkModalProps> = ({
  isVisible,
  setIsVisible,
  currentReuniaoId,
}) => {
  const [editLinkValue, setEditLinkValue] = useState<string>('');

  const handleEditLinkOk = () => {
    if (currentReuniaoId && editLinkValue) {
      // Função para atualizar o link
      setIsVisible(false);
    }
  };

  return (
    <Modal
      title="Editar Link do Agendamento"
      visible={isVisible}
      onOk={handleEditLinkOk}
      onCancel={() => setIsVisible(false)}
    >
      <Form layout="vertical">
        <Form.Item label="Link">
          <Input
            value={editLinkValue}
            onChange={(e) => setEditLinkValue(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditLinkModal;
