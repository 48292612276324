// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { GlobalContext } from "../../../context/globalContext";
// import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import ListQuestinarios from "../../Apps/Questionarios/ListQuestionarios/ListQuestionarios";

const QuestionariosCPPD = () => {
  // const { moduloMapeamento, currentCliente } = React.useContext(
  //   GlobalContext
  // ) as GlobalContextType;

  // useEffect(() => {
  //   currentCliente?._id &&
  //     !moduloMapeamento?.questionarios?.find(
  //       (q) => q.cliente_id === currentCliente._id
  //     ) &&
  //     moduloMapeamento?.getQuestionariosCliente(currentCliente._id);
  // }, [currentCliente]);

  return (
    <div className="questionarios_CPPD">
      <BackHeader title="Questionários CPPD" />
      <ListQuestinarios editCPPD />
    </div>
  );
};

export default QuestionariosCPPD;
