import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Button, Tooltip } from "antd";
import {
  BellFilled,
  BellOutlined,
  CloseOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import "./styles.css";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import { NotificationType } from "../../context/notificacaoContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface NotificationIconProps {}

const NotificationIcon: React.FC<NotificationIconProps> = ({}) => {
  const { userInfo, moduloNotificacao } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const navigate = useNavigate();

  const [shake, setShake] = useState(false);
  const [notificacoes, setNotificacoes] = useState<NotificationType[]>([]);

  useEffect(() => {
    if (notificacoes.length > 0) {
      const intervalId = setInterval(() => {
        setShake(true);
        setTimeout(() => setShake(false), 500);
      }, 3000); // 30 segundos
      return () => clearInterval(intervalId);
    }
  }, [notificacoes]);

  const fetchData = async () => {
    if (userInfo && userInfo._id) {
      try {
        const notificacoes = await moduloNotificacao.obterNotificacoesNaoLidas(
          userInfo
        );
        setNotificacoes(notificacoes);
      } catch (error) {
        console.error("Erro ao obter notificações não lidas:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userInfo]);

  const handleNotificationClick = (notification: NotificationType) => {
    moduloNotificacao
      .marcarNotificacaoComoLida(notification._id)
      .finally(() => {
        fetchData();
      });
  };

  const tooltipContent = (
    <div>
      {notificacoes.map((notification) => (
        <div key={notification._id} className="notification-item">
          <Tooltip title="Ir para a página">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (
                  notification.modulo === "65a141c56b48108dc9ce355e" &&
                  notification.cliente_id
                ) {
                  navigate(`/planoacao/parceiros/${notification.cliente_id}`);
                }
              }}
            >
              <p>
                <strong>{notification.title} </strong>
                <small style={{ opacity: 0.4 }}>
                  {moment(new Date(notification.created_at)).format(
                    "DD/MM/YYYY"
                  )}
                </small>
              </p>
              <p>{notification.description}</p>
            </div>
          </Tooltip>
          <Tooltip title="Marcar como lido">
            <Button
              style={{ color: "#000", opacity: 0.4, marginLeft: "1rem" }}
              shape="circle"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => handleNotificationClick(notification)}
            />
          </Tooltip>
        </div>
      ))}

      {notificacoes.length === 0 && (
        <div
          style={{
            textAlign: "center",
            color: "#000",
            opacity: 0.4,
            padding: "4rem 6rem",
          }}
        >
          <SmileOutlined style={{ fontSize: 20 }} />
          <p>Tudo em dia!</p>
        </div>
      )}
    </div>
  );

  return (
    <Tooltip
      title={tooltipContent}
      trigger="click"
      color="#fff"
      placement="bottomRight"
      overlayStyle={{ maxWidth: "500px", marginTop: -10 }}
    >
      <div style={{ display: "flex" }}>
        <Button
          className={`notification_icon ${shake ? "shake" : ""}`}
          style={{ color: "#000" }}
          size="large"
          shape="circle"
          type="text"
          icon={<BellFilled />}
        />
        {/* <BellFilled
          className={`notification_icon ${shake ? "shake" : ""}`}
          style={{ color: "#fff", marginTop: "0.25rem" }}
        /> */}
        {notificacoes[0] && <div className={`notification_dot`} />}
      </div>
    </Tooltip>
  );
};

export default NotificationIcon;
