import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import { Button, Form, Input, InputNumber, Select } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";

const EditUsers = () => {
  const { gruposFunc, postUser } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const params = useParams();

  const onFinish = (values: any) => {
    postUser(values);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <div className="editUsers">
      <BackHeader title="Editar Usuário" route="/usuarios" />
      <Form
        {...layout}
        layout="vertical"
        name="edit-users-form"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
      >
        <Form.Item name={"name"} label="Nome">
          <Input />
        </Form.Item>
        <Form.Item
          name={"email"}
          label="Email"
          rules={[{ type: "email", required: true }]}
        >
          <Input />
        </Form.Item>
        {/* <Form.Item label="Tipo" name="sponsor" style={{ width: "40%" }}>
          <Select
            options={tiposUser}
            fieldNames={{ label: "name", value: "_id" }}
            // onChange={handleChange}
          />
        </Form.Item> */}
        <Form.Item
          label="Permissão"
          name="grupofuncoes"
          style={{ width: "40%" }}
        >
          <Select
            options={gruposFunc}
            fieldNames={{ label: "name", value: "_id" }}
            // onChange={handleChange}
          />
        </Form.Item>
        {/* <Form.Item name={["user", "celular"]} label="celular">
          <Input />
        </Form.Item>

        <Form.Item name={["user", "obs"]} label="Observações">
          <Input.TextArea />
        </Form.Item> */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUsers;
