import { useNavigate } from "react-router-dom";
import * as React from "react";
import "./styles.css";
import { Table, Input, Button, Tag } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";

const { Search } = Input;

const Capitulos = () => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [searchCap, setSearchCap] = React.useState<string>("");
  const [searchArea, setSearchArea] = React.useState<string>("");
  const [showNovaArea, setShowNovaArea] = React.useState(false);
  const [novaArea, setNovaArea] = React.useState("");
  const navigate = useNavigate();

  const columnsArea = [
    {
      title: "Área",
      dataIndex: "description",
    },
  ];
  const columnsCap = [
    {
      title: "Processo",
      dataIndex: "description",
    },
    {
      title: "Tags Áreas",
      dataIndex: "tags",
      render: (text: any) => (
        <div style={{ display: "flex" }}>
          {text.map((t: any) => (
            <Tag color="magenta">
              {
                moduloProcessos.areas?.filter((a: any) => a._id === t)[0]
                  ?.description
              }
            </Tag>
          ))}
        </div>
      ),
    },
  ];

  const onSearchCap = (value: string) => setSearchCap(value);
  const onSearchArea = (value: string) => setSearchArea(value);

  return (
    <div className="clientes">
      <BackHeader title={"Áreas / Processos / Perguntas"} />

      {/* Busca áreas */}
      <div className="clientes__actions">
        <Search
          placeholder="Buscar área"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          onSearch={onSearchArea}
          style={{ marginRight: "5rem" }}
        />
        <Button
          style={{ width: 130 }}
          className="clientes__actions--new"
          type="primary"
          onClick={() => setShowNovaArea(true)}
        >
          Nova área
        </Button>
      </div>
      {showNovaArea && (
        <div className="clientes__actions">
          <Input
            placeholder="Digite o nome da nova área"
            style={{ marginRight: "5rem" }}
            onChange={(v: any) => setNovaArea(v.target.value)}
          />
          <Button
            className="clientes__actions--new"
            type="primary"
            disabled={novaArea.length > 1 ? false : true}
            onClick={() => {
              novaArea.length > 1 && setShowNovaArea(false);
              novaArea.length > 1 && setNovaArea("");
              novaArea.length > 1 && moduloProcessos.postArea(novaArea);
            }}
          >
            Salvar
          </Button>
        </div>
      )}

      {/* Busca processos */}
      <div className="clientes__actions">
        <Search
          placeholder="Buscar capitulo"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          style={{ marginRight: "5rem" }}
          onSearch={onSearchCap}
        />
        <Button
          style={{ width: 130 }}
          className="clientes__actions--new"
          type="primary"
          onClick={() => navigate("/areas/editar")}
        >
          Novo processo
        </Button>
      </div>

      {/* tabelas area > cap > processo > sub-processo > pergunta */}
      {/* Área */}
      <Table
        className="clientes__list"
        dataSource={moduloProcessos.areas
          ?.filter((a: any) => !a.cliente_id)
          .filter((c: any) =>
            c.description.toUpperCase().includes(searchArea.toUpperCase())
          )}
        columns={columnsArea}
        rowKey={(record) => record._id}
        expandable={{
          expandedRowRender: (recordArea) => (
            <>
              {/* Processo */}
              <Table
                className="clientes__list"
                dataSource={moduloProcessos.capitulos?.filter(
                  (c: any) =>
                    c.description
                      .toUpperCase()
                      .includes(searchCap.toUpperCase()) &&
                    !c.dependencia &&
                    c.tags.includes(recordArea._id)
                )}
                size="small"
                style={{ cursor: "pointer" }}
                onRow={(recordProcesso, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigate("/capitulos/editar/" + recordProcesso._id);
                    },
                  };
                }}
                rowKey={(recordSub) => recordSub._id}
                expandable={{
                  expandedRowRender: (recordSub) => (
                    <>
                      {/* Subprocesso */}
                      <Table
                        size="small"
                        className="clientes__list"
                        dataSource={moduloProcessos.capitulos?.filter(
                          (c: any) =>
                            c.dependencia &&
                            c.dependencia === recordSub._id &&
                            !c.cliente_id
                        )}
                        style={{ cursor: "pointer" }}
                        onRow={(recordSubRow) => {
                          return {
                            onClick: () => {
                              navigate("/capitulos/editar/" + recordSubRow._id);
                            },
                          };
                        }}
                        rowKey={(recordSub) => recordSub._id}
                        columns={[
                          {
                            title: "Subprocesso",
                            dataIndex: "description",
                          },
                        ]}
                      />
                    </>
                  ),
                  rowExpandable: (recordSub) =>
                    moduloProcessos.capitulos?.filter(
                      (c: any) =>
                        c.description
                          .toUpperCase()
                          .includes(searchCap.toUpperCase()) &&
                        !c.dependencia &&
                        c.tags.includes(recordArea._id)
                    )?.[0]
                      ? true
                      : false,
                }}
                columns={columnsCap}
              />
            </>
          ),
          rowExpandable: (recordArea) =>
            moduloProcessos.capitulos?.filter(
              (c: any) =>
                c.description.toUpperCase().includes(searchCap.toUpperCase()) &&
                !c.dependencia &&
                c.tags?.includes(recordArea._id)
            )?.[0]
              ? true
              : false,
        }}
      />
    </div>
  );
};

export default Capitulos;
