import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputRef,
  Select,
  Space,
  Table,
  Tag,
  theme,
} from "antd";
import Modal from "antd/es/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContext } from "../../../../context/globalContext";
import api from "../../../../services/api";
import ModalEditPergunta from "./EditPergunta/ModalEditPergunta";
import "./styles.css";
import { PerguntaType } from "../../../../context/processosContext";
import { AnyObject } from "antd/es/_util/type";

const { TextArea } = Input;

interface EditCapituloProps {
  novo: boolean;
}

const EditCapitulo: React.FC<EditCapituloProps> = ({ novo }) => {
  const { moduloProcessos } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [newCap, setNewCap] = React.useState<any>({});
  const [tipo, setTipo] = React.useState<string>();
  const [recomendacao, setRecomendacao] = useState<any>("");
  const [showModal, setShowModal] = useState<boolean>(false);

  const params = useParams();

  const navigate = useNavigate();

  const onFinish = () => {
    if (params.id) {
      moduloProcessos.postCapitulo({
        id: params.id || null,
        description:
          newCap.description ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .description,
        norma:
          newCap.norma ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .norma,
        recomendacao:
          newCap.recomendacao ||
          moduloProcessos.capitulos?.filter((c: any) => c._id === params.id)[0]
            .recomendacao,
        tags: tags,
      });
    } else {
      moduloProcessos.postCapitulo({
        id: null,
        description: newCap.description,
        norma: newCap.norma,
        recomendacao: newCap.recomendacao,
        tags: tags,
        dependencia: params.id,
      });
    }

    navigate("/capitulos");
    setShowModal(false);
    setNewCap({});
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalOpenSub, setIsModalOpenSub] = useState<boolean>(false);

  const handleShowModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleShowModalSub = () => {
    setIsModalOpenSub(!isModalOpenSub);
  };
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 },
  };

  const { token } = theme.useToken();
  const [tags, setTags] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState<any>(false);
  const [inputValue, setInputValue] = useState<any>("");
  const [editInputIndex, setEditInputIndex] = useState<any>(-1);
  const [editInputValue, setEditInputValue] = useState<any>("");
  const [perguntasListadas, setPerguntasListadas] = useState<any>([]);
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [selectedPergunta, setSelectedPergunta] = useState<any>(null);

  useEffect(() => {
    if (!novo) {
      moduloProcessos.capitulos &&
        moduloProcessos.capitulos[0] &&
        moduloProcessos.capitulos.filter((c: any) => c._id === params.id)[0] &&
        setTags(
          moduloProcessos.capitulos.filter((c: any) => c._id === params.id)[0]
            .tags
        );
    } else {
      setTags([]);
    }
  }, [moduloProcessos.capitulos]);

  useEffect(() => {
    if (!novo) {
      const perguntasRelacionadas = moduloProcessos.capituloPerguntas?.filter(
        (relacao: any) => relacao.capitulo_id.includes(params.id)
      );

      const perguntasListadas = perguntasRelacionadas?.map((relacao: any) => {
        const perguntaId = relacao.pergunta_id;
        return moduloProcessos.perguntas?.find(
          (pergunta: any) => pergunta._id === perguntaId
        );
      });
      setPerguntasListadas(perguntasListadas?.filter((p: any) => p));
    } else {
      setPerguntasListadas([]);
    }
  }, [moduloProcessos.capituloPerguntas, moduloProcessos.perguntas]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag: any) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && tags.indexOf(e) === -1) {
      setTags([...tags, e]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const tagInputStyle: React.CSSProperties = {
    width: "800px",
    verticalAlign: "top",
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  const columns = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
    },
    {
      title: "Ação",
      key: "action",
      render: (text: any, record: any) => (
        <div>
          <Button
            icon={<EditOutlined />}
            type="primary"
            onClick={() => {
              setSelectedPergunta(record);
              handleShowModal();
            }}
          >
            Editar
          </Button>
          <Button
            style={{ color: "red", borderColor: "red", marginLeft: "1rem" }}
            icon={<DeleteOutlined />}
            onClick={() => {
              moduloProcessos?.patchPergunta({
                _id: record._id,
                inativo: true,
              });
            }}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  const columnsSubProcessos = [
    { title: "Descrição", dataIndex: "description", key: "description" },
  ];

  return (
    <div className="editCapitulo">
      {!novo && (
        <BackHeader
          title={params.id ? "Editar Processo" : "Novo Processo"}
          route="/capitulos"
        />
      )}

      <Form
        {...layout}
        layout="vertical"
        name="edit-users-form"
        onFinish={onFinish}
        // style={{ maxWidth: 600 }}
      >
        <Form.Item name={["capitulo", "description"]} label="Nome do processo">
          <Input
            value={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.description || newCap.description
                : newCap.description
            }
            defaultValue={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.description || newCap.description
                : newCap.description
            }
            onChange={(v: any) =>
              setNewCap({ ...newCap, description: v.target.value })
            }
          />
        </Form.Item>
        {!novo && (
          <>
            <h2>Áreas associadas</h2>
            <Space size={[0, 8]} wrap style={{ margin: "1rem 0 1rem 0" }}>
              <Space size={[0, 8]} wrap>
                {tags.map((tag: any, index: number) => (
                  <Tag
                    color="magenta"
                    key={tag}
                    closable={true}
                    style={{ userSelect: "none" }}
                    onClose={() => handleClose(tag)}
                  >
                    <span
                      onDoubleClick={(e) => {
                        if (index !== 0) {
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          e.preventDefault();
                        }
                      }}
                    >
                      {
                        moduloProcessos.areas?.filter(
                          (a: any) => a._id === tag
                        )[0].description
                      }
                    </span>
                  </Tag>
                ))}
              </Space>
              {inputVisible ? (
                <Select
                  options={moduloProcessos.areas}
                  // ref={inputRef}
                  value={inputValue}
                  style={tagInputStyle}
                  fieldNames={{ label: "description", value: "_id" }}
                  onChange={handleInputConfirm}
                />
              ) : (
                <Tag style={tagPlusStyle} onClick={showInput} color="green">
                  <PlusOutlined rev={undefined} /> Nova área
                </Tag>
              )}
            </Space>
            {params.id && !novo && (
              <>
                <h2 style={{ marginTop: "1rem" }}>Subprocessos</h2>
                <br />
                <Button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  icon={<PlusOutlined />}
                  type="primary"
                >
                  Adicionar subprocesso
                </Button>
                <Table
                  style={{ marginTop: "1rem" }}
                  size="small"
                  columns={columnsSubProcessos}
                  expandable={{
                    expandedRowRender: (record) => {
                      const perguntasRelacionadasSub =
                        moduloProcessos.capituloPerguntas?.filter(
                          (relacao: any) =>
                            relacao.capitulo_id.includes(record._id)
                        );
                      const perguntasListadasSub = perguntasRelacionadasSub
                        ?.map((relacao: any) => {
                          const perguntaId = relacao.pergunta_id;
                          return moduloProcessos.perguntas?.find(
                            (pergunta: any) => pergunta._id === perguntaId
                          );
                        })
                        .filter(
                          (
                            pergunta: PerguntaType | undefined
                          ): pergunta is PerguntaType => pergunta !== undefined
                        ) as AnyObject[];

                      return (
                        <div style={{ marginLeft: "3rem" }}>
                          <h3>Norma</h3>
                          <p>{record.norma}</p>
                          <br />
                          <h3>Recomendação</h3>
                          <p>{record.recomendacao}</p>
                          <br />

                          <div
                            className="j-b d-f a-c"
                            style={{ margin: "0.5rem 0" }}
                          >
                            <h3>Perguntas</h3>
                            <Button
                              icon={<PlusOutlined />}
                              size="small"
                              type="primary"
                              onClick={handleShowModalSub}
                            >
                              Nova pergunta
                            </Button>
                          </div>
                          <Table
                            size="small"
                            columns={columns}
                            dataSource={perguntasListadasSub}
                            pagination={{ pageSize: 5 }}
                            locale={{
                              emptyText: "Nenhuma pergunta localizada",
                            }}
                          />
                          <ModalEditPergunta
                            handleShowModal={handleShowModalSub}
                            showModal={isModalOpenSub}
                            capId={record._id}
                            perguntasListadas={perguntasListadasSub}
                          />
                        </div>
                      );
                    },
                    rowExpandable: (record) => record.name !== "Not Expandable",
                  }}
                  dataSource={moduloProcessos.capitulos
                    ?.filter(
                      (cap: any) =>
                        cap.dependencia && cap.dependencia === params.id
                    )
                    .map((cap: any) => {
                      return {
                        ...cap,
                        key: cap._id,
                      };
                    })}
                />
                <hr />
                <div className="j-b d-f a-c" style={{ margin: "0.5rem 0" }}>
                  <h2>Perguntas do processo</h2>
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                    onClick={handleShowModal}
                  >
                    Nova pergunta
                  </Button>
                </div>
                <Table
                  size="small"
                  columns={columns}
                  dataSource={perguntasListadas}
                  pagination={{ pageSize: 5 }}
                  locale={{ emptyText: "Nenhuma pergunta localizada" }}
                />
              </>
            )}
          </>
        )}
        <hr />

        <Form.Item name={["capitulo", "norma"]} label="Norma">
          <TextArea
            rows={5}
            onChange={(v: any) =>
              setNewCap({ ...newCap, norma: v.target.value })
            }
            value={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.norma || newCap.norma
                : newCap.norma
            }
            defaultValue={
              params.id && !novo
                ? moduloProcessos.capitulos?.filter(
                    (c: any) => c._id === params.id
                  )[0]?.norma || newCap.norma
                : newCap.norma
            }
          />
        </Form.Item>
        <Form.Item name={["capitulo", "recomendacao"]} label="Recomendação">
          <div>
            <ReactQuill
              placeholder="Escreva a recomendação"
              // value={
              //   capitulos.filter((c: any) => c._id === params.id)[0]
              //     .recomendacao || newCap.recomendacao
              // }
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
                clipboard: {
                  // toggle to add extra line breaks when pasting HTML:
                  matchVisual: false,
                },
                // imageResize: {
                //   parchment: Quill.import("parchment"),
                //   modules: ["Resize", "DisplaySize"],
                // },
              }}
              defaultValue={
                params.id && !novo
                  ? moduloProcessos.capitulos?.filter(
                      (c: any) => c._id === params.id
                    )[0]?.recomendacao || newCap.recomendacao
                  : newCap.recomendacao
              }
              onChange={(v: any) => setNewCap({ ...newCap, recomendacao: v })}
            />
          </div>

          {/* <TextArea
            rows={5}
            onChange={(v: any) =>
              setNewCap({ ...newCap, recomendacao: v.target.value })
            }
            value={
              capitulos.filter((c: any) => c._id === params.id)[0]
                .recomendacao || newCap.recomendacao
            }
            defaultValue={
              capitulos.filter((c: any) => c._id === params.id)[0]
                .recomendacao || newCap.recomendacao
            }
          /> */}
        </Form.Item>
        <hr style={{ marginTop: "1.5rem" }} />
        <Form.Item>
          <Button type="primary" onClick={() => onFinish()}>
            Salvar
          </Button>
        </Form.Item>
      </Form>
      {params.id && isModalOpen && (
        <ModalEditPergunta
          handleShowModal={handleShowModal}
          showModal={isModalOpen}
          capId={params.id}
          perguntasListadas={perguntasListadas}
          selectedPergunta={selectedPergunta}
        />
      )}

      {!novo && (
        <Modal
          title="Cadastrar subprocesso"
          open={showModal}
          footer={[]}
          width={1000}
          onCancel={() => setShowModal(false)}
        >
          <EditCapitulo novo />
        </Modal>
      )}
    </div>
  );
};

export default EditCapitulo;
