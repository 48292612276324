import {
  CheckCircleFilled,
  PlusCircleOutlined,
  MinusCircleFilled,
  RightOutlined,
  MailOutlined,
  LeftOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Steps,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { Questionario } from "../../../../context/mapeamentoContext";
import "./styles.css";

interface MapeamentoProps {
  currentMapeamento: Questionario | null | undefined; // ou qualquer outro tipo apropriado
  setCurrentMapeamento: (mapeamento: Questionario | null) => void;
}

const Mapeamento: React.FC<MapeamentoProps> = ({
  currentMapeamento,
  setCurrentMapeamento,
}) => {
  const {
    userInfo,
    moduloMapeamento,
    moduloProcessos,
    postPrimeiroAcesso,
    postUser,
    usuarios,
    patchUser,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [clickedArea, setClickedArea] = useState<string | null>(null);
  const [concluirStep1, setConcluirStep1] = useState<boolean>(false);
  const [concluirStep2, setConcluirStep2] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [currentUserGestor, setCurrentUserGestor] = useState<any>(null);
  const [usersGestores, setUsersGestores] = useState<any>(null);
  const [finishStep3, setFinishStep3] = useState(false);
  const [reutilizarGestor, setReutilizarGestor] = useState(false);
  const [currentUserResponsavel, setCurrentUserResponsavel] = useState(false);
  const [criarNovoUsuario, setCriarNovoUsuario] = useState(false);
  const [selectedGestor, setSelectedGestor] = useState(null);
  const [open, setOpen] = useState(false);
  const [newArea, setNewArea] = useState("");

  const showModal = () => {
    setOpen(true);
  };

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [executedOnceStep1, setExecutedOnceStep1] = useState(false);
  const [executedOnceStep2, setExecutedOnceStep2] = useState(false);
  const [executedOnceStep3, setExecutedOnceStep3] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (moduloMapeamento && !executedOnceStep1) {
      if (currentMapeamento) {
        // Marca como executado uma vez
        setExecutedOnceStep1(true);

        if (currentMapeamento?.areas && currentMapeamento.areas.length > 0) {
          setConcluirStep1(true);
          step !== 2 && setStep(1);
        }
      }
    }
  }, [moduloMapeamento, executedOnceStep1]);

  useEffect(() => {
    if (currentMapeamento?.areas && currentMapeamento.areas.length > 0) {
      const usersGestorers = usuarios.filter(
        (user: any) =>
          user.funcoesDetalhadas.find(
            (funcao: any) =>
              funcao.funcao_id.name === "view-area" &&
              currentMapeamento?.areas?.includes(funcao.reference_model_id)
          ) &&
          user.funcoesDetalhadas.find(
            (funcao: any) =>
              funcao.funcao_id.name === "responder-questionario" &&
              funcao.reference_model_id === currentMapeamento._id
          )
      );

      setUsersGestores(usersGestorers);

      // if (usersGestorers.length === currentMapeamento.areas.length) {
      if (usersGestorers.length > 0 && !executedOnceStep2) {
        setConcluirStep2(true);
        setStep(2);

        // Marca como executado uma vez
        setExecutedOnceStep2(true);

        setClickedArea(currentMapeamento?.areas[0]);

        const userGestor = usuarios.find(
          (user: any) =>
            user.funcoesDetalhadas.find(
              (funcao: any) =>
                funcao.funcao_id.name === "view-area" &&
                funcao.reference_model_id === currentMapeamento?.areas?.[0]
            ) &&
            user.funcoesDetalhadas.find(
              (funcao: any) =>
                funcao.funcao_id.name === "responder-questionario" &&
                funcao.reference_model_id === currentMapeamento._id
            )
        );
        setCurrentUserGestor(userGestor);
        if (userGestor)
          form.setFieldsValue({
            name: userGestor.name,
            cargo: userGestor.cargo,
            email: userGestor.email,
            telefone: userGestor.telefone,
          });
      }
    }
  }, [currentMapeamento, usuarios, executedOnceStep2]);

  useEffect(() => {
    if (
      step === 2 &&
      usersGestores?.filter((usr: any) =>
        usr.envios?.find(
          (envio: any) =>
            envio.subject === "Seusdados - Bem-vindo à Plataforma Workday"
        )
      )?.length === usersGestores.length &&
      !executedOnceStep3
    ) {
      showModal();
      setFinishStep3(true);

      setExecutedOnceStep3(true);
    }
  }, [usersGestores, executedOnceStep3]);

  const getCurrentStep = (): number => {
    if (step) {
      return step;
    }
    // if (currentMapeamento?.areas && currentMapeamento.areas.length > 0 && clickedArea) {
    //   return 1; // Or other logic to determine the step based on the state of `currentMapeamento`
    // }
    return 0;
  };

  const toggleArea = (areaId: string) => {
    if (currentMapeamento && currentMapeamento.areas) {
      const currentAreas = currentMapeamento.areas;
      const newAreas = currentAreas.includes(areaId)
        ? currentAreas.filter((id) => id !== areaId)
        : [...currentAreas, areaId];
      setCurrentMapeamento({ ...currentMapeamento, areas: newAreas });
      setClickedArea(areaId);
    }
  };

  const handleAddArea = () => {
    // cadastra nova area e retorna seu _id
    moduloProcessos?.postArea(newArea).then((area) => toggleArea(area._id));

    // Limpar campo de nova área
    setNewArea("");
  };

  const onFinish = (values: any) => {
    if (selectedGestor) {
      patchUser({
        ...usuarios.find((usr: any) => usr._id === selectedGestor),
        user_id: selectedGestor,
        grupos: [
          ...usuarios.find((usr: any) => usr._id === selectedGestor)?.grupos,
          "65f98308c0e72d990258afaf",
        ], // grupo gestor area
        permissoes: [
          ...usuarios
            .find((usr: any) => usr._id === selectedGestor)
            ?.funcoesDetalhadas?.map((permissao: any) => {
              return {
                key: permissao._id,
                name: permissao.funcao_id.name,
                description: permissao.funcao_id.description,
                reference_model_name: permissao.funcao_id.reference_model_name,
                reference_model_id: permissao.reference_model_id,
                funcao_id: permissao.funcao_id._id,
              };
            }),
          {
            reference_model_id: clickedArea,
            funcao_id: "65f982dcc0e72d990258afad",
          },
          {
            reference_model_id: currentMapeamento?._id,
            funcao_id: "65fb2140c0e72d990258afc0",
          },
        ],
      });
    } else {
      postUser({
        ...values,
        cliente_id: currentMapeamento?.cliente_id,
        grupos: ["65f98308c0e72d990258afaf"], // grupo gestor area
        permissoes: [
          // permissao view-area
          {
            reference_model_id: clickedArea,
            funcao_id: "65f982dcc0e72d990258afad",
          },
          // responder-questionario
          {
            reference_model_id: currentMapeamento?._id,
            funcao_id: "65fb2140c0e72d990258afc0",
          },
        ],
      });
    }
  };

  return (
    <div className="mapeamentos">
      {!userInfo?.grupos?.find(
        (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
      ) && (
        <>
          <h1>Olá, {userInfo?.name?.split(" ")[0]}</h1>
          <br />
          <p>
            Para iniciar essa etapa, vamos adicionar os gestores de área para
            que eles possam cadastrar os líderes de cada processo?
          </p>
          <br />
        </>
      )}

      <div className="mapeamentos_steps">
        <Steps
          current={getCurrentStep()}
          items={[
            {
              title: "Adicionar áreas",
              description: (
                <span
                  style={{
                    color:
                      step === 0 ? "#0368C8" : step > 0 ? "#008C76" : "#A3A3A3",
                    fontSize: "0.6rem",
                  }}
                >
                  {step === 0 ? "Em andamento" : step > 0 ? "Concluído" : ""}
                </span>
              ),
              subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 1</p>,
            },
            {
              title: "Definir gestores",
              description: (
                <span
                  style={{
                    color:
                      step === 1 ? "#0368C8" : step > 1 ? "#008C76" : "#A3A3A3",
                    fontSize: "0.6rem",
                  }}
                >
                  {step === 1
                    ? "Em andamento"
                    : step > 1
                    ? "Concluído"
                    : "Pendente"}
                </span>
              ),
              subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 2</p>,
            },
            {
              title: "Revisão",
              description: (
                <span
                  style={{
                    color:
                      step === 2 ? "#0368C8" : step > 2 ? "#008C76" : "#A3A3A3",
                    fontSize: "0.6rem",
                  }}
                >
                  {step === 2
                    ? "Em andamento"
                    : step > 1
                    ? "Concluído"
                    : "Pendente"}
                </span>
              ),
              subTitle: <p style={{ fontSize: "0.6rem" }}>ETAPA 3</p>,
              status: finishStep3 ? "finish" : "process",
            },
          ]}
        />
      </div>
      <br />
      <div className="mapeamentos_containner">
        {getCurrentStep() === 0 && (
          <>
            <div className="mapeamentos_steps">
              <h5>Selecione ou adicione as áreas da sua empresa:</h5>
              <br />
              {moduloProcessos?.areas
                ?.filter(
                  (area) =>
                    !area.cliente_id ||
                    area.cliente_id === currentMapeamento?.cliente_id
                )
                ?.map((area: any) => (
                  <div>
                    <Button
                      style={{ marginBottom: "0.5rem" }}
                      key={area._id}
                      size="small"
                      icon={
                        currentMapeamento?.areas?.includes(area._id) ? (
                          <CheckCircleFilled style={{ color: "#3333FF" }} />
                        ) : (
                          <PlusCircleOutlined />
                        )
                      }
                      onClick={() => toggleArea(area._id)}
                    >
                      {area.description}
                    </Button>
                  </div>
                ))}

              {/* Campo para adicionar nova área */}
              <Space style={{ marginTop: "1rem" }}>
                <Input
                  size="small"
                  value={newArea}
                  onChange={(e) => setNewArea(e.target.value)}
                  placeholder="Digite o nome da nova área"
                  style={{ width: 200 }}
                />
                <Button
                  size="small"
                  type="primary"
                  onClick={handleAddArea}
                  disabled={!newArea.trim()}
                  icon={<PlusCircleOutlined />}
                >
                  Adicionar
                </Button>
              </Space>
            </div>
            <div
              className={
                currentMapeamento?.areas?.length &&
                currentMapeamento?.areas?.length > 0
                  ? "mapeamentos_steps triangulo"
                  : "mapeamentos_steps--hidden"
              }
            >
              {currentMapeamento?.areas &&
                currentMapeamento?.areas?.length > 0 && (
                  <>
                    <h5>Áreas adicionadas</h5>
                    <br />
                    {currentMapeamento?.areas?.map((area: any) => (
                      <div>
                        <Tooltip title="Remover área?">
                          <Button
                            type="primary"
                            style={{ marginBottom: "0.5rem" }}
                            key={area._id}
                            size="small"
                            icon={<MinusCircleFilled style={{}} />}
                            onClick={() => toggleArea(area)}
                          >
                            {
                              moduloProcessos?.areas?.find(
                                (a) => a._id === area
                              )?.description
                            }
                          </Button>
                        </Tooltip>
                      </div>
                    ))}
                    <br />
                    <br />
                    <Button
                      style={{ background: "#32E6BE", color: "#333333" }}
                      size="small"
                      type="primary"
                      onClick={() => {
                        moduloMapeamento?.saveQuestionario(
                          currentMapeamento?.cliente_id,
                          currentMapeamento
                        );
                        setConcluirStep1(true);
                      }}
                    >
                      Concluir
                    </Button>
                  </>
                )}
            </div>
            <div
              className={
                concluirStep1
                  ? "mapeamentos_steps triangulo"
                  : "mapeamentos_steps--hidden"
              }
            >
              {concluirStep1 && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CheckCircleFilled
                    style={{ fontSize: "80px", color: "#32E6BE" }}
                  />
                  <br />
                  <h2>Etapa concluída</h2>
                  <br />
                  <Button
                    onClick={() => setStep(1)}
                    type="text"
                    style={{ color: "#008C76" }}
                  >
                    Ir para próxima etapa <RightOutlined />
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
        {getCurrentStep() === 1 && (
          <>
            <div className="mapeamentos_steps">
              <h5>Defina um gestor para as seguintes áreas:</h5>
              <br />
              {currentMapeamento?.areas?.map((area: any) => {
                const userGestor = usuarios.find(
                  (user: any) =>
                    user.funcoesDetalhadas.find(
                      (funcao: any) =>
                        funcao.funcao_id.name === "view-area" &&
                        funcao.reference_model_id === area
                    ) &&
                    user.funcoesDetalhadas.find(
                      (funcao: any) =>
                        funcao.funcao_id.name === "responder-questionario" &&
                        funcao.reference_model_id === currentMapeamento._id
                    )
                );
                return (
                  <div>
                    <Button
                      key={area}
                      size="small"
                      style={{ marginBottom: "0.5rem" }}
                      icon={
                        userGestor ? (
                          <CheckCircleFilled style={{ color: "#3333FF" }} />
                        ) : (
                          <PlusCircleOutlined />
                        )
                      }
                      onClick={() => {
                        form.resetFields();

                        setClickedArea(area);

                        setCurrentUserGestor(userGestor);
                        if (userGestor)
                          form.setFieldsValue({
                            name: userGestor.name,
                            cargo: userGestor.cargo,
                            email: userGestor.email,
                            telefone: userGestor.telefone,
                          });
                        setReutilizarGestor(false);
                        setCriarNovoUsuario(false);
                        setCurrentUserResponsavel(false);
                      }}
                    >
                      {
                        moduloProcessos?.areas?.find((a) => a._id === area)
                          ?.description
                      }
                    </Button>
                  </div>
                );
              })}
            </div>
            <div
              className={
                getCurrentStep() >= 1 && clickedArea
                  ? "mapeamentos_steps triangulo"
                  : "mapeamentos_steps--hidden"
              }
            >
              {getCurrentStep() >= 1 && clickedArea && (
                <>
                  <h5>
                    Dados do gestor:{" "}
                    {
                      moduloProcessos.areas?.find((a) => a._id === clickedArea)
                        ?.description
                    }
                  </h5>
                  <br />
                  <Form
                    form={form}
                    name="gestorarea"
                    disabled={currentUserGestor}
                    onFinish={onFinish}
                    layout="vertical"
                    autoComplete="off"
                  >
                    {usuarios?.find(
                      (u: any) => u.cliente_id === currentMapeamento?.cliente_id
                    ) &&
                      !currentUserGestor && (
                        <>
                          <Checkbox
                            checked={currentUserResponsavel}
                            onChange={() => {
                              setSelectedGestor(userInfo._id);
                              setCurrentUserResponsavel(true);

                              setReutilizarGestor(false);
                              setCriarNovoUsuario(false);
                            }}
                            style={{ marginBottom: "1rem" }}
                          >
                            Quero ser o responsável
                          </Checkbox>
                          <Checkbox
                            checked={reutilizarGestor}
                            onChange={() => {
                              setReutilizarGestor(!reutilizarGestor);

                              setCurrentUserResponsavel(false);
                              setCriarNovoUsuario(false);
                            }}
                            style={{ marginBottom: "1rem" }}
                          >
                            Utilizar usuário já cadastrado
                          </Checkbox>
                          <Checkbox
                            checked={criarNovoUsuario}
                            onChange={() => {
                              setCriarNovoUsuario(!criarNovoUsuario);

                              setReutilizarGestor(false);
                              setCurrentUserResponsavel(false);
                            }}
                            style={{ marginBottom: "1rem" }}
                          >
                            Cadastrar novo usuário
                          </Checkbox>
                        </>
                      )}
                    {reutilizarGestor && (
                      <Form.Item>
                        <Select
                          placeholder="Selecione um gestor existente"
                          onChange={(v) => {
                            setSelectedGestor(v);
                          }}
                          allowClear
                          options={usuarios
                            ?.filter(
                              (u: any) =>
                                u.cliente_id === currentMapeamento?.cliente_id
                            )
                            ?.map((usr: any) => {
                              return {
                                value: usr._id,
                                label: usr.name.toUpperCase(),
                              };
                            })}
                        />
                      </Form.Item>
                    )}

                    {(currentUserGestor ||
                      (!reutilizarGestor &&
                        !currentUserResponsavel &&
                        criarNovoUsuario)) && (
                      <>
                        <Form.Item
                          label="Nome"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "O nome é obrigatório!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Cargo"
                          name="cargo"
                          rules={[
                            {
                              required: true,
                              message: "O cargo é obrigatório!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "O e-mail é obrigatório!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          label="Telefone"
                          name="telefone"
                          rules={[
                            {
                              required: true,
                              message: "O telefone é obrigatório!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </>
                    )}

                    {!currentUserGestor &&
                      (reutilizarGestor ||
                        currentUserResponsavel ||
                        criarNovoUsuario) && (
                        <Form.Item>
                          <Button
                            size="small"
                            style={{ background: "#32E6BE", color: "#333333" }}
                            type="primary"
                            htmlType="submit"
                          >
                            Concluir
                          </Button>
                        </Form.Item>
                      )}
                  </Form>
                  {currentUserGestor && (
                    <Button
                      disabled
                      size="small"
                      type="primary"
                      htmlType="submit"
                    >
                      Alterar
                    </Button>
                  )}
                  {!concluirStep2 && (
                    <div>
                      <Button
                        icon={<LeftOutlined />}
                        type="text"
                        onClick={() => setStep(0)}
                        style={{
                          color: "#A3A3A3",
                          marginLeft: "-1rem",
                          marginTop: "2rem",
                        }}
                      >
                        Etapa anterior
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className={
                concluirStep2
                  ? "mapeamentos_steps triangulo"
                  : "mapeamentos_steps--hidden"
              }
            >
              {concluirStep2 && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleFilled
                      style={{ fontSize: "80px", color: "#32E6BE" }}
                    />
                    <br />
                    <h2>Etapa concluída</h2>
                    <br />
                    <Button
                      onClick={() => setStep(2)}
                      type="text"
                      style={{ color: "#008C76" }}
                    >
                      Ir para próxima etapa <RightOutlined />
                    </Button>
                  </div>
                  <div className="d-flex j-b">
                    <Button
                      icon={<LeftOutlined />}
                      type="text"
                      onClick={() => setStep(0)}
                      style={{ color: "#A3A3A3" }}
                    >
                      Etapa anterior
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {getCurrentStep() === 2 && (
        <div className="mapeamentos_containner">
          <div
            className="mapeamentos_steps"
            style={{
              width:
                concluirStep2 && moduloMapeamento.currentUserHasGestorPermission
                  ? "70%"
                  : "100%",
            }}
          >
            <h5>Revise os dados e envie para os gestores</h5>
            <br />
            <Table
              size="small"
              bordered
              dataSource={usersGestores}
              columns={[
                {
                  title: "Nome",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Cargo",
                  dataIndex: "cargo",
                  key: "cargo",
                },
                {
                  title: "E-mail",
                  dataIndex: "email",
                  key: "email",
                },
                {
                  title: "Telefone",
                  dataIndex: "telefone",
                  key: "telefone",
                },
                {
                  title: "Ações",
                  dataIndex: "_id",
                  key: "_id",
                  render: (_, record) => (
                    <Tooltip
                      title={
                        record.envios?.find(
                          (envio: any) =>
                            envio.subject ===
                            "Seusdados - Bem-vindo à Plataforma Workday"
                        )
                          ? "Enviar novamente - Último envio: " +
                            moment(
                              new Date(
                                record.envios?.find(
                                  (envio: any) =>
                                    envio.subject ===
                                    "Seusdados - Bem-vindo à Plataforma Workday"
                                ).created_at
                              )
                            ).format("DD/MM/YYYY HH:mm")
                          : "Enviar primeiro acesso"
                      }
                    >
                      <Button
                        icon={
                          record.envios?.find(
                            (envio: any) =>
                              envio.subject ===
                              "Seusdados - Bem-vindo à Plataforma Workday"
                          ) ? (
                            <CheckOutlined />
                          ) : (
                            <MailOutlined />
                          )
                        }
                        style={{
                          backgroundColor: record.envios?.find(
                            (envio: any) =>
                              envio.subject ===
                              "Seusdados - Bem-vindo à Plataforma Workday"
                          )
                            ? "#008C76"
                            : "",
                        }}
                        size="small"
                        type="primary"
                        onClick={() => {
                          postPrimeiroAcesso(record);
                        }}
                      >
                        {record.envios?.find(
                          (envio: any) =>
                            envio.subject ===
                            "Seusdados - Bem-vindo à Plataforma Workday"
                        )
                          ? "Enviado"
                          : "Enviar"}
                      </Button>
                    </Tooltip>
                  ),
                },
              ]}
            />
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex j-b">
              <Button
                icon={<LeftOutlined />}
                type="text"
                onClick={() => setStep(1)}
                style={{ color: "#A3A3A3" }}
              >
                Etapa anterior
              </Button>
            </div>
          </div>
          <div
            style={{
              width:
                concluirStep2 && moduloMapeamento.currentUserHasGestorPermission
                  ? "30%"
                  : "0",
            }}
            className={
              concluirStep2 && moduloMapeamento.currentUserHasGestorPermission
                ? "mapeamentos_steps triangulo"
                : "mapeamentos_steps--hidden"
            }
          >
            {concluirStep2 &&
              moduloMapeamento.currentUserHasGestorPermission && (
                <>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleFilled
                        style={{ fontSize: "80px", color: "#32E6BE" }}
                      />
                      <br />
                      <h2>Etapa concluída</h2>
                      <br />
                      <Button
                        onClick={() =>
                          moduloMapeamento.setActiveMapeamentoTab("2")
                        }
                        type="text"
                        style={{ color: "#008C76" }}
                      >
                        Adicionar processos
                        <RightOutlined />
                      </Button>
                    </div>
                    <br />
                    <div className="d-flex j-b">
                      <Button
                        icon={<LeftOutlined />}
                        type="text"
                        onClick={() => setStep(1)}
                        style={{ color: "#A3A3A3" }}
                      >
                        Etapa anterior
                      </Button>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
      <Modal
        open={open}
        title=""
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            style={{ backgroundColor: "#32E6BE", color: "#333333" }}
            type="primary"
            onClick={() => setOpen(false)}
          >
            Concluir
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <CheckCircleFilled style={{ fontSize: "80px", color: "#32E6BE" }} />
          <br />
          <h2 style={{ textAlign: "center" }}>
            Parabéns!
            <br /> Você concluiu esta etapa.
          </h2>
          <br />
          <Button
            onClick={() => navigate("/dashboardinicial")}
            type="text"
            style={{ color: "#008C76" }}
          >
            Ir para Visão Geral <RightOutlined />
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Mapeamento;
