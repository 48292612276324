import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  Form,
  Input,
  Upload,
  Select,
  Tooltip,
  Modal,
  Table,
  Col,
  Row,
  Collapse,
} from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";

import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import SaveBtn from "../../../../components/SaveBtn/SaveBtn";
import TaskList from "../../../../components/Tarefas/TaskList/TaskList";

const { Dragger } = Upload;

const EditTemplateTask = () => {
  const { moduloTask } = React.useContext(GlobalContext) as GlobalContextType;

  const [currentConjunto, setCurrentConjunto] = React.useState<any>(null);
  const params = useParams();
  const [form] = Form.useForm();

  // useEffect(() => {
  //   tasks &&
  //     params.templateid &&
  //     setCurrentQuestionarios(
  //       templates?.filter((q: any) => q._id === params.templateid)[0]
  //     );
  // }, [params.templateid, templates]);

  useEffect(() => {
    if (currentConjunto) {
      form.setFieldsValue({
        name: currentConjunto.nome,
      });
    }
  }, [currentConjunto]);

  const onFinish = (values: any) => { };

  const [conjuntoName, setConjuntoName] = React.useState<string>("");

  return (
    <div className="editClientes">
      <BackHeader
        title={
          params.templateid || params.qid ? "Editar modelo" : "Novo modelo"
        }
        // route={params.qid ? "/clientes/editar/" + params.id : "/templates"}
        prev
      />
      {moduloTask.tasks[0] && (
        <>
          <Form
            form={form}
            layout="vertical"
            name="edit-clientes-form"
            onFinish={onFinish}
          // style={{ paddingBottom: "2rem" }}
          >
            <>
              <Form.Item name={"nome_conjunto"} label="Nome" required>
                <Input
                  style={{ width: "50%" }}
                  defaultValue={
                    conjuntoName ||
                    moduloTask.tasks?.find(
                      (t: any) => t._id.toString() === params.templateid
                    )?.nome_conjunto
                  }
                  value={
                    conjuntoName ||
                    moduloTask.tasks?.find(
                      (t: any) => t._id.toString() === params.templateid
                    )?.nome_conjunto
                  }
                  placeholder="Nome do questionário"
                  required
                  onChange={(v: any) => setConjuntoName(v.target.value)}
                />
              </Form.Item>
            </>
          </Form>
          <TaskList
            editTemplate
            nomeConjunto={conjuntoName}
            taskId={params.templateid}
            horizontal
          />
        </>
      )}
    </div>
  );
};

export default EditTemplateTask;
