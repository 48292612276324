import React, { useState, useEffect } from "react";
import {
  List,
  Card,
  Row,
  Col,
  Input,
  Button,
  Tooltip,
  Tag,
  Empty,
  Select,
  Table,
} from "antd";
import { EyeFilled, RightOutlined } from "@ant-design/icons";
import { GlobalContextType } from "../../../@types/global";
import { GlobalContext } from "../../../context/globalContext";
import "./styles.css";
import { TasksListTypes } from "../../../@types/taskslist";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import Task from "../Task/Task";
import moment from "moment";

const TaskList: React.FC<TasksListTypes> = ({
  editTemplate,
  taskId,
  nomeConjunto,
  noHeader,
  horizontal,
  csa,
  csaquest,
  mode,
}) => {
  const { moduloTask, windowWidth, usuarios } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [currentTarefa, setCurrentTarefa] = useState<any>({});

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };

  // Ordenar as tarefas com base na data de prazo
  const sortedTasks = moduloTask.tasks.sort(
    (a: any, b: any) => b.prazo_conclusao - a.prazo_conclusao
  );

  // Número de itens por página
  const itemsPerPage = 3;

  // Filtrar as tarefas que têm modelo_info
  const tasksWithModelInfo = taskId
    ? sortedTasks.filter((task) => task._id.toString() === taskId)
    : editTemplate
      ? []
      : sortedTasks.filter(
        (task) => task.modelo_info && Object.keys(task.modelo_info).length > 0
      );

  const handleAddTask = async (currentTasks?: any) => {
    const verify = tasksWithModelInfo[0] ? true : false;

    let newTask;
    if (currentTasks) {
      newTask = {
        ...currentTarefa,
        dependencia: currentTasks[currentTasks.length - 1]._id,
      };
    }

    moduloTask
      .postNewTask(
        tasksWithModelInfo[0]
          ? newTask
          : { ...currentTarefa, nome_conjunto: nomeConjunto }
      )
      .then((res) => {
        !verify && navigate("/modelostarefas/" + res._id);
        setCurrentTarefa({});
      });
  };

  const [taskStatus, setTaskStatus] = useState(
    moduloTask.selectedTask?.status || "Pendente"
  );

  const handleStatusChange = (value: any) => {
    setTaskStatus(value);
  };

  const handleUpdateStatus = () => { };

  return (
    <div
      className="tasks"
      style={
        horizontal
          ? {}
          : {
            display: "flex",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }
      }
    >
      {
        tasksWithModelInfo[0] ? (
          tasksWithModelInfo
            .filter((taskPai: any) => {
              return csa
                ? taskPai.modelo_info.nome.includes("EXECUÇÃO EM")
                : csaquest
                  ? taskPai.modelo_info.nome.includes(
                    "Questionário Tecnologia da Informação - CSA"
                  )
                  : true;
            })
            .map((taskWithModeloInfo: any) => {
              // Função para encontrar todas as tarefas relacionadas a uma tarefa específica
              const findRelatedTasks = (
                taskId: any,
                allTasks: any,
                visited = new Set()
              ) => {
                //   if (!visited.has(taskId)) {
                //     return [];
                //   }

                visited.add(taskId);

                const relatedTasks = allTasks.filter(
                  (task: any) => task.dependencia === taskId
                );

                const recursiveRelatedTasks = relatedTasks.flatMap(
                  (depTask: any) =>
                    findRelatedTasks(depTask._id, allTasks, visited)
                );

                return [...relatedTasks, ...recursiveRelatedTasks];
              };

              // Filtrar as tarefas relacionadas à tarefa específica
              const relatedTasks = findRelatedTasks(
                taskWithModeloInfo._id,
                moduloTask.tasks.filter(
                  (t: any) => t.tipo_dependencia !== "checklist"
                ),
                new Set([taskWithModeloInfo._id])
              );

              return mode === "list" ? (
                <Table
                  style={{ width: "100%" }}
                  size="small"
                  dataSource={[...relatedTasks, taskWithModeloInfo].sort(
                    (a: any, b: any) =>
                      new Date(a.created_at).getTime() -
                      new Date(b.created_at).getTime()
                  )}
                  pagination={false}
                  columns={[
                    {
                      title: "Descrição",
                      dataIndex: "title",
                      key: "title",
                      render: (title: string) => title.replace("Processo:", ""),
                    },
                    {
                      title: "Responsável",
                      dataIndex: "owner_id",
                      key: "owner_id",
                      render: (user_id: string) =>
                        usuarios?.find((u: any) => u._id === user_id).name,
                    },

                    {
                      title: "Data de criação",
                      dataIndex: "created_at",
                      key: "created_at",
                      render: (date: any) =>
                        moment(new Date(date)).format("DD/MM/YYYY HH:mm"),
                    },
                    {
                      title: "Status",
                      dataIndex: "status",
                      key: "status",
                      render: (status: string) => (
                        <Tag
                          color={
                            status === "Em andamento"
                              ? "blue"
                              : status === "Concluída"
                                ? "green"
                                : "grey"
                          }
                        >
                          {status}
                        </Tag>
                      ),
                    },
                  ]}
                />
              ) : (
                <div
                  style={
                    horizontal
                      ? {}
                      : {
                        width: "350px",
                        marginRight: "2rem",
                      }
                  }
                >
                  {!editTemplate && !noHeader && (
                    <h2>
                      {taskWithModeloInfo.tipo === "questionarios"
                        ? taskWithModeloInfo.modelo_info.nome.toUpperCase()
                        : taskWithModeloInfo.modelo_info._id}
                    </h2>
                  )}
                  <List
                    style={{ width: "100%", height: "80vh", overflowY: "auto" }}
                    // grid={
                    //   horizontal
                    //     ? {
                    //         gutter: 0,
                    //         column: itemsPerPage,
                    //       }
                    //     : { column: 1 }
                    // }
                    grid={
                      horizontal
                        ? {
                          xs: 1,
                          sm: 2,
                          md: 2,
                          lg: 2,
                          xl: 3,
                          xxl: 5,
                        }
                        : { column: 1 }
                    }
                    dataSource={
                      editTemplate
                        ? [...relatedTasks, taskWithModeloInfo, {}].sort(
                          (a: any, b: any) =>
                            new Date(a.created_at).getTime() -
                            new Date(b.created_at).getTime()
                        )
                        : [...relatedTasks, taskWithModeloInfo].sort(
                          (a: any, b: any) =>
                            new Date(a.created_at).getTime() -
                            new Date(b.created_at).getTime()
                        )
                    }
                    pagination={
                      horizontal
                        ? {
                          pageSize: windowWidth < 800 ? 1 : itemsPerPage,
                        }
                        : false
                    }
                    renderItem={(task, index) => (
                      <>
                        <List.Item key={task._id || "novo"}>
                          {task._id ? (
                            <Row align="middle" justify="center">
                              <Col span={21}>
                                <Card
                                  className="task_card"
                                  bordered={false}
                                  title={
                                    <Tooltip title={task.title}>
                                      <span
                                        style={{
                                          textDecorationLine:
                                            task.status === "Concluída"
                                              ? "line-through"
                                              : "none",
                                        }}
                                      >
                                        {/* {editTemplate ? "" : "Capitulo "} */}
                                        {task.title}
                                      </span>
                                    </Tooltip>
                                  }
                                  actions={[
                                    <div
                                      style={{
                                        color: "#1677ff",
                                        fontSize: "1rem",
                                      }}
                                      onClick={() => {
                                        moduloTask.setSelectedTask(task);
                                        handleShowModal();
                                      }}
                                    >
                                      <EyeFilled key="edit" /> Ver tarefa
                                    </div>,
                                  ]}
                                >
                                  <div>
                                    {/* {!editTemplate && (
                            <Avatar
                              style={{
                                backgroundColor:
                                  task.status === "Concluída"
                                    ? "#87d068"
                                    : "#108ee9",
                              }}
                              onClick={() =>
                                console.log(taskWithModeloInfo.modelo_info)
                              }
                            >
                              {task.status.charAt(0)}
                            </Avatar>
                          )} */}
                                    <div>
                                      <>
                                        {/* <h3
                                      style={{
                                        marginTop: "0.5rem",
                                        marginBottom: "0.5rem",
                                        fontSize: "1.17rem",
                                      }}
                                    >
                                      {task.title}
                                    </h3> */}
                                        {editTemplate && (
                                          <TextArea
                                            rows={6}
                                            defaultValue={task.description}
                                            value={task.description}
                                            style={{
                                              // marginTop: "2rem",
                                              marginBottom: "2rem",
                                            }}
                                          />
                                        )}
                                        {task.prazo_conclusao !== 0 && (
                                          <p>
                                            Prazo:{" "}
                                            <b>
                                              {task.prazo_conclusao} dia
                                              {task.prazo_conclusao
                                                ? task.prazo_conclusao > 1
                                                  ? "s"
                                                  : ""
                                                : "s"}
                                            </b>
                                          </p>
                                        )}
                                      </>
                                      {/* )} */}

                                      {!editTemplate && (
                                        <p>
                                          Status:{" "}
                                          <Tag
                                            style={{
                                              marginTop: "0.5rem",
                                              marginBottom: "0.5rem",
                                            }}
                                            color={
                                              task.status === "Em andamento"
                                                ? "blue"
                                                : task.status === "Concluída"
                                                  ? "green"
                                                  : "grey"
                                            }
                                          >
                                            {task.status}
                                          </Tag>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Card>
                              </Col>
                              {editTemplate && (
                                <Col
                                  span={3}
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  <RightOutlined className="task__arrow-right" />
                                </Col>
                              )}
                              {/* Adicione uma seta à direita, exceto para a última tarefa */}
                              {/* {!editTemplate &&
                            task._id.toString() !==
                              [...relatedTasks, taskWithModeloInfo]
                                .sort(
                                  (a: any, b: any) =>
                                    new Date(a.created_at).getTime() -
                                    new Date(b.created_at).getTime()
                                )
                                [
                                  [...relatedTasks, taskWithModeloInfo].length -
                                    1
                                ]._id.toString() && (
                              <Col
                                span={3}
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                <RightOutlined className="task__arrow-right" />
                              </Col>
                            )} */}
                            </Row>
                          ) : (
                            <Row align="middle" justify="center">
                              <Col span={21}>
                                <Card className="task_card" bordered={false}>
                                  <div>
                                    <Input
                                      placeholder="Título"
                                      value={currentTarefa.title}
                                      onChange={(v: any) =>
                                        setCurrentTarefa((prev: any) => ({
                                          ...prev,
                                          title: v.target.value,
                                        }))
                                      }
                                      style={{
                                        fontWeight: 200,
                                        fontSize: "1.17rem",
                                      }}
                                    />
                                    <TextArea
                                      rows={6}
                                      value={currentTarefa.description}
                                      placeholder="Descrição..."
                                      style={{
                                        marginTop: "2rem",
                                        marginBottom: "2rem",
                                      }}
                                      onChange={(v: any) =>
                                        setCurrentTarefa((prev: any) => ({
                                          ...prev,
                                          description: v.target.value,
                                        }))
                                      }
                                    />
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Input
                                        placeholder="Prazo"
                                        type="number"
                                        value={currentTarefa.prazo_conclusao}
                                        onChange={(v: any) =>
                                          setCurrentTarefa((prev: any) => ({
                                            ...prev,
                                            prazo_conclusao: v.target.value,
                                          }))
                                        }
                                      />
                                      <p
                                        style={{
                                          paddingLeft: "0.5rem",
                                          fontSize: "1rem",
                                        }}
                                      >
                                        dia
                                        {currentTarefa.prazo_conclusao
                                          ? currentTarefa.prazo_conclusao > 1
                                            ? "s"
                                            : ""
                                          : "s"}
                                      </p>
                                    </div>
                                  </div>
                                </Card>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    marginTop: "1rem",
                                  }}
                                >
                                  {currentTarefa.title && (
                                    <Button
                                      type="primary"
                                      size="small"
                                      onClick={() =>
                                        handleAddTask(
                                          [
                                            ...relatedTasks,
                                            taskWithModeloInfo,
                                          ].sort(
                                            (a: any, b: any) =>
                                              new Date(a.created_at).getTime() -
                                              new Date(b.created_at).getTime()
                                          )
                                        )
                                      }
                                    >
                                      Adicionar
                                    </Button>
                                  )}
                                </div>
                              </Col>

                              <Col
                                span={3}
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {/* <RightOutlined className="task__arrow-right" /> */}
                              </Col>
                            </Row>
                          )}
                        </List.Item>
                      </>
                    )}
                  />
                </div>
              );
            })
        ) : editTemplate ? (
          <List
            style={{ width: "100%" }}
            grid={{ gutter: 0, column: itemsPerPage }}
            dataSource={[1]}
            renderItem={(item, index) => (
              <List.Item key={item}>
                <Row align="middle" justify="center">
                  <Col span={21}>
                    <Card>
                      <div>
                        <Input
                          placeholder="Título"
                          value={currentTarefa.title}
                          onChange={(v: any) =>
                            setCurrentTarefa((prev: any) => ({
                              ...prev,
                              title: v.target.value,
                            }))
                          }
                          style={{
                            fontWeight: 200,
                            fontSize: "1.17rem",
                          }}
                        />

                        <TextArea
                          rows={6}
                          value={currentTarefa.description}
                          placeholder="Descrição..."
                          style={{
                            marginTop: "2rem",
                            marginBottom: "2rem",
                          }}
                          onChange={(v: any) =>
                            setCurrentTarefa((prev: any) => ({
                              ...prev,
                              description: v.target.value,
                            }))
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Input
                            placeholder="Prazo"
                            type="number"
                            value={currentTarefa.prazo_conclusao}
                            onChange={(v: any) =>
                              setCurrentTarefa((prev: any) => ({
                                ...prev,
                                prazo_conclusao: v.target.value,
                              }))
                            }
                          />
                          <p style={{ paddingLeft: "0.5rem", fontSize: "1rem" }}>
                            dia
                            {currentTarefa.prazo_conclusao
                              ? currentTarefa.prazo_conclusao > 1
                                ? "s"
                                : ""
                              : "s"}
                          </p>
                        </div>
                      </div>
                    </Card>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "1rem",
                      }}
                    >
                      {currentTarefa.title && nomeConjunto !== "" && (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleAddTask()}
                        >
                          Adicionar
                        </Button>
                      )}
                    </div>
                  </Col>

                  <Col
                    span={3}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {/* <RightOutlined className="task__arrow-right" /> */}
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        ) : (
          <Empty
            description={"Nenhuma tarefa encontrada"}
            style={{ margin: "3rem", paddingBottom: "3rem" }}
          />
        )}

      <Task setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default TaskList;
