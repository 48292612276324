import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import "./styles.css";
import { Upload, Input, Radio, Form, Button } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import SaveBtn from "../../../../components/SaveBtn/SaveBtn";
import { CheckOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const FormRespostas = () => {
  const { moduloProcessos, moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const [currentQuest, setCurrentQuest] = React.useState<any>({});

  const params = useParams();

  useEffect(() => {
    if (params.id && params.noauthuserid) {
      moduloMapeamento.getQuestionario(params.id, params.noauthuserid);
    }
  }, []);

  useEffect(() => {
    setCurrentQuest(
      moduloMapeamento.questionarios.filter((q: any) => q._id === params.id)[0]
    );
    console.log(
      moduloMapeamento.questionarios.filter((q: any) => q._id === params.id)[0]
    );
  }, [moduloMapeamento.questionarios]);

  useEffect(() => {
    if (params.noauthuserid) {
      moduloProcessos.getRespostas(params.noauthuserid);
    }
  }, []);

  const onFinish = (values: any) => {
    let respostas: any = [];
    Object.keys(values).forEach((key) => {
      respostas.push({ value: values[key], questionario_perguntas_id: key });
    });
    moduloProcessos.saveRespostas({
      resp: respostas,
      user_id: params.noauthuserid,
    });
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <div className="formRespostas">
      {/* <div className="indice" onClick={() => console.log("")}>
        <h3 className="indice__title">Índice</h3>
        {currentQuest &&
          currentQuest !== null &&
          currentQuest.areas &&
          areas
            ?.filter((area: any) => currentQuest.areas.includes(area._id))
            .map((area: any) => {
              return (
                <div className="indice__area">
                  <p
                    onClick={() =>
                      console.log(
                        currentQuest.capitulos.filter((cap: any) =>
                          cap.areas_id.includes(area._id)
                        )
                      )
                    }
                  >
                    {area.description}
                  </p>
                  {currentQuest.capitulos
                    .filter((cap: any) => cap.areas_id.includes(area._id))
                    .map((cap: any) => {
                      return (
                        <p style={{ marginLeft: "1rem" }}>{cap.description}</p>
                      );
                    })}
                </div>
              );
            })}
      </div> */}
      <div className="formRespostas__form">
        <h3 className="formRespostas__form--title">{currentQuest?.nome}</h3>
        <Form {...layout} name="" onFinish={onFinish}>
          {moduloProcessos.areas
            ?.filter((area: any) => currentQuest?.areas.includes(area._id))
            .map((area: any) => {
              return (
                <div className="indice__area">
                  {currentQuest.capitulos
                    .filter((cap: any) => cap.areas_id.includes(area._id))
                    .map((cap: any) => {
                      return (
                        <div className="indice__perguntas">
                          <p style={{ marginLeft: "3rem", marginTop: "1rem" }}>
                            {cap.description}
                          </p>
                          {currentQuest.perguntasRelacao
                            .filter(
                              (p: any) =>
                                p.capitulo_id.toString() === cap._id.toString()
                            )
                            .map((p: any) => (
                              <div>
                                <p
                                  style={{
                                    marginLeft: "6rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  {
                                    moduloProcessos.perguntas?.filter(
                                      (perg: any) => perg._id === p.pergunta_id
                                    )[0].description
                                  }
                                </p>
                                <Form.Item name={p._id}>
                                  {/* // p._id = id questionarioperguntas */}
                                  <Radio.Group
                                    buttonStyle="solid"
                                    disabled={
                                      moduloProcessos.respostas.filter(
                                        (r: any) =>
                                          r.questionario_perguntas_id === p._id
                                      )[0]
                                        ? true
                                        : false
                                    }
                                    defaultValue={
                                      moduloProcessos.respostas.filter(
                                        (r: any) =>
                                          r.questionario_perguntas_id === p._id
                                      )[0]
                                        ? moduloProcessos.respostas.filter(
                                            (r: any) =>
                                              r.questionario_perguntas_id ===
                                              p._id
                                          )[0].value
                                        : undefined
                                    }
                                    style={{
                                      marginLeft: "6rem",
                                      marginTop: "0.5rem",
                                    }}
                                  >
                                    <Radio.Button value="s">Sim</Radio.Button>
                                    <Radio.Button value="n">Não</Radio.Button>
                                  </Radio.Group>
                                </Form.Item>
                                {moduloProcessos.respostas.filter(
                                  (r: any) =>
                                    r.questionario_perguntas_id === p._id
                                )[0] && (
                                  <CheckOutlined
                                    className="perguntaCheck"
                                    rev={undefined}
                                    style={{
                                      marginLeft: "1rem",
                                      color: "#00940c",
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                      );
                    })}
                </div>
              );
            })}
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 3 }}>
            <SaveBtn />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FormRespostas;
