import React, { useEffect, useState } from "react";
import { Table, Switch, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import { GlobalContext } from "../../context/globalContext";
import { GlobalContextType } from "../../@types/global";

interface Modulo {
  _id: string;
  description: string;
}

interface ModulosLiberacaoProps {
  cliente_id: string | undefined;
}

const ModulosLiberacao: React.FC<ModulosLiberacaoProps> = ({ cliente_id }) => {
  const {
    modulos,
    getModulosPermissao,
    modulosPermissao,
    updatePermissaoModulo,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    cliente_id && getModulosPermissao(cliente_id);
  }, [cliente_id]);

  const handleSwitchChange = async (id: string, checked: boolean) => {
    // Atualizar o valor de inativo em modulosPermissao Model
    cliente_id && setLoading(true);
    cliente_id &&
      updatePermissaoModulo(cliente_id, id, checked).finally(() =>
        setLoading(false)
      );
  };

  const columns: ColumnType<Modulo>[] = [
    {
      title: "Nome",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Acesso",
      dataIndex: "_id",
      key: "_id",
      render: (id: string) => (
        <Tooltip
          title={
            id === "65a141c56b48108dc9ce355e"
              ? "Ao liberar o módulo, será adicionado uma tarefa para cadastro de Parceiros / Fornecedores ao primeiro usuário SPONSOR cadastrado."
              : ""
          }
        >
          {/* 65a141c56b48108dc9ce355e = Ação com Parceiros / Fornecedores */}
          <Switch
            checked={modulosPermissao.some(
              (permissao: any) =>
                permissao.modulo_id === id &&
                !permissao.inativo &&
                permissao.cliente_id === cliente_id
            )}
            loading={loading}
            onChange={(checked: boolean) => handleSwitchChange(id, checked)}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      {modulos && modulosPermissao && (
        <Table dataSource={modulos} columns={columns} />
      )}
    </>
  );
};

export default ModulosLiberacao;
