import { useParams } from "react-router-dom";
import React from "react";
import "./styles.css";
import Analise from "./components/analise";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import moment from "moment";

const AnaliseMultipla = () => {
  const params = useParams();
  const { moduloMapeamento } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  return (
    <div className="editAnalise" translate="no">
      <BackHeader
        title={`Análise ${
          moduloMapeamento.questionarios?.filter(
            (q: any) => q._id === params.qid
          )[0]?.nome
        } - ${moment(
          new Date(
            moduloMapeamento.questionarios
              ?.filter((q: any) => q._id === params.qid)[0]
              ?.versoes?.filter(
                (v: any) => v._id.toString() === params.vid
              )[0]?.created_at
          )
        ).format("DD/MM/YYYY HH:mm")}`}
        prev
      />
      <Analise questId={params.qid} versaoId={params.vid} />
    </div>
  );
};

export default AnaliseMultipla;
