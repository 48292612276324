import * as React from "react";
import { ButtomTypes } from "../../@types/buttom";
import "./styles.css";
import { CloseOutlined, FileFilled, FileOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { AnexosTypes } from "../../@types/anexos";

const Anexos: React.FC<AnexosTypes> = ({ currentAnexos, onChange, setCurrentAnexos, showUpload = true, textSecondary, text }) => {
  return (
    <div className={`anexos`}>
      {currentAnexos?.map((anexo: any) => {
        return (
          <div className="anexos__containner">
            {anexo.url ? (
              <Tooltip title="Fazer download">
                <div
                  className="anexos__containner--info"
                  onClick={() => {
                    window.open(anexo.url, "_blank");
                  }}
                >
                  <FileOutlined className="anexos__containner--info__icon" />
                  <p>{decodeURIComponent(escape(anexo.name))}</p>
                </div>
              </Tooltip>
            ) : (
              <>
                <div className="anexos__containner--info" style={{ cursor: "default" }}>
                  <FileOutlined className="anexos__containner--info__icon" />
                  <p>{anexo.name}</p>
                </div>
                <Tooltip title="Excluir">
                  <div
                    className="anexos__containner--action"
                    onClick={() => setCurrentAnexos((prev: any) => prev.filter((file: any) => file !== anexo))}
                  >
                    <CloseOutlined />
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        );
      })}
      {showUpload && (
        <>
          {currentAnexos && <br />}
          <Dragger onChange={onChange} showUploadList={false}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">{text ? text : "Adicionar anexo"}</p>
            <p className="ant-upload-hint">{textSecondary ? textSecondary : "Clique ou arraste um ou mais arquivos para anexar"}</p>
          </Dragger>
        </>
      )}
    </div>
  );
};
export default Anexos;
