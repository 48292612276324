import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./styles.css";
import { Button } from "antd";
import background from "../../../../../assets/images/background_pattern_rosa.png";
import { ArrowRightOutlined } from "@ant-design/icons";

const Introducao1 = () => {
  const navigate = useNavigate();
  const params = useParams();
  return (
    <div className="intro-1" style={{ backgroundImage: `url(${background})` }}>
      <h1>LGPD na prática: Conformidade com fornecedores</h1>

      <Button
        icon={<ArrowRightOutlined />}
        className="btn-next"
        onClick={() =>
          navigate(
            `/formulario/2/${params.id}/${params.fornecedorid}/${params.questionarioid}`
          )
        }
      >
        Avançar
      </Button>
    </div>
  );
};

export default Introducao1;
