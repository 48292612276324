import React from "react";
import "./styles.css";
import { Alert, Table } from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";

import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";

import ModulosLiberacao from "../../../components/ModulosLiberacao/ModulosLiberacao";

const Liberacao = () => {
  const { usuarios, currentCliente } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  return (
    <div className="editClientes">
      <BackHeader title={"Liberação de Módulos"} />
      {currentCliente ? (
        <>
          <h3>Dados da empresa</h3>
          <hr />
          <Table
            size="small"
            style={{ marginTop: "1rem", marginBottom: "2rem" }}
            dataSource={[
              {
                razao_social: currentCliente.razao_social,
                representante: currentCliente.representante,
                email: currentCliente.email,
                telefone: currentCliente.telefone,
              },
            ]}
            pagination={false}
            columns={[
              {
                title: "Razão social",
                dataIndex: "razao_social",
                key: "razao_social",
              },
              {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
              },
              {
                title: "Telefone",
                dataIndex: "telefone",
                key: "telefone",
              },
              {
                title: "Responsável legal",
                dataIndex: "representante",
                key: "representante",
              },
            ]}
          />
          <h3>Módulos</h3>
          <hr />
          {usuarios
            ?.filter((u: any) => u.cliente_id === currentCliente._id)
            ?.find((usr: any) =>
              usr.grupos?.find((g: any) => g.name === "sponsor")
            ) ? (
            <ModulosLiberacao cliente_id={currentCliente._id} />
          ) : (
            <Alert showIcon type="warning" message="Nenhum sponsor cadastro" />
          )}
        </>
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default Liberacao;
