import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { Table, Input, Button, Switch, Tag } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import { useAuth0 } from "@auth0/auth0-react";
import TaskList from "../../../../components/Tarefas/TaskList/TaskList";

const { Search } = Input;

const PlanoAcaoQuestCSA = () => {
  const { clientes, pageSize, windowHeight, windowWidth } = React.useContext(
    GlobalContext,
  ) as GlobalContextType;

  const [search, setSearch] = useState<string>("");

  const navigate = useNavigate();

  const onChange = () => {};

  const columns = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      render: (tipoId: any) =>
        tipoId === "64e64283902fd5e820397ea8" && (
          <Tag color="#fa9623">Assinante</Tag>
        ),
    },
    // {
    //   title: "Contato",
    //   dataIndex: "age",
    //   render: (text: any) => "contato@outlook.com",
    // },
    // {
    //   title: "Ativo",
    //   dataIndex: "ativo",
    //   render: (v: any) => <Switch checked={true} onChange={onChange} />,
    // },
  ];

  const columnsMobile = [
    {
      title: "Nome",
      dataIndex: "razao_social",
    },

    // {
    //   title: "Contato",
    //   dataIndex: "age",
    //   render: (text: any) => "contato@outlook.com",
    // },
    // {
    //   title: "Ativo",
    //   dataIndex: "ativo",
    //   render: (v: any) => <Switch checked={true} onChange={onChange} />,
    // },
  ];

  const onSearch = (value: string) => setSearch(value);

  return (
    <div className="csa">
      <BackHeader title="Questionário de T.I." />
      <TaskList csaquest />
    </div>
  );
};

export default PlanoAcaoQuestCSA;
