import React, { useState } from "react";
import "./styles.css";
import { Button, Col, Collapse, Form, Modal, Row, Select, Tooltip } from "antd";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import { ModalSelectQuestionarioTypes } from "../../../../../../@types/modalSelectQuestionario";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";

const ModalSelectQuestionario: React.FC<ModalSelectQuestionarioTypes> = ({
  showModal,
  handleShowModal,
  clientId,
}) => {
  const {
    moduloMapeamento,
    moduloProcessos,
    createQuestionarioDeTemplate,
    usuarios,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);
  const [selectedSponsor, setSelectedSponsor] = useState<any>(null);

  const onChange = (value: string) => {
    setSelectedTemplate(
      moduloMapeamento.templates?.filter(
        (template: any) => template._id === value
      )
    );
  };

  const onSearch = (value: string) => {};

  const onFinish = () => {
    createQuestionarioDeTemplate(
      selectedTemplate[0]._id,
      clientId,
      selectedTemplate[0].nome,
      selectedSponsor
    );
    setSelectedSponsor(null);
    handleShowModal();
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal
        title={"Aplicar mapeamento"}
        open={showModal}
        footer={[]}
        onCancel={handleShowModal}
        width={700}
      >
        <Form
          // {...layoutForn}
          layout="vertical"
          form={form}
          name="adiciona_fornecedor"
          onFinish={onFinish}
          autoComplete="off"
          style={{
            marginTop: "2rem",
            maxHeight: "70vh",
            overflowY: "auto",
            paddingRight: "0.5rem",
          }}
        >
          <Form.Item label="Selecione um mapeamento" name="template" required>
            <Select
              showSearch
              allowClear
              placeholder="Selecione um modelo de questionário"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              // @ts-ignore
              options={moduloMapeamento.templates
                .filter((template: any) => !template.cliente_id)
                .map((template: any) => {
                  return {
                    value: template._id,
                    label: (
                      <span className="template_select_label">
                        <p>{template.nome}</p>
                        <p className="template_select_label--date">
                          {moment(new Date(template.updated_at)).format(
                            "DD/MM/YYYY HH:mm"
                          )}
                        </p>
                      </span>
                    ),
                  };
                })}
            />
          </Form.Item>
          <Form.Item>
            {selectedTemplate && selectedTemplate[0] && (
              <>
                {selectedTemplate[0].nome === "Assessment Process" ? (
                  <>
                    <Select
                      onChange={(v) => setSelectedSponsor(v)}
                      placeholder="Selecione um sponsor encarregado"
                      options={usuarios
                        ?.filter((usr: any) =>
                          usr.grupos.find((g: any) => g.name === "sponsor")
                        )
                        ?.filter((usr: any) => usr.cliente_id === clientId)
                        ?.map((user: any) => {
                          return {
                            label: user.name,
                            value: user._id,
                          };
                        })}
                    />
                  </>
                ) : (
                  <>
                    <hr />

                    <Collapse
                      collapsible="icon"
                      items={selectedTemplate[0].areas?.map(
                        (area: any, index: number) => {
                          return {
                            key: index + "area",
                            label: moduloProcessos.areas?.filter(
                              (a: any) => a._id === area
                            )?.[0]?.description,
                            children: (
                              <Collapse
                                collapsible="icon"
                                items={selectedTemplate[0].capitulos
                                  .filter((c: any) => {
                                    if (c.areas_id && c.areas_id[0]) {
                                      return c.areas_id.some(
                                        (areaId: any) => areaId === area
                                      );
                                    } else {
                                      return c.tags.some(
                                        (areaId: any) => areaId === area
                                      );
                                    }
                                  })
                                  .filter((c: any) => !c.inativo)
                                  .map((cap: any, index: number) => {
                                    return {
                                      key: index + "cap",
                                      label: cap.description,
                                      children: (
                                        <>
                                          {selectedTemplate[0].perguntasRelacao
                                            .filter(
                                              (p: any) =>
                                                p.capitulo_id.toString() ===
                                                  cap._id.toString() &&
                                                !p.inativo
                                            )
                                            .filter((p: any) =>
                                              moduloProcessos.perguntas?.find(
                                                (perg: any) =>
                                                  perg._id === p.pergunta_id &&
                                                  !perg.inativo
                                              )
                                            )
                                            .map(
                                              (
                                                p: any,
                                                indexPergunta: number
                                              ) => (
                                                <p className="d-flex j-b a-c perguntas-opt">
                                                  {`${indexPergunta + 1}. ${
                                                    moduloProcessos.perguntas?.filter(
                                                      (perg: any) =>
                                                        perg._id.toString() ===
                                                        p.pergunta_id.toString()
                                                    )[0]?.description
                                                  }`}
                                                </p>
                                              )
                                            )}
                                        </>
                                      ),
                                    };
                                  })}
                              />
                            ),
                          };
                        }
                      )}
                    />
                  </>
                )}
              </>
            )}
          </Form.Item>
          <Form.Item>
            <Row align="middle">
              <Col span={4}>
                <Button
                  type="primary"
                  className="btn-success"
                  // loading={loading}
                  disabled={
                    selectedTemplate && selectedTemplate[0]
                      ? selectedTemplate[0].nome === "Assessment Process"
                        ? selectedSponsor
                          ? false
                          : true
                        : false
                      : true
                  }
                  htmlType="submit"
                >
                  Adicionar{" "}
                </Button>
              </Col>
              <Col span={18}>
                {selectedTemplate && selectedTemplate[0] && (
                  <Row style={{ opacity: 0.4 }}>
                    <Col span={1}>
                      <InfoCircleOutlined />
                    </Col>
                    <Col span={23}>
                      <p>O mapeamento poderá ser editado posteriormente.</p>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalSelectQuestionario;
