import React from "react";
import { Modal, Button } from "antd";

interface CriarReuniaoExtraModalProps {
  isVisible: boolean;
  onClose: () => void;
  onCreate: () => void;
  loading: boolean; // Adicionei o loading aqui para ser passado como prop
}

const CriarReuniaoExtraModal: React.FC<CriarReuniaoExtraModalProps> = ({
  isVisible,
  onClose,
  onCreate,
  loading,
}) => {
  return (
    <Modal
      title="Criar Reunião Extraordinária"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onCreate}
          loading={loading}
        >
          Criar Reunião Agora
        </Button>,
      ]}
    >
      <p>Deseja iniciar a reunião extraordinária agora?</p>
    </Modal>
  );
};

export default CriarReuniaoExtraModal;
