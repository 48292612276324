import React, { useEffect, useState } from "react";

import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Tooltip,
} from "antd";
import "./styles.css";
import {
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  PlusOutlined,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Questionario } from "../../context/mapeamentoContext";

interface NewUserProps {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  type?: "grupo";
  currentMapeamento?: Questionario | undefined | null;
}

const { Search } = Input;

const NewUser: React.FC<NewUserProps> = ({
  showModal,
  setShowModal,
  type,
  currentMapeamento,
}) => {
  const {
    postUserNoPermissions,
    clientes,
    gruposFunc,
    currentUser,
    userInfo,
    funcoes,
    patchUser,
    setCurrentUser,
    postPrimeiroAcesso,
    postUser,
    usuarios,
    currentCliente,
    moduloProcessos,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();
  const params = useParams();

  const [grupo, setGrupo] = useState<any[string] | null>([]);
  const [permissoes, setPermissoes] = useState<any[]>([]);
  const [searchPermissao, setSearchPermissao] = useState<string>("");
  const [selectedPermissao, setSelectedPermissao] = useState(null);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const [adicionarPerm, setAdicionarPerm] = useState<boolean>(false);

  useEffect(() => {
    if (currentCliente?._id) {
      setSelectedCliente(currentCliente._id);
      form.setFieldsValue({ cliente_id: currentCliente._id });
    }
  }, [currentCliente]);

  useEffect(() => {
    // Atualiza os valores do formulário quando o currentUser mudar
    if (currentUser) {
      form.setFieldsValue({
        name: currentUser.name,
        email: currentUser.email,
        auth_user_id: currentUser.auth_user_id,
        cliente_id: currentUser.cliente_id,
        grupos: currentUser.grupos.map((g: any) => g._id),
        inativo: currentUser.inativo ? false : true,
      });

      setGrupo(currentUser.grupos.map((g: any) => g._id));
      setPermissoes(
        currentUser?.funcoesDetalhadas?.map((permissao: any) => {
          return {
            key: permissao._id,
            name: permissao.funcao_id.name,
            description: permissao.funcao_id.description,
            reference_model_name: permissao.funcao_id.reference_model_name,
            reference_model_id: permissao.reference_model_id,
            funcao_id: permissao.funcao_id._id,
          };
        })
      );
    } else {
      // Reseta os valores do formulário caso não haja currentUser
      form.resetFields();
    }
  }, [currentUser, form, showModal]);

  useEffect(() => {
    if (currentUser) {
      setCurrentUser(usuarios.find((u: any) => u._id === currentUser._id));
    }
  }, [usuarios]);

  const onFinish = (values: any) => {
    let valores = values;

    if (!valores.cliente_id) {
      valores.cliente_id = params.id || currentCliente._id;
    }

    if (type === "grupo") {
      if (currentUser) {
        patchUser({
          ...values,

          grupos: grupo,
          user_id: currentUser._id,
          permissoes: permissoes,
        });
      } else {
        postUser({
          ...values,
          grupos: grupo,
          permissoes: permissoes,
        });
      }
    } else {
      postUserNoPermissions({
        ...values,
        cliente_id: params.id || currentCliente._id,
      }).then((r) => {
        setShowModal(false);
      });
    }

    setGrupo(null);
    setPermissoes([]);
    setSearchPermissao("");
    setShowModal(false);
    setAdicionarPerm(false);
    setSelectedPermissao(null);
    setCurrentUser(null);
  };

  const onSearchPermissao = (value: string) => setSearchPermissao(value);

  const handleRemovePermission = (keyToRemove: string) => {
    setPermissoes(
      permissoes.filter((permissao) => permissao.key !== keyToRemove)
    );
  };
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (name: string) => <Tag>{name}</Tag>,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      render: (desc: string) => (
        <p style={{ opacity: 0.4, fontSize: "0.8rem" }}>{desc}</p>
      ),
    },
    {
      title: "Local",
      dataIndex: "key",
      key: "key",
      render: (key: string, record: any) => {
        const currentPerm = permissoes?.find((p: any) => p.key === key);
        switch (record.reference_model_name) {
          case "clientes":
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: "0.8rem", marginRight: "0.25rem" }}>
                  Cliente:
                </p>
                <Select
                  allowClear
                  size="small"
                  style={{ width: "14rem" }}
                  placeholder="Selecione um cliente"
                  options={clientes?.map((c: any) => {
                    return {
                      value: c._id,
                      label: clientes?.find((cli: any) => cli._id === c._id)
                        ?.razao_social,
                    };
                  })}
                  // mode="multiple"
                  maxTagTextLength={8}
                  maxTagCount={1}
                  defaultValue={[currentPerm?.reference_model_id]}
                  filterOption={(input: any, option: any) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(value) => {
                    const updatedPermissoes = permissoes.map((permissao) => {
                      if (permissao.key === currentPerm.key) {
                        return { ...permissao, reference_model_id: value };
                      }
                      return permissao;
                    });

                    setPermissoes(updatedPermissoes);
                  }}
                />
              </div>
            );
          case "areas":
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ fontSize: "0.8rem", marginRight: "0.25rem" }}>
                  Área:
                </p>

                {currentMapeamento?.areas?.[0] ? (
                  <Select
                    allowClear
                    size="small"
                    style={{ width: "14rem" }}
                    placeholder="Selecione uma área"
                    options={currentMapeamento?.areas
                      ?.map((a) =>
                        moduloProcessos?.areas?.find((area) => area._id === a)
                      )
                      ?.map((a: any) => {
                        return {
                          value: a._id,
                          label: a.description,
                        };
                      })}
                    // mode="multiple"
                    maxTagTextLength={8}
                    maxTagCount={1}
                    defaultValue={[currentPerm?.reference_model_id]}
                    filterOption={(input: any, option: any) =>
                      option?.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(value) => {
                      const updatedPermissoes = permissoes.map((permissao) => {
                        if (permissao.key === currentPerm.key) {
                          return { ...permissao, reference_model_id: value };
                        }
                        return permissao;
                      });

                      setPermissoes(updatedPermissoes);
                    }}
                  />
                ) : (
                  <Alert type="warning" showIcon message="Cliente sem áreas" />
                )}
              </div>
            );
          default:
            return (
              <p style={{ fontSize: "0.8rem" }}>{record.reference_model_id}</p>
            );
        }
      },
    },
    {
      title: "Ação",
      dataIndex: "address",
      key: "address",
      render: (_: any, record: any) => (
        <Popconfirm
          title="Tem certeza que deseja excluir esta permissão?"
          onConfirm={() => handleRemovePermission(record.key)}
          onCancel={() => {}}
          okText="Sim"
          cancelText="Não"
        >
          <Button type="link" icon={<DeleteOutlined />}>
            Excluir
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const columnsEnvio = [
    {
      title: "Assunto",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Data de envio",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) =>
        moment(new Date(date)).format("DD/MM/YYYY HH:mm"),
    },

    {
      title: "Ação",
      dataIndex: "envio",
      key: "envio",
      render: (_: any, record: any) => (
        <Popconfirm
          title={
            <div style={{ width: "700px" }}>
              <b style={{ marginRight: "0.25rem" }}>Para:</b>
              <span>{record?.email}</span>
              <br />
              <b style={{ marginRight: "0.25rem" }}>Assunto:</b>
              <span>{record?.subject}</span>
              <br />
              <b>Corpo:</b>
              <div
                dangerouslySetInnerHTML={{
                  __html: record?.body,
                }}
              />
            </div>
          }
          okText="Fechar"
          showCancel={false}
        >
          <Button type="link" icon={<EyeOutlined />}>
            Conteúdo
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={currentUser ? "Editar usuário" : "Cadastrar novo usuário"}
        open={showModal}
        footer={[]}
        width={type === "grupo" ? 1200 : 500}
        onCancel={() => {
          setShowModal(false);
          setGrupo(null);
          setPermissoes([]);
          setSearchPermissao("");
          setAdicionarPerm(false);
          setSelectedPermissao(null);
          setCurrentUser(null);
        }}
      >
        <Form
          layout="vertical"
          name="edit-clientes-form"
          form={form}
          onFinish={onFinish}
        >
          <Row gutter={[24, 0]} style={{ marginTop: "2rem" }}>
            {type === "grupo" && (
              <Col span={24}>
                <Form.Item name={"inativo"}>
                  <Switch
                    checkedChildren="Ativo"
                    unCheckedChildren="Inativo"
                    defaultChecked={currentUser?.inativo ? false : true}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={type === "grupo" ? 12 : 24}>
              <Form.Item
                name={"name"}
                rules={[{ required: true }]}
                label="Nome"
              >
                <Input defaultValue={currentUser?.name || ""} />
              </Form.Item>
            </Col>
            <Col span={type === "grupo" ? 12 : 24}>
              <Form.Item
                name={"email"}
                label="E-mail"
                rules={[{ type: "email", required: true }]}
              >
                <Input defaultValue={currentUser?.email || ""} />
              </Form.Item>
            </Col>
            {type === "grupo" && (
              <>
                <Col span={12}>
                  <Form.Item name={"auth_user_id"} label="Auth0 ID">
                    <Input
                      type={
                        userInfo?.grupos?.find(
                          (gp: any) => gp.name === "seusdados"
                        )
                          ? "text"
                          : "password"
                      }
                      disabled={
                        userInfo?.grupos?.find(
                          (gp: any) => gp.name === "seusdados"
                        )
                          ? false
                          : true
                      }
                      defaultValue={currentUser?.auth_user_id || ""}
                    />
                  </Form.Item>
                  {/* <Form.Item
                  name={"noauth0"}
                  valuePropName="checked"
                  style={{ marginTop: "-1.5rem" }}
                >
                  <Checkbox>Não registrar em Auth0</Checkbox>
                </Form.Item> */}
                </Col>
                <Col span={12}>
                  <Form.Item name={"cliente_id"} label="Cliente">
                    <Select
                      disabled={
                        userInfo?.grupos?.find(
                          (gp: any) => gp.name === "seusdados"
                        )
                          ? false
                          : true
                      }
                      allowClear
                      placeholder="Selecione um cliente"
                      value={selectedCliente}
                      defaultValue={selectedCliente}
                      options={clientes?.map((c: any) => {
                        return {
                          value: c._id,
                          label: clientes?.find((cli: any) => cli._id === c._id)
                            ?.razao_social,
                        };
                      })}
                      onChange={(v) => {
                        setSelectedCliente(v);
                      }}
                      filterOption={(input: any, option: any) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {type === "grupo" && (
              <>
                <Col span={24}>
                  <Form.Item label="Grupos" name={"grupos"}>
                    <div style={{ display: "flex" }}>
                      <Select
                        allowClear
                        placeholder="Selecione um grupo"
                        onChange={(v: any) => {
                          // ao selecionar um grupo, verificar cada um
                          v.forEach((g: any) => {
                            // se tiver um item novo no array comparando com o state de grupo anterior
                            if (
                              !grupo ||
                              !grupo[0] ||
                              grupo?.find((item: string) => item !== g)
                            ) {
                              const grupoCompleto = gruposFunc?.find(
                                (grupoFunc: any) => grupoFunc._id === g
                              );

                              // adicionar as funcoes do grupo ao array permissoes
                              if (grupoCompleto?.funcoes?.length > 0) {
                                let permissoesToAdd: any[] = [];

                                grupoCompleto?.funcoes?.forEach(
                                  (funcao: any) => {
                                    const permissaoToAdd = funcoes.find(
                                      (f: any) => f._id === funcao
                                    );

                                    if (permissaoToAdd) {
                                      const newPermissao = {
                                        key: permissaoToAdd._id,
                                        name: permissaoToAdd.name,
                                        description: permissaoToAdd.description,
                                        reference_model_name:
                                          permissaoToAdd.reference_model_name,
                                        reference_model_id:
                                          permissaoToAdd.reference_model_name ===
                                          "clientes"
                                            ? selectedCliente ||
                                              currentUser?.cliente_id
                                            : null,
                                        funcao_id: permissaoToAdd._id,
                                      };

                                      permissoesToAdd.push(newPermissao);
                                    }
                                  }
                                );

                                setPermissoes([
                                  ...permissoes,
                                  ...permissoesToAdd,
                                ]);
                              }
                            }
                          });

                          // atualizar lista de grupos
                          setGrupo(v);
                        }}
                        mode="multiple"
                        options={gruposFunc?.map((g: any) => {
                          return {
                            value: g._id,
                            label: g.name.toUpperCase(),
                          };
                        })}
                        value={grupo}
                      />
                      {/* <Button
                        style={{ marginLeft: "1rem" }}
                        icon={<UsergroupAddOutlined />}
                      >
                        Criar novo
                      </Button> */}
                    </div>
                  </Form.Item>
                </Col>
                <Col span="24">
                  <Form.Item name={"funcoes"} label="Permissões">
                    <div className="clientes__actions">
                      {!adicionarPerm ? (
                        <Search
                          placeholder="Buscar permissão"
                          className="clientes__actions--search"
                          allowClear
                          enterButton="Buscar"
                          style={{ marginRight: "1rem" }}
                          size="small"
                          onSearch={onSearchPermissao}
                          onPressEnter={(e) => {
                            // Impede a submissão do formulário quando Enter é pressionado
                            e.preventDefault();

                            onSearchPermissao(e.currentTarget.value);
                          }}
                        />
                      ) : (
                        <Space.Compact
                          style={{ width: "100%", marginRight: "1rem" }}
                        >
                          <Select
                            allowClear
                            size="small"
                            placeholder="Selecione uma permissão para adicionar"
                            onChange={(value) => setSelectedPermissao(value)}
                            options={funcoes?.map((f: any) => {
                              return {
                                value: f._id,
                                label: (
                                  <Tooltip
                                    title={
                                      <span>
                                        {f.name}
                                        <br />
                                        <br />
                                        {f.description}
                                      </span>
                                    }
                                  >
                                    <div style={{ display: "flex" }}>
                                      <Tag>{f.name}</Tag>
                                      <p
                                        style={{
                                          opacity: 0.4,
                                          fontSize: "0.8rem",
                                        }}
                                      >
                                        {f.description}
                                      </p>
                                    </div>
                                  </Tooltip>
                                ),
                              };
                            })}
                          />
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                              if (selectedPermissao) {
                                const permissaoToAdd = funcoes.find(
                                  (f: any) => f._id === selectedPermissao
                                );
                                if (permissaoToAdd) {
                                  const newPermissao = {
                                    key: permissaoToAdd._id,
                                    name: permissaoToAdd.name,
                                    description: permissaoToAdd.description,
                                    reference_model_name:
                                      permissaoToAdd.reference_model_name,
                                    reference_model_id:
                                      permissaoToAdd.reference_model_name ===
                                      "clientes"
                                        ? currentUser?.cliente_id
                                        : null,
                                    funcao_id: permissaoToAdd._id,
                                  };
                                  setPermissoes([...permissoes, newPermissao]);
                                  setSelectedPermissao(null);
                                }
                              }
                            }}
                          >
                            Adicionar
                          </Button>
                        </Space.Compact>
                      )}

                      <Button
                        className="clientes__actions--new"
                        type={adicionarPerm ? "default" : "primary"}
                        size="small"
                        icon={
                          adicionarPerm ? <CloseOutlined /> : <PlusOutlined />
                        }
                        onClick={() => {
                          if (adicionarPerm) {
                            setAdicionarPerm(false);
                            setSelectedPermissao(null);
                          } else {
                            setAdicionarPerm(true);
                          }
                        }}
                      >
                        {adicionarPerm ? "Cancelar" : "Adicionar"}
                      </Button>
                    </div>
                    <Table
                      size="small"
                      dataSource={permissoes.filter(
                        (p: any) =>
                          p.name.toLowerCase().includes(searchPermissao) ||
                          p.description.toLowerCase().includes(searchPermissao)
                      )}
                      columns={columns}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
            {currentUser && (
              <Col span={24}>
                <Form.Item label="Envios">
                  <Button
                    // type="link"
                    icon={<SendOutlined />}
                    onClick={() => postPrimeiroAcesso(currentUser)}
                  >
                    Enviar primeiro acesso
                  </Button>
                  <Table
                    style={{ marginTop: "1rem" }}
                    size="small"
                    dataSource={currentUser?.envios}
                    columns={columnsEnvio}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Form.Item
            style={{
              marginTop: "2rem",
              marginBottom: "-0.5rem",
              marginRight: "-0.5rem",
            }}
          >
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="default"
                form="form-pergunta"
                style={{ marginRight: "1rem" }}
                onClick={() => setShowModal(false)}
              >
                Cancelar
              </Button>
              <Button
                icon={<SaveOutlined />}
                type="primary"
                className="btn-success"
                htmlType="submit"
              >
                Salvar
              </Button>
            </span>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewUser;
