import React, { useState } from "react";
import html2canvas from "html2canvas";
import { Button, Input, Dropdown, Menu, Tooltip } from "antd";
import {
  CameraOutlined,
  SendOutlined,
  DownOutlined,
  DeleteOutlined,
  MessageOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import "./styles.css";
import { GlobalContext } from "../../context/globalContext";
import { GlobalContextType } from "../../@types/global";

const { TextArea } = Input;

const FeedbackDropdown: React.FC = () => {
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [feedbackText, setFeedbackText] = useState<string>("");
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const { userInfo, saveFeedback } = React.useContext(
    GlobalContext
  ) as GlobalContextType;

  const handleCapture = (): void => {
    html2canvas(document.body).then((canvas: HTMLCanvasElement) => {
      setScreenshot(canvas.toDataURL("image/png"));
    });
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = (): void => {
    saveFeedback(userInfo._id, feedbackText, screenshot);

    setFeedbackText("");
    setScreenshot(null);
    setMenuVisible(false); // Close the dropdown after submit
  };

  const handleVisibleChange = (flag: boolean) => {
    setMenuVisible(flag);
  };

  const handleDeleteScreenshot = (): void => {
    setScreenshot(null);
  };

  const handlePaste = (
    event: React.ClipboardEvent<HTMLTextAreaElement>
  ): void => {
    const items = event.clipboardData?.items;
    if (items) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf("image") !== -1) {
          const blob = items[i].getAsFile();
          if (blob) {
            const reader = new FileReader();
            reader.onload = () => {
              if (reader.result) {
                setScreenshot(reader.result.toString());
              }
            };
            reader.readAsDataURL(blob);
          }
          break;
        }
      }
    }
  };

  const menu = (
    <Menu
      onClick={(e) => e.domEvent.stopPropagation()}
      style={{
        width: 360,
        flexDirection: "column",
        display: "flex",
        padding: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3>Compartilhe seu feedback!</h3>
        <CloseOutlined
          style={{ fontSize: "1.2rem", cursor: "pointer" }}
          onClick={() => {
            setFeedbackText("");
            setScreenshot(null);
            setMenuVisible(false);
          }}
        />
      </div>
      <br />

      <TextArea
        value={feedbackText}
        onChange={handleTextChange}
        style={{ height: "220px" }}
        onPaste={handlePaste}
      />

      {screenshot ? (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
          }}
        >
          <img
            src={screenshot}
            alt="Screenshot"
            style={{
              height: "38px",
              width: "39px",
              borderRadius: "8px",
              border: "1px solid #d9d9d9",
              cursor: "pointer",
            }}
            onClick={handleDeleteScreenshot} // Excluir a imagem ao clicar
          />
          <div
            style={{
              position: "absolute",
              left: "1rem",
              marginTop: "0rem",
              fontSize: "1.2rem",
              background: "rgb(0,0,0,0.4)",
              height: "40px",
              width: "40px",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={handleDeleteScreenshot} // Excluir a imagem ao clicar
          >
            <DeleteOutlined style={{ fontSize: "1.2rem", color: "#fff" }} />
          </div>
          <Button
            size="large"
            style={{ width: "100%", marginLeft: "1rem" }}
            icon={<SendOutlined />}
            onClick={handleSubmit}
            type="primary"
          >
            Enviar
          </Button>
        </div>
      ) : (
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
          }}
        >
          <Tooltip title="Adicionar captura de tela">
            <Button
              size="large"
              style={{ width: "50px" }}
              icon={<CameraOutlined />}
              onClick={handleCapture}
              type="default"
            />
          </Tooltip>

          <Button
            size="large"
            style={{ width: "100%", marginLeft: "1rem" }}
            icon={<SendOutlined />}
            onClick={handleSubmit}
            type="primary"
          >
            Enviar
          </Button>
        </div>
      )}
    </Menu>
  );

  return (
    <div className="feedback">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        onVisibleChange={handleVisibleChange}
        visible={menuVisible}
      >
        <Button type="text" style={{ color: "#000" }}>
          <MessageOutlined /> Feedback
        </Button>
      </Dropdown>
    </div>
  );
};

export default FeedbackDropdown;
