import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Empty,
  List,
  Modal,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
import {
  EyeFilled,
  EyeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Cell, Legend, Pie, PieChart } from "recharts";

import MeuDpo from "../../../assets/images/meudpo.png";
import { TaskType } from "../../../context/taskContext";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";
import BackHeader from "../../../components/BackHeader/BackHeader";
import CriarTarefa from "../../../components/Tarefas/CriarTarefa/CriarTarefa";
import Task from "../../../components/Tarefas/Task/Task";

const DashboardInicial = () => {
  const {
    windowWidth,
    grauRiscosPergunta,
    moduloMapeamento,
    moduloProcessos,
    moduloTask,
    userInfo,
    accessToken,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const navigate = useNavigate();
  const location = useLocation();

  const [showModalQuestionario, setShowModalQuestionario] =
    React.useState(false);
  React.useEffect(() => {
    const desiredRoute = localStorage.getItem("@desiredroute");
    // console.log(location.pathname);
    if (desiredRoute && desiredRoute.includes("responder")) {
      setShowModalQuestionario(true);
      // if (
      //   location.pathname === desiredRoute &&
      //   location.pathname !== "/dashboardinicial" &&
      //   location.pathname !== "/"
      // ) {
      //   console.log("ja esta na rota, removendo...");
      //   localStorage.removeItem("@desiredroute");
      // }
    }
  }, [location]);

  const [itemsPerPage, setItemsPerPage] = useState(5);

  useEffect(() => {
    if (windowWidth < 576) {
      setItemsPerPage(1);
    } else if (windowWidth < 768) {
      setItemsPerPage(1);
    } else if (windowWidth < 992) {
      setItemsPerPage(2);
    } else if (windowWidth < 1245) {
      setItemsPerPage(2);
    } else if (windowWidth < 1600) {
      setItemsPerPage(3);
    } else {
      setItemsPerPage(5);
    }
  }, [windowWidth]);

  const [graus, setGraus] = useState<any>([]);
  const [dataAll, setDataAll] = useState<any>([]);
  const [conforme, setConforme] = useState<any>([]);
  const [showCriarTarefa, setShowCriarTarefa] = React.useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };
  const handleShowCriarTarefa = () => {
    showCriarTarefa ? setShowCriarTarefa(false) : setShowCriarTarefa(true);
  };

  const columns = [
    {
      title: "Assunto",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let iconColor = "";
        let text = "";
        if (!status || status === "Pendente") {
          iconColor = "#b5b5b5";
        } else if (status === "Concluída") {
          iconColor = "green";
        } else if (status === "Em andamento") {
          iconColor = "#fcba03";
        }

        if (!status || status === "Pendente") {
          text = "Pendente";
        } else if (status === "Em andamento") {
          text = "Em andamento";
        } else if (status === "Concluída") {
          text = "Concluído";
        }

        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Ação",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, record: any) => (
        <Tooltip title="Ver chamado">
          <Button
            icon={<EyeOutlined style={{ fontSize: "1.1rem" }} />}
            onClick={() => {
              moduloTask.setSelectedTask(record);
              handleShowModal();
            }}
          >
            Ver chamado
          </Button>
        </Tooltip>
      ),
    },
  ];
  const data = [{ value: 100, fill: "#ffffff" }]; // Um único dado para o gráfico vazio

  useEffect(() => {
    if (moduloMapeamento.questionarios[0]) {
      const versoes = moduloMapeamento.questionarios
        .map((q: any) => {
          return q.versoes[q.versoes.length - 1];
        })
        .filter((v: any) => v);

      moduloProcessos.getMultipleRespostasAuth(versoes);
    }
  }, [moduloMapeamento.questionarios]);

  useEffect(() => {
    const respostas = moduloProcessos.respostasAuth?.map((resposta: any) => {
      return resposta.value.map(
        (value: any) =>
          grauRiscosPergunta.filter(
            (g: any) =>
              g.pergunta_id.toString() === resposta.pergunta?._id.toString() &&
              g.value.includes(value)
          )[0]?.grau
      );
    });

    setGraus([].concat.apply([], respostas).filter((item: any) => item));
  }, [moduloProcessos.respostasAuth]);

  useEffect(() => {
    if (graus[0]) {
      let porcentagemConforme: any =
        (graus.filter((r: any) => r === "sim").length /
          (graus.filter((r: any) => r === "sim").length +
            graus.filter((r: any) => r === "nao").length)) *
        100;

      setConforme(porcentagemConforme);

      porcentagemConforme = porcentagemConforme.toFixed(2);

      setDataAll([
        {
          value: Number(porcentagemConforme),
          fill: "#33CCFF",
          label: "Conformidades",
        },
        {
          value: 100 - Number(porcentagemConforme),
          fill: "#5F29CC",
          label: "N/ Conformidades",
        },
      ]);
    }
  }, [graus]);

  return (
    <div className="dashboard-inicial">
      <BackHeader title="Visão geral" />
      <div style={{ marginBottom: "2rem" }}>
        <h3 style={{ marginBottom: "1rem" }}>O que devo fazer?</h3>
        {/* <TaskList horizontal noHeader />
         */}
        <List
          style={{ width: "100%" }}
          // grid={
          //   horizontal
          //     ? {
          //         gutter: 0,
          //         column: itemsPerPage,
          //       }
          //     : { column: 1 }
          // }
          grid={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 5,
          }}
          pagination={{
            pageSize: itemsPerPage,
          }}
          dataSource={moduloTask.tasks
            .sort(
              (a: any, b: any) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .filter(
              (t: any) =>
                // !moduloTask.tasks.find((item) => item._id === t.dependencia) &&
                !t.title.includes("Pergunta: ")
            )
            .filter((t: any) => t.assignment_id === userInfo._id)}
          renderItem={(task: TaskType, index) => (
            <>
              <List.Item key={task._id || "novo"}>
                <Row align="middle" justify="center">
                  <Col span={21}>
                    <Card
                      className="task_card"
                      bordered={false}
                      title={
                        <Tooltip title={task.title}>
                          <span
                            style={{
                              textDecorationLine:
                                task.status === "Concluída"
                                  ? "line-through"
                                  : "none",
                            }}
                          >
                            {/* Capitulo {task.title} */}
                            {task.title}
                          </span>
                        </Tooltip>
                      }
                      actions={[
                        <div
                          style={{
                            color: "#1677ff",
                            fontSize: "1rem",
                          }}
                          onClick={() => {
                            moduloTask.setSelectedTask(task);
                            handleShowModal();
                          }}
                        >
                          <EyeFilled key="edit" /> Ver tarefa
                        </div>,
                      ]}
                    >
                      <div>
                        <div>
                          <>
                            {task.prazo_conclusao !== 0 &&
                              task.prazo_conclusao && (
                                <p>
                                  Prazo:{" "}
                                  <b>
                                    {task.prazo_conclusao} dia
                                    {task.prazo_conclusao
                                      ? task.prazo_conclusao > 1
                                        ? "s"
                                        : ""
                                      : "s"}
                                  </b>
                                </p>
                              )}
                          </>

                          <p>
                            Status:{" "}
                            <Tag
                              style={{
                                marginTop: "0.5rem",
                                marginBottom: "0.5rem",
                              }}
                              color={
                                task.status === "Em andamento"
                                  ? "blue"
                                  : task.status === "Concluída"
                                  ? "green"
                                  : "grey"
                              }
                            >
                              {task.status}
                            </Tag>
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>{" "}
              </List.Item>
            </>
          )}
        />
      </div>
      <Row gutter={[48, 8]}>
        <Col
          span={windowWidth > 800 ? 12 : 24}
          style={windowWidth > 800 ? {} : { marginBottom: "5rem" }}
        >
          <h3 style={{ margin: "0rem !important" }}>Métricas</h3>
          {moduloMapeamento.questionarios
            ?.sort(
              (a: any, b: any) =>
                new Date(b.created_at).getTime() -
                new Date(a.created_at).getTime()
            )
            .map((quest: any) => {
              return { value: quest._id, label: quest.nome };
            })[0] && (
            <Select
              defaultValue={
                moduloMapeamento.questionarios
                  ?.sort(
                    (a: any, b: any) =>
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                  )
                  .map((quest: any) => {
                    return { value: quest._id, label: quest.nome };
                  })[0]
              }
              style={{ marginTop: "1rem", zIndex: 999 }}
              options={moduloMapeamento.questionarios
                ?.sort(
                  (a: any, b: any) =>
                    new Date(b.created_at).getTime() -
                    new Date(a.created_at).getTime()
                )
                .map((quest: any) => {
                  return { value: quest._id, label: quest.nome };
                })}
            />
          )}
          <div
            style={
              windowWidth > 800
                ? {
                    display: "flex",
                    alignItems: "center",
                    marginTop: "-2rem",
                  }
                : {
                    marginTop: "-2rem",
                  }
            }
          >
            {windowWidth > 800 && (
              <PieChart
                width={400}
                height={400}
                style={{ marginLeft: "-5rem" }}
              >
                {dataAll[0] ? (
                  <>
                    <Pie
                      data={dataAll}
                      dataKey="value"
                      cx={200}
                      cy={200}
                      innerRadius={55}
                      outerRadius={90}
                      strokeWidth={0}
                      // label
                    >
                      <Cell
                        // fill={windowWidth > 800 ? "#fff" : "#00000010"}
                        style={
                          windowWidth > 800
                            ? {
                                filter: `drop-shadow(10px 10px 25px #00000040`,
                              }
                            : {}
                        }
                        stroke="0"
                      />{" "}
                      {/* Cor do gráfico (pode ser ajustada conforme necessário) */}
                    </Pie>
                    <Legend
                      layout="vertical"
                      align="right"
                      verticalAlign="middle"
                      wrapperStyle={{ right: -100, bottom: 110 }}
                      payload={dataAll.map((entry: any) => ({
                        value: entry.label,
                        type: "circle",
                        color: entry.fill,
                      }))}
                    />
                  </>
                ) : (
                  <Pie
                    data={data}
                    dataKey="value"
                    cx={200}
                    cy={200}
                    innerRadius={55}
                    outerRadius={90}
                    fill="#ffffff" // Cor de fundo do gráfico
                    stroke="#ffffff" // Cor da borda do gráfico
                    strokeWidth={2}
                  >
                    <Cell
                      fill={windowWidth > 800 ? "#fff" : "#00000010"}
                      style={
                        windowWidth > 800
                          ? {
                              filter: `drop-shadow(10px 10px 25px #00000040`,
                            }
                          : {}
                      }
                      stroke="0"
                    />{" "}
                    {/* Cor do gráfico (pode ser ajustada conforme necessário) */}
                  </Pie>
                )}
              </PieChart>
            )}

            {dataAll[0] && conforme ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "-2.7rem",
                  marginTop: "-5rem",
                }}
              >
                <p
                  style={{
                    // position: "relative",
                    // top: 110 + "px",
                    // marginTop: "-29px",
                    // left: "78px",
                    color:
                      conforme > 33 && conforme < 66
                        ? "#4C72E3"
                        : conforme >= 66
                        ? "#33CCFF"
                        : "#5F29CC",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  RISCO {conforme >= 66 && "BAIXO"}
                  {conforme > 33 && conforme < 66 && "MÉDIO"}
                  {conforme <= 33 && "ALTO"}
                </p>
                <p
                  style={{
                    fontSize: "1rem",
                    marginTop: "0.5rem",
                    color:
                      conforme > 33 && conforme < 66
                        ? "#4C72E3"
                        : conforme >= 66
                        ? "#33CCFF"
                        : "#5F29CC",
                  }}
                >
                  {conforme.toFixed(2)} pontos
                </p>
              </div>
            ) : (
              <h2
                style={{
                  opacity: 0.3,
                  marginLeft: windowWidth > 800 ? "-2rem" : 0,
                  textAlign: "center",
                }}
              >
                Aguardando
                <br />
                análise
              </h2>
            )}
          </div>
        </Col>
        <Col span={windowWidth > 800 ? 12 : 24}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <h3 style={{}}>
              Chamados{" "}
              <img
                src={MeuDpo}
                alt="Meu DPO"
                style={{
                  height: "1.17rem",
                  marginBottom: "-0.275rem",
                  marginLeft: "0.1rem",
                }}
              />
            </h3>
            <Button
              onClick={handleShowCriarTarefa}
              icon={<FileAddOutlined />}
              type="primary"
            >
              Criar chamado
            </Button>
          </div>
          <Table
            dataSource={moduloTask.tasks?.filter(
              (task: any) => task.tipo === "chamado"
            )}
            columns={columns}
            locale={{
              emptyText: (
                <Empty
                  style={{ marginTop: "2rem" }}
                  description={<span>Nenhum chamado encontrado</span>}
                >
                  <Button
                    onClick={handleShowCriarTarefa}
                    icon={<FileAddOutlined />}
                  >
                    Criar agora
                  </Button>
                </Empty>
              ),
            }}
          />
        </Col>
      </Row>

      <CriarTarefa
        showModal={showCriarTarefa}
        handleShowModal={handleShowCriarTarefa}
        tipo="chamado"
      />

      <Task setShowModal={setShowModal} showModal={showModal} />

      <Modal
        title={""}
        open={showModalQuestionario}
        footer={null}
        width={"500px"}
        onCancel={() => setShowModalQuestionario(false)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <br />

          <FileDoneOutlined style={{ fontSize: "80px", color: "#32E6BE" }} />
          <br />
          <h2>Questionário disponível!</h2>
          <br />
          <br />

          <Button
            onClick={() => {
              const desiredRoute = localStorage.getItem("@desiredroute");
              if (desiredRoute) {
                setShowModalQuestionario(false);
                navigate(desiredRoute);
                localStorage.removeItem("@desiredroute");
              }
            }}
            type="text"
            style={{ color: "#008C76" }}
          >
            Responder já <RightOutlined />
          </Button>
          <br />
          <br />
        </div>
      </Modal>
    </div>
  );
};

export default DashboardInicial;
