import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Alert,
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from "antd";
import { GlobalContextType } from "../../../../../../@types/global";
import { GlobalContext } from "../../../../../../context/globalContext";
import InputMask from "react-input-mask";
import Anexos from "../../../../../../components/Anexos/Anexos";
import { ModalEditFornecedoresTypes } from "../../../../../../@types/modalFornecedores";

interface FormValues {
  nome: string;
  cnpj: string;
  email: string;
  telefone: string;
  type: string;
  files: any;
}

interface Fornecedor {
  _id: string;
  cliente_id: string;
  nome: string;
  cnpj: string;
  telefone: string;
  email: string;
}

const ModalEditar: React.FC<ModalEditFornecedoresTypes> = ({
  editFornecedor,
  showFornecedores,
  showAddFornecedor,
  clientId,
}) => {
  const {
    postFornecedor,
    getFornecedores,
    getAnexo,
    userInfo,
    moduloMapeamento,
    moduloProcessos,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [mask, setMask] = useState("(99) 99999-9999");
  const [currentAnexos, setCurrentAnexos] = useState<any[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);

  const novoFornecedor = async (values: FormValues) => {
    setLoading(true);

    let valores: any = values;

    if (
      userInfo?.funcoesDetalhadas?.find(
        (fd: any) => fd.funcao_id?.name === "cadastrar-parceiro-fornecedor-area"
      )?.reference_model_id
    ) {
      valores.area_id = userInfo?.funcoesDetalhadas?.find(
        (fd: any) => fd.funcao_id?.name === "cadastrar-parceiro-fornecedor-area"
      )?.reference_model_id;
    }

    const formData = new FormData();
    if (currentAnexos) {
      for (const arquivo of currentAnexos) {
        formData.append("files", arquivo);
      }
    }

    if (editFornecedor) {
      const { nome, email, cnpj, type } = valores;

      if (!nome && !email && !cnpj && !type) {
        message.error("Preencha todos os campos!");
        setLoading(false);
      } else {
        postFornecedor(
          { ...valores, _id: editFornecedor._id },
          currentAnexos.filter((anexos: any) => !anexos.url),
          clientId
        )
          .then((r) => {
            form.resetFields();
            setCurrentAnexos([]);
            clientId && getFornecedores(clientId);
            showAddFornecedor();
          })
          .finally(() => {
            setLoading(false);
            form.resetFields();
          });
      }
    } else {
      const { nome, email, cnpj, type } = valores;

      if (!nome && !email && !cnpj && !type) {
        message.error("Preencha todos os campos!");
        setLoading(false);
      } else {
        postFornecedor(values, currentAnexos, clientId)
          .then((r) => {
            form.resetFields();
            setCurrentAnexos([]);
            clientId && getFornecedores(clientId);
            showAddFornecedor();
          })
          .finally(() => {
            setLoading(false);
            form.resetFields();
          });
      }
    }
  };

  useEffect(() => {
    if (editFornecedor && showFornecedores) {
      form.setFieldsValue({
        nome: editFornecedor.nome,
        cnpj: editFornecedor.cnpj,
        telefone: editFornecedor.telefone,
        email: editFornecedor.email,
        type: editFornecedor.type,
      });

      setLoading(true);
      getAnexo(editFornecedor._id)
        .then((res) => {
          setAnexos(res);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      form.resetFields();
      setCurrentAnexos([]);
    }
  }, [showFornecedores, editFornecedor]);

  return (
    <>
      <Modal
        title={editFornecedor ? "Editar fornecedor" : "Adicionar fornecedor"}
        open={showFornecedores}
        footer={[]}
        onCancel={showAddFornecedor}
        width={700}
      >
        <Form
          // {...layoutForn}
          layout="vertical"
          form={form}
          name="adiciona_fornecedor"
          onFinish={novoFornecedor}
          autoComplete="off"
          style={{
            marginTop: "2rem",
            maxHeight: "70vh",
            overflowY: "auto",
            paddingRight: "0.5rem",
          }}
        >
          <Form.Item label="Razão social" name="nome" required>
            <Input type="text" />
          </Form.Item>
          <Form.Item label="Nome do responsável" name="representante" required>
            <Input type="text" />
          </Form.Item>
          <Form.Item label="CNPJ" name="cnpj" required>
            <InputMask type="text" mask="99.999.999/9999-99">
              <Input />
            </InputMask>
          </Form.Item>
          <Form.Item label="E-mail" name="email" required>
            <Input type="email" />
          </Form.Item>
          <Form.Item label="Telefone" name="telefone">
            <InputMask
              type="text"
              mask={mask}
              onBlur={(e) => {
                if (e.target.value.replace("_", "").length === 14) {
                  setMask("(99) 9999-9999");
                }
              }}
              onFocus={(e) => {
                if (e.target.value.replace("_", "").length === 14) {
                  setMask("(99) 99999-9999");
                }
              }}
            >
              <Input />
            </InputMask>
          </Form.Item>
          <Form.Item label="Fornecedor" name="type" required>
            <Radio.Group
              options={[
                { label: "Novo", value: "novo" },
                { label: "Vigente", value: "vigente" },
              ]}
              optionType="button"
            />
          </Form.Item>
          {userInfo?.grupos?.find(
            (gp: any) =>
              gp.name === "seusdados" ||
              gp.name === "assinante" ||
              gp.name === "sponsor"
          ) && (
            <Form.Item label="Área" name="area_id">
              {moduloMapeamento.currentMapeamento?.areas?.[0] ? (
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Selecione uma área"
                  options={moduloMapeamento.currentMapeamento?.areas
                    ?.map((a) =>
                      moduloProcessos?.areas?.find((area) => area._id === a)
                    )
                    ?.map((a: any) => {
                      return {
                        value: a._id,
                        label: a.description,
                      };
                    })}
                  filterOption={(input: any, option: any) =>
                    option?.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  onChange={(value) => {
                    form.setFieldsValue({ area_id: value });
                  }}
                />
              ) : (
                <Alert
                  onClick={() =>
                    console.log(moduloMapeamento.currentMapeamento)
                  }
                  type="warning"
                  showIcon
                  message="Cliente sem áreas"
                />
              )}
            </Form.Item>
          )}

          {/* <Form.Item label="Contratos" name="files">
            {loading ? (
              <Spin tip="Carregando" size="small">
                <div className="content" />
              </Spin>
            ) : (
              <Anexos
                currentAnexos={[...currentAnexos, ...anexos]}
                onChange={(v) =>
                  setCurrentAnexos(v.fileList.map((f: any) => f.originFileObj))
                }
                setCurrentAnexos={setCurrentAnexos}
              />
            )}
          </Form.Item> */}
          <Form.Item>
            <Button
              type="primary"
              className="btn-success"
              loading={loading}
              htmlType="submit"
            >
              Salvar
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalEditar;
