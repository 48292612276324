import React, { useEffect } from "react";
import { Button, Steps } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import ReuniaoStatus from "./ReuniaoStatus";
import { Reuniao } from "./types";
import { EditOutlined, ExportOutlined } from "@ant-design/icons";

const { Step } = Steps;

interface ReuniaoPanelProps {
  reuniao: Reuniao;
  membroId: string;
  cppdId: string;
  setIsAtaModalVisible: (value: boolean) => void;
  setIsEditLinkModalVisible: (value: boolean) => void;
  setIsEnviarAtaModalVisible: (value: boolean) => void;
  setCurrentReuniaoId: (id: string | null) => void;
  setEditDateModalVisible: (value: boolean) => void;
  handleUpdateStatus: (id: string, status: string) => Promise<void>;
  setIsPautasModalVisible: (value: boolean) => void;
}

const statusMapping: { [key: string]: number } = {
  pendente: 1, // Convocação
  "em andamento": 2, // Reunião em andamento
  finalizado: 3, // Reunião realizada
  cancelada: 4, // Reunião cancelada
};

const ReuniaoPanel: React.FC<ReuniaoPanelProps> = ({
  reuniao,
  setIsAtaModalVisible,
  setIsEditLinkModalVisible,
  setIsEnviarAtaModalVisible,
  setCurrentReuniaoId,
  setEditDateModalVisible,
  handleUpdateStatus,
  setIsPautasModalVisible,
  membroId,
}) => {
  const navigate = useNavigate();

  // Mapeia o status atual da reunião para a etapa correspondente
  const currentStep = statusMapping[reuniao.status.toLowerCase()] || 0;

  // Lógica para verificar o status da reunião e atualizar conforme necessário
  useEffect(() => {
    const agora = dayjs(); // Obtém o horário atual
    const inicioReuniao = dayjs(reuniao.data_agendamento); // Data e hora de início da reunião

    // Verifica se a reunião não está finalizada e se não está "Em Andamento"
    if (
      reuniao.status.toLowerCase() !== "realizada" &&
      reuniao.status.toLowerCase() !== "em andamento"
    ) {
      // Se a data e hora atuais coincidirem com o início da reunião, marca como "Em Andamento"
      if (agora.isSame(inicioReuniao, "minute")) {
        handleUpdateStatus(reuniao._id, "Em Andamento");
      }
    }
  }, [reuniao, handleUpdateStatus]);

  const handleOpenAtaModal = () => {
    setCurrentReuniaoId(reuniao._id); // Atualiza o ID da reunião atual
    setTimeout(() => {
      setIsAtaModalVisible(true); // Abre o modal após um pequeno delay
    }, 0);
  };

  return (
    <div className="p-4 space-y-5 text-sm">
      <Steps
        current={currentStep >= 4 ? 3 : currentStep}
        size="small"
        className="mb-4"
      >
        <Step title="Envio da reunião" status="finish" />
        <Step
          title="Convocação"
          status={
            currentStep === 2 ? "finish" : currentStep > 2 ? "finish" : "wait"
          }
        />
        <Step
          title="Reunião em andamento"
          status={
            currentStep === 2 ? "process" : currentStep > 2 ? "finish" : "wait"
          }
        />
        <Step
          title="Reunião realizada"
          status={currentStep === 3 ? "finish" : "wait"}
        />
        <Step
          title="Reunião cancelada"
          status={
            reuniao.status.toLowerCase() === "cancelada" ? "finish" : "wait"
          }
        />
      </Steps>
      <p>
        Data de agendamento:{" "}
        {dayjs(reuniao.data_agendamento).format("HH:mm - DD/MM/YYYY")}
        <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setCurrentReuniaoId(reuniao._id);
            setEditDateModalVisible(true);
          }}
        >
          Editar
        </Button>
      </p>
      <ReuniaoStatus
        reuniaoId={reuniao._id}
        status={reuniao.status}
        onUpdateStatus={handleUpdateStatus}
      />
      <div className="flex items-center">
        <p>
          Link do agendamento:{" "}
          <Button
            type="link"
            href={`${reuniao.link}`}
            target="_blank"
            icon={<ExportOutlined />}
            rel="noopener noreferrer"
          >
            {reuniao.link!.length > 13
              ? `${reuniao.link?.substring(0, 28)}...`
              : reuniao.link}
          </Button>
        </p>
        {/* <Button
          type="link"
          icon={<EditOutlined />}
          onClick={() => {
            setCurrentReuniaoId(reuniao._id);
            setIsEditLinkModalVisible(true);
          }}
        >
          Editar
        </Button> */}
      </div>
      <div className="mt-4">
        <Button
          type="primary"
          className="mr-2"
          onClick={() => {
            setCurrentReuniaoId(reuniao._id);
            setIsPautasModalVisible(true);
          }}
        >
          Pautas
        </Button>
        <Button
          type="primary"
          className="mr-2"
          onClick={() => {
            navigate(`/cppd/${reuniao.cppd_id}/reunioes/dia-da-reuniao`, {
              state: { membroId, reuniao },
            });
          }}
        >
          Dia da reunião
        </Button>
        <Button type="primary" onClick={handleOpenAtaModal}>
          ATA
        </Button>
      </div>
    </div>
  );
};

export default ReuniaoPanel;
