import { useLocation, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Button,
  Form,
  Input,
  Modal,
  Col,
  Row,
  Tabs,
  Popconfirm,
  Table,
  Alert,
} from "antd";
import BackHeader from "../../../components/BackHeader/BackHeader";
import {
  ArrowLeftOutlined,
  EyeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  NodeIndexOutlined,
  SnippetsOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../context/globalContext";
import { GlobalContextType } from "../../../@types/global";

import ListQuestinarios from "../../Apps/Questionarios/ListQuestionarios/ListQuestionarios";
import InputMask from "react-input-mask";
import ModulosLiberacao from "../../../components/ModulosLiberacao/ModulosLiberacao";
import OWASP from "../../Apps/CSA/OWASP/OWASP";
import moment from "moment";
import Fornecedores from "../../Apps/AcaoFornecedores/Fornecedores/Fornecedores";
import Usuarios from "../Usuarios/Usuarios";
import Task from "../../../components/Tarefas/Task/Task";

const { TextArea } = Input;

interface CustomPopconfirmProps {
  record: {
    assunto_email: string;
    corpo_email: string;
  };
  date: string;
}

const EditClientes = () => {
  const {
    postUser,
    moduloMapeamento,
    clientes,
    clienteAreas,
    clientesSponsor,
    saveCliente,
    userInfo,
    moduloTask,
    windowWidth,
    usuarios,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [currentSponsor, setCurrentSponsor] = useState<any>(null);
  const [currentAreas, setCurrentAreas] = useState<any>([]);
  const [currentQuestionarios, setCurrentQuestionarios] = React.useState<any>(
    []
  );
  const [multipleSponsors, setMultipleSponsors] = useState<any>(null);
  const [categoriaId, setCategoriaId] = useState<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = () => {
    showModal ? setShowModal(false) : setShowModal(true);
  };
  const params = useParams();
  const location = useLocation();

  React.useEffect(() => {
    setCurrentAreas(
      clienteAreas?.filter((c: any) => c.cliente_id === params.id)[0]
        ? clienteAreas?.filter((c: any) => c.cliente_id === params.id)[0]
            .area_id
        : []
    );
    setCurrentQuestionarios(
      moduloMapeamento.questionarios
        ? moduloMapeamento.questionarios?.filter(
            (q: any) => q.cliente_id === params.id
          )[0]
          ? moduloMapeamento.questionarios?.filter(
              (q: any) => q.cliente_id === params.id
            )
          : []
        : []
    );
  }, [
    clienteAreas,
    params,
    moduloMapeamento.questionarios,
    clientes,
    location,
  ]);

  useEffect(() => {
    if (
      params.id &&
      !moduloMapeamento?.questionarios?.find(
        (q) => q.cliente_id === params.id
      ) &&
      clientes?.filter((c: any) => c._id === params.id)?.[0]?.razao_social
    ) {
      moduloMapeamento.getQuestionariosCliente(params.id);
    }
  }, [clientes]);

  React.useEffect(() => {
    if (
      clientesSponsor[0] &&
      clientesSponsor?.filter(
        (c: any) => c.reference_model_id?.toString() === params.id
      )[0]
    ) {
      setCurrentSponsor(
        clientesSponsor?.filter(
          (c: any) => c.reference_model_id?.toString() === params.id
        )[0].user_id
      );
      setMultipleSponsors(
        clientesSponsor?.filter(
          (c: any) => c.reference_model_id?.toString() === params.id
        )
      );
    }
  }, [clientesSponsor, params.id]);

  React.useEffect(() => {
    if (params.id && userInfo?.name) {
      moduloTask?.fetchClienteTasks(params.id);
    }
  }, [params.id, userInfo]);

  const onFinish = (values: any) => {
    saveCliente(
      {
        razao_social: values.cliente.razaoSocial,
        representante: values.cliente.representante,
        email: values.cliente.email,
        telefone: values.cliente.telefone,
        obs: values.cliente.obs,
        cnpj: values.cliente.cnpj,
      },
      params.id
    );
  };

  const onFinishSponsor = (values: any) => {
    postUser({
      name: values.nome,
      email: values.email,
      grupofuncoes: "64e4ef74ae11d9515cd31e9c",
    }).then((res) => {
      if (res.status === 200) {
        setShowCreateSponsor(false);
        setCurrentSponsor(res.data._id);
      }
    });
  };

  const layout = {
    labelCol: { span: 23 },
    wrapperCol: { span: 23 },
  };

  const CustomPopconfirm: React.FC<CustomPopconfirmProps> = ({
    record,
    date,
  }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleConfirm = () => {
      // Lógica a ser executada quando confirmado
      setIsVisible(false);
    };

    const handleCancel = () => {
      // Lógica a ser executada quando cancelado
      setIsVisible(false);
    };

    const handleClick = () => {
      setIsVisible(true);
    };

    return (
      <Popconfirm
        title={
          <div style={{ width: "700px" }}>
            <b>Assunto:</b>
            <p>{record?.assunto_email}</p>
            <b>Corpo:</b>
            <br />
            <div
              dangerouslySetInnerHTML={{
                __html: record?.corpo_email,
              }}
            />
          </div>
        }
        visible={isVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Fechar"
        showCancel={false}
      >
        <Button icon={<EyeOutlined />} onClick={handleClick}>
          {moment(new Date(date)).format("DD/MM/YYYY HH:mm")}
        </Button>
      </Popconfirm>
    );
  };

  const [showCreateSponsor, setShowCreateSponsor] =
    React.useState<boolean>(false);

  const FormCliente = () => {
    return (
      <Form layout="vertical" name="edit-clientes-form" onFinish={onFinish}>
        <Row gutter={[24, 0]}>
          <Col span={windowWidth > 800 ? 12 : 24}>
            <Form.Item name={["cliente", "razaoSocial"]} label="Razão Social">
              {params.id ? (
                clientes?.filter((c: any) => c._id === params.id)[0]
                  .razao_social && (
                  <Input
                    value={
                      clientes?.filter((c: any) => c._id === params.id)[0]
                        .razao_social
                    }
                    defaultValue={
                      clientes?.filter((c: any) => c._id === params.id)[0]
                        .razao_social
                    }
                  />
                )
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col span={windowWidth > 800 ? 6 : 24}>
            <Form.Item name={["cliente", "cnpj"]} label="CNPJ">
              {params.id ? (
                clientes?.filter((c: any) => c._id === params.id)[0].cnpj && (
                  <Input
                    value={
                      clientes?.filter((c: any) => c._id === params.id)[0].cnpj
                    }
                    defaultValue={
                      clientes?.filter((c: any) => c._id === params.id)[0].cnpj
                    }
                  />
                )
              ) : (
                <InputMask type="text" mask="99.999.999/9999-99">
                  <Input />
                </InputMask>
              )}
            </Form.Item>
          </Col>
          <Col span={windowWidth > 800 ? 6 : 24}>
            <Form.Item name={["cliente", "email"]} label="E-mail">
              {params.id ? (
                clientes?.filter((c: any) => c._id === params.id)[0].email && (
                  <Input
                    value={
                      clientes?.filter((c: any) => c._id === params.id)[0].email
                    }
                    defaultValue={
                      clientes?.filter((c: any) => c._id === params.id)[0].email
                    }
                  />
                )
              ) : (
                <Input type="email" />
              )}
            </Form.Item>
          </Col>

          <Col span={windowWidth > 800 ? 12 : 24}>
            <Form.Item
              name={["cliente", "telefone"]}
              label="Telefone / Celular"
            >
              {params.id &&
              clientes?.filter((c: any) => c._id === params.id)[0].telefone ? (
                <Input
                  value={
                    clientes?.filter((c: any) => c._id === params.id)[0]
                      .telefone
                  }
                  defaultValue={
                    clientes?.filter((c: any) => c._id === params.id)[0]
                      .telefone
                  }
                />
              ) : (
                <InputMask type="text" mask="99 *9999-9999">
                  <Input />
                </InputMask>
              )}
            </Form.Item>
          </Col>
          <Col span={windowWidth > 800 ? 12 : 24}>
            <Form.Item
              name={["cliente", "representante"]}
              label="Representante legal"
            >
              {params.id &&
              clientes?.filter((c: any) => c._id === params.id)[0]
                .representante ? (
                <Input
                  value={
                    clientes?.filter((c: any) => c._id === params.id)[0]
                      .representante
                  }
                  defaultValue={
                    clientes?.filter((c: any) => c._id === params.id)[0]
                      .representante
                  }
                />
              ) : (
                <Input />
              )}
            </Form.Item>
          </Col>
          {/* {params.id && (
            <Col span={windowWidth > 800 ? 12 : 24}>
              <Form.Item name={["cliente", "sponsor"]} label="Responsáveis (sponsor)">
                <Space.Compact style={{ width: "100%" }}>
                  <Select
                    status={currentSponsor ? "" : "error"}
                    defaultValue={currentSponsor}
                    value={currentSponsor}
                    onChange={(v) => setCurrentSponsor(v)}
                    placeholder="Adicione um responsável"
                    options={usuarios?.filter((usr: any) =>
                      usr.permissao?.includes("64e4ef74ae11d9515cd31e9c")
                    )}
                    fieldNames={{ label: "name", value: "_id" }}
                  />
                  <Button type="primary" onClick={() => setShowCreateSponsor(true)}>
                    Novo
                  </Button>
                </Space.Compact>
              </Form.Item>
            </Col>
          )} */}

          {/* {multipleSponsors && usuarios && (
            <Col span={24} style={{ marginBottom: "1.5rem" }}>
              <Table
                size="small"
                dataSource={multipleSponsors}
                columns={[
                  {
                    title: "Nome sponsor",
                    dataIndex: "user_id",
                    key: "user_id",
                    render: (user_id: string) => usuarios.find((usr: any) => usr._id === user_id)?.name,
                  },
                  {
                    title: "E-mail",
                    dataIndex: "user_id",
                    key: "email",
                    render: (user_id: string) => usuarios.find((usr: any) => usr._id === user_id)?.email,
                  },
                  {
                    title: "Último envio",
                    dataIndex: "ultimo_envio",
                    key: "ultimo_envio",
                    render: (date: string, record: any) => (date ? <CustomPopconfirm date={date} record={record} /> : <Tag>Nunca</Tag>),
                  },
                  {
                    title: "Ação",
                    key: "action",
                    render: (item: any) => (
                      <Button
                        type="link"
                        icon={<SendOutlined />}
                        onClick={() =>
                          postPrimeiroAcesso(
                            usuarios.find(
                              (usr: any) => usr._id === item.user_id
                            )
                          )
                        }
                      >
                        Enviar primeiro acesso
                      </Button>
                    ),
                  },
                ]}
              />
            </Col>
          )} */}

          <Col span={24}>
            <Form.Item name={["cliente", "obs"]} label="Observações">
              {params.id &&
              clientes?.filter((c: any) => c._id === params.id)[0].obs ? (
                <TextArea
                  rows={6}
                  value={
                    clientes?.filter((c: any) => c._id === params.id)[0].obs
                  }
                  defaultValue={
                    clientes?.filter((c: any) => c._id === params.id)[0].obs
                  }
                />
              ) : (
                <TextArea rows={6} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" className="btn-success" htmlType="submit">
            Salvar cliente
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="editClientes">
      <BackHeader
        title={params.id ? "Editar Cliente" : "Novo Cliente"}
        route="/clientes"
      />
      {params.id ? (
        <Tabs
          defaultActiveKey="1"
          style={{
            height: "fit-content",
            marginBottom: "5rem",
          }}
          items={[
            UsergroupAddOutlined,
            NodeIndexOutlined,
            WarningOutlined,
            UsergroupAddOutlined,
            SnippetsOutlined,
          ].map((Icon, i) => {
            const id = String(i + 1);
            return {
              key: id,
              label:
                id === "1"
                  ? `Dados`
                  : id === "3"
                  ? "Análise de Vulnerabilidades"
                  : id === "4"
                  ? "Usuários"
                  : id === "2"
                  ? "Módulos"
                  : "Tarefas",
              children:
                id === "1" ? (
                  <>
                    <FormCliente />
                    <br />

                    {params.id && (
                      <Tabs
                        tabPosition={windowWidth > 800 ? "left" : "top"}
                        items={[FileDoneOutlined, FileAddOutlined].map(
                          (Icon, i) => {
                            const id = String(i + 1);

                            let tab: any;

                            switch (id) {
                              case "1":
                                tab = {
                                  label: (
                                    <span>
                                      <Icon /> Questionários
                                    </span>
                                  ),
                                  key: id,
                                  children: <ListQuestinarios editCliente />,
                                  // children: currentSponsor ? (
                                  //   <ListQuestinarios editCliente />
                                  // ) : (
                                  //   <Empty description="Adicione um sponsor para visualizar" />
                                  // ),
                                };
                                break;
                              case "2":
                                tab = {
                                  label: (
                                    <span>
                                      <Icon /> Fornecedores
                                    </span>
                                  ),
                                  key: id,
                                  children: <Fornecedores type="editcliente" />,
                                };
                                break;

                              default:
                                break;
                            }
                            return tab;
                          }
                        )}
                      />
                    )}
                  </>
                ) : id === "3" ? (
                  <Tabs
                    defaultActiveKey="1"
                    tabPosition="left"
                    style={{ marginTop: "0rem", marginLeft: "-1.5rem" }}
                    size="small"
                    items={[
                      {
                        key: "1",
                        label: "OWASP",
                        children: <OWASP />,
                      },
                    ]}
                  />
                ) : id === "2" ? (
                  <>
                    {usuarios
                      ?.filter((u: any) => u.cliente_id === params.id)
                      ?.find((usr: any) =>
                        usr.grupos?.find((g: any) => g.name === "sponsor")
                      ) ? (
                      <ModulosLiberacao cliente_id={params.id} />
                    ) : (
                      <Alert
                        showIcon
                        type="warning"
                        message="Nenhum sponsor cadastro"
                      />
                    )}
                  </>
                ) : id === "4" ? (
                  <Usuarios noHeader cliente_id={params.id} />
                ) : (
                  <>
                    {categoriaId ? (
                      <>
                        <ArrowLeftOutlined
                          onClick={() => setCategoriaId(null)}
                        />
                        <Table
                          size="small"
                          dataSource={moduloTask.tasksCliente.filter(
                            (task) => task.modelo_referencia_id === categoriaId
                          )}
                          columns={[
                            {
                              title: "Tarefa",
                              dataIndex: "title",
                              key: "title",
                              render: (text, record: any) => (
                                <p
                                  onClick={() => {
                                    moduloTask.setSelectedTask(record);
                                    handleShowModal();
                                  }}
                                >
                                  {text}
                                </p>
                              ),
                            },
                          ]}
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          size="small"
                          dataSource={moduloTask.tasksCliente
                            .filter(
                              (task) =>
                                task.modelo_info &&
                                task.modelo_info.cliente_id === params.id
                            )
                            .map((task) => task.modelo_info)
                            .reduce((acc, task) => {
                              const stringifiedTask = JSON.stringify(task);
                              if (!acc.includes(stringifiedTask)) {
                                acc.push(stringifiedTask);
                              }
                              return acc;
                            }, [])
                            .map((task: any) => JSON.parse(task))}
                          columns={[
                            {
                              title: "Tarefas por categoria",
                              dataIndex: "nome",
                              key: "nome",
                              render: (text, record: any) => (
                                <p
                                  onClick={() => {
                                    setCategoriaId(record._id);
                                  }}
                                >
                                  {text}
                                </p>
                              ),
                            },
                          ]}
                        />
                        <Table
                          size="small"
                          dataSource={moduloTask.tasksCliente.filter((task) =>
                            task.title.includes("Análise do site")
                          )}
                          columns={[
                            {
                              title: "Outras tarefas",
                              dataIndex: "title",
                              key: "title",
                              render: (text, record: any) => (
                                <p
                                  onClick={() => {
                                    moduloTask.setSelectedTask(record);
                                    handleShowModal();
                                  }}
                                >
                                  {text}
                                </p>
                              ),
                            },
                          ]}
                        />
                      </>
                    )}
                  </>
                ),
              icon: <Icon />,
            };
          })}
        />
      ) : (
        <FormCliente />
      )}

      <Modal
        title="Cadastrar novo sponsor"
        open={showCreateSponsor}
        footer={[]}
        width={493}
        onCancel={() => setShowCreateSponsor(false)}
      >
        <Form
          {...layout}
          layout="vertical"
          name="edit-clientes-form"
          onFinish={onFinishSponsor}
        >
          <Row style={{ marginTop: "2rem" }}>
            <Col span={24}>
              <Form.Item name={"nome"} label="Nome">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name={"email"} label="E-mail">
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginTop: "2rem", marginBottom: "-1rem" }}>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="default"
                form="form-pergunta"
                style={{ marginRight: "2rem" }}
                onClick={() => setShowCreateSponsor(false)}
              >
                Cancelar
              </Button>
              <Button type="primary" className="btn-success" htmlType="submit">
                Adicionar
              </Button>
            </span>
          </Form.Item>
        </Form>
      </Modal>
      <Task setShowModal={setShowModal} showModal={showModal} />
    </div>
  );
};

export default EditClientes;
