import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles.css";
import InputMask from "react-input-mask";

import { Button, Upload, Table, message, Tag, Spin } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  PlusOutlined,
  SearchOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import api from "../../../../services/api";
import moment from "moment";
import Search from "antd/es/input/Search";
import { useAuth0 } from "@auth0/auth0-react";
import ModalEditar from "../Fornecedores/components/ModalEditar/ModalEditar";
import { Tooltip } from "antd/lib";
import Anexos from "../../../../components/Anexos/Anexos";

interface Fornecedor {
  _id: string;
  cliente_id: string;
  nome: string;
  cnpj: string;
  telefone: string;
  email: string;
}
interface Contratos {
  _id: string;
  cliente_id: string;
  fornecedor_id: string;
  data_inicio: Date;
  data_fim: Date;
  link_contrato: string;
}

const EditFornecedores = () => {
  const {
    accessToken,
    windowWidth,
    pageSizeBig,
    editFornecedor,
    setEditFornecedor,
    getAnexo,
    userInfo,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const { user } = useAuth0();
  const navigate = useNavigate();
  const params = useParams();
  const [formularios, setFormularios] = useState<Fornecedor[]>([]);
  const [fornecedor, setFornecedor] = useState<Fornecedor | null>(null);
  const [contratos, setContratos] = useState<Contratos[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [showFornecedores, setShowFornecedores] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [currentAnexos, setCurrentAnexos] = useState<any[]>([]);
  const [anexos, setAnexos] = useState<any[]>([]);
  const [loadingDados, setLoadingDados] = useState<boolean>(true);
  const [loadingFormularios, setLoadingFormularios] = useState<boolean>(true);

  useEffect(() => {
    if (editFornecedor) {
      setLoading(true);
      getAnexo(editFornecedor._id)
        .then((res) => {
          setAnexos(res);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editFornecedor]);

  useEffect(() => {
    if (fornecedor) {
      setEditFornecedor(fornecedor);
    }
  }, [fornecedor]);

  const fetchFormularios = async () => {
    try {
      if (!formularios?.[0]) {
        await api
          .get(`/formularios/${params.fornecedorid}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              User: user?.sub,
            },
          })
          .then((res) => setFormularios(res.data))
          .finally(() => setLoadingFormularios(false));
      }
    } catch (error) {
      console.error("Error fetching formularios:", error);
    }
  };

  const fetchData = async () => {
    if (accessToken && user && !fornecedor) {
      try {
        await api
          .get(`/fornecedoresmodel/${params.fornecedorid}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              User: user?.sub,
            },
          })
          .then((res) => setFornecedor(res.data))
          .finally(() => setLoadingDados(false));
      } catch (error) {
        console.error("Error fetching fornecedores:", error);
      }
    }
  };

  const showAddFornecedor = () => {
    showFornecedores ? setShowFornecedores(false) : setShowFornecedores(true);
  };

  const columnsFornecedor: any = [
    { title: "Razão social", dataIndex: "nome", key: "nome" },
    {
      title: "Nome do responsável",
      dataIndex: "representante",
      key: "representante",
    },
    {
      title: "CNPJ",
      dataIndex: "cnpj",
      key: "cnpj",
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Telefone",
      dataIndex: "telefone",
      key: "telefone",
      render: (tel: string) =>
        tel ? (
          <InputMask
            type="text"
            mask={
              tel
                .replaceAll(" ", "")
                .replaceAll("(", "")
                .replaceAll(")", "")
                .replaceAll("-", "").length === 10
                ? "(99) 9999-9999"
                : tel
                    .replaceAll(" ", "")
                    .replaceAll("(", "")
                    .replaceAll(")", "")
                    .replaceAll("-", "").length === 11
                ? "(99) 9 9999-9999"
                : tel
                    .replaceAll(" ", "")
                    .replaceAll("(", "")
                    .replaceAll(")", "")
                    .replaceAll("-", "").length === 9
                ? "9 9999-9999"
                : "9999-9999"
            }
            value={tel}
            defaultValue={tel}
            disabled
          >
            <input className="input_dis_text" style={{ width: "9rem" }} />
          </InputMask>
        ) : (
          <p style={{ textAlign: "center", opacity: 0.3 }}>-</p>
        ),
    },
    {
      title: "Último envio",
      dataIndex: "envio",
      key: "envio",
      render: (envio: any) =>
        envio?.user_name ? (
          <>
            {moment(new Date(envio.created_at)).format("DD/MM/YYYY")} por
            <Tooltip title={envio.user_name}>
              <Button style={{ marginLeft: "-0.7rem" }} type="link">
                {envio.user_name.length > 10
                  ? envio.user_name.slice(0, 10) + "..."
                  : envio.user_name}
              </Button>
            </Tooltip>
          </>
        ) : (
          <p style={{ fontSize: "0.6rem", opacity: 0.3, fontWeight: "300" }}>
            Sem informações
          </p>
        ),
    },
    {
      title: "Status",
      dataIndex: "status_acao",
      key: "status_acao",
      render: (status: string) => {
        let icon;
        let color;
        let text;
        if (status === "continuarcontrato") {
          icon = <EditOutlined />;
          color = "#fcba03";
          text = "Aditar contrato";
        } else if (status === "continuar") {
          icon = <CheckOutlined />;
          color = "green";
          text = "Continuar contrato";
        } else if (status === "descontinuar") {
          icon = <CloseOutlined />;
          color = "red";
          text = "Descontinuar contrato";
        }

        return (
          <span style={{ color: color }}>
            {icon} {text}
          </span>
        );
      },
    },
  ];

  // Fetch fornecedores and update the state
  useEffect(() => {
    if (userInfo?._id) {
      fetchFormularios();
      fetchData();
    }
  }, [userInfo]);

  const sendEmailToFornecedor = async (record: any) => {
    try {
      if (fornecedor) {
        const requestData = {
          client_id: record.cliente_id,
          fornecedores: [
            {
              fornecedor_id: fornecedor._id,
              email: fornecedor.email,
              questionario_id: record._id,
            },
          ],
        };

        await api
          .post("/enviarEmailsFornecedor", requestData)
          .then((res: any) => {
            console.log(res.data);
            message.success("Questionário enviado com sucesso!");
          });
      }
    } catch (error) {
      message.error("Erro ao enviar questionário para o fornecedor!");
    }
    fetchFormularios();
    fetchData();
  };

  const novoQuestionario = async () => {
    try {
      if (fornecedor) {
        const requestData = {
          client_id: params.id,
          fornecedores: [
            {
              fornecedor_id: fornecedor._id,
              email: fornecedor.email,
            },
          ],
        };

        message.loading("Criando questionário...");
        await api.post("/criarQuestionario", requestData).finally(() => {
          fetchFormularios();
          message.success("Questionário criado com sucesso!");
        });
      }
    } catch (error) {
      message.error("Erro ao criar questionário para o fornecedor!");
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, record: any, index: number) =>
        `Questionário ${
          formularios
            .sort(
              (a: any, b: any) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .indexOf(record) + 1
        }`,
    },
    {
      title: "Criação do questionário",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: any) => moment(new Date(date)).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, record: any) => {
        let iconColor = "";
        let text = "";
        if (!status) {
          iconColor = "#b5b5b5";
        } else if (status === "respondido") {
          iconColor = "green";
        } else if (status === "enviado") {
          iconColor = "#fcba03";
        }

        if (!status) {
          text = "Não Enviado";
        } else if (status === "respondido") {
          text = "Questionário respondido";
        } else if (status === "enviado") {
          text = "Enviado";
        }

        return (
          <>
            <Tag color={iconColor} style={{ fontWeight: "bold" }}>
              {text}
            </Tag>
            {text === "Questionário respondido" && (
              <span>
                em {moment(new Date(record.updated_at)).format("DD/MM/YYYY")}
              </span>
            )}
            {text === "Enviado" && (
              <span>
                em {moment(new Date(record.updated_at)).format("DD/MM/YYYY")}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "_id",
      key: "_id",
      render: (id: String, record: any) => (
        <div>
          {record.status !== "respondido" && (
            <Button
              type="primary"
              onClick={() => sendEmailToFornecedor(record)}
              icon={<SendOutlined />}
              style={{ marginRight: "1rem" }}
            >
              {record.status === "enviado" ? "Enviar novamente" : "Enviar"}
            </Button>
          )}
          {(record.status === "respondido" ||
            userInfo?.grupos?.find(
              (gp: any) => gp.name === "seusdados" || gp.name === "assinante"
            )) && (
            <>
              <Button
                type="default"
                className="btn-success"
                htmlType="submit"
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(
                    `/analisefornecedor/${params.id}/${params.fornecedorid}/${id}`
                  );
                }}
              >
                Análise
              </Button>
              {/* <Button
                type="default"
                className="btn-success"
                htmlType="submit"
                icon={<EyeOutlined />}
                style={{ marginLeft: "1rem" }}
                onClick={() => {
                  navigate(
                    `/resposta/${params.id}/${params.fornecedorid}/${id}`
                  );
                }}
              >
                Respostas
              </Button> */}
            </>
          )}
        </div>
      ),
    },
  ];

  const columnsMobile = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      render: (id: any, record: any, index: number) =>
        `${
          formularios
            .sort(
              (a: any, b: any) =>
                new Date(a.created_at).getTime() -
                new Date(b.created_at).getTime()
            )
            .indexOf(record) + 1
        }`,
    },
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: any) => moment(new Date(date)).format("DD/MM/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        let iconColor = "";
        let text = "";
        if (!status) {
          iconColor = "#b5b5b5";
        } else if (status === "respondido") {
          iconColor = "green";
        } else if (status === "enviado") {
          iconColor = "#fcba03";
        }

        if (!status) {
          text = "N/ Enviado";
        } else if (status === "respondido") {
          text = "Respondido";
        } else if (status === "enviado") {
          text = "Enviado";
        }

        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Ações",
      dataIndex: "_id",
      key: "_id",
      render: (id: String, record: any) => (
        <div>
          {record.status !== "respondido" && (
            <Button
              type="primary"
              onClick={() => sendEmailToFornecedor(record)}
              icon={<SendOutlined />}
              style={{ marginRight: "1rem" }}
            >
              {record.status === "enviado" ? "Enviar novamente" : "Enviar"}
            </Button>
          )}
          {record.status === "respondido" && (
            <>
              <Button
                type="default"
                className="btn-success"
                htmlType="submit"
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(
                    `/analisefornecedor/${params.id}/${params.fornecedorid}/${id}`
                  );
                }}
              >
                Análise
              </Button>
              {/* <Button
                type="default"
                className="btn-success"
                htmlType="submit"
                icon={<EyeOutlined />}
                style={{ marginTop: "1rem" }}
                onClick={() => {
                  navigate(
                    `/resposta/${params.id}/${params.fornecedorid}/${id}`
                  );
                }}
              >
                Respostas
              </Button> */}
            </>
          )}
        </div>
      ),
    },
  ];

  const [tableHeight, setTableHeight] = useState(window.innerHeight);
  const [pageSize, setPageSize] = useState(10); // Número padrão de itens por página
  const tableData = [
    /* Seus dados da tabela */
  ]; // Substitua com seus próprios dados

  const calculatePageSize = () => {
    const rowsPerPage = Math.floor((tableHeight - 150) / 54); // Altura de uma linha da tabela é assumida como 54 (ajuste conforme necessário)
    setPageSize(rowsPerPage);
  };

  const onSearch = (value: string) => setSearch(value);

  const handleDownloadCSV = async () => {
    try {
      const response = await api.get(
        `/download-csv/fornecedor/${params.fornecedorid}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fornecedor_questionarios.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error("Erro ao gerar CSV");
    }
  };
  return (
    <div className="Fornecedores">
      <BackHeader
        title={"Questionários " + (fornecedor?.nome || "")}
        route={`/clientesFornecedor/fornecedor/${params.id}`}
      />
      <br />
      <h3>Dados do fornecedor</h3>
      <br />
      <Table
        loading={loadingDados}
        size="small"
        dataSource={fornecedor ? [fornecedor] : []}
        columns={columnsFornecedor}
        pagination={false}
      />
      <br />
      <div>
        <Button
          onClick={() => {
            setEditFornecedor(fornecedor);
            showAddFornecedor();
          }}
          icon={<EditOutlined />}
        >
          Editar
        </Button>
        {userInfo?.grupos?.find(
          (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
        ) && (
          <Button
            style={{
              marginBottom: "2rem",
              marginLeft: "2rem",
              backgroundColor: "green",
            }}
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={handleDownloadCSV}
          >
            Baixar CSV
          </Button>
        )}
      </div>

      <br />
      <br />
      <br />
      <h3>Evidências</h3>
      <br />
      {loading ? (
        <Spin tip="Carregando" size="small">
          <div className="content" />
        </Spin>
      ) : anexos[0] ? (
        <Anexos
          currentAnexos={[...currentAnexos, ...anexos]}
          onChange={(v) =>
            setCurrentAnexos(v.fileList.map((f: any) => f.originFileObj))
          }
          setCurrentAnexos={setCurrentAnexos}
          showUpload={false}
        />
      ) : (
        <p
          style={{
            opacity: 0.4,
            fontSize: "0.8rem",
          }}
        >
          Nenhum anexo adicionado
        </p>
      )}
      <br />
      <br />
      <h3>Questionários aplicados</h3>

      <br />
      <div className="clientes__actions">
        <Search
          placeholder="Buscar questionário"
          className="clientes__actions--search"
          allowClear
          enterButton={<SearchOutlined />}
          onSearch={onSearch}
        />
        <div>
          <Button
            className="clientes__actions--new"
            type="primary"
            onClick={() => novoQuestionario()}
            icon={<PlusOutlined />}
            style={{ marginLeft: "1rem" }}
          >
            Aplicar questionário
          </Button>
        </div>
      </div>
      <Table
        loading={loadingFormularios}
        size="small"
        dataSource={formularios
          .sort(
            (a: any, b: any) =>
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
          )
          .filter(
            (form: any, index: number) =>
              form._id.toString().toUpperCase() === search.toUpperCase() ||
              !search
          )}
        columns={windowWidth > 800 ? columns : columnsMobile}
        pagination={{ pageSize: pageSizeBig + 2 }}
        scroll={{ x: 0 }}
      />
      {params.id && (
        <ModalEditar
          editFornecedor={editFornecedor}
          showFornecedores={showFornecedores}
          showAddFornecedor={showAddFornecedor}
          clientId={params.id}
        />
      )}
    </div>
  );
};

export default EditFornecedores;
