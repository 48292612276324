import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "react-quill/dist/quill.snow.css";
import 'dayjs/locale/pt-br';
import dayjs from 'dayjs';
import { ConfigProvider } from "antd";
import ptBR from 'antd/lib/locale/pt_BR';

dayjs.locale('pt-br');

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ConfigProvider locale={ptBR}>
      <HashRouter>
        <Auth0Provider
          domain="seusdados.us.auth0.com"
          clientId="6ShrIGGcig8zNzuILVNTNY9nDp3IhmTQ"
          authorizationParams={{
            redirect_uri: window.location.pathname
              ? window.location.origin + window.location.pathname
              : window.location.origin,
            audience: "https://seusdados.us.auth0.com/api/v2/",
            scope: "read:current_user",
          }}
          cacheLocation="localstorage"
        >
          <PrimeReactProvider>
            <App />
          </PrimeReactProvider>
        </Auth0Provider>
      </HashRouter>
    </ConfigProvider>
  </React.StrictMode>
);
