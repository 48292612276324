import React, { useState } from 'react';
import { Modal, Form, DatePicker, message, Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

interface EditDateModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  currentReuniaoId: string | null;
  onUpdateDate: (reuniaoId: string, date: Dayjs) => Promise<void>;
}

const EditDateModal: React.FC<EditDateModalProps> = ({
  isVisible,
  setIsVisible,
  currentReuniaoId,
  onUpdateDate,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [loading, setLoading] = useState<boolean>(false);

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
  };

  const handleUpdateDate = async () => {
    if (selectedDate && currentReuniaoId) {
      setLoading(true);
      try {
        await onUpdateDate(currentReuniaoId, selectedDate);
        message.success('Data de agendamento atualizada com sucesso!');
        setIsVisible(false);
      } catch (error) {
        message.error('Erro ao atualizar a data de agendamento.');
      } finally {
        setLoading(false);
      }
    } else {
      message.warning('Por favor, selecione uma data.');
    }
  };

  return (
    <Modal
      title="Editar Data de Agendamento"
      visible={isVisible}
      onOk={handleUpdateDate}
      onCancel={() => setIsVisible(false)}
      footer={[
        <Button key="cancel" onClick={() => setIsVisible(false)}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleUpdateDate}>
          Ok
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item label="Data de Agendamento">
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            showTime
            format="HH:mm DD/MM/YYYY"
            className="w-full"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditDateModal;
