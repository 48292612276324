import * as React from "react";
import "./styles.css";
import {
  ArrowLeftOutlined,
  LogoutOutlined,
  MenuOutlined,
  MessageOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { Button, Tag, Tooltip } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalContextType } from "../../@types/global";
import { GlobalContext } from "../../context/globalContext";
import NotificationIcon from "../NotificationIcon/NotificationIcon";
import { useNavigate } from "react-router-dom";
import FeedbackDropdown from "../Feedback/Feedback";
import {
  Building,
  ClipboardText,
  Diagram,
  Edit,
  Edit2,
  Home2,
  HomeTrendUp,
  LogoutCurve,
  Map1,
  Truck,
  User,
} from "iconsax-react";

const Header: React.FC = () => {
  const { logout } = useAuth0();

  const { userInfo, setCollapsed, rote, clientes } = React.useContext(
    GlobalContext
  ) as GlobalContextType;
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: "https://workday.seusdados.com",
      },
    });
  };

  const getIcon = (title: string) => {
    let icon;
    switch (title) {
      case "Visão geral":
        icon = (
          <HomeTrendUp
            size={42}
            style={{ marginRight: "1rem", color: "#33CCFF" }}
          />
        );

        break;
      case "Clientes":
        icon = (
          <Building
            size={42}
            style={{ marginRight: "1rem", color: "#33CCFF" }}
          />
        );
        break;
      case "Editar Cliente":
        icon = (
          <Edit size={42} style={{ marginRight: "1rem", color: "#33CCFF" }} />
        );
        break;
      case "Parceiros / Fornecedores":
        icon = (
          <Truck size={42} style={{ marginRight: "1rem", color: "#33CCFF" }} />
        );
        break;
      case "Ficha de processo":
        icon = (
          <ClipboardText
            size={42}
            style={{ marginRight: "1rem", color: "#33CCFF" }}
          />
        );
        break;
      case "Mapeamentos":
        icon = (
          <Map1 size={42} style={{ marginRight: "1rem", color: "#33CCFF" }} />
        );
        break;
      case "Usuários":
        icon = (
          <User size={42} style={{ marginRight: "1rem", color: "#33CCFF" }} />
        );
        break;
      case "Análise consolidada":
        icon = (
          <Diagram
            size={42}
            style={{ marginRight: "1rem", color: "#33CCFF" }}
          />
        );
        break;
      case "Questionários CPPD":
        icon = (
          <ProfileOutlined
            style={{ marginRight: "1rem", color: "#33CCFF", fontSize: 42 }}
          />
        );
        break;
      default:
        icon = (
          <Diagram
            size={42}
            style={{ marginRight: "1rem", color: "#33CCFF" }}
          />
        );
        break;
    }

    return icon;
  };

  return (
    <div
      className="Header"
      // style={{ width: collapsed ? "calc(100% - 80px)" : "calc(100% - 280px)" }}
    >
      {isAuthenticated && (
        <MenuOutlined
          className="openmenuMobile"
          onClick={() => setCollapsed(false)}
        />
      )}

      {rote?.title ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          {rote?.route && (
            <ArrowLeftOutlined
              className="back"
              onClick={() => navigate(rote?.route)}
            />
          )}
          {rote?.prev && (
            <ArrowLeftOutlined className="back" onClick={() => navigate(-1)} />
          )}
          {getIcon(rote?.title)}
          <h1 style={{ fontSize: rote?.title.length > 50 ? 32 : 42 }}>
            {rote?.title}
          </h1>
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }} />
      )}

      {isAuthenticated && (
        <div className="Header__right">
          {userInfo?.name && <FeedbackDropdown />}
          <NotificationIcon />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "1rem",
            }}
          >
            <p>{userInfo?.name}</p>
            {userInfo?.cliente_id && (
              <p className="Header__right--empresa">
                {clientes
                  ?.find((c: any) => c._id === userInfo?.cliente_id)
                  ?.razao_social?.toUpperCase(0)}
              </p>
            )}
          </div>
          {userInfo?.grupos?.map((gp: any) => (
            <Tag className="Header__right--nivel" color="purple">
              {gp.name?.replace("assinante", "consultor").toUpperCase()}
            </Tag>
          ))}

          {/* <span className="Header__right--line" /> */}
          <Tooltip title="Sair">
            <LogoutCurve
              className="Header__right--exit"
              onClick={handleLogout}
              style={{
                marginLeft: "2rem",
                marginTop: "0.3rem",
                width: 28,
                height: 28,
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
export default Header;
