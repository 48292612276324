import {
  ArrowRightOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownCircleOutlined,
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  LeftOutlined,
  MinusCircleFilled,
  PlusCircleFilled,
  PlusCircleOutlined,
  RightCircleFilled,
  RightCircleOutlined,
  RightOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Input,
  message,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Spin,
  Switch,
  Tabs,
  Tooltip,
} from "antd";
import { Back, BackSquare, InfoCircle } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContextType } from "../../../../../@types/global";
import { Titular } from "../../../../../@types/titular";
import { GlobalContext } from "../../../../../context/globalContext";
import { Questionario } from "../../../../../context/mapeamentoContext";
import { CapituloType } from "../../../../../context/processosContext";
import EditFornecedor from "./EditFornecedor/EditFornecedor";
import "./styles.css";
import Fichas from "../../../../Modulos/Fichas/Fichas";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

interface GuiasMapeamentoProps {
  processo: CapituloType;
  mapeamento: Questionario | null;
  setTabProcesso: (v: any) => void;
  setMapeamento: (v: any) => void;
}
const GuiasMapeamento: React.FC<GuiasMapeamentoProps> = ({
  processo,
  mapeamento,
  setTabProcesso,
  setMapeamento,
}) => {
  const { moduloProcessos, moduloMapeamento, getFornecedores, fornecedores } =
    React.useContext(GlobalContext) as GlobalContextType;
  const [selectedDados, setSelectedDados] = useState<any[] | null>(null);
  const [showEditProcessamento, setShowEditProcessamento] =
    useState<boolean>(true);
  const [showEditCompartilhamento, setShowEditCompartilhamento] =
    useState<boolean>(true);
  const [showCreateFornecedor, setShowCreateFornecedor] =
    useState<boolean>(false);
  const [lastInputArmazenamento, setLastInputArmazenamento] = useState<
    string | null
  >(null);
  const [showEditArmazenamento, setShowEditArmazenamento] =
    useState<boolean>(true);
  const [showPrazo, setShowPrazo] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [showFicha, setShowFicha] = useState(false);
  const [loadingTitulares, setLoadingTitulares] = useState(false);
  const [icon, setIcon] = useState(<SaveOutlined />);
  const [activeKey, setActiveKey] = useState("0");

  const categoriasPermitidas = [
    "Candidato",
    "Cliente",
    "Colaborador",
    "Lead (cliente em potencial)",
    "Conselheiro",
    "Diretor",
    "Estagiário",
    "Prestador de Serviço",
    "Servidor Público",
    "Voluntário",
    "Acionista",
    "Visitante",
    "Aluno",
    "Paciente",
  ];

  const showModal = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (
      moduloMapeamento?.currentTitulares?.find(
        (titular) =>
          titular.dados?.[0]?.frequencia &&
          (titular.dados?.[0].bases_legais_comuns ||
            titular.dados?.[0].bases_legais_sensiveis) &&
          titular.relacao_id === processo?.questionarioCapitulo?._id
      )
    ) {
      setShowEditProcessamento(false);
    }
    if (
      moduloMapeamento?.currentTitulares?.find(
        (titular) =>
          titular.dados?.[0] &&
          (titular.dados?.[0].areas_compartilhadas?.[0] ||
            titular.dados?.[0].externos?.[0]) &&
          titular.dados?.[0].compartilhamento?.[0] &&
          titular.relacao_id === processo?.questionarioCapitulo?._id
      )
    ) {
      setShowEditCompartilhamento(false);
    }

    if (
      moduloMapeamento?.currentTitulares?.find(
        (titular) =>
          titular.dados?.[0] &&
          titular.dados?.[0].local?.[0] &&
          titular.dados?.[0].prazo &&
          titular.relacao_id === processo?.questionarioCapitulo?._id
      )
    ) {
      setShowEditArmazenamento(false);
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     moduloMapeamento?.currentTitulares?.find(
  //       (titular) =>
  //         titular.dados?.[0]?.frequencia &&
  //         (titular.dados?.[0].bases_legais_comuns ||
  //           titular.dados?.[0].bases_legais_sensiveis) &&
  //         titular.relacao_id === processo?.questionarioCapitulo?._id
  //     ) &&
  //     moduloMapeamento?.currentTitulares?.find(
  //       (titular) =>
  //         titular.dados?.[0] &&
  //         (titular.dados?.[0].areas_compartilhadas?.[0] ||
  //           titular.dados?.[0].externos?.[0]) &&
  //         titular.dados?.[0].compartilhamento?.[0] &&
  //         titular.relacao_id === processo?.questionarioCapitulo?._id
  //     ) &&
  //     moduloMapeamento?.currentTitulares?.find(
  //       (titular) =>
  //         titular.dados?.[0] &&
  //         titular.dados?.[0].local?.[0] &&
  //         (titular.dados?.[0].prazo === "Não definido" ||
  //           titular.dados?.[0].prazo !== null) &&
  //         titular.relacao_id === processo?.questionarioCapitulo?._id
  //     )
  //   ) {
  //     showModal();
  //   }
  // }, [moduloMapeamento]);

  useEffect(() => {
    mapeamento?.cliente_id &&
      !fornecedores?.[0] &&
      getFornecedores(mapeamento.cliente_id);
  }, [mapeamento]);

  const handleDeleteTitular = (titular: Titular) => {
    // deixar titular inativo
    moduloMapeamento.setCurrentTitulares((prevTitulares: Titular[]) => {
      let currentTitular = titular;
      currentTitular.inativo = true;

      return [
        ...prevTitulares.filter((t) => t.key !== titular.key),
        currentTitular,
      ];
    });

    // salvar titulares atualizados
    moduloMapeamento
      .saveTitulares(moduloMapeamento.currentTitulares)
      .then(() => {
        message.success("Alterações salvas com sucesso!");
      })
      .catch(() => {
        message.success("Erro ao salvar alterações!");
      })
      .finally(() => setLoadingTitulares(false));
  };

  const uniqueDataIds = new Set();

  const truncateDescription = (description: any, maxLength = 15) => {
    if (description?.length > maxLength) {
      return description.slice(0, maxLength) + "...";
    }
    return description;
  };

  const TitularTitle = () => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>Dados do Titular​</h2>
          <Tooltip title="É a pessoa a quem os dados dizem respeito">
            <InfoCircle
              size={18}
              style={{ marginLeft: "0.5rem", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
        <br />
        <p style={{ fontSize: 18 }}>Estamos falando sobre: </p>
        <br />
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <span
          style={{
            color: moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0] &&
                titular.faixa_etaria &&
                titular.categoria &&
                titular.quantidade &&
                titular.relacao_id === processo?.questionarioCapitulo?._id &&
                titular.finalidade_coleta &&
                titular.medidas_coleta
            )
              ? "#008C76"
              : "",
          }}
        >
          1. Coleta{" "}
          {moduloMapeamento?.currentTitulares?.find(
            (titular) =>
              titular.dados?.[0] &&
              titular.faixa_etaria &&
              titular.categoria &&
              titular.quantidade &&
              titular.relacao_id === processo?.questionarioCapitulo?._id &&
              titular.finalidade_coleta &&
              titular.medidas_coleta
          ) && <CheckCircleOutlined style={{ marginLeft: "0.2rem" }} />}
        </span>
      ),
      children: (
        <div className="tabs_mapeamento">
          <TitularTitle />

          {moduloMapeamento.currentTitulares ? (
            moduloMapeamento.currentTitulares
              .filter(
                (titular: Titular) =>
                  titular.relacao_id === processo?.questionarioCapitulo?._id
              )
              .map((titular: Titular) => {
                // Extrair todas as categorias em um array
                const categorias = moduloMapeamento.dados.map(
                  (item: any) => item.categoria
                );

                // Usar um conjunto para remover valores duplicados
                const categoriasUnicasSet = new Set(categorias);

                // Converter o conjunto de volta para um array
                const categoriasUnicas = Array.from(categoriasUnicasSet);

                return (
                  <Collapse
                    defaultActiveKey={"1"}
                    size="large"
                    items={[
                      {
                        key: "1",
                        extra: loadingTitulares ? (
                          <Spin size="small" />
                        ) : (
                          <Popconfirm
                            title="Você tem certeza que deseja excluir este item?"
                            onConfirm={() => handleDeleteTitular(titular)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <DeleteOutlined
                              style={{ marginRight: "1rem", cursor: "pointer" }}
                            />
                          </Popconfirm>
                        ),
                        label: (
                          <p style={{ fontSize: 18, color: "#fff" }}>
                            {`${truncateDescription(
                              moduloProcessos.areas?.find(
                                (a) =>
                                  a._id ===
                                  (processo.dependencia
                                    ? mapeamento?.capitulos?.find(
                                        (c: any) =>
                                          c._id === processo.dependencia
                                      )?.tags?.[0]
                                    : processo.tags?.[0])
                              )?.description,
                              20
                            )} ${
                              mapeamento?.abordagem === "detalhada"
                                ? ">" +
                                  truncateDescription(
                                    moduloProcessos?.capitulos?.find(
                                      (c) => c._id === processo.dependencia
                                    )?.description,
                                    20
                                  )
                                : ""
                            } > ${truncateDescription(
                              processo.description,
                              20
                            )} > ` + (titular.categoria || "Novo titular")}
                          </p>
                        ),
                        children: (
                          <>
                            <p>
                              <ExclamationCircleOutlined /> Por favor, selecione
                              cada um dos itens abaixo e preencha conforme
                              necessário:
                            </p>
                            <br />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ width: "45%" }}>
                                <Collapse
                                  className={
                                    titular.categoria ? "collapse_done" : ""
                                  }
                                  defaultActiveKey={
                                    titular.categoria ? "0" : "categoria"
                                  }
                                  items={[
                                    {
                                      key: "categoria",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.categoria
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Categoria dos titulares de dados{" "}
                                          <Tooltip title="É o tipo de relação que as pessoas têm com sua empresa">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),
                                      children: (
                                        <>
                                          <Radio.Group
                                            onChange={(value) => {
                                              moduloMapeamento.setCurrentTitulares(
                                                (prevTitulares: Titular[]) => {
                                                  let currentTitular = titular;
                                                  currentTitular.categoria =
                                                    value.target.value;

                                                  return [
                                                    ...prevTitulares.filter(
                                                      (t) =>
                                                        t.key !== titular.key
                                                    ),
                                                    currentTitular,
                                                  ];
                                                }
                                              );
                                            }}
                                            value={titular.categoria}
                                          >
                                            <Space direction="vertical">
                                              <Tooltip title="Pessoa que se aplica a uma vaga de emprego ou posição na sua empresa">
                                                <Radio value={"Candidato"}>
                                                  Candidato{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Indivíduo que contrata serviços ou adquire mercadorias mediante pagamento">
                                                <Radio value={"Cliente"}>
                                                  Cliente{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>

                                              <Radio value={"Colaborador"}>
                                                Colaborador
                                              </Radio>
                                              <Tooltip title="Pessoa que demonstrou interesse em seus produtos ou serviços e pode se tornar um cliente no futuro">
                                                <Radio
                                                  value={
                                                    "Lead (cliente em potencial)"
                                                  }
                                                >
                                                  Lead (cliente em potencial){" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Indivíduo que fornece orientação ou conselhos para a sua empresa, geralmente como parte de um conselho consultivo ou de administração">
                                                <Radio value={"Conselheiro"}>
                                                  Conselheiro{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Membro da alta gestão da empresa, responsável por tomar decisões estratégicas e liderar setores/organização como um todo">
                                                <Radio value={"Diretor"}>
                                                  Diretor{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Pessoa contratada sob o regime de contratação de estágio">
                                                <Radio value={"Estagiário"}>
                                                  Estagiário{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Pessoa contratada para realizar serviços específicos, sem ser um empregado direto">
                                                <Radio
                                                  value={"Prestador de Serviço"}
                                                >
                                                  Prestador de Serviço{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Pessoa que trabalha em alguma capacidade governamental ou para uma entidade do setor público">
                                                <Radio
                                                  value={"Servidor Público"}
                                                >
                                                  Servidor Público{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Pessoa que oferece seu tempo e habilidades sem receber pagamento">
                                                <Radio value={"Voluntário"}>
                                                  Voluntário{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Indivíduo  que possui uma parte do capital social da sua empresa, tendo interesse direto no seu desempenho financeiro.">
                                                <Radio value={"Acionista"}>
                                                  Acionista{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Pessoa que visita as instalações da sua empresa, podendo ser por motivos profissionais, comerciais ou como convidado.">
                                                <Radio value={"Visitante"}>
                                                  Visitante{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Indivíduo matriculado para estudar em instituições de ensino associadas ou oferecidas pela sua organização">
                                                <Radio value={"Aluno"}>
                                                  Aluno{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                              <Tooltip title="Indivíduo que recebe cuidados ou tratamentos médicos dos serviços de saúde associados à sua empresa.">
                                                <Radio value={"Paciente"}>
                                                  Paciente{" "}
                                                  <InfoCircle
                                                    style={{ marginBottom: -2 }}
                                                    size={15}
                                                  />
                                                </Radio>
                                              </Tooltip>
                                            </Space>
                                          </Radio.Group>
                                          <div style={{ height: "0.4rem" }} />
                                          <Space
                                            style={{
                                              marginBottom: "1rem",
                                            }}
                                          >
                                            <Radio
                                              checked={
                                                categoriasPermitidas.find(
                                                  (c: any) =>
                                                    c === titular.categoria
                                                )
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <Input
                                                size="small"
                                                placeholder="Outro"
                                                maxLength={500}
                                                showCount
                                                style={{ width: 300 }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.categoria =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  categoriasPermitidas.find(
                                                    (c: any) =>
                                                      c === titular.categoria
                                                  )
                                                    ? ""
                                                    : titular.categoria
                                                }
                                              />
                                            </Radio>
                                          </Space>
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                                <Collapse
                                  className={
                                    titular.quantidade ? "collapse_done" : ""
                                  }
                                  items={[
                                    {
                                      key: "quantidade",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.quantidade
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Número estimado de titulares{" "}
                                          <Tooltip title="É a quantidade aproximada de pessoas cujos dados pessoais são tratados neste subprocesso">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),

                                      children: (
                                        <>
                                          <Radio.Group
                                            onChange={(value) => {
                                              moduloMapeamento.setCurrentTitulares(
                                                (prevTitulares: Titular[]) => {
                                                  let currentTitular = titular;
                                                  currentTitular.quantidade =
                                                    value.target.value;

                                                  return [
                                                    ...prevTitulares.filter(
                                                      (t) =>
                                                        t.key !== titular.key
                                                    ),
                                                    currentTitular,
                                                  ];
                                                }
                                              );
                                            }}
                                            value={titular.quantidade}
                                          >
                                            <Space direction="vertical">
                                              <Radio value={"1 a 99 pessoas"}>
                                                1 a 99 pessoas
                                              </Radio>
                                              <Radio
                                                value={"100 a 999 pessoas"}
                                              >
                                                100 a 999 pessoas
                                              </Radio>
                                              <Radio
                                                value={"1.000 a 9.999 pessoas"}
                                              >
                                                1.000 a 9.999 pessoas
                                              </Radio>
                                              <Radio
                                                value={
                                                  "10.000 a 99.999 pessoas"
                                                }
                                              >
                                                10.000 a 99.999 pessoas
                                              </Radio>
                                              <Radio
                                                value={
                                                  "100.000 a 999.999 pessoas"
                                                }
                                              >
                                                100.000 a 999.999 pessoas
                                              </Radio>
                                              <Radio
                                                value={"+ 1.000.000 pessoas"}
                                              >
                                                + 1.000.000 pessoas
                                              </Radio>
                                              <Radio
                                                value={"Não é possivel estimar"}
                                              >
                                                Não é possivel estimar
                                              </Radio>
                                            </Space>
                                          </Radio.Group>
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                                <Collapse
                                  className={
                                    titular.faixa_etaria?.[0]
                                      ? "collapse_done"
                                      : ""
                                  }
                                  items={[
                                    {
                                      key: "3",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.faixa_etaria?.[0]
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Faixa etária dos titulares{" "}
                                          <Tooltip title="É a classificação das pessoas (titulares) de acordo com a sua idade">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),
                                      children: (
                                        <Checkbox.Group
                                          onChange={(value) => {
                                            moduloMapeamento.setCurrentTitulares(
                                              (prevTitulares: Titular[]) => {
                                                let currentTitular = titular;
                                                currentTitular.faixa_etaria =
                                                  value;

                                                return [
                                                  ...prevTitulares.filter(
                                                    (t) => t.key !== titular.key
                                                  ),
                                                  currentTitular,
                                                ];
                                              }
                                            );
                                          }}
                                          value={titular.faixa_etaria}
                                        >
                                          <Space direction="vertical">
                                            <Checkbox
                                              value={
                                                "Crianças (até 12 anos incompletos)"
                                              }
                                            >
                                              Crianças (até 12 anos incompletos)
                                            </Checkbox>
                                            <Checkbox
                                              value={
                                                "Adolescentes (13 a 18 anos)"
                                              }
                                            >
                                              Adolescentes (13 a 18 anos)
                                            </Checkbox>
                                            <Checkbox
                                              value={"Adultos (18 a 60 anos)"}
                                            >
                                              Adultos (18 a 60 anos)
                                            </Checkbox>
                                            <Checkbox
                                              value={"Idosos (+ de 60 anos)"}
                                            >
                                              Idosos (+ de 60 anos)
                                            </Checkbox>
                                          </Space>
                                        </Checkbox.Group>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                              <div style={{ width: "45%" }}>
                                <Collapse
                                  className={
                                    titular.dados?.[0] ? "collapse_done" : ""
                                  }
                                  items={[
                                    {
                                      key: "4",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.dados?.[0]
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Tipos de dados pessoais coletados{" "}
                                          <Tooltip title="São todos os dados pessoais que você coleta ou trata neste subprocesso">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),
                                      children: (
                                        <>
                                          {categoriasUnicas?.map(
                                            (categoria: any) => (
                                              <Collapse
                                                className="collapse_ghost"
                                                ghost
                                                items={[
                                                  {
                                                    key: "4",
                                                    label: categoria,
                                                    children:
                                                      moduloMapeamento?.dados
                                                        ?.filter(
                                                          (dado: any) =>
                                                            dado.categoria ===
                                                            categoria
                                                        )
                                                        ?.map((dado: any) => (
                                                          <div
                                                            style={{
                                                              marginBottom:
                                                                "0.5rem",
                                                            }}
                                                          >
                                                            <Button
                                                              size="small"
                                                              icon={
                                                                titular?.dados?.find(
                                                                  (d) =>
                                                                    d.dado_id ===
                                                                    dado._id
                                                                ) ? (
                                                                  <CheckCircleFilled
                                                                    style={{
                                                                      color:
                                                                        "#5F29CC",
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  <PlusCircleOutlined />
                                                                )
                                                              }
                                                              onClick={() => {
                                                                moduloMapeamento.setCurrentTitulares(
                                                                  (
                                                                    prevTitulares: Titular[]
                                                                  ) => {
                                                                    let currentTitular =
                                                                      titular;
                                                                    if (
                                                                      titular?.dados?.find(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado._id
                                                                      )
                                                                    ) {
                                                                      // remove
                                                                      currentTitular.dados =
                                                                        currentTitular.dados?.filter(
                                                                          (d) =>
                                                                            d.dado_id !==
                                                                            dado._id
                                                                        );
                                                                    } else {
                                                                      // Adiciona
                                                                      currentTitular.dados =
                                                                        currentTitular.dados
                                                                          ? [
                                                                              ...currentTitular.dados.filter(
                                                                                (
                                                                                  d
                                                                                ) =>
                                                                                  d.dado_id !==
                                                                                  dado._id
                                                                              ),
                                                                              {
                                                                                dado_id:
                                                                                  dado._id,
                                                                              },
                                                                            ]
                                                                          : [
                                                                              {
                                                                                dado_id:
                                                                                  dado._id,
                                                                              },
                                                                            ];
                                                                    }
                                                                    return [
                                                                      ...prevTitulares.filter(
                                                                        (t) =>
                                                                          t.key !==
                                                                          titular.key
                                                                      ),
                                                                      currentTitular,
                                                                    ];
                                                                  }
                                                                );
                                                              }}
                                                            >
                                                              {dado.nome}
                                                            </Button>
                                                            {titular?.dados?.find(
                                                              (d) =>
                                                                d.dado_id ===
                                                                dado._id
                                                            ) && (
                                                              <>
                                                                <Tooltip title="Origem do Dado é onde as informações pessoais são coletadas pela primeira vez">
                                                                  <Select
                                                                    size="small"
                                                                    style={{
                                                                      width: 150,
                                                                      marginLeft:
                                                                        "1rem",
                                                                    }}
                                                                    placeholder="Origem do Dado"
                                                                    value={
                                                                      titular?.dados?.find(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado._id
                                                                      )?.origem
                                                                    }
                                                                    onChange={(
                                                                      value: string
                                                                    ) => {
                                                                      moduloMapeamento.setCurrentTitulares(
                                                                        (
                                                                          prevTitulares: Titular[]
                                                                        ) => {
                                                                          let currentTitular =
                                                                            titular;

                                                                          if (
                                                                            currentTitular.dados
                                                                          ) {
                                                                            // Check if the dado exists in the array
                                                                            const existingDadoIndex =
                                                                              currentTitular.dados.findIndex(
                                                                                (
                                                                                  d
                                                                                ) =>
                                                                                  d.dado_id ===
                                                                                  dado._id
                                                                              );

                                                                            if (
                                                                              existingDadoIndex !==
                                                                              -1
                                                                            ) {
                                                                              // se dado existe, update origem
                                                                              currentTitular.dados[
                                                                                existingDadoIndex
                                                                              ].origem =
                                                                                value;
                                                                            }
                                                                          }
                                                                          return [
                                                                            ...prevTitulares.filter(
                                                                              (
                                                                                t
                                                                              ) =>
                                                                                t.key !==
                                                                                titular.key
                                                                            ),
                                                                            currentTitular,
                                                                          ];
                                                                        }
                                                                      );
                                                                    }}
                                                                    options={[
                                                                      {
                                                                        value:
                                                                          "Fornecido pelo titular de dados",
                                                                        label:
                                                                          "Fornecido pelo " +
                                                                          (titular?.categoria ||
                                                                            "titular de dados"),
                                                                      },
                                                                      {
                                                                        value:
                                                                          "Fornecidos por terceiros",
                                                                        label:
                                                                          "Fornecidos por terceiros",
                                                                      },
                                                                      {
                                                                        value:
                                                                          "Fornecidos por outro departamento ou área interna",
                                                                        label:
                                                                          "Fornecidos por outro departamento ou área interna",
                                                                      },
                                                                    ]}
                                                                  />
                                                                </Tooltip>
                                                                {titular.dados.find(
                                                                  (d) =>
                                                                    d.dado_id ===
                                                                    dado._id
                                                                )?.origem ===
                                                                  "Fornecidos por terceiros" && (
                                                                  <Input
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      marginTop:
                                                                        "0.25rem",
                                                                    }}
                                                                    maxLength={
                                                                      500
                                                                    }
                                                                    placeholder="Informe o nome do departamento interno"
                                                                    value={
                                                                      titular?.dados?.find(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado._id
                                                                      )
                                                                        ?.nome_dep_interno
                                                                    }
                                                                    onChange={(
                                                                      value
                                                                    ) => {
                                                                      moduloMapeamento.setCurrentTitulares(
                                                                        (
                                                                          prevTitulares: Titular[]
                                                                        ) => {
                                                                          let currentTitular =
                                                                            titular;

                                                                          if (
                                                                            currentTitular.dados
                                                                          ) {
                                                                            // Check if the dado exists in the array
                                                                            const existingDadoIndex =
                                                                              currentTitular.dados.findIndex(
                                                                                (
                                                                                  d
                                                                                ) =>
                                                                                  d.dado_id ===
                                                                                  dado._id
                                                                              );

                                                                            if (
                                                                              existingDadoIndex !==
                                                                              -1
                                                                            ) {
                                                                              // se dado existe, update origem
                                                                              currentTitular.dados[
                                                                                existingDadoIndex
                                                                              ].nome_dep_interno =
                                                                                value.target.value;
                                                                            }
                                                                          }
                                                                          return [
                                                                            ...prevTitulares.filter(
                                                                              (
                                                                                t
                                                                              ) =>
                                                                                t.key !==
                                                                                titular.key
                                                                            ),
                                                                            currentTitular,
                                                                          ];
                                                                        }
                                                                      );
                                                                    }}
                                                                  />
                                                                )}
                                                                {titular.dados.find(
                                                                  (d) =>
                                                                    d.dado_id ===
                                                                    dado._id
                                                                )?.origem ===
                                                                  "Fornecido pelo titular de dados" && (
                                                                  <Select
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      marginTop:
                                                                        "0.25rem",
                                                                    }}
                                                                    placeholder="Selecione"
                                                                    value={
                                                                      titular?.dados?.find(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado._id
                                                                      )
                                                                        ?.consentimento_titular
                                                                    }
                                                                    onChange={(
                                                                      value: string
                                                                    ) => {
                                                                      moduloMapeamento.setCurrentTitulares(
                                                                        (
                                                                          prevTitulares: Titular[]
                                                                        ) => {
                                                                          let currentTitular =
                                                                            titular;

                                                                          if (
                                                                            currentTitular.dados
                                                                          ) {
                                                                            // Check if the dado exists in the array
                                                                            const existingDadoIndex =
                                                                              currentTitular.dados.findIndex(
                                                                                (
                                                                                  d
                                                                                ) =>
                                                                                  d.dado_id ===
                                                                                  dado._id
                                                                              );

                                                                            if (
                                                                              existingDadoIndex !==
                                                                              -1
                                                                            ) {
                                                                              // se dado existe, update origem
                                                                              currentTitular.dados[
                                                                                existingDadoIndex
                                                                              ].consentimento_titular =
                                                                                value;
                                                                            }
                                                                          }
                                                                          return [
                                                                            ...prevTitulares.filter(
                                                                              (
                                                                                t
                                                                              ) =>
                                                                                t.key !==
                                                                                titular.key
                                                                            ),
                                                                            currentTitular,
                                                                          ];
                                                                        }
                                                                      );
                                                                    }}
                                                                    options={[
                                                                      {
                                                                        value:
                                                                          "O Titular não sabe que seus dados estão sendo usados",
                                                                        label:
                                                                          "O Titular não sabe que seus dados estão sendo usados",
                                                                      },
                                                                      {
                                                                        value:
                                                                          "O Titular sabe, mas não autorizou o uso",
                                                                        label:
                                                                          "O Titular sabe, mas não autorizou o uso",
                                                                      },
                                                                      {
                                                                        value:
                                                                          "O Titular sabe e autorizou o uso dos dados",
                                                                        label:
                                                                          "O Titular sabe e autorizou o uso dos dados",
                                                                      },
                                                                    ]}
                                                                  />
                                                                )}
                                                              </>
                                                            )}

                                                            {titular?.dados?.find(
                                                              (d) =>
                                                                d.dado_id ===
                                                                  dado._id &&
                                                                dado.tipo ===
                                                                  "sensivel"
                                                            ) && (
                                                              <Input
                                                                style={{
                                                                  marginTop:
                                                                    "0.25rem",
                                                                }}
                                                                placeholder="Adicione o motivo deste dado ser indispensável"
                                                                value={
                                                                  titular?.dados?.find(
                                                                    (d) =>
                                                                      d.dado_id ===
                                                                      dado._id
                                                                  )?.motivo
                                                                }
                                                                onChange={(
                                                                  event: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                  moduloMapeamento.setCurrentTitulares(
                                                                    (
                                                                      prevTitulares: Titular[]
                                                                    ) => {
                                                                      let currentTitular =
                                                                        titular;

                                                                      if (
                                                                        currentTitular.dados
                                                                      ) {
                                                                        // Check if the dado exists in the array
                                                                        const existingDadoIndex =
                                                                          currentTitular.dados.findIndex(
                                                                            (
                                                                              d
                                                                            ) =>
                                                                              d.dado_id ===
                                                                              dado._id
                                                                          );

                                                                        if (
                                                                          existingDadoIndex !==
                                                                          -1
                                                                        ) {
                                                                          // se dado existe, update motivo
                                                                          currentTitular.dados[
                                                                            existingDadoIndex
                                                                          ].motivo =
                                                                            event.target.value;
                                                                        }
                                                                      }
                                                                      return [
                                                                        ...prevTitulares.filter(
                                                                          (t) =>
                                                                            t.key !==
                                                                            titular.key
                                                                        ),
                                                                        currentTitular,
                                                                      ];
                                                                    }
                                                                  );
                                                                }}
                                                              />
                                                            )}
                                                          </div>
                                                        )),
                                                  },
                                                ]}
                                              />
                                            )
                                          )}
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <hr />

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ width: "45%" }}>
                                <Collapse
                                  className={
                                    titular.finalidade_coleta
                                      ? "collapse_done"
                                      : ""
                                  }
                                  items={[
                                    {
                                      key: "4",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.finalidade_coleta
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Finalidade e Necessidade{" "}
                                          <Tooltip title="Descrever por quais motivos os dados são coletados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),
                                      children: (
                                        <>
                                          <TextArea
                                            style={{ marginBottom: "1rem" }}
                                            onChange={(value) => {
                                              moduloMapeamento.setCurrentTitulares(
                                                (prevTitulares: Titular[]) => {
                                                  let currentTitular = titular;
                                                  currentTitular.finalidade_coleta =
                                                    value.target.value;

                                                  return [
                                                    ...prevTitulares.filter(
                                                      (t) =>
                                                        t.key !== titular.key
                                                    ),
                                                    currentTitular,
                                                  ];
                                                }
                                              );
                                            }}
                                            value={titular.finalidade_coleta}
                                            autoSize={{ minRows: 3 }}
                                            showCount
                                            maxLength={5000}
                                          />
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                              <div style={{ width: "45%" }}>
                                <Collapse
                                  className={
                                    titular.medidas_coleta
                                      ? "collapse_done"
                                      : ""
                                  }
                                  items={[
                                    {
                                      key: "4",
                                      label: (
                                        <p
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            color: titular.medidas_coleta
                                              ? "#000"
                                              : "#fff",
                                          }}
                                        >
                                          Medidas de Segurança{" "}
                                          <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais coletados.">
                                            <InfoCircle
                                              size={16}
                                              style={{
                                                marginLeft: "0.5rem",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                      ),
                                      children: (
                                        <>
                                          <TextArea
                                            style={{ marginBottom: "1rem" }}
                                            onChange={(value) => {
                                              moduloMapeamento.setCurrentTitulares(
                                                (prevTitulares: Titular[]) => {
                                                  let currentTitular = titular;
                                                  currentTitular.medidas_coleta =
                                                    value.target.value;

                                                  return [
                                                    ...prevTitulares.filter(
                                                      (t) =>
                                                        t.key !== titular.key
                                                    ),
                                                    currentTitular,
                                                  ];
                                                }
                                              );
                                            }}
                                            value={titular.medidas_coleta}
                                            autoSize={{ minRows: 3 }}
                                            showCount
                                            maxLength={5000}
                                          />
                                        </>
                                      ),
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                            <br />

                            <p>
                              Deixe aqui{" "}
                              <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
                                <ExclamationCircleOutlined />
                              </Tooltip>
                            </p>

                            <TextArea
                              style={{ marginTop: "0.5rem" }}
                              onChange={(value) => {
                                moduloMapeamento.setCurrentTitulares(
                                  (prevTitulares: Titular[]) => {
                                    let currentTitular = titular;
                                    currentTitular.obs_coleta =
                                      value.target.value;

                                    return [
                                      ...prevTitulares.filter(
                                        (t) => t.key !== titular.key
                                      ),
                                      currentTitular,
                                    ];
                                  }
                                );
                              }}
                              value={titular.obs_coleta}
                              // onChange={(e) => setValue(e.target.value)}
                              autoSize={{ minRows: 3 }}
                              showCount
                              maxLength={5000}
                            />
                            <br />
                            <Button
                              style={{
                                background: "#32E6BE",
                                color: "#333333",
                                marginTop: "1rem",
                              }}
                              icon={icon}
                              type="primary"
                              loading={loadingTitulares}
                              onClick={() => {
                                setLoadingTitulares(true);
                                setIcon(<SaveOutlined />);

                                moduloMapeamento
                                  .saveTitulares(
                                    moduloMapeamento.currentTitulares
                                  )
                                  .then(() => {
                                    setIcon(<CheckOutlined />);
                                  })
                                  .catch(() => {
                                    setIcon(<CloseOutlined />);
                                  })
                                  .finally(() => setLoadingTitulares(false));
                              }}
                            >
                              Salvar
                            </Button>
                          </>
                        ),
                      },
                    ]}
                  />
                );
              })
          ) : (
            <h5 style={{ fontSize: 18, color: "#3D364D" }}>
              Nenhum titular cadastrado
            </h5>
          )}
          <br />
          <Button
            onClick={() => {
              moduloMapeamento.setCurrentTitulares(
                (prevTitulares: Titular[] | null) => {
                  const newTitular: Titular = {
                    relacao_id: processo.questionarioCapitulo._id,
                    key: moduloMapeamento.currentTitulares?.length || 0,
                  };

                  if (prevTitulares === null) {
                    return [newTitular];
                  } else {
                    return [...prevTitulares, newTitular];
                  }
                }
              );
            }}
            type="primary"
            style={{ background: "#5F29CC" }}
          >
            Cadastrar titular <PlusCircleOutlined />
          </Button>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span
          style={{
            color: moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0]?.frequencia &&
                (titular.dados?.[0].bases_legais_comuns ||
                  titular.dados?.[0].bases_legais_sensiveis) &&
                titular.relacao_id === processo?.questionarioCapitulo?._id &&
                titular.finalidade_processamento &&
                titular.medidas_processamento
            )
              ? "#008C76"
              : "",
          }}
        >
          2. Processamento{" "}
          {moduloMapeamento?.currentTitulares?.find(
            (titular) =>
              titular.dados?.[0]?.frequencia &&
              (titular.dados?.[0].bases_legais_comuns ||
                titular.dados?.[0].bases_legais_sensiveis) &&
              titular.relacao_id === processo?.questionarioCapitulo?._id &&
              titular.finalidade_processamento &&
              titular.medidas_processamento
          ) && <CheckCircleOutlined style={{ marginLeft: "0.2rem" }} />}
        </span>
      ),
      children: (
        <div className="tabs_mapeamento">
          <TitularTitle />

          {moduloMapeamento.currentTitulares ? (
            moduloMapeamento.currentTitulares
              .filter(
                (titular: Titular) =>
                  titular.relacao_id === processo?.questionarioCapitulo?._id
              )
              .map((titular: Titular) => {
                return (
                  <Collapse
                    defaultActiveKey={"1"}
                    items={[
                      {
                        key: "1",
                        extra: loadingTitulares ? (
                          <Spin size="small" />
                        ) : (
                          <Popconfirm
                            title="Você tem certeza que deseja excluir este item?"
                            onConfirm={() => handleDeleteTitular(titular)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <DeleteOutlined
                              style={{ marginRight: "1rem", cursor: "pointer" }}
                            />
                          </Popconfirm>
                        ),
                        label: (
                          <p style={{ fontSize: 18, color: "#fff" }}>
                            {`${truncateDescription(
                              moduloProcessos.areas?.find(
                                (a) =>
                                  a._id ===
                                  (processo.dependencia
                                    ? mapeamento?.capitulos?.find(
                                        (c: any) =>
                                          c._id === processo.dependencia
                                      )?.tags?.[0]
                                    : processo.tags?.[0])
                              )?.description,
                              20
                            )} ${truncateDescription(
                              moduloProcessos?.capitulos?.find(
                                (c) => c._id === processo.dependencia
                              )?.description,
                              20
                            )} > ${truncateDescription(
                              processo.description,
                              20
                            )} > ` + (titular.categoria || "Novo titular")}
                          </p>
                        ),
                        children: titular.dados?.[0] ? (
                          <>
                            {showEditProcessamento && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Switch
                                    onClick={(v) => {
                                      if (v && titular.dados) {
                                        setSelectedDados(titular.dados);
                                      } else {
                                        setSelectedDados(null);
                                      }
                                    }}
                                    checked={
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? true
                                        : false
                                    }
                                  />
                                  <h5 style={{ marginLeft: "0.5rem" }}>
                                    As informações de processamento se aplicam a
                                    todos os dados pessoais cadastrados?
                                  </h5>
                                </div>
                                <br />
                              </>
                            )}
                            {showEditProcessamento &&
                              titular.dados?.map((dado) => (
                                <Button
                                  size="small"
                                  style={{
                                    background:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )
                                          ? "#5F29CC"
                                          : "#32E6BE"
                                        : "#5F29CC",
                                    color:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                          ? "#fff"
                                          : "#333"
                                        : selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                        ? "#fff"
                                        : "#333",
                                    marginRight: "0.5rem",
                                    marginBottom: "0.5rem",
                                    opacity:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.frequencia &&
                                          (titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_comuns ||
                                            titular?.dados?.find(
                                              (d) => d.dado_id === dado.dado_id
                                            )?.bases_legais_sensiveis)
                                          ? 1
                                          : 0.4
                                        : 1,
                                    cursor:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? "default"
                                        : "pointer",
                                  }}
                                  icon={
                                    titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.frequencia &&
                                    (titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.bases_legais_comuns ||
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_sensiveis) ? (
                                      selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                        <DownCircleOutlined />
                                      ) : (
                                        <CheckCircleOutlined />
                                      )
                                    ) : selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                      <RightCircleOutlined />
                                    ) : (
                                      <PlusCircleOutlined />
                                    )
                                  }
                                  type={
                                    selectedDados?.find(
                                      (d: any) => d.dado_id === dado.dado_id
                                    )
                                      ? "primary"
                                      : titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.frequencia &&
                                        (titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_comuns ||
                                          titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_sensiveis)
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() => {
                                    setSelectedDados((prev) => {
                                      if (
                                        selectedDados?.find(
                                          (d: any) => d.dado_id === dado.dado_id
                                        )
                                      ) {
                                        // remove
                                        if (prev) {
                                          return [
                                            ...prev.filter(
                                              (d) => d.dado_id !== dado.dado_id
                                            ),
                                          ];
                                        } else {
                                          return null;
                                        }
                                      } else {
                                        // adiciona
                                        if (prev) {
                                          return [
                                            ...prev.filter(
                                              (d) => d.dado_id !== dado.dado_id
                                            ),
                                            dado,
                                          ];
                                        } else {
                                          return [dado];
                                        }
                                      }
                                    });
                                  }}
                                >
                                  {
                                    moduloMapeamento.dados?.find(
                                      (d: any) => d._id === dado.dado_id
                                    )?.nome
                                  }
                                </Button>
                              ))}
                            {selectedDados?.[0] && showEditProcessamento && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1rem",
                                }}
                              >
                                <div style={{ width: "45%" }}>
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color: "#fff",
                                            }}
                                          >
                                            Frequência do processamento{" "}
                                            <Tooltip title="É a periodicidade com que os dados pessoas serão acessados e processados">
                                              <InfoCircle
                                                size={16}
                                                style={{
                                                  marginLeft: "0.5rem",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        ),
                                        children: (
                                          <Radio.Group
                                            onChange={(event) => {
                                              moduloMapeamento.setCurrentTitulares(
                                                (prevTitulares: Titular[]) => {
                                                  let currentTitular = titular;
                                                  selectedDados?.forEach(
                                                    (dado) => {
                                                      const dadoIndex:
                                                        | number
                                                        | undefined =
                                                        currentTitular.dados?.findIndex(
                                                          (d) =>
                                                            d.dado_id ===
                                                            dado.dado_id
                                                        );
                                                      if (
                                                        dadoIndex !==
                                                          undefined &&
                                                        currentTitular?.dados?.[
                                                          dadoIndex
                                                        ]
                                                      ) {
                                                        currentTitular.dados[
                                                          dadoIndex
                                                        ] = {
                                                          ...currentTitular
                                                            .dados[dadoIndex],
                                                          frequencia:
                                                            event.target.value,
                                                        };
                                                      }
                                                    }
                                                  );
                                                  return [
                                                    ...prevTitulares.filter(
                                                      (t) =>
                                                        t.key !== titular.key
                                                    ),
                                                    currentTitular,
                                                  ];
                                                }
                                              );
                                            }}
                                            value={
                                              titular?.dados?.find(
                                                (d) =>
                                                  d.dado_id ===
                                                  selectedDados?.[0]?.dado_id
                                              )?.frequencia
                                            }
                                          >
                                            <Space direction="vertical">
                                              <Radio
                                                value={
                                                  "Contínuo ou em tempo real"
                                                }
                                              >
                                                <Tooltip title="Você acessa ou utiliza os dados a todo momento ou em tempo real.">
                                                  Contínuo ou em tempo real
                                                </Tooltip>
                                              </Radio>
                                              <Radio value={"Semanal"}>
                                                <Tooltip title="Você acessa ou utiliza os dados semanalmente.">
                                                  Semanal
                                                </Tooltip>
                                              </Radio>
                                              <Radio value={"Mensal"}>
                                                <Tooltip title="Você acessa ou utiliza os dados cadastrados mensalmente.">
                                                  Mensal
                                                </Tooltip>
                                              </Radio>
                                              <Radio
                                                value={"Semestral ou Anual"}
                                              >
                                                <Tooltip title="Você geralmente acessa ou utiliza os dados a cada 6 meses a 1 ano.">
                                                  Semestral ou Anual
                                                </Tooltip>
                                              </Radio>
                                              <Radio value={"Uma única vez"}>
                                                <Tooltip title="Você acessa ou utiliza os dados apenas uma vez.">
                                                  Uma única vez
                                                </Tooltip>
                                              </Radio>
                                            </Space>
                                          </Radio.Group>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>

                                <div style={{ width: "45%" }}>
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color: "#fff",
                                            }}
                                          >
                                            Bases Legais{" "}
                                            <Tooltip title="São as justificativas legais previstas pela LGPD que permitem que sua empresa processe dados pessoais de indivíduos.">
                                              <InfoCircle
                                                size={16}
                                                style={{
                                                  marginLeft: "0.5rem",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        ),
                                        children: (
                                          <div>
                                            <p
                                              style={{
                                                marginBottom: "0.5rem",
                                              }}
                                            >
                                              Dados comuns{" "}
                                              <Tooltip
                                                overlayStyle={{
                                                  maxWidth: "500px",
                                                }}
                                                title={
                                                  <>
                                                    <ul>
                                                      <li>
                                                        Consentimento pelo
                                                        titular (i) Art. 7º, I,
                                                        LGPD
                                                      </li>
                                                      <li>
                                                        Cumprimento de obrigação
                                                        legal ou regulatória (i)
                                                        Art. 7º, II, LGPD
                                                      </li>
                                                      <li>
                                                        Para execução de
                                                        Políticas Públicas
                                                        previstas em leis e
                                                        regulamentos (i) Art.
                                                        7º, III, LGPD
                                                      </li>
                                                      <li>
                                                        Realização de Estudos
                                                        por órgãos de pesquisa
                                                        (i) Art. 7º, IV, LGPD
                                                      </li>
                                                      <li>
                                                        Execução de contratos e
                                                        procedimentos
                                                        preliminares (i) Art.
                                                        7º, V, LGPD
                                                      </li>
                                                      <li>
                                                        Exercício regular de
                                                        direitos em processo
                                                        judicial, administrativo
                                                        ou arbitral (i) Art. 7º,
                                                        VI, LGPD
                                                      </li>
                                                      <li>
                                                        Proteção da vida e da
                                                        incolumidade física do
                                                        titular ou de terceiro
                                                        (i) Art. 7º, VII, LGPD
                                                      </li>
                                                      <li>
                                                        Tutela de Saúde
                                                        exclusivamente ... (i)
                                                        Art. 7º, VIII, LGPD
                                                      </li>
                                                      <li>
                                                        Legítimo Interesse (i)
                                                        Art. 7º, XI, LGPD
                                                      </li>
                                                      <li>
                                                        Proteção ao crédito (i)
                                                        Art. 7º, X, LGPD
                                                      </li>
                                                    </ul>
                                                  </>
                                                }
                                              >
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                            <Select
                                              style={{ width: "100%" }}
                                              placeholder="Selecione a base legal"
                                              allowClear
                                              value={
                                                titular?.dados.filter(
                                                  (dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                )?.[0]?.bases_legais_comuns
                                              }
                                              onChange={(value: string) => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          const dado =
                                                            currentTitular
                                                              .dados[dadoIndex];
                                                          dado.bases_legais_comuns =
                                                            value;
                                                        }
                                                      }
                                                    );

                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              options={[
                                                {
                                                  value:
                                                    "Consentimento pelo titular",
                                                  label:
                                                    "Consentimento pelo titular",
                                                },
                                                {
                                                  value:
                                                    "Cumprimento de obrigação legal ou regulatória",
                                                  label:
                                                    "Cumprimento de obrigação legal ou regulatória",
                                                },
                                                {
                                                  value:
                                                    "Para execução de políticas públicas previstas em leis ou regulamentos",
                                                  label:
                                                    "Para execução de políticas públicas previstas em leis ou regulamentos",
                                                },
                                                {
                                                  value:
                                                    "Realização de estudos por órgão de pesquisa ",
                                                  label:
                                                    "Realização de estudos por órgão de pesquisa ",
                                                },
                                                {
                                                  value:
                                                    "Execução de contrato ou de procedimentos preliminares ",
                                                  label:
                                                    "Execução de contrato ou de procedimentos preliminares ",
                                                },
                                                {
                                                  value:
                                                    "Exercício regular de direitos em processo judicial, administrativo ou arbitral",
                                                  label:
                                                    "Exercício regular de direitos em processo judicial, administrativo ou arbitral",
                                                },
                                                {
                                                  value:
                                                    "Proteção da vida ou da incolumidade física do titular ou de terceiro",
                                                  label:
                                                    "Proteção da vida ou da incolumidade física do titular ou de terceiro",
                                                },
                                                {
                                                  value:
                                                    "Tutela da saúde, exclusivamente, em procedimento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária.",
                                                  label:
                                                    "Tutela da saúde, exclusivamente, em procedimento realizado por profissionais da saúde, serviços de saúde ou autoridade sanitária.",
                                                },
                                                {
                                                  value: "Legítimo Interesse",
                                                  label: "Legítimo Interesse",
                                                },
                                                {
                                                  value: "Proteção do crédito",
                                                  label: "Proteção do crédito",
                                                },
                                              ]}
                                            />
                                            <p
                                              style={{
                                                marginBottom: "0.5rem",
                                                marginTop: "1rem",
                                              }}
                                            >
                                              Dados sensíveis{" "}
                                              <Tooltip
                                                overlayStyle={{
                                                  maxWidth: "500px",
                                                }}
                                                title={
                                                  <>
                                                    <ul>
                                                      <li>
                                                        Consentimento pelo
                                                        titular (i) Art. 11, I,
                                                        LGPD
                                                      </li>
                                                      <li>
                                                        Cumprimento de obrigação
                                                        legal ou regulatória (i)
                                                        Art. 11, II, “a” LGPD
                                                      </li>
                                                      <li>
                                                        Para execução de
                                                        Políticas Públicas
                                                        previstas em leis e
                                                        regulamentos (i) Art.
                                                        11, II, “b” LGPD
                                                      </li>
                                                      <li>
                                                        Realização de Estudos
                                                        por órgãos de pesquisa
                                                        (i) Art. 11, II, “c”
                                                        LGPD
                                                      </li>
                                                      <li>
                                                        Exercício regular de
                                                        direitos em processo
                                                        judicial, administrativo
                                                        ou arbitral (i) Art. 11,
                                                        II, “d” LGPD
                                                      </li>
                                                      <li>
                                                        Proteção da vida e da
                                                        incolumidade física do
                                                        titular ou de terceiro
                                                        (i) Art. 11, II, “e”
                                                        LGPD
                                                      </li>
                                                      <li>
                                                        Tutela de Saúde
                                                        exclusivamente ... (i)
                                                        Art. 11, II, “f” LGPD
                                                      </li>
                                                      <li>
                                                        Garantia da prevenção à
                                                        fraude e à segurança do
                                                        titular (i) Art. 11, II,
                                                        “g” LGPD
                                                      </li>
                                                    </ul>
                                                  </>
                                                }
                                              >
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                            <Select
                                              style={{ width: "100%" }}
                                              allowClear
                                              placeholder="Selecione a base legal"
                                              value={
                                                titular?.dados.filter(
                                                  (dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                )?.[0]?.bases_legais_sensiveis
                                              }
                                              onChange={(value: string) => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          const dado =
                                                            currentTitular
                                                              .dados[dadoIndex];
                                                          dado.bases_legais_sensiveis =
                                                            value;
                                                        }
                                                      }
                                                    );

                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              options={[
                                                {
                                                  value: "Consentimento",
                                                  label: "Consentimento",
                                                },
                                                {
                                                  value:
                                                    "Cumprimento de obrigação legal ou regulatória pelo controlador;",
                                                  label:
                                                    "Cumprimento de obrigação legal ou regulatória pelo controlador;",
                                                },
                                                {
                                                  value:
                                                    "Compartilhamento de dados necessários à execução, pela administração pública, de políticas públicas previstas em leis ou regulamentos;",
                                                  label:
                                                    "Compartilhamento de dados necessários à execução, pela administração pública, de políticas públicas previstas em leis ou regulamentos;",
                                                },
                                                {
                                                  value:
                                                    "Realização de estudos por órgão de pesquisa; ",
                                                  label:
                                                    "Realização de estudos por órgão de pesquisa; ",
                                                },
                                                {
                                                  value:
                                                    "Exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral;",
                                                  label:
                                                    "Exercício regular de direitos, inclusive em contrato e em processo judicial, administrativo e arbitral;",
                                                },
                                                {
                                                  value:
                                                    "Proteção da vida ou da incolumidade física do titular ou de terceiros;",
                                                  label:
                                                    "Proteção da vida ou da incolumidade física do titular ou de terceiros;",
                                                },
                                                {
                                                  value:
                                                    "Tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;",
                                                  label:
                                                    "Tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias;",
                                                },
                                                {
                                                  value:
                                                    "Garantia da prevenção à fraude e à segurança do titular, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos",
                                                  label:
                                                    "Garantia da prevenção à fraude e à segurança do titular, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos",
                                                },
                                              ]}
                                            />
                                          </div>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            )}
                            {showEditProcessamento && (
                              <>
                                <hr />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.finalidade_processamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.finalidade_processamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Finalidade e Necessidade{" "}
                                              <Tooltip title="Descrever por quais motivos os dados são processados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.finalidade_processamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.finalidade_processamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.medidas_processamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.medidas_processamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Medidas de Segurança{" "}
                                              <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais processados.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.medidas_processamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.medidas_processamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />

                                <p>
                                  Deixe aqui{" "}
                                  <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
                                    <ExclamationCircleOutlined />
                                  </Tooltip>
                                </p>

                                <TextArea
                                  style={{ marginTop: "0.5rem" }}
                                  onChange={(value) => {
                                    moduloMapeamento.setCurrentTitulares(
                                      (prevTitulares: Titular[]) => {
                                        let currentTitular = titular;
                                        currentTitular.obs_processamento =
                                          value.target.value;

                                        return [
                                          ...prevTitulares.filter(
                                            (t) => t.key !== titular.key
                                          ),
                                          currentTitular,
                                        ];
                                      }
                                    );
                                  }}
                                  value={titular.obs_processamento}
                                  autoSize={{ minRows: 3 }}
                                  showCount
                                  maxLength={5000}
                                />
                                <br />
                                <div>
                                  <Button
                                    style={{
                                      background: "#32E6BE",
                                      color: "#333333",
                                      marginTop: "1rem",
                                    }}
                                    icon={icon}
                                    type="primary"
                                    loading={loadingTitulares}
                                    onClick={() => {
                                      setLoadingTitulares(true);
                                      setIcon(<SaveOutlined />);

                                      moduloMapeamento
                                        .saveTitulares(
                                          moduloMapeamento.currentTitulares
                                        )
                                        .then(() => {
                                          setIcon(<CheckOutlined />);
                                        })
                                        .catch(() => {
                                          setIcon(<CloseOutlined />);
                                        })
                                        .finally(() => {
                                          setLoadingTitulares(false);
                                          setShowEditProcessamento(false);
                                        });
                                    }}
                                  >
                                    Salvar
                                  </Button>
                                </div>
                              </>
                            )}
                            {!showEditProcessamento && (
                              <Button
                                style={{ marginTop: "1rem" }}
                                onClick={() => {
                                  setShowEditProcessamento(true);
                                }}
                              >
                                Editar
                              </Button>
                            )}
                            <div style={{ height: "2rem" }} />
                            {!showEditProcessamento && (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr",
                                  gap: "1rem",
                                }}
                              >
                                {Object.entries(
                                  (titular.dados as any[])?.reduce(
                                    (agrupados: any, dado) => {
                                      const chave = `${dado.frequencia}-${dado.bases_legais_comuns}-${dado.bases_legais_sensiveis}`;
                                      if (!agrupados[chave]) {
                                        agrupados[chave] = [];
                                      }
                                      agrupados[chave].push(dado);
                                      return agrupados;
                                    },
                                    {}
                                  )
                                ).map(([chave, dadosItem], index) => {
                                  const dadosArray: any = dadosItem;

                                  return (
                                    <div
                                      key={chave}
                                      style={{
                                        background: "#fff",
                                        padding: "1.5rem",
                                        borderRadius: 10,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Processamento {index + 1}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.map(
                                          (d: any) =>
                                            moduloMapeamento.dados.find(
                                              (dado: any) =>
                                                dado._id === d.dado_id
                                            )?.nome +
                                            (dadosArray.length > 1 ? " | " : "")
                                        )}
                                      </p>
                                      <br />
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Frequência do processamento
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.[0]?.frequencia}
                                      </p>
                                      <br />

                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Bases legais
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.[0]?.bases_legais_comuns}
                                        {dadosArray?.[0]?.bases_legais_comuns &&
                                        dadosArray?.[0]?.bases_legais_sensiveis
                                          ? " | " +
                                            dadosArray?.[0]
                                              ?.bases_legais_sensiveis
                                          : dadosArray?.[0]
                                              ?.bases_legais_sensiveis}
                                      </p>
                                      <br />
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {titular.finalidade_processamento &&
                                !showEditProcessamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Finalidade e Necessidade
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.finalidade_processamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={titular.finalidade_processamento}
                                    />
                                  </div>
                                )}
                              {titular.medidas_processamento &&
                                !showEditProcessamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Medidas de segurança
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.medidas_processamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={titular.medidas_processamento}
                                    />
                                    <br />
                                  </div>
                                )}
                            </div>
                            {titular.obs_processamento &&
                              !showEditProcessamento && (
                                <>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 600,
                                      color: "#5F29CC",
                                    }}
                                  >
                                    Observações
                                  </p>
                                  <TextArea
                                    disabled
                                    style={{
                                      marginTop: "0.5rem",
                                      backgroundColor: "#fff",
                                    }}
                                    onChange={(value) => {
                                      moduloMapeamento.setCurrentTitulares(
                                        (prevTitulares: Titular[]) => {
                                          let currentTitular = titular;
                                          currentTitular.obs_processamento =
                                            value.target.value;

                                          return [
                                            ...prevTitulares.filter(
                                              (t) => t.key !== titular.key
                                            ),
                                            currentTitular,
                                          ];
                                        }
                                      );
                                    }}
                                    value={titular.obs_processamento}
                                  />
                                </>
                              )}
                          </>
                        ) : (
                          <h5 style={{ fontSize: 18, color: "#3D364D" }}>
                            Nenhum dado cadastrado
                          </h5>
                        ),
                      },
                    ]}
                  />
                );
              })
          ) : (
            <h5 style={{ fontSize: 18, color: "#3D364D" }}>
              Nenhum titular cadastrado
            </h5>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <span
          onClick={() => console.log(moduloMapeamento?.currentTitulares)}
          style={{
            color: moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0] &&
                (titular.dados?.[0].areas_compartilhadas?.[0] ||
                  titular.dados?.[0].externos?.[0]) &&
                titular.dados?.[0].compartilhamento?.[0] &&
                titular.relacao_id === processo?.questionarioCapitulo?._id &&
                titular.finalidade_compartilhamento &&
                titular.medidas_compartilhamento
            )
              ? "#008C76"
              : "",
          }}
        >
          3. Compartilhamento{" "}
          {moduloMapeamento?.currentTitulares?.find(
            (titular) =>
              titular.dados?.[0] &&
              (titular.dados?.[0].areas_compartilhadas?.[0] ||
                titular.dados?.[0].externos?.[0]) &&
              titular.dados?.[0].compartilhamento?.[0] &&
              titular.relacao_id === processo?.questionarioCapitulo?._id &&
              titular.finalidade_compartilhamento &&
              titular.medidas_compartilhamento
          ) && <CheckCircleOutlined style={{ marginLeft: "0.2rem" }} />}
        </span>
      ),
      children: (
        <div className="tabs_mapeamento">
          <TitularTitle />

          {moduloMapeamento.currentTitulares ? (
            moduloMapeamento.currentTitulares
              .filter(
                (titular: Titular) =>
                  titular.relacao_id === processo?.questionarioCapitulo?._id
              )
              .map((titular: Titular) => {
                return (
                  <Collapse
                    defaultActiveKey={"1"}
                    items={[
                      {
                        key: "1",
                        extra: loadingTitulares ? (
                          <Spin size="small" />
                        ) : (
                          <Popconfirm
                            title="Você tem certeza que deseja excluir este item?"
                            onConfirm={() => handleDeleteTitular(titular)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <DeleteOutlined
                              style={{ marginRight: "1rem", cursor: "pointer" }}
                            />
                          </Popconfirm>
                        ),
                        label: (
                          <p style={{ fontSize: 18, color: "#fff" }}>
                            {`${truncateDescription(
                              moduloProcessos.areas?.find(
                                (a) =>
                                  a._id ===
                                  (processo.dependencia
                                    ? mapeamento?.capitulos?.find(
                                        (c: any) =>
                                          c._id === processo.dependencia
                                      )?.tags?.[0]
                                    : processo.tags?.[0])
                              )?.description,
                              20
                            )} ${truncateDescription(
                              moduloProcessos?.capitulos?.find(
                                (c) => c._id === processo.dependencia
                              )?.description,
                              20
                            )} > ${truncateDescription(
                              processo.description,
                              20
                            )} > ` + (titular.categoria || "Novo titular")}
                          </p>
                        ),
                        children: titular.dados?.[0] ? (
                          <>
                            {showEditCompartilhamento && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Switch
                                    onClick={(v) => {
                                      if (v && titular.dados) {
                                        setSelectedDados(titular.dados);
                                      } else {
                                        setSelectedDados(null);
                                      }
                                    }}
                                    checked={
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? true
                                        : false
                                    }
                                  />
                                  <h5 style={{ marginLeft: "0.5rem" }}>
                                    As informações de processamento se aplicam a
                                    todos os dados pessoais cadastrados?
                                  </h5>
                                </div>
                                <br />
                              </>
                            )}
                            {showEditCompartilhamento &&
                              titular.dados?.map((dado) => (
                                <Button
                                  size="small"
                                  style={{
                                    background:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )
                                          ? "#5F29CC"
                                          : "#32E6BE"
                                        : "",
                                    color:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                          ? "#fff"
                                          : "#333"
                                        : selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                        ? "#fff"
                                        : "#333",
                                    marginRight: "0.5rem",
                                    marginBottom: "0.5rem",
                                    opacity:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.frequencia &&
                                          (titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_comuns ||
                                            titular?.dados?.find(
                                              (d) => d.dado_id === dado.dado_id
                                            )?.bases_legais_sensiveis)
                                          ? 1
                                          : 0.4
                                        : 1,
                                    cursor:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? "default"
                                        : "pointer",
                                  }}
                                  icon={
                                    titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.frequencia &&
                                    (titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.bases_legais_comuns ||
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_sensiveis) ? (
                                      selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                        <DownCircleOutlined />
                                      ) : (
                                        <CheckCircleOutlined />
                                      )
                                    ) : selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                      <RightCircleOutlined />
                                    ) : (
                                      <PlusCircleOutlined />
                                    )
                                  }
                                  type={
                                    selectedDados?.find(
                                      (d: any) => d.dado_id === dado.dado_id
                                    )
                                      ? "primary"
                                      : titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.frequencia &&
                                        (titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_comuns ||
                                          titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_sensiveis)
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() => {
                                    setSelectedDados((prev) => {
                                      if (
                                        selectedDados?.find(
                                          (d: any) => d.dado_id === dado.dado_id
                                        )
                                      ) {
                                        // remove
                                        if (prev) {
                                          return [
                                            ...prev.filter(
                                              (d) => d.dado_id !== dado.dado_id
                                            ),
                                          ];
                                        } else {
                                          return null;
                                        }
                                      } else {
                                        // adiciona
                                        return [dado];
                                      }
                                    });
                                  }}
                                >
                                  {
                                    moduloMapeamento.dados?.find(
                                      (d: any) => d._id === dado.dado_id
                                    )?.nome
                                  }
                                </Button>
                              ))}
                            {selectedDados?.[0] && showEditCompartilhamento && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1rem",
                                }}
                              >
                                <div style={{ width: "45%" }}>
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color: "#fff",
                                            }}
                                          >
                                            Compartilhamento dos dados{" "}
                                            <Tooltip title="Você deve cadastrar as áreas e/ou terceiros com quem os dados pessoais são compartilhados nesta atividade">
                                              <InfoCircle
                                                size={16}
                                                style={{
                                                  marginLeft: "0.5rem",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        ),
                                        children: (
                                          <Space direction="vertical">
                                            <Button
                                              onClick={() => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        const valor =
                                                          "Departamento interno";

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          let currentCompartilhamento: any =
                                                            currentTitular
                                                              .dados[dadoIndex]
                                                              .compartilhamento?.[0]
                                                              ? currentTitular
                                                                  .dados[
                                                                  dadoIndex
                                                                ]
                                                                  .compartilhamento
                                                              : [];

                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            compartilhamento:
                                                              currentCompartilhamento.find(
                                                                (
                                                                  item: string
                                                                ) =>
                                                                  item === valor
                                                              )
                                                                ? currentCompartilhamento.filter(
                                                                    (
                                                                      item: string
                                                                    ) =>
                                                                      item !==
                                                                      valor
                                                                  )
                                                                : [
                                                                    ...currentCompartilhamento.filter(
                                                                      (
                                                                        c: string
                                                                      ) =>
                                                                        c !==
                                                                        "Não há compartilhamento"
                                                                    ),
                                                                    valor,
                                                                  ],
                                                          };
                                                        }
                                                      }
                                                    );
                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              size="small"
                                              type={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Departamento interno"
                                                    )
                                                  )
                                                  ? "primary"
                                                  : "default"
                                              }
                                              icon={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Departamento interno"
                                                    )
                                                  ) ? (
                                                  <CheckCircleFilled />
                                                ) : (
                                                  <PlusCircleOutlined />
                                                )
                                              }
                                              style={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Departamento interno"
                                                    )
                                                  )
                                                  ? {
                                                      background: "#3333FF",
                                                      color: "#fff",
                                                    }
                                                  : {}
                                              }
                                            >
                                              Departamento interno{" "}
                                              <Tooltip title="É a área da sua organização em que os dados são compartilhados">
                                                <ExclamationCircleOutlined />
                                              </Tooltip>
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        const valor =
                                                          "Pessoa jurídica externa";

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          let currentCompartilhamento: any =
                                                            currentTitular
                                                              .dados[dadoIndex]
                                                              .compartilhamento?.[0]
                                                              ? currentTitular
                                                                  .dados[
                                                                  dadoIndex
                                                                ]
                                                                  .compartilhamento
                                                              : [];

                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            compartilhamento:
                                                              currentCompartilhamento.find(
                                                                (
                                                                  item: string
                                                                ) =>
                                                                  item === valor
                                                              )
                                                                ? currentCompartilhamento.filter(
                                                                    (
                                                                      item: string
                                                                    ) =>
                                                                      item !==
                                                                      valor
                                                                  )
                                                                : [
                                                                    ...currentCompartilhamento.filter(
                                                                      (
                                                                        c: string
                                                                      ) =>
                                                                        c !==
                                                                        "Não há compartilhamento"
                                                                    ),
                                                                    valor,
                                                                  ],
                                                          };
                                                        }
                                                      }
                                                    );
                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              size="small"
                                              type={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa jurídica externa"
                                                    )
                                                  )
                                                  ? "primary"
                                                  : "default"
                                              }
                                              icon={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa jurídica externa"
                                                    )
                                                  ) ? (
                                                  <CheckCircleFilled />
                                                ) : (
                                                  <PlusCircleOutlined />
                                                )
                                              }
                                              style={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa jurídica externa"
                                                    )
                                                  )
                                                  ? {
                                                      background: "#3333FF",
                                                      color: "#fff",
                                                    }
                                                  : {}
                                              }
                                            >
                                              Pessoa jurídica externa{" "}
                                              <Tooltip title="É um terceiro PJ (fornecedor, parceiro ou prestador) com quem os dados são compartilhados">
                                                <ExclamationCircleOutlined />
                                              </Tooltip>
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        const valor =
                                                          "Pessoa física externa";

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          let currentCompartilhamento: any =
                                                            currentTitular
                                                              .dados[dadoIndex]
                                                              .compartilhamento?.[0]
                                                              ? currentTitular
                                                                  .dados[
                                                                  dadoIndex
                                                                ]
                                                                  .compartilhamento
                                                              : [];

                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            compartilhamento:
                                                              currentCompartilhamento.find(
                                                                (
                                                                  item: string
                                                                ) =>
                                                                  item === valor
                                                              )
                                                                ? currentCompartilhamento.filter(
                                                                    (
                                                                      item: string
                                                                    ) =>
                                                                      item !==
                                                                      valor
                                                                  )
                                                                : [
                                                                    ...currentCompartilhamento.filter(
                                                                      (
                                                                        c: string
                                                                      ) =>
                                                                        c !==
                                                                        "Não há compartilhamento"
                                                                    ),
                                                                    valor,
                                                                  ],
                                                          };
                                                        }
                                                      }
                                                    );
                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              size="small"
                                              type={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa física externa"
                                                    )
                                                  )
                                                  ? "primary"
                                                  : "default"
                                              }
                                              icon={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa física externa"
                                                    )
                                                  ) ? (
                                                  <CheckCircleFilled />
                                                ) : (
                                                  <PlusCircleOutlined />
                                                )
                                              }
                                              style={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Pessoa física externa"
                                                    )
                                                  )
                                                  ? {
                                                      background: "#3333FF",
                                                      color: "#fff",
                                                    }
                                                  : {}
                                              }
                                            >
                                              Pessoa física externa{" "}
                                              <Tooltip title="É um terceiro PF (fornecedor, parceiro ou prestador) com quem os dados são compartilhados">
                                                <ExclamationCircleOutlined />
                                              </Tooltip>
                                            </Button>
                                            <Button
                                              onClick={() => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        const valor =
                                                          "Não há compartilhamento";

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          let currentCompartilhamento: any =
                                                            currentTitular
                                                              .dados[dadoIndex]
                                                              .compartilhamento?.[0]
                                                              ? currentTitular
                                                                  .dados[
                                                                  dadoIndex
                                                                ]
                                                                  .compartilhamento
                                                              : [];

                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            compartilhamento:
                                                              currentCompartilhamento.includes(
                                                                "Não há compartilhamento"
                                                              )
                                                                ? []
                                                                : [valor],
                                                          };
                                                        }
                                                      }
                                                    );
                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              size="small"
                                              type={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Não há compartilhamento"
                                                    )
                                                  )
                                                  ? "primary"
                                                  : "default"
                                              }
                                              icon={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Não há compartilhamento"
                                                    )
                                                  ) ? (
                                                  <CheckCircleFilled />
                                                ) : (
                                                  <PlusCircleOutlined />
                                                )
                                              }
                                              style={
                                                titular?.dados
                                                  .filter((dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                  )
                                                  ?.find((dado) =>
                                                    dado.compartilhamento?.find(
                                                      (item: any) =>
                                                        item ===
                                                        "Não há compartilhamento"
                                                    )
                                                  )
                                                  ? {
                                                      background: "#3333FF",
                                                      color: "#fff",
                                                    }
                                                  : {}
                                              }
                                            >
                                              Não há compartilhamento{" "}
                                              <Tooltip title="Se você não compartilha dados, selecione essa opção">
                                                <ExclamationCircleOutlined />
                                              </Tooltip>
                                            </Button>
                                          </Space>
                                        ),
                                      },
                                    ]}
                                  />
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label:
                                          "Os dados serão transferidos para outro país?",
                                        children: (
                                          <>
                                            <Radio.Group
                                              onChange={(event) => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );
                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            internacional:
                                                              event.target
                                                                .value,
                                                          };
                                                        }
                                                      }
                                                    );
                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              value={
                                                titular?.dados?.find(
                                                  (d) =>
                                                    d.dado_id ===
                                                    selectedDados?.[0]?.dado_id
                                                )?.internacional
                                              }
                                            >
                                              <Space direction="vertical">
                                                <Radio value={true}>Sim</Radio>
                                                <Radio value={false}>Não</Radio>
                                              </Space>
                                            </Radio.Group>
                                            {titular?.dados?.find(
                                              (d) =>
                                                d.dado_id ===
                                                selectedDados?.[0]?.dado_id
                                            )?.internacional && (
                                              <Input
                                                style={{ marginTop: "1rem" }}
                                                onChange={(v) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      selectedDados?.forEach(
                                                        (dado) => {
                                                          const dadoIndex:
                                                            | number
                                                            | undefined =
                                                            currentTitular.dados?.findIndex(
                                                              (d) =>
                                                                d.dado_id ===
                                                                dado.dado_id
                                                            );

                                                          if (
                                                            dadoIndex !==
                                                              undefined &&
                                                            currentTitular
                                                              ?.dados?.[
                                                              dadoIndex
                                                            ]
                                                          ) {
                                                            currentTitular.dados[
                                                              dadoIndex
                                                            ] = {
                                                              ...currentTitular
                                                                .dados[
                                                                dadoIndex
                                                              ],
                                                              paises:
                                                                v.target.value,
                                                            };
                                                          }
                                                        }
                                                      );

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                placeholder="Digitar aqui os país(es)"
                                                value={
                                                  titular?.dados?.filter(
                                                    (dado: any) =>
                                                      selectedDados.find(
                                                        (d) =>
                                                          dado.dado_id ===
                                                          d.dado_id
                                                      )
                                                  )?.[0]?.paises
                                                }
                                              />
                                            )}
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                                <div style={{ width: "45%" }}>
                                  {titular?.dados
                                    .filter((dado: any) =>
                                      selectedDados.find(
                                        (d) => dado.dado_id === d.dado_id
                                      )
                                    )
                                    ?.find((dado) =>
                                      dado.compartilhamento?.find(
                                        (item: any) =>
                                          item === "Pessoa jurídica externa" ||
                                          item === "Pessoa física externa"
                                      )
                                    ) && (
                                    <Collapse
                                      defaultActiveKey={"1"}
                                      className="collapse-white-bg"
                                      items={[
                                        {
                                          key: "1",
                                          label: "Externos",
                                          children: (
                                            <>
                                              <Button
                                                onClick={() => {
                                                  setShowCreateFornecedor(true);
                                                }}
                                                type={"text"}
                                                style={{
                                                  borderColor: "#5F29CC",
                                                  color: "#5F29CC",
                                                }}
                                              >
                                                Cadastrar pessoa física ou
                                                jurídica <PlusCircleFilled />
                                              </Button>
                                              {mapeamento?.cliente_id && (
                                                <EditFornecedor
                                                  setShowAddFornecedor={
                                                    setShowCreateFornecedor
                                                  }
                                                  showAddFornecedor={
                                                    showCreateFornecedor
                                                  }
                                                  clientId={
                                                    mapeamento?.cliente_id
                                                  }
                                                  titular={titular}
                                                  selectedDados={selectedDados}
                                                />
                                              )}

                                              {fornecedores
                                                .filter((f: any) =>
                                                  titular?.dados
                                                    ?.filter((d: any) =>
                                                      selectedDados.find(
                                                        (dado) =>
                                                          dado.dado_id ===
                                                          d.dado_id
                                                      )
                                                    )
                                                    ?.map((d) => d.externos)
                                                    ?.reduce(
                                                      (acc: any, curr: any) =>
                                                        acc.concat(curr),
                                                      []
                                                    )
                                                    ?.map(
                                                      (externo: any) =>
                                                        externo?.fornecedor_id
                                                    )
                                                    ?.includes(f._id)
                                                )
                                                ?.map((fornecedor: any) => {
                                                  const currentExterno: any =
                                                    titular?.dados
                                                      ?.filter((dado: any) =>
                                                        selectedDados.find(
                                                          (d) =>
                                                            dado.dado_id ===
                                                            d.dado_id
                                                        )
                                                      )
                                                      ?.find((dado) =>
                                                        dado.compartilhamento?.find(
                                                          (item: any) =>
                                                            item ===
                                                            "Departamento interno"
                                                        )
                                                      )
                                                      ?.externos?.find(
                                                        (ext: any) =>
                                                          ext.fornecedor_id ===
                                                          fornecedor._id
                                                      );

                                                  return (
                                                    <div
                                                      style={{
                                                        marginTop: "2rem",
                                                      }}
                                                    >
                                                      <p
                                                        style={{
                                                          color: "#5F29CC",
                                                          fontSize: 16,
                                                          fontWeight: 600,
                                                        }}
                                                      >
                                                        {fornecedor.nome}
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: "#333333",
                                                          fontSize: 16,
                                                        }}
                                                      >
                                                        {fornecedor.cnpj}
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: "#333333",
                                                          fontSize: 16,
                                                        }}
                                                      >
                                                        {fornecedor.email}
                                                      </p>
                                                      <Select
                                                        size="small"
                                                        style={{
                                                          width: 240,
                                                          marginTop: "0.25rem",
                                                        }}
                                                        placeholder="Forma de compartilhamento"
                                                        value={
                                                          currentExterno?.forma
                                                        }
                                                        onChange={(
                                                          value: string
                                                        ) => {
                                                          moduloMapeamento.setCurrentTitulares(
                                                            (
                                                              prevTitulares: Titular[]
                                                            ) => {
                                                              let currentTitular =
                                                                titular;
                                                              selectedDados?.forEach(
                                                                (dado) => {
                                                                  const dadoIndex:
                                                                    | number
                                                                    | undefined =
                                                                    currentTitular.dados?.findIndex(
                                                                      (d) =>
                                                                        d.dado_id ===
                                                                        dado.dado_id
                                                                    );

                                                                  if (
                                                                    dadoIndex !==
                                                                      undefined &&
                                                                    currentTitular
                                                                      ?.dados?.[
                                                                      dadoIndex
                                                                    ]
                                                                  ) {
                                                                    let currentExternoCompartilhado: any =
                                                                      currentTitular
                                                                        .dados[
                                                                        dadoIndex
                                                                      ]
                                                                        .externos;

                                                                    currentTitular.dados[
                                                                      dadoIndex
                                                                    ] = {
                                                                      ...currentTitular
                                                                        .dados[
                                                                        dadoIndex
                                                                      ],
                                                                      externos:
                                                                        [
                                                                          ...currentExternoCompartilhado.filter(
                                                                            (
                                                                              e: any
                                                                            ) =>
                                                                              e.fornecedor_id !==
                                                                              fornecedor._id
                                                                          ),
                                                                          {
                                                                            ...currentExternoCompartilhado?.find(
                                                                              (
                                                                                e: any
                                                                              ) =>
                                                                                e.fornecedor_id ===
                                                                                fornecedor._id
                                                                            ),
                                                                            forma:
                                                                              value,
                                                                          },
                                                                        ],
                                                                    };
                                                                  }
                                                                }
                                                              );
                                                              return [
                                                                ...prevTitulares.filter(
                                                                  (t) =>
                                                                    t.key !==
                                                                    titular.key
                                                                ),
                                                                currentTitular,
                                                              ];
                                                            }
                                                          );
                                                        }}
                                                      >
                                                        <OptGroup label="Físico">
                                                          <Option value="Papel">
                                                            Papel
                                                          </Option>
                                                          <Option value="CD/DVD">
                                                            CD/DVD
                                                          </Option>
                                                          <Option value="Pen-drive">
                                                            Pen-drive
                                                          </Option>
                                                          <Option value="HD Externo">
                                                            HD Externo
                                                          </Option>
                                                          <Option value="Cartão SD">
                                                            Cartão SD
                                                          </Option>
                                                          <Option value="MicroSD">
                                                            MicroSD
                                                          </Option>
                                                        </OptGroup>
                                                        <OptGroup label="Digital">
                                                          <Option value="Drive de armazenamento em nuvem (Google Drive; Dropbox; Sharepoint; Onedrive; Icloud)">
                                                            Drive de
                                                            armazenamento em
                                                            nuvem (Google Drive;
                                                            Dropbox; Sharepoint;
                                                            Onedrive; Icloud)
                                                          </Option>
                                                          <Option value="Transferência por sistemas (STFP)">
                                                            Transferência por
                                                            sistemas (STFP)
                                                          </Option>
                                                          <Option value="Plataformas de Comunicação Licenciadas (Teams; Google Workspace ou Similiar)">
                                                            Plataformas de
                                                            Comunicação
                                                            Licenciadas (Teams;
                                                            Google Workspace ou
                                                            Similiar)
                                                          </Option>
                                                          <Option value="E-mail">
                                                            E-mail
                                                          </Option>
                                                          <Option value="Serviço de Transferência de Arquivos de Terceiros (Ex: Wetransfer)">
                                                            Serviço de
                                                            Transferência de
                                                            Arquivos de
                                                            Terceiros (Ex:
                                                            Wetransfer)
                                                          </Option>
                                                          <Option value="Redes Sociais (Facebook; Linkedin)">
                                                            Redes Sociais
                                                            (Facebook; Linkedin)
                                                          </Option>
                                                          <Option value="Aplicativo de Mensagens (WhatsApp, Telegram, WeChat)">
                                                            Aplicativo de
                                                            Mensagens (WhatsApp,
                                                            Telegram, WeChat)
                                                          </Option>
                                                        </OptGroup>
                                                      </Select>
                                                    </div>
                                                  );
                                                })}
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  )}
                                  {titular?.dados
                                    .filter((dado: any) =>
                                      selectedDados.find(
                                        (d) => dado.dado_id === d.dado_id
                                      )
                                    )
                                    ?.find((dado) =>
                                      dado.compartilhamento?.find(
                                        (item: any) =>
                                          item === "Departamento interno"
                                      )
                                    ) && (
                                    <Collapse
                                      defaultActiveKey={"1"}
                                      items={[
                                        {
                                          key: "1",
                                          label: "Áreas compartilhadas",
                                          children: (
                                            <Space direction="vertical">
                                              {mapeamento?.areas?.map(
                                                (areaId) => {
                                                  const currentFormaArea: any =
                                                    titular?.dados
                                                      ?.filter((dado: any) =>
                                                        selectedDados.find(
                                                          (d) =>
                                                            dado.dado_id ===
                                                            d.dado_id
                                                        )
                                                      )
                                                      ?.find((dado) =>
                                                        dado.areas_compartilhadas?.find(
                                                          (item: any) =>
                                                            item.area_id ===
                                                            areaId
                                                        )
                                                      )
                                                      ?.areas_compartilhadas?.find(
                                                        (ac) =>
                                                          ac.area_id === areaId
                                                      );

                                                  return (
                                                    <div
                                                      style={{
                                                        marginBottom: "1rem",
                                                      }}
                                                    >
                                                      <div>
                                                        <Button
                                                          onClick={() => {
                                                            moduloMapeamento.setCurrentTitulares(
                                                              (
                                                                prevTitulares: Titular[]
                                                              ) => {
                                                                let currentTitular =
                                                                  titular;
                                                                selectedDados?.forEach(
                                                                  (dado) => {
                                                                    const dadoIndex:
                                                                      | number
                                                                      | undefined =
                                                                      currentTitular.dados?.findIndex(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado.dado_id
                                                                      );

                                                                    if (
                                                                      dadoIndex !==
                                                                        undefined &&
                                                                      currentTitular
                                                                        ?.dados?.[
                                                                        dadoIndex
                                                                      ]
                                                                    ) {
                                                                      let currentAreaCompartilhada: any =
                                                                        currentTitular
                                                                          .dados[
                                                                          dadoIndex
                                                                        ]
                                                                          .areas_compartilhadas ||
                                                                        [];

                                                                      currentTitular.dados[
                                                                        dadoIndex
                                                                      ] = {
                                                                        ...currentTitular
                                                                          .dados[
                                                                          dadoIndex
                                                                        ],
                                                                        areas_compartilhadas:
                                                                          currentAreaCompartilhada?.find(
                                                                            (
                                                                              a: any
                                                                            ) =>
                                                                              a.area_id ===
                                                                              areaId
                                                                          )
                                                                            ? currentAreaCompartilhada.filter(
                                                                                (
                                                                                  a: any
                                                                                ) =>
                                                                                  a.area_id !==
                                                                                  areaId
                                                                              )
                                                                            : [
                                                                                ...currentAreaCompartilhada,
                                                                                {
                                                                                  area_id:
                                                                                    areaId,
                                                                                },
                                                                              ],
                                                                      };
                                                                    }
                                                                  }
                                                                );
                                                                return [
                                                                  ...prevTitulares.filter(
                                                                    (t) =>
                                                                      t.key !==
                                                                      titular.key
                                                                  ),
                                                                  currentTitular,
                                                                ];
                                                              }
                                                            );
                                                          }}
                                                          size="small"
                                                          type={
                                                            titular?.dados
                                                              ?.filter(
                                                                (dado: any) =>
                                                                  selectedDados.find(
                                                                    (d) =>
                                                                      dado.dado_id ===
                                                                      d.dado_id
                                                                  )
                                                              )
                                                              ?.find((dado) =>
                                                                dado.areas_compartilhadas?.find(
                                                                  (item: any) =>
                                                                    item.area_id ===
                                                                    areaId
                                                                )
                                                              )
                                                              ? "primary"
                                                              : "default"
                                                          }
                                                          icon={
                                                            titular?.dados
                                                              ?.filter(
                                                                (dado: any) =>
                                                                  selectedDados.find(
                                                                    (d) =>
                                                                      dado.dado_id ===
                                                                      d.dado_id
                                                                  )
                                                              )
                                                              ?.find((dado) =>
                                                                dado.areas_compartilhadas?.find(
                                                                  (item: any) =>
                                                                    item.area_id ===
                                                                    areaId
                                                                )
                                                              ) ? (
                                                              <CheckCircleFilled />
                                                            ) : (
                                                              <PlusCircleOutlined />
                                                            )
                                                          }
                                                          style={
                                                            titular?.dados
                                                              ?.filter(
                                                                (dado: any) =>
                                                                  selectedDados.find(
                                                                    (d) =>
                                                                      dado.dado_id ===
                                                                      d.dado_id
                                                                  )
                                                              )
                                                              ?.find((dado) =>
                                                                dado.areas_compartilhadas?.find(
                                                                  (item: any) =>
                                                                    item.area_id ===
                                                                    areaId
                                                                )
                                                              )
                                                              ? {
                                                                  background:
                                                                    "#3333FF",
                                                                  color: "#fff",
                                                                }
                                                              : {}
                                                          }
                                                        >
                                                          {
                                                            moduloProcessos.areas?.find(
                                                              (a) =>
                                                                a._id === areaId
                                                            )?.description
                                                          }
                                                        </Button>
                                                      </div>
                                                      <div>
                                                        {titular?.dados
                                                          ?.filter(
                                                            (dado: any) =>
                                                              selectedDados.find(
                                                                (d) =>
                                                                  dado.dado_id ===
                                                                  d.dado_id
                                                              )
                                                          )
                                                          ?.find((dado) =>
                                                            dado.areas_compartilhadas?.find(
                                                              (item: any) =>
                                                                item.area_id ===
                                                                areaId
                                                            )
                                                          ) && (
                                                          <Select
                                                            size="small"
                                                            style={{
                                                              width: 240,
                                                              marginTop:
                                                                "0.5rem",
                                                            }}
                                                            placeholder="Forma de compartilhamento"
                                                            value={
                                                              currentFormaArea?.forma
                                                            }
                                                            onChange={(
                                                              value: string
                                                            ) => {
                                                              moduloMapeamento.setCurrentTitulares(
                                                                (
                                                                  prevTitulares: Titular[]
                                                                ) => {
                                                                  let currentTitular =
                                                                    titular;
                                                                  selectedDados?.forEach(
                                                                    (dado) => {
                                                                      const dadoIndex:
                                                                        | number
                                                                        | undefined =
                                                                        currentTitular.dados?.findIndex(
                                                                          (d) =>
                                                                            d.dado_id ===
                                                                            dado.dado_id
                                                                        );

                                                                      if (
                                                                        dadoIndex !==
                                                                          undefined &&
                                                                        currentTitular
                                                                          ?.dados?.[
                                                                          dadoIndex
                                                                        ]
                                                                      ) {
                                                                        let currentAreaCompartilhada: any =
                                                                          currentTitular
                                                                            .dados[
                                                                            dadoIndex
                                                                          ]
                                                                            .areas_compartilhadas;

                                                                        currentTitular.dados[
                                                                          dadoIndex
                                                                        ] = {
                                                                          ...currentTitular
                                                                            .dados[
                                                                            dadoIndex
                                                                          ],
                                                                          areas_compartilhadas:
                                                                            [
                                                                              ...currentAreaCompartilhada.filter(
                                                                                (
                                                                                  a: any
                                                                                ) =>
                                                                                  a.area_id !==
                                                                                  areaId
                                                                              ),
                                                                              {
                                                                                ...currentAreaCompartilhada?.find(
                                                                                  (
                                                                                    a: any
                                                                                  ) =>
                                                                                    a.area_id ===
                                                                                    areaId
                                                                                ),
                                                                                forma:
                                                                                  value,
                                                                              },
                                                                            ],
                                                                        };
                                                                      }
                                                                    }
                                                                  );
                                                                  return [
                                                                    ...prevTitulares.filter(
                                                                      (t) =>
                                                                        t.key !==
                                                                        titular.key
                                                                    ),
                                                                    currentTitular,
                                                                  ];
                                                                }
                                                              );
                                                            }}
                                                          >
                                                            <OptGroup label="Físico">
                                                              <Option value="Papel">
                                                                Papel
                                                              </Option>
                                                              <Option value="CD/DVD">
                                                                CD/DVD
                                                              </Option>
                                                              <Option value="Pen-drive">
                                                                Pen-drive
                                                              </Option>
                                                              <Option value="HD Externo">
                                                                HD Externo
                                                              </Option>
                                                              <Option value="Cartão SD">
                                                                Cartão SD
                                                              </Option>
                                                              <Option value="MicroSD">
                                                                MicroSD
                                                              </Option>
                                                            </OptGroup>
                                                            <OptGroup label="Digital">
                                                              <Option value="Drive de armazenamento em nuvem (Google Drive; Dropbox; Sharepoint; Onedrive; Icloud)">
                                                                Drive de
                                                                armazenamento em
                                                                nuvem (Google
                                                                Drive; Dropbox;
                                                                Sharepoint;
                                                                Onedrive;
                                                                Icloud)
                                                              </Option>
                                                              <Option value="Transferência por sistemas (STFP)">
                                                                Transferência
                                                                por sistemas
                                                                (STFP)
                                                              </Option>
                                                              <Option value="Plataformas de Comunicação Licenciadas (Teams; Google Workspace ou Similiar)">
                                                                Plataformas de
                                                                Comunicação
                                                                Licenciadas
                                                                (Teams; Google
                                                                Workspace ou
                                                                Similiar)
                                                              </Option>
                                                              <Option value="E-mail">
                                                                E-mail
                                                              </Option>
                                                              <Option value="Serviço de Transferência de Arquivos de Terceiros (Ex: Wetransfer)">
                                                                Serviço de
                                                                Transferência de
                                                                Arquivos de
                                                                Terceiros (Ex:
                                                                Wetransfer)
                                                              </Option>
                                                              <Option value="Redes Sociais (Facebook; Linkedin)">
                                                                Redes Sociais
                                                                (Facebook;
                                                                Linkedin)
                                                              </Option>
                                                              <Option value="Aplicativo de Mensagens (WhatsApp, Telegram, WeChat)">
                                                                Aplicativo de
                                                                Mensagens
                                                                (WhatsApp,
                                                                Telegram,
                                                                WeChat)
                                                              </Option>
                                                            </OptGroup>
                                                          </Select>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </Space>
                                          ),
                                        },
                                      ]}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {showEditCompartilhamento && (
                              <>
                                <hr />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.finalidade_compartilhamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.finalidade_compartilhamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Finalidade e Necessidade{" "}
                                              <Tooltip title="Descrever por quais motivos os dados são compartilhados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.finalidade_compartilhamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.finalidade_compartilhamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.medidas_compartilhamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.medidas_compartilhamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Medidas de Segurança{" "}
                                              <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais compartilhados.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.medidas_compartilhamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.medidas_compartilhamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />

                                <p>
                                  Deixe aqui{" "}
                                  <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
                                    <ExclamationCircleOutlined />
                                  </Tooltip>
                                </p>

                                <TextArea
                                  style={{ marginTop: "0.5rem" }}
                                  onChange={(value) => {
                                    moduloMapeamento.setCurrentTitulares(
                                      (prevTitulares: Titular[]) => {
                                        let currentTitular = titular;
                                        currentTitular.obs_compartilhamento =
                                          value.target.value;

                                        return [
                                          ...prevTitulares.filter(
                                            (t) => t.key !== titular.key
                                          ),
                                          currentTitular,
                                        ];
                                      }
                                    );
                                  }}
                                  value={titular.obs_compartilhamento}
                                  autoSize={{ minRows: 3 }}
                                  showCount
                                  maxLength={5000}
                                />
                                <br />
                                <div>
                                  <Button
                                    style={{
                                      background: "#32E6BE",
                                      color: "#333333",
                                      marginTop: "1rem",
                                    }}
                                    type="primary"
                                    icon={icon}
                                    loading={loadingTitulares}
                                    onClick={() => {
                                      setLoadingTitulares(true);
                                      setIcon(<SaveOutlined />);

                                      moduloMapeamento
                                        .saveTitulares(
                                          moduloMapeamento.currentTitulares
                                        )
                                        .then(() => {
                                          setIcon(<CheckOutlined />);
                                        })
                                        .catch(() => {
                                          setIcon(<CloseOutlined />);
                                        })
                                        .finally(() => {
                                          setLoadingTitulares(false);
                                          setShowEditCompartilhamento(false);
                                        });
                                    }}
                                  >
                                    Salvar
                                  </Button>
                                </div>
                              </>
                            )}
                            {!showEditCompartilhamento && (
                              <Button
                                style={{ marginTop: "1rem" }}
                                onClick={() => {
                                  setShowEditCompartilhamento(true);
                                }}
                              >
                                Editar
                              </Button>
                            )}
                            <div style={{ height: "2rem" }} />
                            {!showEditCompartilhamento && (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr",
                                  gap: "1rem",
                                }}
                              >
                                {titular.dados?.filter((d) =>
                                  d.compartilhamento?.includes(
                                    "Não há compartilhamento"
                                  )
                                )?.[0] && (
                                  <div
                                    style={{
                                      background: "#fff",
                                      padding: "1.5rem",
                                      borderRadius: 10,
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Não há compartilhamento
                                    </p>
                                  </div>
                                )}
                                {["Departamento interno", "Externos"]
                                  .filter((compartilhamento: string) => {
                                    if (
                                      compartilhamento ===
                                      "Departamento interno"
                                    ) {
                                      if (
                                        titular.dados?.filter((d) =>
                                          d.compartilhamento?.includes(
                                            "Departamento interno"
                                          )
                                        )?.[0]
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }
                                    if (compartilhamento === "Externos") {
                                      if (
                                        titular?.dados
                                          ?.map((d) => d.externos)
                                          ?.reduce(
                                            (acc: any, curr: any) =>
                                              acc.concat(curr),
                                            []
                                          )
                                          ?.map(
                                            (externo: any) =>
                                              externo?.fornecedor_id
                                          )?.[0]
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    }
                                  })
                                  .map((compartilhamento: string) => {
                                    return (
                                      <div
                                        key={compartilhamento}
                                        style={{
                                          background: "#fff",
                                          padding: "1.5rem",
                                          borderRadius: 10,
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            color: "#5F29CC",
                                          }}
                                        >
                                          {compartilhamento}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 22,
                                            marginTop: "0.5rem",
                                          }}
                                        >
                                          {compartilhamento ===
                                            "Departamento interno" &&
                                            titular.dados
                                              ?.filter((d: any) =>
                                                d.compartilhamento?.includes(
                                                  compartilhamento
                                                )
                                              )
                                              ?.flatMap((d: any) =>
                                                d?.areas_compartilhadas?.filter(
                                                  (ac: any) => ac.area_id
                                                )
                                              )
                                              ?.reduce(
                                                (unique: any[], item: any) => {
                                                  const isDuplicate =
                                                    unique.some(
                                                      (u) =>
                                                        u.area_id ===
                                                        item.area_id
                                                    );
                                                  if (!isDuplicate) {
                                                    unique.push(item);
                                                  }
                                                  return unique;
                                                },
                                                []
                                              )
                                              .map((a: any) => (
                                                <div
                                                  onClick={() => console.log(a)}
                                                  key={a.area_id}
                                                >
                                                  {
                                                    moduloProcessos?.areas?.find(
                                                      (area: any) =>
                                                        area._id === a.area_id
                                                    )?.description
                                                  }
                                                </div>
                                              ))}
                                          {compartilhamento === "Externos" &&
                                            fornecedores
                                              .filter((f: any) =>
                                                titular?.dados
                                                  ?.map((d) => d.externos)
                                                  ?.reduce(
                                                    (acc: any, curr: any) =>
                                                      acc.concat(curr),
                                                    []
                                                  )
                                                  ?.map(
                                                    (externo: any) =>
                                                      externo?.fornecedor_id
                                                  )
                                                  ?.includes(f._id)
                                              )
                                              ?.map(
                                                (f: any) =>
                                                  f.nome +
                                                  (fornecedores.filter(
                                                    (f: any) =>
                                                      titular?.dados
                                                        ?.map((d) => d.externos)
                                                        ?.reduce(
                                                          (
                                                            acc: any,
                                                            curr: any
                                                          ) => acc.concat(curr),
                                                          []
                                                        )
                                                        ?.map(
                                                          (externo: any) =>
                                                            externo?.fornecedor_id
                                                        )
                                                        ?.includes(f._id)
                                                  )
                                                    ? fornecedores.filter(
                                                        (f: any) =>
                                                          titular?.dados
                                                            ?.map(
                                                              (d) => d.externos
                                                            )
                                                            ?.reduce(
                                                              (
                                                                acc: any,
                                                                curr: any
                                                              ) =>
                                                                acc.concat(
                                                                  curr
                                                                ),
                                                              []
                                                            )
                                                            ?.map(
                                                              (externo: any) =>
                                                                externo?.fornecedor_id
                                                            )
                                                            ?.includes(f._id)
                                                      ).length > 1
                                                      ? " | "
                                                      : ""
                                                    : "")
                                              )}
                                        </p>
                                        <br />
                                        <p
                                          style={{
                                            fontSize: 20,
                                            fontWeight: 600,
                                            color: "#5F29CC",
                                          }}
                                        >
                                          Formas de Compartilhamento
                                        </p>
                                        <p
                                          style={{
                                            fontSize: 22,
                                            marginTop: "0.5rem",
                                          }}
                                        >
                                          {compartilhamento ===
                                            "Departamento interno" &&
                                            titular.dados
                                              ?.filter((d) =>
                                                d.compartilhamento?.includes(
                                                  compartilhamento
                                                )
                                              )
                                              ?.reduce(
                                                (unique: any[], item: any) => {
                                                  const isDuplicate =
                                                    unique.some(
                                                      (u) =>
                                                        u.area_id ===
                                                        item.area_id
                                                    );
                                                  if (!isDuplicate) {
                                                    unique.push(item);
                                                  }
                                                  return unique;
                                                },
                                                []
                                              )
                                              ?.map((d) =>
                                                d?.areas_compartilhadas
                                                  ?.map((a: any) => a.forma)
                                                  ?.join(" | ")
                                              )
                                              ?.join(" | ")}
                                          {compartilhamento === "Externos" &&
                                            titular?.dados

                                              ?.map((d) =>
                                                d?.externos
                                                  ?.map((ext: any) => ext.forma)
                                                  ?.join(" | ")
                                              )
                                              ?.join(" | ")}
                                        </p>
                                        <br />
                                        <br />
                                      </div>
                                    );
                                  })}
                              </div>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {titular.finalidade_compartilhamento &&
                                !showEditCompartilhamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Finalidade e Necessidade
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.finalidade_compartilhamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={
                                        titular.finalidade_compartilhamento
                                      }
                                    />
                                  </div>
                                )}
                              {titular.medidas_compartilhamento &&
                                !showEditCompartilhamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Medidas de segurança
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.medidas_compartilhamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={titular.medidas_compartilhamento}
                                    />
                                    <br />
                                  </div>
                                )}
                            </div>

                            {titular.obs_compartilhamento &&
                              !showEditCompartilhamento && (
                                <>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 600,
                                      color: "#5F29CC",
                                    }}
                                  >
                                    Observações
                                  </p>
                                  <TextArea
                                    disabled
                                    style={{
                                      marginTop: "0.5rem",
                                      backgroundColor: "#fff",
                                    }}
                                    onChange={(value) => {
                                      moduloMapeamento.setCurrentTitulares(
                                        (prevTitulares: Titular[]) => {
                                          let currentTitular = titular;
                                          currentTitular.obs_compartilhamento =
                                            value.target.value;

                                          return [
                                            ...prevTitulares.filter(
                                              (t) => t.key !== titular.key
                                            ),
                                            currentTitular,
                                          ];
                                        }
                                      );
                                    }}
                                    value={titular.obs_compartilhamento}
                                  />
                                </>
                              )}
                          </>
                        ) : (
                          <h5 style={{ fontSize: 18, color: "#3D364D" }}>
                            Nenhum dado cadastrado
                          </h5>
                        ),
                      },
                    ]}
                  />
                );
              })
          ) : (
            <h5 style={{ fontSize: 18, color: "#3D364D" }}>
              Nenhum titular cadastrado
            </h5>
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <span
          style={{
            color: moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0] &&
                titular.dados?.[0].local?.[0] &&
                titular.dados?.[0].prazo &&
                titular.relacao_id === processo?.questionarioCapitulo?._id &&
                titular.finalidade_armazenamento &&
                titular.medidas_armazenamento
            )
              ? "#008C76"
              : "",
          }}
        >
          4. Armazenamento e Retenção{" "}
          {moduloMapeamento?.currentTitulares?.find(
            (titular) =>
              titular.dados?.[0] &&
              titular.dados?.[0].local?.[0] &&
              titular.dados?.[0].prazo &&
              titular.relacao_id === processo?.questionarioCapitulo?._id &&
              titular.finalidade_armazenamento &&
              titular.medidas_armazenamento
          ) && <CheckCircleOutlined style={{ marginLeft: "0.2rem" }} />}
        </span>
      ),
      children: (
        <div className="tabs_mapeamento">
          <TitularTitle />

          {moduloMapeamento.currentTitulares ? (
            moduloMapeamento.currentTitulares
              .filter(
                (titular: Titular) =>
                  titular.relacao_id === processo?.questionarioCapitulo?._id
              )
              .map((titular: Titular) => {
                return (
                  <Collapse
                    defaultActiveKey={"1"}
                    items={[
                      {
                        key: "1",
                        extra: loadingTitulares ? (
                          <Spin size="small" />
                        ) : (
                          <Popconfirm
                            title="Você tem certeza que deseja excluir este item?"
                            onConfirm={() => handleDeleteTitular(titular)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <DeleteOutlined
                              style={{ marginRight: "1rem", cursor: "pointer" }}
                            />
                          </Popconfirm>
                        ),
                        label: (
                          <p style={{ fontSize: 18, color: "#fff" }}>
                            {`${truncateDescription(
                              moduloProcessos.areas?.find(
                                (a) =>
                                  a._id ===
                                  (processo.dependencia
                                    ? mapeamento?.capitulos?.find(
                                        (c: any) =>
                                          c._id === processo.dependencia
                                      )?.tags?.[0]
                                    : processo.tags?.[0])
                              )?.description,
                              20
                            )} ${truncateDescription(
                              moduloProcessos?.capitulos?.find(
                                (c) => c._id === processo.dependencia
                              )?.description,
                              20
                            )} > ${truncateDescription(
                              processo.description,
                              20
                            )} > ` + (titular.categoria || "Novo titular")}
                          </p>
                        ),
                        children: titular.dados?.[0] ? (
                          <>
                            {showEditArmazenamento && (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Switch
                                    onClick={(v) => {
                                      if (v && titular.dados) {
                                        setSelectedDados(titular.dados);
                                      } else {
                                        setSelectedDados(null);
                                      }
                                    }}
                                    checked={
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? true
                                        : false
                                    }
                                  />
                                  <h5 style={{ marginLeft: "0.5rem" }}>
                                    As informações de processamento se aplicam a
                                    todos os dados pessoais cadastrados?
                                  </h5>
                                </div>
                                <br />
                              </>
                            )}

                            {showEditArmazenamento &&
                              titular.dados?.map((dado) => (
                                <Button
                                  size="small"
                                  style={{
                                    background:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )
                                          ? "#5F29CC"
                                          : "#32E6BE"
                                        : "",
                                    color:
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.frequencia &&
                                      (titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_comuns ||
                                        titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_sensiveis)
                                        ? selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                          ? "#fff"
                                          : "#333"
                                        : selectedDados?.find(
                                            (d: any) =>
                                              d.dado_id === dado.dado_id
                                          )
                                        ? "#fff"
                                        : "#333",
                                    marginRight: "0.5rem",
                                    marginBottom: "0.5rem",
                                    opacity:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.frequencia &&
                                          (titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_comuns ||
                                            titular?.dados?.find(
                                              (d) => d.dado_id === dado.dado_id
                                            )?.bases_legais_sensiveis)
                                          ? 1
                                          : 0.4
                                        : 1,
                                    cursor:
                                      selectedDados?.length ===
                                      titular.dados?.length
                                        ? "default"
                                        : "pointer",
                                  }}
                                  icon={
                                    titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.frequencia &&
                                    (titular?.dados?.find(
                                      (d) => d.dado_id === dado.dado_id
                                    )?.bases_legais_comuns ||
                                      titular?.dados?.find(
                                        (d) => d.dado_id === dado.dado_id
                                      )?.bases_legais_sensiveis) ? (
                                      selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                        <DownCircleOutlined />
                                      ) : (
                                        <CheckCircleOutlined />
                                      )
                                    ) : selectedDados?.find(
                                        (d: any) => d.dado_id === dado.dado_id
                                      ) ? (
                                      <RightCircleOutlined />
                                    ) : (
                                      <PlusCircleOutlined />
                                    )
                                  }
                                  type={
                                    selectedDados?.find(
                                      (d: any) => d.dado_id === dado.dado_id
                                    )
                                      ? "primary"
                                      : titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.frequencia &&
                                        (titular?.dados?.find(
                                          (d) => d.dado_id === dado.dado_id
                                        )?.bases_legais_comuns ||
                                          titular?.dados?.find(
                                            (d) => d.dado_id === dado.dado_id
                                          )?.bases_legais_sensiveis)
                                      ? "primary"
                                      : "default"
                                  }
                                  onClick={() => {
                                    setSelectedDados((prev) => {
                                      if (
                                        selectedDados?.find(
                                          (d: any) => d.dado_id === dado.dado_id
                                        )
                                      ) {
                                        // remove
                                        if (prev) {
                                          return [
                                            ...prev.filter(
                                              (d) => d.dado_id !== dado.dado_id
                                            ),
                                          ];
                                        } else {
                                          return null;
                                        }
                                      } else {
                                        // adiciona
                                        return [dado];
                                      }
                                    });
                                  }}
                                >
                                  {
                                    moduloMapeamento.dados?.find(
                                      (d: any) => d._id === dado.dado_id
                                    )?.nome
                                  }
                                </Button>
                              ))}

                            {selectedDados?.[0] && showEditArmazenamento && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1rem",
                                }}
                              >
                                <div style={{ width: "45%" }}>
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color: "#fff",
                                            }}
                                          >
                                            Local de armazenamento nos dados{" "}
                                            <Tooltip title="É o lugar onde os dados pessoais coletados ficam armazenados.">
                                              <InfoCircle
                                                size={16}
                                                style={{
                                                  marginLeft: "0.5rem",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        ),
                                        children: (
                                          <>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Input
                                                onChange={(v) =>
                                                  setLastInputArmazenamento(
                                                    v.target.value
                                                  )
                                                }
                                                placeholder="Adicionar local de armazenamento"
                                                value={
                                                  lastInputArmazenamento || ""
                                                }
                                              />
                                              <Button
                                                onClick={() => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      selectedDados?.forEach(
                                                        (dado) => {
                                                          const dadoIndex:
                                                            | number
                                                            | undefined =
                                                            currentTitular.dados?.findIndex(
                                                              (d) =>
                                                                d.dado_id ===
                                                                dado.dado_id
                                                            );

                                                          if (
                                                            dadoIndex !==
                                                              undefined &&
                                                            currentTitular
                                                              ?.dados?.[
                                                              dadoIndex
                                                            ]
                                                          ) {
                                                            let currentLocal: any =
                                                              currentTitular
                                                                .dados[
                                                                dadoIndex
                                                              ].local?.[0]
                                                                ? currentTitular
                                                                    .dados[
                                                                    dadoIndex
                                                                  ].local
                                                                : [];

                                                            currentTitular.dados[
                                                              dadoIndex
                                                            ] = {
                                                              ...currentTitular
                                                                .dados[
                                                                dadoIndex
                                                              ],
                                                              local: [
                                                                ...currentLocal,
                                                                lastInputArmazenamento,
                                                              ],
                                                            };
                                                          }
                                                        }
                                                      );
                                                      setLastInputArmazenamento(
                                                        null
                                                      );

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                style={{
                                                  marginLeft: "1rem",
                                                  background: "#5F29CC",
                                                }}
                                                type="primary"
                                              >
                                                Adicionar
                                              </Button>
                                            </div>
                                            <br />
                                            {titular?.dados
                                              ?.filter((d) =>
                                                selectedDados.find(
                                                  (dado) =>
                                                    dado.dado_id === d.dado_id
                                                )
                                              )
                                              ?.filter((d) => d.local?.[0])
                                              ?.map((dado) =>
                                                dado?.local
                                                  ?.filter((local) => {
                                                    if (
                                                      !uniqueDataIds.has(local)
                                                    ) {
                                                      uniqueDataIds.add(local);
                                                      return true;
                                                    }
                                                    return false;
                                                  })
                                                  ?.map((local) => (
                                                    <Tooltip title="Remover">
                                                      <div>
                                                        <Button
                                                          size="small"
                                                          style={{
                                                            background:
                                                              "#3333FF",
                                                            color: "#fff",
                                                            marginBottom:
                                                              "0.5rem",
                                                          }}
                                                          icon={
                                                            <MinusCircleFilled />
                                                          }
                                                          type="primary"
                                                          onClick={() => {
                                                            // setSelectedLocal(local);
                                                            moduloMapeamento.setCurrentTitulares(
                                                              (
                                                                prevTitulares: Titular[]
                                                              ) => {
                                                                let currentTitular =
                                                                  titular;
                                                                selectedDados?.forEach(
                                                                  (dado) => {
                                                                    const dadoIndex:
                                                                      | number
                                                                      | undefined =
                                                                      currentTitular.dados?.findIndex(
                                                                        (d) =>
                                                                          d.dado_id ===
                                                                          dado.dado_id
                                                                      );
                                                                    if (
                                                                      dadoIndex !==
                                                                        undefined &&
                                                                      currentTitular
                                                                        ?.dados?.[
                                                                        dadoIndex
                                                                      ]
                                                                    ) {
                                                                      let currentLocal: any =
                                                                        currentTitular
                                                                          .dados[
                                                                          dadoIndex
                                                                        ]
                                                                          .local?.[0]
                                                                          ? currentTitular
                                                                              .dados[
                                                                              dadoIndex
                                                                            ]
                                                                              .local
                                                                          : [];
                                                                      currentTitular.dados[
                                                                        dadoIndex
                                                                      ] = {
                                                                        ...currentTitular
                                                                          .dados[
                                                                          dadoIndex
                                                                        ],
                                                                        local:
                                                                          currentLocal.filter(
                                                                            (
                                                                              l: string
                                                                            ) =>
                                                                              l !==
                                                                              local
                                                                          ),
                                                                      };
                                                                    }
                                                                  }
                                                                );
                                                                return [
                                                                  ...prevTitulares.filter(
                                                                    (t) =>
                                                                      t.key !==
                                                                      titular.key
                                                                  ),
                                                                  currentTitular,
                                                                ];
                                                              }
                                                            );
                                                          }}
                                                        >
                                                          {local}
                                                        </Button>
                                                      </div>
                                                    </Tooltip>
                                                  ))
                                              )}
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>

                                <div style={{ width: "45%" }}>
                                  {titular?.dados
                                    ?.filter((d) =>
                                      selectedDados.find(
                                        (dado) => dado.dado_id === d.dado_id
                                      )
                                    )
                                    ?.filter((d) => d.local?.[0])?.[0] && (
                                    <Collapse
                                      defaultActiveKey={"1"}
                                      items={[
                                        {
                                          key: "1",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              Prazo de armazenamento{" "}
                                              <Tooltip title="É o periodo pelo qual os dados pessoais ficarão armaenados na sua organização.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <Radio.Group
                                                onChange={(value) => {
                                                  if (
                                                    value.target.value ===
                                                    "definido"
                                                  ) {
                                                    setShowPrazo(true);
                                                  }

                                                  if (
                                                    value.target.value ===
                                                    "indefinido"
                                                  ) {
                                                    setShowPrazo(false);

                                                    moduloMapeamento.setCurrentTitulares(
                                                      (
                                                        prevTitulares: Titular[]
                                                      ) => {
                                                        let currentTitular =
                                                          titular;
                                                        selectedDados?.forEach(
                                                          (dado) => {
                                                            const dadoIndex:
                                                              | number
                                                              | undefined =
                                                              currentTitular.dados?.findIndex(
                                                                (d) =>
                                                                  d.dado_id ===
                                                                  dado.dado_id
                                                              );

                                                            if (
                                                              dadoIndex !==
                                                                undefined &&
                                                              currentTitular
                                                                ?.dados?.[
                                                                dadoIndex
                                                              ]
                                                            ) {
                                                              currentTitular.dados[
                                                                dadoIndex
                                                              ].prazo =
                                                                "Não definido";
                                                            }
                                                          }
                                                        );

                                                        return [
                                                          ...prevTitulares.filter(
                                                            (t) =>
                                                              t.key !==
                                                              titular.key
                                                          ),
                                                          currentTitular,
                                                        ];
                                                      }
                                                    );
                                                  }
                                                }}
                                                value={
                                                  showPrazo
                                                    ? "definido"
                                                    : titular?.dados.filter(
                                                        (dado: any) =>
                                                          selectedDados.find(
                                                            (d) =>
                                                              dado.dado_id ===
                                                              d.dado_id
                                                          )
                                                      )?.[0]?.prazo ===
                                                      "Não definido"
                                                    ? "indefinido"
                                                    : null
                                                }
                                              >
                                                <Space direction="vertical">
                                                  <Radio value={"definido"}>
                                                    Prazo de armazenamento{" "}
                                                    <b>definido</b>
                                                  </Radio>
                                                  <Radio value={"indefinido"}>
                                                    Prazo de armazenamento{" "}
                                                    <b>indefinido</b>
                                                  </Radio>
                                                </Space>
                                              </Radio.Group>

                                              {(showPrazo ||
                                                (titular?.dados.filter(
                                                  (dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                )?.[0]?.prazo !==
                                                  "Não definido" &&
                                                  titular?.dados.filter(
                                                    (dado: any) =>
                                                      selectedDados.find(
                                                        (d) =>
                                                          dado.dado_id ===
                                                          d.dado_id
                                                      )
                                                  )?.[0]?.prazo)) && (
                                                <>
                                                  <p
                                                    style={{
                                                      marginBottom: "0.5rem",
                                                      marginTop: "1rem",
                                                    }}
                                                  >
                                                    Selecione
                                                  </p>
                                                  <div>
                                                    <Select
                                                      style={{ width: "100%" }}
                                                      placeholder="Período"
                                                      value={
                                                        titular?.dados.filter(
                                                          (dado: any) =>
                                                            selectedDados.find(
                                                              (d) =>
                                                                dado.dado_id ===
                                                                d.dado_id
                                                            )
                                                        )?.[0]?.prazo
                                                      }
                                                      onChange={(
                                                        value: string
                                                      ) => {
                                                        moduloMapeamento.setCurrentTitulares(
                                                          (
                                                            prevTitulares: Titular[]
                                                          ) => {
                                                            let currentTitular =
                                                              titular;
                                                            selectedDados?.forEach(
                                                              (dado) => {
                                                                const dadoIndex:
                                                                  | number
                                                                  | undefined =
                                                                  currentTitular.dados?.findIndex(
                                                                    (d) =>
                                                                      d.dado_id ===
                                                                      dado.dado_id
                                                                  );

                                                                if (
                                                                  dadoIndex !==
                                                                    undefined &&
                                                                  currentTitular
                                                                    ?.dados?.[
                                                                    dadoIndex
                                                                  ]
                                                                ) {
                                                                  const dado =
                                                                    currentTitular
                                                                      .dados[
                                                                      dadoIndex
                                                                    ];
                                                                  dado.prazo =
                                                                    value;
                                                                }
                                                              }
                                                            );

                                                            return [
                                                              ...prevTitulares.filter(
                                                                (t) =>
                                                                  t.key !==
                                                                  titular.key
                                                              ),
                                                              currentTitular,
                                                            ];
                                                          }
                                                        );
                                                      }}
                                                      options={[
                                                        {
                                                          value:
                                                            "Por prazo de guarda definido em lei ou regulamento",
                                                          label:
                                                            "Por prazo de guarda definido em lei ou regulamento",
                                                        },
                                                        {
                                                          value:
                                                            "Por prazo de guarda definido, mas sem previsão em lei ou regulamento",
                                                          label:
                                                            "Por prazo de guarda definido, mas sem previsão em lei ou regulamento",
                                                        },
                                                        {
                                                          value:
                                                            "Sem prazo de guarda definido, mas com justificativa conhecida pela organização ",
                                                          label:
                                                            "Sem prazo de guarda definido, mas com justificativa conhecida pela organização ",
                                                        },
                                                      ]}
                                                    />
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  )}
                                  <Collapse
                                    defaultActiveKey={"1"}
                                    items={[
                                      {
                                        key: "1",
                                        label: (
                                          <p
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                              color: "#fff",
                                            }}
                                          >
                                            Norma{" "}
                                            <Tooltip title="Opcional">
                                              <InfoCircle
                                                size={16}
                                                style={{
                                                  marginLeft: "0.5rem",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </Tooltip>
                                          </p>
                                        ),
                                        children: (
                                          <>
                                            <Input
                                              onChange={(v) => {
                                                moduloMapeamento.setCurrentTitulares(
                                                  (
                                                    prevTitulares: Titular[]
                                                  ) => {
                                                    let currentTitular =
                                                      titular;
                                                    selectedDados?.forEach(
                                                      (dado) => {
                                                        const dadoIndex:
                                                          | number
                                                          | undefined =
                                                          currentTitular.dados?.findIndex(
                                                            (d) =>
                                                              d.dado_id ===
                                                              dado.dado_id
                                                          );

                                                        if (
                                                          dadoIndex !==
                                                            undefined &&
                                                          currentTitular
                                                            ?.dados?.[dadoIndex]
                                                        ) {
                                                          currentTitular.dados[
                                                            dadoIndex
                                                          ] = {
                                                            ...currentTitular
                                                              .dados[dadoIndex],
                                                            norma:
                                                              v.target.value,
                                                          };
                                                        }
                                                      }
                                                    );
                                                    setLastInputArmazenamento(
                                                      null
                                                    );

                                                    return [
                                                      ...prevTitulares.filter(
                                                        (t) =>
                                                          t.key !== titular.key
                                                      ),
                                                      currentTitular,
                                                    ];
                                                  }
                                                );
                                              }}
                                              placeholder="Digite aqui a norma utilizada..."
                                              value={
                                                titular?.dados.filter(
                                                  (dado: any) =>
                                                    selectedDados.find(
                                                      (d) =>
                                                        dado.dado_id ===
                                                        d.dado_id
                                                    )
                                                )?.[0]?.norma
                                              }
                                            />
                                          </>
                                        ),
                                      },
                                    ]}
                                  />
                                </div>
                              </div>
                            )}

                            {showEditArmazenamento && (
                              <>
                                <hr />
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.finalidade_armazenamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.finalidade_armazenamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Finalidade e Necessidade{" "}
                                              <Tooltip title="Descrever por quais motivos os dados são armazenados e identificar quais dados pessoais são desnecessários para alcançar essa finalidade.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.finalidade_armazenamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.finalidade_armazenamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div style={{ width: "45%" }}>
                                    <Collapse
                                      className={
                                        titular.medidas_armazenamento
                                          ? "collapse_done"
                                          : ""
                                      }
                                      items={[
                                        {
                                          key: "4",
                                          label: (
                                            <p
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                color:
                                                  titular.medidas_armazenamento
                                                    ? "#000"
                                                    : "#fff",
                                              }}
                                            >
                                              Medidas de Segurança{" "}
                                              <Tooltip title="Indicar quais medidas garantem maior proteção aos dados pessoais armazenados.">
                                                <InfoCircle
                                                  size={16}
                                                  style={{
                                                    marginLeft: "0.5rem",
                                                    cursor: "pointer",
                                                  }}
                                                />
                                              </Tooltip>
                                            </p>
                                          ),
                                          children: (
                                            <>
                                              <TextArea
                                                style={{ marginBottom: "1rem" }}
                                                onChange={(value) => {
                                                  moduloMapeamento.setCurrentTitulares(
                                                    (
                                                      prevTitulares: Titular[]
                                                    ) => {
                                                      let currentTitular =
                                                        titular;
                                                      currentTitular.medidas_armazenamento =
                                                        value.target.value;

                                                      return [
                                                        ...prevTitulares.filter(
                                                          (t) =>
                                                            t.key !==
                                                            titular.key
                                                        ),
                                                        currentTitular,
                                                      ];
                                                    }
                                                  );
                                                }}
                                                value={
                                                  titular.medidas_armazenamento
                                                }
                                                autoSize={{ minRows: 3 }}
                                                showCount
                                                maxLength={5000}
                                              />
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </div>
                                </div>
                                <br />
                                <br />

                                <p>
                                  Deixe aqui{" "}
                                  <Tooltip title="Caso esta etapa do processo tenha alguma particularidade, deixe-a aqui">
                                    <ExclamationCircleOutlined />
                                  </Tooltip>
                                </p>

                                <TextArea
                                  style={{ marginTop: "0.5rem" }}
                                  onChange={(value) => {
                                    moduloMapeamento.setCurrentTitulares(
                                      (prevTitulares: Titular[]) => {
                                        let currentTitular = titular;
                                        currentTitular.obs_armazenamento =
                                          value.target.value;

                                        return [
                                          ...prevTitulares.filter(
                                            (t) => t.key !== titular.key
                                          ),
                                          currentTitular,
                                        ];
                                      }
                                    );
                                  }}
                                  value={titular.obs_armazenamento}
                                  autoSize={{ minRows: 3 }}
                                  showCount
                                  maxLength={5000}
                                />
                                <br />
                                <div>
                                  <Button
                                    style={{
                                      background: "#32E6BE",
                                      color: "#333333",
                                      marginTop: "1rem",
                                    }}
                                    icon={icon}
                                    type="primary"
                                    loading={loadingTitulares}
                                    onClick={() => {
                                      setLoadingTitulares(true);
                                      setIcon(<SaveOutlined />);

                                      moduloMapeamento
                                        .saveTitulares(
                                          moduloMapeamento.currentTitulares
                                        )
                                        .then(() => {
                                          setIcon(<CheckOutlined />);
                                        })
                                        .catch(() => {
                                          setIcon(<CloseOutlined />);
                                        })
                                        .finally(() => {
                                          setLoadingTitulares(false);
                                          setShowEditArmazenamento(false);
                                          showModal();
                                        });
                                    }}
                                  >
                                    Salvar
                                  </Button>
                                </div>
                              </>
                            )}

                            {!showEditArmazenamento && (
                              <Button
                                style={{ marginTop: "1rem" }}
                                onClick={() => {
                                  setShowEditArmazenamento(true);
                                }}
                              >
                                Editar
                              </Button>
                            )}
                            <div style={{ height: "2rem" }} />
                            {!showEditArmazenamento && (
                              <div
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 1fr",
                                  gap: "1rem",
                                }}
                              >
                                {Object.entries(
                                  (titular.dados as any[])?.reduce(
                                    (agrupados: any, dado) => {
                                      const chave = `${dado.frequencia}-${dado.bases_legais}`;
                                      if (!agrupados[chave]) {
                                        agrupados[chave] = [];
                                      }
                                      agrupados[chave].push(dado);
                                      return agrupados;
                                    },
                                    {}
                                  )
                                ).map(([chave, dadosItem], index) => {
                                  const dadosArray: any = dadosItem;

                                  return (
                                    <div
                                      key={chave}
                                      style={{
                                        background: "#fff",
                                        padding: "1.5rem",
                                        borderRadius: 10,
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Armazenamento {index + 1}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.map(
                                          (d: any) =>
                                            moduloMapeamento.dados.find(
                                              (dado: any) =>
                                                dado._id === d.dado_id
                                            )?.nome +
                                            (dadosArray.length > 1 ? " | " : "")
                                        )}
                                      </p>
                                      <br />
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Local de armazenamento dos dados
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.[0]?.local?.[0]
                                          ? dadosArray?.[0]?.local?.map(
                                              (local: any) =>
                                                local +
                                                (dadosArray?.[0]?.local
                                                  ? dadosArray?.[0]?.local
                                                      .length > 1
                                                    ? " | "
                                                    : ""
                                                  : "")
                                            )
                                          : "-"}
                                      </p>
                                      <br />

                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Prazo de armazenamento
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.[0]?.prazo}
                                        {/* {dadosArray?.[0]?.prazo?.final?.value +
                                      dadosArray?.[0]?.prazo?.final?.tipo
                                        ? dadosArray?.[0]?.prazo?.final?.value +
                                          " " +
                                          dadosArray?.[0]?.prazo?.final?.tipo +
                                          (dadosArray?.[0]?.prazo?.adicional
                                            ?.value +
                                          dadosArray?.[0]?.prazo?.adicional
                                            ?.tipo
                                            ? " | " +
                                              dadosArray?.[0]?.prazo?.adicional
                                                ?.value +
                                              " " +
                                              dadosArray?.[0]?.prazo?.adicional
                                                ?.tipo +
                                              " de prazo adicional"
                                            : "")
                                        : "-"} */}
                                      </p>

                                      <br />
                                      <p
                                        style={{
                                          fontSize: 20,
                                          fontWeight: 600,
                                          color: "#5F29CC",
                                        }}
                                      >
                                        Norma
                                      </p>
                                      <p
                                        style={{
                                          fontSize: 22,
                                          marginTop: "0.5rem",
                                        }}
                                      >
                                        {dadosArray?.[0]?.norma
                                          ? "Adicionada"
                                          : "Não adicionada"}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              {titular.finalidade_armazenamento &&
                                !showEditArmazenamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Finalidade e Necessidade
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.finalidade_armazenamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={titular.finalidade_armazenamento}
                                    />
                                  </div>
                                )}
                              {titular.medidas_armazenamento &&
                                !showEditArmazenamento && (
                                  <div style={{ width: "48%" }}>
                                    <br />
                                    <p
                                      style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        color: "#5F29CC",
                                      }}
                                    >
                                      Medidas de segurança
                                    </p>
                                    <TextArea
                                      disabled
                                      style={{
                                        marginTop: "0.5rem",
                                        backgroundColor: "#fff",
                                      }}
                                      onChange={(value) => {
                                        moduloMapeamento.setCurrentTitulares(
                                          (prevTitulares: Titular[]) => {
                                            let currentTitular = titular;
                                            currentTitular.medidas_armazenamento =
                                              value.target.value;

                                            return [
                                              ...prevTitulares.filter(
                                                (t) => t.key !== titular.key
                                              ),
                                              currentTitular,
                                            ];
                                          }
                                        );
                                      }}
                                      value={titular.medidas_armazenamento}
                                    />
                                    <br />
                                  </div>
                                )}
                            </div>

                            {titular.obs_armazenamento &&
                              !showEditArmazenamento && (
                                <>
                                  <br />
                                  <p
                                    style={{
                                      fontSize: 20,
                                      fontWeight: 600,
                                      color: "#5F29CC",
                                    }}
                                  >
                                    Observações
                                  </p>
                                  <TextArea
                                    disabled
                                    style={{
                                      marginTop: "0.5rem",
                                      backgroundColor: "#fff",
                                    }}
                                    onChange={(value) => {
                                      moduloMapeamento.setCurrentTitulares(
                                        (prevTitulares: Titular[]) => {
                                          let currentTitular = titular;
                                          currentTitular.obs_armazenamento =
                                            value.target.value;

                                          return [
                                            ...prevTitulares.filter(
                                              (t) => t.key !== titular.key
                                            ),
                                            currentTitular,
                                          ];
                                        }
                                      );
                                    }}
                                    value={titular.obs_armazenamento}
                                  />
                                </>
                              )}
                          </>
                        ) : (
                          <h5 style={{ fontSize: 18, color: "#3D364D" }}>
                            Nenhum dado cadastrado
                          </h5>
                        ),
                      },
                    ]}
                  />
                );
              })
          ) : (
            <h5 style={{ fontSize: 18, color: "#3D364D" }}>
              Nenhum titular cadastrado
            </h5>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <h5 style={{ color: "#A3A3A3" }}>
        <span
          onClick={() => {
            showFicha ? setShowFicha(false) : setTabProcesso(null);
          }}
          style={{ cursor: "pointer" }}
        >
          <BackSquare size={18} style={{ marginBottom: "-4px" }} /> Etapa
          anterior
        </span>
        <RightOutlined
          onClick={() => setTabProcesso(null)}
          style={{
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            fontSize: 12,
            cursor: "pointer",
          }}
        />
        {processo.dependencia && (
          <>
            <b>
              {
                moduloProcessos?.capitulos?.find(
                  (c) => c._id === processo.dependencia
                )?.description
              }
            </b>
            <RightOutlined
              style={{
                marginRight: "0.5rem",
                marginLeft: "0.5rem",
                fontSize: 12,
              }}
            />
          </>
        )}
        <b>{processo?.description}</b>
      </h5>
      {!showFicha && (
        <Tabs
          defaultActiveKey="1"
          size="large"
          items={items}
          tabBarExtraContent={
            moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0]?.frequencia &&
                (titular.dados?.[0].bases_legais_comuns ||
                  titular.dados?.[0].bases_legais_sensiveis) &&
                titular.relacao_id === processo?.questionarioCapitulo?._id
            ) &&
            moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0] &&
                (titular.dados?.[0].areas_compartilhadas?.[0] ||
                  titular.dados?.[0].externos?.[0]) &&
                titular.dados?.[0].compartilhamento?.[0] &&
                titular.relacao_id === processo?.questionarioCapitulo?._id
            ) &&
            moduloMapeamento?.currentTitulares?.find(
              (titular) =>
                titular.dados?.[0] &&
                titular.dados?.[0].local?.[0] &&
                titular.dados?.[0].prazo &&
                titular.relacao_id === processo?.questionarioCapitulo?._id
            )
              ? {
                  right: (
                    <Button
                      onClick={() => setShowFicha(true)}
                      type="text"
                      style={{ color: "#008C76" }}
                    >
                      Ir para Ficha de Processo <RightOutlined />
                    </Button>
                  ),
                }
              : {}
          }
        />
      )}

      {showFicha && (
        <Fichas
          filtrosFeitos={{
            cliente: mapeamento?.cliente_id,
            mapeamento: mapeamento?._id,
            area: processo.areas_id?.[0],
            ...(processo.dependencia
              ? {
                  processo: processo.dependencia,
                  subprocesso: processo._id,
                }
              : { processo: processo._id }),
          }}
        />
      )}

      <Modal
        open={open && !showFicha}
        title=""
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button
            style={{ backgroundColor: "#32E6BE", color: "#333333" }}
            type="primary"
            icon={<SaveOutlined />}
            onClick={() => setOpen(false)}
          >
            Salvar
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <br />
          <br />
          <CheckCircleFilled style={{ fontSize: "80px", color: "#32E6BE" }} />
          <br />
          <h2 style={{ textAlign: "center" }}>
            Parabéns!
            <br /> Você concluiu este processo.
          </h2>
          <br />
          <Button
            onClick={() => setTabProcesso(null)}
            type="text"
            style={{ color: "#008C76", marginBottom: "0.8rem" }}
          >
            Selecionar outro processo <RightOutlined />
          </Button>
          <Button
            onClick={() => {
              setShowFicha(true);
              setOpen(false);
            }}
            type="text"
            style={{ color: "#008C76" }}
          >
            Ir para Ficha de Processo <RightOutlined />
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default GuiasMapeamento;
