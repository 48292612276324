import React, { useState } from "react";
import "./styles.css";
import { Button, Input, Table } from "antd";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const { Search } = Input;

const TemplatesTarefas = () => {
  const { moduloTask } = React.useContext(GlobalContext) as GlobalContextType;

  const navigate = useNavigate();

  const [search, setSearch] = useState<string>("");

  const onSearch = (value: string) => setSearch(value);

  const columns = [
    {
      title: "Nome do modelo",
      dataIndex: "nome_conjunto",
    },
    {
      title: "Data criação",
      dataIndex: "created_at",
      render: (date: any) => (
        <p style={{ fontWeight: "bold" }}>
          {moment(new Date(date)).format("DD/MM/YYYY")}
        </p>
      ),
    },
  ];
  return (
    <div className="templates-tarefas">
      <BackHeader title="Modelos de tarefas" />
      <div className="clientes__actions">
        <Search
          placeholder="Buscar modelo"
          className="clientes__actions--search"
          allowClear
          enterButton="Buscar"
          onSearch={onSearch}
        />
        <Button
          className="clientes__actions--new"
          type="primary"
          onClick={() => navigate("/modelostarefas/novo")}
        >
          Criar modelo
        </Button>
      </div>

      <Table
        className="clientes__list"
        dataSource={moduloTask.tasks
          ?.filter((task) => !task.modelo_info && task.nome_conjunto)
          .filter((c: any) =>
            c.nome_conjunto.toUpperCase().includes(search.toUpperCase())
          )}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate("/modelostarefas/" + record._id);
            },
          };
        }}
        columns={columns}
      />
    </div>
  );
};

export default TemplatesTarefas;
