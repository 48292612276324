import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Dropdown,
  Input,
  List,
  message,
  Modal,
  Space,
  Spin,
  Tag,
  theme,
  Tooltip,
} from "antd";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContextType } from "../../../../../@types/global";
import { GlobalContext } from "../../../../../context/globalContext";
import AdicionarTarefaModal from "../Modals/AdicionarTarefaModal";
import BackHeader from "../../../../BackHeader/BackHeader";
import { InfoCircle, Task as TaskIcon } from "iconsax-react";
import Task from "../../../../Tarefas/Task/Task";
import Comments from "../../../../Comments/Comments";
import CriarTarefa from "../../../../Tarefas/CriarTarefa/CriarTarefa";
import Search from "antd/es/input/Search";
import { Reuniao } from "../types";
const { useToken } = theme;

const { Panel } = Collapse;

const MembrosPresentes: React.FC<{
  cliente_id: string;
  cppdId: string;
  reuniao: Reuniao;
}> = ({ cliente_id, cppdId, reuniao }) => {
  const [selectedMembros, setSelectedMembros] = useState<CheckboxValueType[]>(
    []
  );

  const navigate = useNavigate();

  const [membros, setMembros] = useState<any[]>([]);
  const { usuarios, atualizarReuniaoPorId, currentCliente } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [loading, setLoading] = useState(true);
  const [disabledSelectMembros, setDisabledSelectMembros] = useState(false);

  useEffect(() => {
    if (usuarios && usuarios.length > 0) {
      // Verifica se `usuarios` está carregado

      const gruposDoComite = [
        "65f1a790ff2de399bce35a8d",
        "66aa6aa01386fb3c3b13bafd",
        "66c61fe39810d75998778418",
        "66c61ff79810d75998778419",
      ];

      const usuariosComite = usuarios?.filter(
        (usr: any) =>
          usr.cliente_id === cliente_id &&
          usr.grupos
            ?.map((g: any) => g._id)
            .some((grupo: string) => gruposDoComite.includes(grupo))
      );

      setMembros(usuariosComite);
      setLoading(false); // Parar o spin quando os membros são carregados
    } else {
      console.error("Usuarios ainda não estão disponíveis");
    }
  }, [cliente_id, usuarios]);

  useEffect(() => {
    if (reuniao?.membrosPresentes?.[0]) {
      const ids = reuniao.membrosPresentes.map((membro: any) => membro._id); // Extract _id from each member
      setSelectedMembros(ids);
      setDisabledSelectMembros(true);
    }
  }, [reuniao.membrosPresentes]);

  useEffect(() => {
    !currentCliente?._id && navigate("/cppd");
  }, []);

  const handleUpdateMembros = async () => {
    try {
      // Filtrar os membros selecionados
      const membrosSelecionados = membros
        .filter((membro) => selectedMembros.includes(membro._id))
        .map((membro) => ({
          name: membro.name,
          _id: membro._id,
        }));

      if (membrosSelecionados.length === 0) {
        message.warning("Nenhum membro foi selecionado.");
        return;
      }

      // Enviar o patch request com os membros presentes
      const response = await atualizarReuniaoPorId(cppdId, reuniao._id, {
        membrosPresentes: membrosSelecionados,
      });

      // Verifique se a resposta contém dados válidos
      if (response && response.membrosPresentes) {
        message.success("Membros presentes atualizados com sucesso!");
      } else {
        throw new Error("Resposta inesperada do servidor.");
      }
    } catch (error) {
      // Captura e exibe o erro
      console.error("Erro ao atualizar os membros presentes:", error);
      message.error("Erro ao atualizar os membros presentes.");
    }
  };

  return (
    <div className="mb-6">
      <BackHeader title="Dia da reunião" prev />

      <h3 className="text-lg font-semibold mb-4">1. Membros presentes</h3>
      {loading ? (
        <Spin /> // Exibe o spin enquanto os membros estão carregando
      ) : (
        <>
          <div>
            <Checkbox.Group
              onChange={setSelectedMembros}
              value={selectedMembros}
              className="space-y-2"
              disabled={disabledSelectMembros}
            >
              <Space direction="vertical">
                {membros.map((membro) => (
                  <Checkbox key={membro._id} value={membro._id}>
                    {membro.name}{" "}
                    {membro?.grupos?.map((gp: any) => (
                      <Tag color="blue" className="Header__right--nivel">
                        {gp.name.toUpperCase()}
                      </Tag>
                    ))}
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </div>
          {!disabledSelectMembros && (
            <Button
              type="primary"
              className="mt-6"
              icon={<SaveOutlined />}
              onClick={handleUpdateMembros}
            >
              Salvar
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const OrdemDoDiaModal: React.FC<{
  reuniaoId: string;
}> = ({ reuniaoId }) => {
  const { moduloTask } = useContext(GlobalContext) as GlobalContextType;

  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">2. Ordem do dia</h3>
      <List
        dataSource={moduloTask?.tasksCliente?.filter(
          (Task) =>
            Task.tipo === "pauta" && Task.modelo_referencia_id === reuniaoId
        )}
        renderItem={(item) => (
          <div className="microTask_container" style={{ marginLeft: 0 }}>
            <Tooltip title="Ver item">
              <EyeOutlined
                style={{
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  color: "#1677ff",
                }}
                onClick={() => {
                  moduloTask?.setSelectedTask(item);
                  setIsVerPautaModalVisible(true);
                }}
              />
            </Tooltip>
            {item.title}
          </div>
        )}
      />

      <Task
        setShowModal={setIsVerPautaModalVisible}
        showModal={isVerPautaModalVisible}
      />
    </div>
  );
};

const FollowUpModal: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
}> = ({ reuniao, cliente_id }) => {
  const { moduloTask, usuarios } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    useState<boolean>(false);

  const gruposDoComite = [
    "65f1a790ff2de399bce35a8d",
    "66aa6aa01386fb3c3b13bafd",
    "66c61fe39810d75998778418",
    "66c61ff79810d75998778419",
  ];

  const usuariosComite = usuarios
    ?.filter(
      (usr: any) =>
        usr.cliente_id === cliente_id &&
        usr.grupos
          ?.map((g: any) => g._id)
          .some((grupo: string) => gruposDoComite.includes(grupo))
    )
    .map((u: any) => u._id);

  return (
    <div className="mb-6">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3
          onClick={() => console.log(reuniao)}
          className="text-lg font-semibold"
        >
          2. Itens em aberto
        </h3>

        <Tooltip title="Aguardando resolução">
          <InfoCircle
            size={18}
            style={{ marginLeft: "0.5rem", cursor: "pointer" }}
          />
        </Tooltip>
      </div>
      <br />
      {moduloTask?.tasksCliente?.filter(
        (Task) =>
          Task.tipo !== "pauta" &&
          Task.status !== "Concluída" &&
          (usuariosComite?.includes(Task.owner_id) ||
            usuariosComite?.includes(Task.assignment_id))
      )?.[0] ? (
        <List
          dataSource={moduloTask?.tasksCliente
            ?.filter(
              (Task) =>
                Task.tipo !== "pauta" &&
                Task.status !== "Concluída" &&
                (usuariosComite?.includes(Task.owner_id) ||
                  usuariosComite?.includes(Task.assignment_id)) &&
                new Date(Task.created_at) < new Date(reuniao.data_agendamento) // tarefas anteriores a reuniao
            )
            ?.reduce((accumulator: any[], task: any) => {
              if (
                !accumulator.some(
                  (existingTask) => existingTask._id === task._id
                )
              ) {
                accumulator.push(task);
              }
              return accumulator;
            }, [])}
          renderItem={(item) => (
            <div className="microTask_container" style={{ marginLeft: 0 }}>
              <Tooltip title="Ver item">
                <EyeOutlined
                  style={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    color: "#1677ff",
                  }}
                  onClick={() => {
                    moduloTask?.setSelectedTask(item);
                    setIsVerPautaModalVisible(true);
                  }}
                />
              </Tooltip>
              {item.title}
            </div>
          )}
        />
      ) : (
        <Alert type="info" message="Nenhum item encontrado" showIcon />
      )}

      <Task
        setShowModal={setIsVerPautaModalVisible}
        showModal={isVerPautaModalVisible}
      />
    </div>
  );
};

const Deliberacoes: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
}> = ({ reuniao, cliente_id }) => {
  const { moduloTask } = useContext(GlobalContext) as GlobalContextType;
  const [isVerPautaModalVisible, setIsVerPautaModalVisible] =
    useState<boolean>(false);

  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">3. Pautas</h3>
      <Collapse className="border rounded-lg">
        {moduloTask?.tasksCliente
          ?.filter(
            (Task) =>
              Task.tipo === "pauta" && Task.modelo_referencia_id === reuniao._id
          )
          ?.map((pauta: any, index: number) => {
            return (
              <Panel
                header={
                  <>
                    <b>{index + 1}. </b>
                    {pauta.title}
                  </>
                }
                key={pauta._id}
              >
                {pauta?.description?.length > 1 && (
                  <>
                    <h5>Descrição da pauta</h5>
                    <br />
                    <div
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: pauta.description,
                      }}
                    />

                    <br />
                    <br />
                  </>
                )}

                <h5>Deliberações</h5>
                {/* <br />
                <Comments tipo="pauta" task_id={pauta._id} /> */}
                <br />
                <DefinirTarefas
                  reuniao={reuniao}
                  cliente_id={cliente_id}
                  pautaId={pauta._id}
                />
              </Panel>
            );
          })}
      </Collapse>
    </div>
  );
};

const DefinirTarefas: React.FC<{
  reuniao: Reuniao;
  cliente_id: string;
  pautaId?: string;
}> = ({ reuniao, cliente_id, pautaId }) => {
  const { moduloTask, usuarios } = useContext(
    GlobalContext
  ) as GlobalContextType;

  const [isVerTarefaModalVisible, setIsVerTarefaModalVisible] =
    useState<boolean>(false);
  const [criarTarefaModalVisible, setCriarTarefaModalVisible] =
    useState<boolean>(false);

  const handleShowModalCriar = () => {
    setCriarTarefaModalVisible(!criarTarefaModalVisible);
  };

  const [searchValue, setSearchValue] = useState("");
  const [filteredUsuarios, setFilteredUsuarios] = useState([]);
  const [showModalNewUser, setShowModalNewUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = (value: any) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (usuarios) {
      setFilteredUsuarios(
        usuarios.filter((usr: any) =>
          usr.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }
  }, [searchValue, usuarios]);

  const { token } = useToken();

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: "300px",
    maxHeight: "800px",
    overflowY: "auto",
    overflowX: "hidden",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  return (
    <div className="mb-6">
      {/* <h3 className="text-lg font-semibold mb-4">3. Definir tarefas</h3> */}
      {moduloTask?.tasksCliente
        ?.filter((Task: any) =>
          moduloTask?.tasksCliente
            ?.filter(
              (Task: any) =>
                Task.tipo === "pauta" &&
                Task.modelo_referencia_id === reuniao._id
            )
            ?.map((task) => task._id)
            ?.find((id: string) => id === Task.dependencia)
        )
        ?.filter((task: any) => task.dependencia === pautaId)
        ?.reduce((accumulator: any[], task: any) => {
          if (
            !accumulator.some((existingTask) => existingTask._id === task._id)
          ) {
            accumulator.push(task);
          }
          return accumulator;
        }, [])
        ?.map((task: any, index: number) => {
          return (
            <div className="microtask_container" style={{ marginLeft: "0rem" }}>
              <Tooltip title="Ver tarefa">
                <EyeOutlined
                  onClick={() => {
                    moduloTask?.setSelectedTask(task);
                    setIsVerTarefaModalVisible(true);
                  }}
                  style={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    color: "#1677ff",
                  }}
                />
              </Tooltip>

              {/* <Tooltip title="Concluir tarefa">
                  <Checkbox>{task.title}</Checkbox>
                </Tooltip> */}
              <p style={{ maxWidth: "600px" }}>
                <b>{index + 1}.</b> {task.title}
              </p>
              <Tooltip title="Alterar atribuíção">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  {loading && (
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 16 }} spin />
                      }
                    />
                  )}
                  <Dropdown
                    placement="bottom"
                    disabled={loading}
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: "1",
                          type: "group",
                          label: "Sponsors",
                          children: usuarios
                            ?.filter((usr: any) =>
                              usr.grupos.find((g: any) => g.name === "sponsor")
                            )
                            ?.filter(
                              (usr: any) => usr.cliente_id === cliente_id
                            )
                            ?.map((usr: any) => {
                              return {
                                key: usr._id,
                                label: usr.name,
                                onClick: (v: any) => {
                                  setLoading(true);
                                  moduloTask
                                    .updateTaskAssignment(task._id, usr._id)
                                    .finally(() => setLoading(false));
                                },
                              };
                            }),
                        },
                        {
                          key: "2",
                          type: "group",
                          label: "Outros",
                          children: usuarios
                            ?.filter((usr: any) =>
                              usr.grupos.find((g: any) => g.name !== "sponsor")
                            )
                            ?.filter(
                              (usr: any) => usr.cliente_id === cliente_id
                            )
                            ?.map((usr: any) => {
                              return {
                                key: usr._id,
                                label: usr.name,
                                onClick: (v: any) => {
                                  setLoading(true);
                                  moduloTask
                                    .updateTaskAssignment(task._id, usr._id)
                                    .finally(() => setLoading(false));
                                },
                              };
                            }),
                        },
                      ],
                    }}
                    dropdownRender={(menu) => (
                      <div style={contentStyle}>
                        <Search
                          style={{
                            margin: "10px 18px",
                            width: "264px",
                          }}
                          placeholder="Buscar"
                          onSearch={handleSearch}
                          enterButton
                        />
                        <Divider style={{ margin: 0 }} />
                        {React.cloneElement(menu as React.ReactElement, {
                          style: menuStyle,
                        })}
                        <Divider style={{ margin: 0 }} />
                        <Space
                          style={{
                            padding: 8,
                            justifyContent: "flex-end",
                            display: "flex",
                          }}
                        >
                          <Button
                            type="primary"
                            icon={<UserAddOutlined />}
                            onClick={() => setShowModalNewUser(true)}
                          >
                            Novo usuário
                          </Button>
                        </Space>
                      </div>
                    )}
                  >
                    <div
                      className="microtask_user"
                      style={{ cursor: "pointer", marginLeft: "auto" }}
                    >
                      <Avatar
                        className="avatar-task"
                        src={`https://ui-avatars.com/api/?name=${task?.assignment_name?.replaceAll(
                          " ",
                          "+"
                        )}?background=random`}
                      />
                      <p onClick={() => console.log(task)}>
                        {task?.assignment_name || ""}
                      </p>
                      <EditOutlined style={{ marginLeft: "0.5rem" }} />
                    </div>
                  </Dropdown>
                </div>
              </Tooltip>
            </div>
          );
        })}
      {reuniao.status !== "Realizada" && (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "right" }}
        >
          <Button
            type="primary"
            className="mb-4"
            style={{ marginTop: "1rem" }}
            icon={<PlusOutlined />}
            onClick={handleShowModalCriar}
          >
            {pautaId ? "Adicionar deliberação" : "Adicionar tarefa"}
          </Button>
        </div>
      )}

      <CriarTarefa
        tipo="tarefa-pauta"
        handleShowModal={handleShowModalCriar}
        showModal={criarTarefaModalVisible}
        reuniaoId={reuniao._id}
        pautaId={pautaId}
      />
      <Task
        setShowModal={setIsVerTarefaModalVisible}
        showModal={isVerTarefaModalVisible}
      />
    </div>
  );
};

const Transcricao: React.FC<{
  reuniao: Reuniao;
}> = ({ reuniao }) => {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-4">4. Transcrição</h3>
      {reuniao.transcricaoAta ? (
        <div
          dangerouslySetInnerHTML={{ __html: reuniao?.transcricaoAta }}
          style={{
            whiteSpace: "pre-wrap",
          }}
        />
      ) : (
        <Alert type="info" message="Transcrição pendente" showIcon />
      )}
    </div>
  );
};

const DiaDaReuniao: React.FC = () => {
  const location = useLocation();
  const { membroId, reuniao } = location.state || {};
  const [isSavingStatus, setIsSavingStatus] = useState<boolean>(false);

  const { atualizarReuniaoPorId } = useContext(
    GlobalContext
  ) as GlobalContextType;

  // Verifica se o membroId e a reunião foram passados corretamente
  if (!membroId || !reuniao) {
    return <div>Erro: Dados de membro ou reunião não encontrados</div>;
  }

  const handleSaveReuniaoStatus = async () => {
    setIsSavingStatus(true);

    await atualizarReuniaoPorId(reuniao.cppd_id, reuniao._id, {
      status: "Realizada",
    });

    message.success("Reunião finalizada com sucesso!");

    setIsSavingStatus(false);
  };

  return (
    <div className="container mx-auto p-6">
      {reuniao.status === "Realizada" && (
        <Alert
          message="Reunião realizada"
          type="success"
          showIcon
          style={{ marginBottom: "1rem" }}
        />
      )}
      <MembrosPresentes
        cliente_id={membroId}
        cppdId={reuniao.cppd_id}
        reuniao={reuniao}
      />
      {/* <OrdemDoDiaModal reuniaoId={reuniao._id} /> */}
      <FollowUpModal reuniao={reuniao} cliente_id={membroId} />
      <Deliberacoes reuniao={reuniao} cliente_id={membroId} />
      {/* <DefinirTarefas reuniaoId={reuniao._id} cliente_id={membroId} /> */}
      <Transcricao reuniao={reuniao} />
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={handleSaveReuniaoStatus}
        loading={isSavingStatus}
        disabled={reuniao.status === "Realizada" ? true : false}
      >
        Finalizar reunião
      </Button>
    </div>
  );
};

export default DiaDaReuniao;
