import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./styles.css";
import {
  Button,
  Input,
  Upload,
  Table,
  message,
  Tag,
  Tabs,
  Dropdown,
  Alert,
  Steps,
} from "antd";
import {
  CalendarOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
  EyeOutlined,
  FileAddOutlined,
  FileDoneOutlined,
  FileExcelFilled,
  FileExcelOutlined,
  PlusOutlined,
  SearchOutlined,
  SendOutlined,
  SnippetsOutlined,
  UsergroupDeleteOutlined,
} from "@ant-design/icons";
import { GlobalContext } from "../../../../context/globalContext";
import { GlobalContextType } from "../../../../@types/global";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import InputMask from "react-input-mask";
import api from "../../../../services/api";
import moment from "moment";
import * as XLSX from "xlsx";
import ModalEditar from "./components/ModalEditar/ModalEditar";
import ModalImportar from "./components/ModalImportar/ModalImportar";
import type { MenuProps } from "antd";

const { Search } = Input;

const Fornecedores: React.FC<{ type?: string }> = ({ type }) => {
  const navigate = useNavigate();

  const {
    clientes,
    accessToken,
    getFornecedores,
    fornecedores,
    windowWidth,
    prazos,
    userInfo,
    pageSize,
    currentCliente,
    moduloProcessos,
    setCurrentCliente,
    moduloMapeamento,
  } = React.useContext(GlobalContext) as GlobalContextType;

  const [formValues, setFormValues] = useState<any>({});
  const params = useParams();
  const { id } = useParams();
  const [showFornecedores, setShowFornecedores] = useState<boolean>(false);
  const [showImport, setShowImport] = useState<boolean>(false);
  const [importFile, setImportFile] = useState<any>(null);
  const [searchNovos, setSearchNovos] = useState<string>("");
  const [searchVigentes, setSearchVigentes] = useState<string>("");
  const [searchDescontinuados, setSearchDescontinuados] = useState<string>("");

  const [importQuestionario, setImportQuestionario] = useState<boolean>(false);
  const [selectedFonecedores, setSelectedFonecedores] = useState<any>([]);

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/modelo_importar_parceiros.xlsx"
          style={{ marginLeft: "-0.5rem", color: "#1677ff" }}
          size="small"
          type="link"
          icon={<DownloadOutlined />}
        >
          Baixar modelo
        </Button>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Upload
          showUploadList={false}
          beforeUpload={(file: any) => handleImport(file)}
          accept="text/csv,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        >
          <Button
            size="small"
            style={{ marginLeft: "-2.4rem", color: "#000" }}
            type="link"
            icon={<FileExcelFilled style={{ color: "green" }} />}
          >
            Enviar XLSX
          </Button>
        </Upload>
      ),
      key: "1",
    },
  ];

  function formatarTempoRestante(dataAlvo: any) {
    const agora: any = new Date();
    const dataAlvoObj: any = new Date(dataAlvo);

    // Calcula a diferença em milissegundos
    const diferenca: any = dataAlvoObj - agora;

    // Calcula dias, horas, minutos e segundos
    const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));
    const horas = Math.floor(
      (diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutos = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
    const segundos = Math.floor((diferenca % (1000 * 60)) / 1000);

    // Formata a string de retorno
    const tempoRestanteFormatado = `${dias} dias ${String(horas).padStart(
      2,
      "0"
    )}:${String(minutos).padStart(2, "0")}:${String(segundos).padStart(
      2,
      "0"
    )}`;

    return tempoRestanteFormatado;
  }

  const handleImport = (file: any) => {
    setShowImport(true);

    const reader = new FileReader();

    if (
      file.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      reader.readAsArrayBuffer(file);
      reader.onload = (e: any) => {
        const workbook = XLSX.read(e.target.result, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        // Transforma o nome das propriedades para maiúsculas
        const newData = data.map((item: any) => {
          const newItem: any = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              newItem[key.toUpperCase()] = item[key];
            }
          }
          return newItem;
        });

        newData[0]["HORA DE INÍCIO"] && setImportQuestionario(true);
        setImportFile(newData);
      };
    } else {
      reader.onload = (e: any) => {
        convertCSVToJson(e.target.result);
      };
      reader.readAsText(file);
      const convertCSVToJson = (csvData: string) => {
        const lines = csvData.split("\n");
        let headers: any = [];
        if (lines[0].includes(",")) {
          headers = lines[0].split(",");
        }
        if (lines[0].includes(";")) {
          headers = lines[0].split(";");
        }
        const result = [];
        headers = headers.map((h: string) => h.toUpperCase());
        for (let i = 1; i < lines.filter((l: any) => l).length; i++) {
          const obj: any = {};
          let currentLine: any = [];
          if (lines[0].includes(";")) {
            currentLine = lines[i].split(";");
          } else {
            currentLine = lines[i].split(",");
          }
          for (let j = 0; j < headers.length; j++) {
            obj[headers[j].trim()] = currentLine[j].trim();
          }
          result.push(obj);
        }
        const newData = result.map((item: any) => {
          const newItem: any = {};
          for (const key in item) {
            if (Object.prototype.hasOwnProperty.call(item, key)) {
              newItem[key.toUpperCase()] = item[key];
            }
          }
          return newItem;
        });
        setImportFile(newData);
      };
    }

    return false;
  };

  const handleInputChange = (fieldName: string, value: any) => {
    setFormValues((prevValues: any) => ({ ...prevValues, [fieldName]: value }));
  };

  const showAddFornecedor = () => {
    showFornecedores ? setShowFornecedores(false) : setShowFornecedores(true);
  };

  // Fetch fornecedores and update the state
  useEffect(() => {
    type === "diagnosticos" &&
      accessToken &&
      getFornecedores("656de00ca80c7c99f88fd1fb");
  }, [accessToken]);

  useEffect(() => {
    params.id &&
      clientes?.[0] &&
      setCurrentCliente(clientes.find((cli: any) => cli._id === params.id));

    params.id &&
      clientes?.[0] &&
      !fornecedores?.find((f: any) => f.cliente_id === params.id) &&
      getFornecedores(params.id);
  }, [clientes]);

  const columns: any = [
    { title: "Razão social", dataIndex: "nome", key: "nome" },
    {
      title: "CNPJ",
      dataIndex: "cnpj",
      key: "cnpj",
      render: (cnpj: string) =>
        cnpj ? (
          <InputMask
            type="text"
            mask="99.999.999/9999-99"
            required
            value={cnpj}
            defaultValue={cnpj}
            disabled
          >
            <input className="input_dis_text" style={{ width: "10rem" }} />
          </InputMask>
        ) : (
          <Tag color="error">Sem CNPJ</Tag>
        ),
    },
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Área",
      dataIndex: "area_id",
      key: "area_id",
      filters: moduloMapeamento.currentMapeamento?.areas?.map(
        (area_id: any) => ({
          text: moduloProcessos?.areas?.find((a) => a._id === area_id)
            ?.description,
          value: area_id,
        })
      ),
      onFilter: (value: string, record: any) => record.area_id === value,
      render: (area_id: string) =>
        moduloProcessos?.areas?.find((a) => a._id === area_id)?.description ||
        "-",
    },
    {
      title: "Último questionário",
      dataIndex: "status_ultimo_questionario",
      key: "status_ultimo_questionario",
      render: (status: string) => {
        let iconColor = "";
        let text = "";
        if (status === "") {
          iconColor = "#b5b5b5";
        } else if (status === "respondido") {
          iconColor = "green";
        } else if (status === "enviado") {
          iconColor = "#fcba03";
        }

        if (!status) {
          text = "Não Enviado";
        } else if (status === "respondido") {
          text = "Questionário respondido";
        } else if (status === "enviado") {
          text = "Enviado";
        }

        return (
          <Tag color={iconColor} style={{ fontWeight: "bold" }}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "Ações",
      key: "_id",
      render: (text: any, record: any) => (
        <Button
          type="default"
          onClick={() => {
            navigate("/formularioresposta/" + params.id + "/" + record._id);
          }}
          icon={<EyeOutlined />}
        >
          Ver
        </Button>
      ),
    },
  ];

  const onSearchNovos = (value: string) => setSearchNovos(value);
  const onSearchVigentes = (value: string) => setSearchVigentes(value);
  const onSearchDescontinuados = (value: string) =>
    setSearchDescontinuados(value);

  const handleDownloadCSV = async () => {
    try {
      const response = await api.get(`/download-csv/cliente/${params.id}`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "fornecedores_questionarios.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      message.error("Erro ao gerar CSV");
    }
  };

  const novoQuestionario = async (forn: any) => {
    try {
      const requestData = {
        client_id: params.id,
        fornecedores: forn.map((fornecedor: any) => {
          return {
            fornecedor_id: fornecedor._id,
            email: fornecedor.email,
          };
        }),
      };

      message.loading("Criando questionários...");
      await api.post("/criarQuestionario", requestData).finally(() => {
        message.success("Questionários criados com sucesso!");
        sendEmailsToSuppliers(forn);
      });
    } catch (error) {
      message.error("Erro ao criar questionário para o fornecedor!");
    }
  };

  const sendEmailsToSuppliers = async (forn: any) => {
    try {
      const fornecedorData = forn.map((fornecedor: any) => ({
        fornecedor_id: fornecedor._id,
        email: fornecedor.email,
      }));

      const requestData = {
        client_id: id,
        fornecedores: fornecedorData,
        currentUser: userInfo?._id,
      };

      message.loading("Enviando questionários...");
      await api.post("/enviarEmailsFornecedor", requestData);
      params.id && getFornecedores(params.id);
      message.success("Questionários enviados com sucesso!");
    } catch (error) {
      message.error("Erro ao enviar questionários!");
      console.error("Erro enviando email para os fornecedores:", error);
    }
  };

  return (
    <div className="Fornecedores">
      {type !== "editcliente" && type !== "diagnosticos" && (
        <>
          {clientes?.length === 1 || currentCliente ? (
            <BackHeader title={"Parceiros / Fornecedores"} prev />
          ) : (
            <BackHeader title={"Parceiros / Fornecedores"} prev />
          )}
          <br />
          <h3>Dados da empresa</h3>
          <hr />
          <Table
            size="small"
            style={{ marginTop: "1rem", marginBottom: "2rem" }}
            dataSource={[
              {
                razao_social: clientes?.filter(
                  (c: any) => c._id === params?.id
                )[0]?.razao_social,
                representante: clientes?.filter(
                  (c: any) => c._id === params?.id
                )[0]?.representante,
                email: clientes?.filter((c: any) => c._id === params?.id)[0]
                  ?.email,
                telefone: clientes?.filter((c: any) => c._id === params?.id)[0]
                  ?.telefone,
              },
            ]}
            pagination={false}
            columns={[
              {
                title: "Razão social",
                dataIndex: "razao_social",
                key: "razao_social",
              },
              {
                title: "E-mail",
                dataIndex: "email",
                key: "email",
              },
              {
                title: "Telefone",
                dataIndex: "telefone",
                key: "telefone",
              },
              {
                title: "Responsável legal",
                dataIndex: "representante",
                key: "representante",
              },
            ]}
          />
        </>
      )}

      {type !== "diagnosticos" && (
        <div>
          <Button
            icon={<SnippetsOutlined />}
            type="primary"
            style={{
              marginBottom: "1rem",
              marginTop: type !== "editcliente" ? "-0.5rem" : 0,
            }}
            size={type !== "editcliente" ? "middle" : "small"}
            onClick={() => navigate(`/planoacao/parceiros/${params.id}`)}
          >
            Plano de Ação
          </Button>

          {userInfo?.grupos?.find(
            (gp: any) => gp.name === "assinante" || gp.name === "seusdados"
          ) && (
            <Button
              style={{
                marginBottom: "2rem",
                marginLeft: "2rem",
                backgroundColor: "green",
              }}
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={handleDownloadCSV}
            >
              Baixar CSV
            </Button>
          )}
        </div>
      )}

      {/* <Button
        icon={<FilePdfOutlined />}
        href="https://arquivos-portal-seusdados.nyc3.cdn.digitaloceanspaces.com/manual_acao_parceiros.pdf"
        type="primary"
        target="_blank"
        style={{ marginBottom: "1rem", marginTop: "-0.5rem" }}
      >
        Baixar manual de instruções
      </Button> */}
      {/* {prazos[0] && (
        <Steps
          current={1}
          items={[
            {
              title: "Cadastro Parceiros / Fornecedores",
              description: "This is a description.",
            },
            {
              title: "Envio do questionário",
              description: "This is a description.",
              subTitle: `Prazo ${moment(prazos[0].data_limite).format(
                "DD/MM/YYYY"
              )}`,
            },
            {
              title: "Aguardando respostas",
              description: "This is a description.",
            },
            {
              title: "Plano de Ação",
              description: "This is a description.",
            },
          ]}
        />
      )}

      <br />
      <br /> */}
      {prazos?.filter(
        (p: any) =>
          p.modulo_id === "65a141c56b48108dc9ce355e" &&
          p.sub_modulo?.sub_modulo_id === "65cf507a398102d5fd35e201" &&
          new Date(p.data_limite).getTime() < new Date().getTime() &&
          p.cliente_id &&
          (p.cliente_id === params.id || p.cliente_id === currentCliente?._id)
      )?.[0] &&
        type !== "diagnosticos" && (
          <Alert
            message={`Prazo para cadastro de fornecedores e envio de questionários expirado em ${moment(
              prazos?.find(
                (p: any) =>
                  p.modulo_id === "65a141c56b48108dc9ce355e" &&
                  p.sub_modulo?.sub_modulo_id === "65cf507a398102d5fd35e201" &&
                  new Date(p.data_limite).getTime() > new Date().getTime() &&
                  p.cliente_id === params.id
              )?.data_limite
            ).format("DD/MM/YYYY")}.`}
            type="warning"
            style={{ marginBottom: "1rem" }}
            showIcon
            action={
              <Button
                icon={<CalendarOutlined />}
                // onCanPlay={() =>
                //   (window.location.href = "mailto:comunicacao@seusdados.com")
                // }
                href="mailto:comunicacao@seusdados.com"
                size="small"
              >
                Prorrogar prazo
              </Button>
            }
          />
        )}
      {type !== "diagnosticos" ? (
        <Tabs
          defaultActiveKey={"1"}
          items={[
            FileDoneOutlined,
            FileAddOutlined,
            UsergroupDeleteOutlined,
          ].map((Icon, i) => {
            const id = String(i + 1);

            return {
              label: (
                <span>
                  <Icon style={id === "3" ? { color: "red" } : {}} />{" "}
                  {id === "1" && "Vigentes"}
                  {id === "2" && "Novos"}
                  {id === "3" && (
                    <span style={{ color: "red" }}>Descontinuados</span>
                  )}
                </span>
              ),
              key: id,
              children: (
                <div style={{ height: "fit-content" }}>
                  <div
                    style={
                      windowWidth > 800
                        ? {
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                          }
                        : {}
                    }
                  >
                    <Search
                      size={windowWidth > 800 ? "small" : "middle"}
                      placeholder="Buscar fornecedores"
                      className="clientes__actions--search"
                      style={{ marginRight: "1rem" }}
                      allowClear
                      enterButton={<SearchOutlined />}
                      onSearch={
                        id === "1"
                          ? onSearchVigentes
                          : id === "2"
                          ? onSearchNovos
                          : onSearchDescontinuados
                      }
                    />

                    {!prazos?.filter(
                      (p: any) =>
                        p.modulo_id === "65a141c56b48108dc9ce355e" &&
                        p.sub_modulo?.sub_modulo_id ===
                          "65cf507a398102d5fd35e201" &&
                        new Date(p.data_limite).getTime() <
                          new Date().getTime() &&
                        p.cliente_id === params.id
                    )[0] &&
                      id !== "3" && (
                        <div
                          style={
                            windowWidth > 800
                              ? { display: "flex" }
                              : {
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1rem",
                                }
                          }
                        >
                          <Button
                            size={windowWidth > 800 ? "small" : "middle"}
                            type="primary"
                            style={{ background: "green" }}
                            icon={<PlusOutlined />}
                            onClick={() => {
                              showAddFornecedor();
                            }}
                          >
                            Novo
                          </Button>
                          {windowWidth > 800 && (
                            <Dropdown menu={{ items }} trigger={["click"]}>
                              <Button
                                style={{ margin: "0 1rem" }}
                                size="small"
                                icon={
                                  <FileExcelFilled style={{ color: "green" }} />
                                }
                              >
                                Importar XLSX
                              </Button>
                            </Dropdown>
                          )}
                          {fornecedores
                            ?.filter((f: any) => f.cnpj && f.type)
                            .filter(
                              (f: any) =>
                                f.type === (id === "1" ? "vigente" : "novo") &&
                                f.status_ultimo_questionario !== "respondido"
                            )[0] &&
                            userInfo?.grupos?.find(
                              (gp: any) =>
                                gp.name !== "assinante" &&
                                gp.name !== "seusdados"
                            ) && (
                              <Button
                                size={windowWidth > 800 ? "small" : "middle"}
                                type="primary"
                                style={{
                                  margin: "0 1rem",
                                  background: "green",
                                }}
                                onClick={() => {
                                  setSelectedFonecedores(
                                    fornecedores
                                      ?.filter((f: any) => f.cnpj && f.type)
                                      .filter(
                                        (f: any) =>
                                          f.type ===
                                            (id === "1" ? "vigente" : "novo") &&
                                          f.status_ultimo_questionario !==
                                            "respondido"
                                      )
                                  );
                                  novoQuestionario(
                                    fornecedores
                                      ?.filter((f: any) => f.cnpj && f.type)
                                      .filter(
                                        (f: any) =>
                                          f.type ===
                                            (id === "1" ? "vigente" : "novo") &&
                                          f.status_ultimo_questionario !==
                                            "respondido"
                                      )
                                  );
                                }}
                                icon={<SendOutlined />}
                              >
                                Enviar para todos não respondidos{" "}
                                {`(${
                                  fornecedores
                                    ?.filter(
                                      (f: any) =>
                                        f.cnpj &&
                                        f.type &&
                                        f.cliente_id === params.id
                                    )
                                    .filter(
                                      (f: any) =>
                                        f.type ===
                                          (id === "1" ? "vigente" : "novo") &&
                                        f.status_ultimo_questionario !==
                                          "respondido" &&
                                        f.status_acao !== "descontinuar"
                                    )?.length
                                })`}
                              </Button>
                            )}

                          <Button
                            size={windowWidth > 800 ? "small" : "middle"}
                            type="primary"
                            onClick={() =>
                              novoQuestionario(selectedFonecedores)
                            }
                            icon={<SendOutlined />}
                            disabled={selectedFonecedores[0] ? false : true}
                          >
                            Enviar para selecionados{" "}
                            {selectedFonecedores[0] &&
                              `(${selectedFonecedores.length})`}
                          </Button>
                        </div>
                      )}
                  </div>
                  <Table
                    size="small"
                    dataSource={fornecedores
                      ?.filter((f: any) => f.cnpj && f.type)
                      .filter((f: any) => f.cliente_id === params.id)
                      .filter((c: any) =>
                        id === "3"
                          ? c.status_acao === "descontinuar"
                          : c.status_acao !== "descontinuar" &&
                            c.type === (id === "1" ? "vigente" : "novo")
                      )
                      .filter(
                        (c: any) =>
                          c.nome
                            ?.toUpperCase()
                            .includes(
                              id === "1"
                                ? searchVigentes.toUpperCase()
                                : id === "2"
                                ? searchNovos.toUpperCase()
                                : searchDescontinuados.toUpperCase()
                            ) ||
                          c.cnpj.includes(
                            id === "1"
                              ? searchVigentes.toUpperCase()
                              : id === "2"
                              ? searchNovos.toUpperCase()
                              : searchDescontinuados.toUpperCase()
                          )
                      )}
                    columns={columns}
                    rowSelection={{
                      onChange: (selectedRowKeys: any, selectedRows) => {
                        setSelectedFonecedores(selectedRows);
                      },
                    }}
                    scroll={{ x: 0 }}
                    pagination={{ pageSize }}
                  />
                </div>
              ),
            };
          })}
        />
      ) : (
        <div style={{ height: "fit-content" }}>
          <div
            style={
              windowWidth > 800
                ? {
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }
                : {}
            }
          >
            <Search
              size={windowWidth > 800 ? "small" : "middle"}
              placeholder="Buscar fornecedores"
              className="clientes__actions--search"
              style={{ marginRight: "1rem" }}
              allowClear
              enterButton={<SearchOutlined />}
              onSearch={onSearchNovos}
            />
            <div
              style={
                windowWidth > 800
                  ? { display: "flex" }
                  : {
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1rem",
                    }
              }
            >
              <Button
                size={windowWidth > 800 ? "small" : "middle"}
                type="primary"
                style={{ background: "green" }}
                icon={<PlusOutlined />}
                onClick={showAddFornecedor}
              >
                Novo
              </Button>
              {windowWidth > 800 && (
                <Dropdown menu={{ items }} trigger={["click"]}>
                  <Button
                    style={{ margin: "0 1rem" }}
                    size="small"
                    icon={<FileExcelFilled style={{ color: "green" }} />}
                  >
                    Importar XLSX
                  </Button>
                </Dropdown>
              )}
              <Button
                size={windowWidth > 800 ? "small" : "middle"}
                type="primary"
                onClick={() => novoQuestionario(selectedFonecedores)}
                icon={<SendOutlined />}
                disabled={selectedFonecedores[0] ? false : true}
              >
                Enviar para selecionados{" "}
                {selectedFonecedores[0] && `(${selectedFonecedores.length})`}
              </Button>
            </div>
          </div>
          <Table
            size="small"
            dataSource={fornecedores
              ?.filter((f: any) => f.cliente_id === "656de00ca80c7c99f88fd1fb")
              .filter((c: any) =>
                c.nome?.toUpperCase().includes(searchNovos.toUpperCase())
              )}
            columns={columns}
            rowSelection={{
              onChange: (selectedRowKeys: any, selectedRows) => {
                setSelectedFonecedores(selectedRows);
              },
            }}
            scroll={{ x: 0 }}
            pagination={{ pageSize }}
          />
        </div>
      )}

      {params.id && (
        <>
          <ModalImportar
            clientId={params.id}
            importFile={importFile}
            importQuestionario={importQuestionario}
            setImportFile={setImportFile}
            setShowImport={setShowImport}
            showImport={showImport}
          />
          {showAddFornecedor && (
            <ModalEditar
              editFornecedor={null}
              showFornecedores={showFornecedores}
              showAddFornecedor={showAddFornecedor}
              clientId={params.id}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Fornecedores;
