import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./styles.css";
import {
  Upload,
  Input,
  Radio,
  Form,
  Button,
  Tabs,
  Select,
  Row,
  Col,
  Spin,
  Alert,
} from "antd";
import Analise from "./components/analise";
import { GlobalContextType } from "../../../../@types/global";
import { GlobalContext } from "../../../../context/globalContext";
import BackHeader from "../../../../components/BackHeader/BackHeader";
import { useAuth0 } from "@auth0/auth0-react";
import api from "../../../../services/api";

const DashboardFornecedor = () => {
  const params = useParams();

  const {
    grauRiscos,
    accessToken,
    fornecedores,
    currentCliente,
    questoesFornecedores,
    getFornecedores,
  } = React.useContext(GlobalContext) as GlobalContextType;
  const { user } = useAuth0();

  const [allRespostas, setAllRespostas] = useState<any>([]);
  const [qtdRespostas, setQtdRespostas] = useState<any[]>([]);
  const [allRespostasSemDuplicatas, setAllRespostasSemDuplicatas] =
    useState<any>([]);
  const [respostasAnaliseFornecedor, setRespostasAnaliseFornecedor] =
    useState<any>(null);

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    async function fetchData() {
      if (currentCliente) {
        try {
          const response = await api.get(
            `/analisefornecedores/${currentCliente._id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                User: user?.sub,
              },
            }
          );

          setRespostasAnaliseFornecedor(response);

          setQtdRespostas([]);

          response.data.forEach((res: any) => {
            setQtdRespostas((prev: any) => [
              ...prev.filter((p: any) => p.fornecedor_id !== res.fornecedor_id),
              { fornecedor_id: res.fornecedor_id },
            ]);
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    }
    fetchData();
  }, [currentCliente]);

  useEffect(() => {
    currentCliente?._id &&
      !fornecedores?.find(
        (f: any) =>
          f.cliente_id === params.id || f.cliente_id === currentCliente._id
      ) &&
      getFornecedores(currentCliente._id);
  }, [currentCliente]);

  return (
    <div className="editAnalise">
      <BackHeader title={"Análise consolidada"} />

      {currentCliente || params.id ? (
        !questoesFornecedores ||
        !grauRiscos?.[0] ||
        !fornecedores ||
        !respostasAnaliseFornecedor ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Spin />
            <p style={{ fontSize: 14, opacity: 0.5, marginTop: "0.5rem" }}>
              Carregando todas as respostas, isso pode levar algum tempo...
            </p>
          </div>
        ) : (
          <>
            <Form
              style={{
                marginLeft: "2rem",
                marginTop: "1rem",
                marginBottom: "0rem",
              }}
            >
              <Row>
                <Col span="16"></Col>
                <Col span={1} />
                <Col
                  span="7"
                  style={{ textAlign: "right", paddingRight: "1.5rem" }}
                >
                  <Form.Item label="Respostas / Questionários / Fornecedores">
                    <p style={{ color: "#1677ff" }}>
                      <b>
                        {qtdRespostas && qtdRespostas[0]
                          ? qtdRespostas.length
                          : 0}
                      </b>{" "}
                      {respostasAnaliseFornecedor?.data?.length &&
                        " / " + respostasAnaliseFornecedor.data.length}{" "}
                      / {fornecedores?.length}
                    </p>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Analise
              questId={params.qid}
              respostas={respostasAnaliseFornecedor}
            />
          </>
        )
      ) : (
        <Alert
          type="warning"
          message="Selecione um cliente para visualizar!"
          showIcon
        />
      )}
    </div>
  );
};

export default DashboardFornecedor;
